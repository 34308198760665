import firebase from "firebase";
import "firebase/firebase";
import config from "./config";

export const firebaseApp = firebase.initializeApp(config);

export const db = firebase.firestore();

export const auth = firebase.auth();

export default firebaseApp;
