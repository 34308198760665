import React, { useState, useEffect } from "react";
import StepProgress from "../step_progress";
import StepProductConfig from "../step-product-config";
import styles from "./index.module.scss";
import { Field, Form, ErrorMessage } from "formik";
import { useFormikContext } from "formik";
import axios from "axios";

// import sizingRect from "./sizingRect.png";
// import sizingShape from "./sizingShape.png";
// import sizingCircle from "./sizingCircle.png";
import sizingRect from "./ไดคัทสี่เหลี่ยม-01.png";
import sizingShape from "./ไดคัทตามรูป-01.png";
import sizingCircle from "./ไดคัทวงกลม-01.png";

const widthHeight = [
  {
    width: 30,
    height: 30,
  },
  {
    width: 40,
    height: 40,
  },
  {
    width: 50,
    height: 50,
  },
  {
    width: 60,
    height: 60,
  },
  // {
  //   width: 70,
  //   height: 70,
  // },
  // {
  //   width: 80,
  //   height: 80,
  // },
  // {
  //   width: 90,
  //   height: 90,
  // },
];

const Order1AmountConfigComponent = (props) => {
  const [selectStep] = useState(1);
  const { values, setFieldValue, setFieldError } = useFormikContext();

  // console.log("A", values);

  useEffect(() => {
    setFieldValue("loading", true, false);
    setFieldValue("optionUnitOptions", [], false);
    // console.log("selectedMaterial", values.selectedMaterial);
    setFieldValue(
      "optionUnitOptions",
      values.selectedMaterial && values.selectedMaterial.count_list
        ? values.selectedMaterial.count_list
        : [],
      false
    );
    setFieldValue("loading", false, false);
    // axios
    //   .get(
    //     `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptions/Rf8b0x8ktshu0y0VGzyV`,
    //     {
    //       headers: {
    //         Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log("res.data[0]", res.data);
    //     if (values.selectedShape.type === "แผ่น") {
    //       setFieldValue("optionUnitOptions", res.data.count_list, false);
    //     } else if (values.selectedShape.type === "ม้วน") {
    //       setFieldValue("optionUnitOptions", res.data.count_list_type_1, false);
    //     }
    //     setFieldValue("loading", false, false);
    //   })
    //   .catch(function (err) {
    //     console.log("err", err);
    //     setFieldValue("loading", false, false);
    //   });

    return;
  }, [values.selectedShape, values.selectedMaterial]);

  useEffect(() => {
    // let fixed_cost = 0;
    // let variable_cost_1 = 0;
    // let variable_cost_2 = 0;
    // if (values.pricingType == "แผ่น") {
    //   fixed_cost =
    //     values.optionMaterial[values.material_index].coating_list[
    //       values.coat_index
    //     ].price.fixed_cost;
    //   variable_cost_1 =
    //     values.optionMaterial[values.material_index].coating_list[
    //       values.coat_index
    //     ].price.variable_cost_1;
    //   variable_cost_2 =
    //     values.optionMaterial[values.material_index].coating_list[
    //       values.coat_index
    //     ].price.variable_cost_2;
    // } else if (values.pricingType == "ม้วน") {
    //   fixed_cost =
    //     values.optionMaterial[values.material_index].coating_list[
    //       values.coat_index
    //     ].price_type_1.fixed_cost;
    //   variable_cost_1 =
    //     values.optionMaterial[values.material_index].coating_list[
    //       values.coat_index
    //     ].price_type_1.variable_cost_1;
    //   variable_cost_2 =
    //     values.optionMaterial[values.material_index].coating_list[
    //       values.coat_index
    //     ].price_type_1.variable_cost_2;
    // }

    // console.log("fixed_cost", values.fixed_cost);
    // console.log("variable_cost_1", values.variable_cost_1);
    // console.log("variable_cost_2", values.variable_cost_2);
    // if (parseInt(values.width) > 300 || parseInt(values.width) < 10) {
    //   setErrorWidth("ความกว้าง 10-305mm เท่านั้น");
    //   return;
    // }

    // if (parseInt(values.height) > 300 || parseInt(values.height) < 10) {
    //   setErrorHeight("ความสูง 10-305mm เท่านั้น");
    //   return;
    // }

    if (values.units && values.width && values.height) {
      let price = pricing(
        parseInt(values.width),
        parseInt(values.height),
        parseInt(values.units),
        parseInt(values.fixed_cost),
        parseInt(values.variable_cost_1),
        parseInt(values.variable_cost_2)
      );
      if (price) {
        setFieldValue("price", price.total_price, false);
      } else return;
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.material_index,
    values.coat_index,
    values.pricingType,
    values.width,
    values.height,
    values.units,
  ]);

  return (
    <main>
      <section className={styles.section1}>
        <StepProgress stepIndex={selectStep} />
      </section>

      <div className={styles.wrapContent}>
        <StepProductConfig />

        <section className={styles.groupOptionProductConfig}>
          <Form>
            <div className={styles.customGrid50}>
              <div>
                <h3 className={styles.titalPage}>
                  เลือกขนาด
                  <div
                    className={styles.gruop}
                    style={{ marginTop: "10px", maxWidth: "450px" }}
                  >
                    {widthHeight.map((data) => {
                      return (
                        <button type="submit" className={styles.nextButton}>
                          <article
                            className={`${styles.smallCardProductConfig}`}
                            onClick={() => {
                              setFieldValue("width", data.width, true);
                              setFieldValue("height", data.height, false);
                              setFieldValue("units", null, false);
                            }}
                          >
                            <p style={{ fontSize: "1.3rem" }}>
                              {data.width} X {data.height} mm
                            </p>
                          </article>
                        </button>
                      );
                    })}
                  </div>
                  {/* <ErrorMessage
                    name="width"
                    render={(msg) => <span className="error">{msg}</span>}
                  />
                  <ErrorMessage
                    name="height"
                    render={(msg) => <span className="error">{msg}</span>}
                  /> */}
                </h3>
                <div className={styles.sizeWrapper}>
                  <h4>หรือกําหนดขนาดเอง</h4>
                  <div className={styles.in} style={{ marginTop: "10px" }}>
                    <div style={{ margin: "0 10px 5px 0", fontSize: "1.3rem" }}>
                      W กว้าง(mm)
                    </div>
                    <div>
                      <ErrorMessage
                        name="width"
                        render={(msg) => (
                          <span className="error" style={{ fontSize: "1rem" }}>
                            {msg}
                          </span>
                        )}
                      />
                    </div>
                    <Field
                      name="width"
                      type="text"
                      placeholder="กว้าง (5 - 305)"
                      onChange={(event) => {
                        const onlyNumbers = event.target.value.replace(
                          /[^\d]/g,
                          ""
                        );
                        if (onlyNumbers > 999) setFieldValue("width", "305");
                        else setFieldValue("width", onlyNumbers);
                      }}
                      // onChange={(e) => {
                      //   setFieldError("width", "ABC");
                      //   if (e.target.value > 305) {
                      //     setFieldError("width", "ABC");
                      //     setFieldValue("width", 305);
                      //   } else if (e.target.value < 10) {
                      //     setFieldError("width", "ABC");
                      //     setFieldValue("width", 10);
                      //   } else setFieldValue("width", e.target.value);
                      // }}
                    />
                  </div>
                  <div className={styles.in}>
                    <div style={{ margin: "0 10px 5px 0", fontSize: "1.3rem" }}>
                      H สูง(mm)
                    </div>
                    <div>
                      <ErrorMessage
                        name="height"
                        render={(msg) => (
                          <span className="error" style={{ fontSize: "1rem" }}>
                            {msg}
                          </span>
                        )}
                      />
                    </div>
                    <Field
                      name="height"
                      type="text"
                      placeholder="ยาว (5 - 305)"
                      onChange={(event) => {
                        const onlyNumbers = event.target.value.replace(
                          /[^\d]/g,
                          ""
                        );
                        if (onlyNumbers > 999) setFieldValue("width", "305");
                        else setFieldValue("height", onlyNumbers);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <h3
                    className={styles.titalPage}
                    style={{ marginTop: "20px" }}
                  >
                    ตัวอย่าง
                  </h3>
                  {values.shape &&
                    [
                      "ไดคัทแบบสี่เหลี่ยม (แผ่น)",
                      "ไดคัทแบบสี่เหลี่ยม (ม้วน)",
                    ].includes(values.shape) && (
                      <>
                        <img
                          src={sizingRect}
                          style={{
                            width: "400px",
                            maxWidth: "100%",
                            borderRadius: "20px",
                          }}
                        />
                      </>
                    )}
                  {values.shape &&
                    ["ไดคัทแบบตามรูป (แผ่น)", "ไดคัทแบบตามรูป (ม้วน)"].includes(
                      values.shape
                    ) && (
                      <>
                        <img
                          src={sizingShape}
                          style={{
                            width: "400px",
                            maxWidth: "100%",
                            borderRadius: "20px",
                          }}
                        />
                      </>
                    )}
                  {values.shape &&
                    ["ไดคัทแบบวงกลม (แผ่น)", "ไดคัทแบบวงกลม (ม้วน)"].includes(
                      values.shape
                    ) && (
                      <>
                        <img
                          src={sizingCircle}
                          style={{
                            width: "400px",
                            maxWidth: "100%",
                            borderRadius: "20px",
                          }}
                        />
                      </>
                    )}
                </div>
              </div>

              <div>
                <h3 className={styles.titalPage}>
                  เลือกจำนวน
                  <ErrorMessage
                    name="units"
                    render={(msg) => (
                      <span className="error" style={{ fontSize: "1rem" }}>
                        {msg}
                      </span>
                    )}
                  />
                </h3>
                {values.width &&
                parseInt(values.width) >= 5 &&
                parseInt(values.width) <= 305 &&
                values.height &&
                parseInt(values.height) >= 5 &&
                parseInt(values.height) <= 305 ? (
                  // <div className={styles.gruop}>
                  <div className={styles.gruop1}>
                    {values.optionUnitOptions
                      .slice() // creates a shallow copy to avoid mutating the original array
                      .sort((a, b) => a - b) // sorts the array in ascending order
                      .map((data) => {
                        let price = pricing(
                          parseInt(values.width),
                          parseInt(values.height),
                          parseInt(data),
                          parseInt(values.fixed_cost),
                          parseInt(values.variable_cost_1),
                          parseInt(values.variable_cost_2)
                        );

                        return (
                          data && (
                            <>
                              <label className={styles.newLable}>
                                <button
                                  type="submit"
                                  className={styles.nextButton}
                                >
                                  <article
                                    className={`${
                                      styles.smallCardProductConfig1
                                    } ${
                                      values.units === data && styles.active
                                    }`}
                                    onClick={() => {
                                      setFieldValue("units", data, true);
                                      setFieldValue(
                                        "price",
                                        price.total_price,
                                        false
                                      );
                                    }}
                                  ></article>
                                </button>
                                <div className={styles.newGroup}>
                                  <h3>{data && data.toLocaleString()} ชิ้น</h3>
                                  <p style={{ fontSize: "1.2rem" }}>
                                    ({(price.unit_price * 1.07).toFixed(2)}{" "}
                                    บาท/ชิ้น)
                                  </p>
                                </div>
                              </label>
                            </>
                          )
                        );
                      })}
                    {/* {values.optionUnitOptions.map((data) => {
                  let price = pricing(
                    parseInt(values.width),
                    parseInt(values.height),
                    parseInt(data),
                    parseInt(values.fixed_cost),
                    parseInt(values.variable_cost_1),
                    parseInt(values.variable_cost_2)
                  );

                  return (
                    <button type="submit" className={styles.nextButton}>
                      <article
                        className={`${styles.smallCardProductConfig} ${
                          values.units === data && styles.active
                        }`}
                        onClick={() => {
                          setFieldValue("units", data, true);
                          setFieldValue("price", price.total_price, false);
                        }}
                      >
                        <h3>{data} ชิ้น</h3>
                        <p>
                          รวม {price.total_price} บาท ({price.unit_price}{" "}
                          บาท/ชิ้น)
                        </p>
                      </article>
                    </button>
                  );
                })} */}
                  </div>
                ) : (
                  "กรุณาเลือกขนาด"
                )}
              </div>
            </div>
          </Form>
        </section>
      </div>
    </main>
  );
};

export default Order1AmountConfigComponent;

function round(value, precision) {
  let multiplier = Math.pow(10, precision);
  return Math.floor(value * multiplier) / multiplier;
}

const arrayRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

function pricing(width, height, count, fixed, variable_1, variable_2) {
  // step 1 Setup
  const calculate_width = parseInt(width) + 2;
  const calculate_height = parseInt(height) + 2;

  // step 2: Artboard height
  const printing_area_width = 312;
  const printing_area_height = 980;
  const artboard_variation_start = 3; // เดิม 4 ใหม่ 3
  const artboard_variation_end = 23; // เดิม 17 ใหม่ 23
  let artboard_variation_range = arrayRange(
    artboard_variation_start,
    artboard_variation_end,
    1
  );
  // console.log(artboard_variation_range)

  const artboards_maximum_height = [];
  for (let i = artboard_variation_start; i < artboard_variation_end; i++) {
    const maximum_height = round((printing_area_height - i * 5) / i, 1);
    artboards_maximum_height.push(maximum_height);
    // console.log(maximum_height)
  }
  // console.log(artboards_maximum_height)

  // step 3: จำนวนภาพต่อรีพีท
  function calculate_repeat_qty(type) {
    if (type === 1) {
      const lens_qty = Math.floor(printing_area_width / calculate_width);
      const columns_qty = [...artboards_maximum_height].map((data) => {
        return round(data / calculate_height, 0);
      });
      const artboard_height_remain = [...columns_qty].map((data) => {
        return round(data * calculate_height, 0);
      });
      const repeat_height = [...artboard_height_remain].map((data, index) => {
        return round(
          data * artboard_variation_range[index] +
            5 * artboard_variation_range[index],
          0
        );
      });
      const repeat_maximum_qty = [...columns_qty].map((data, index) => {
        return round(data * lens_qty * artboard_variation_range[index], 0);
      });
      const maximum_repeat = Math.max(...repeat_maximum_qty);
      // console.log(lens_qty)
      // console.log(columns_qty)
      // console.log(artboard_height_remain)
      // console.log(repeat_height)
      // console.log(repeat_maximum_qty)
      // console.log(maximum_repeat)
      return maximum_repeat;
    } else {
      const lens_qty = Math.floor(printing_area_width / calculate_height);
      const columns_qty = [...artboards_maximum_height].map((data) => {
        return round(data / calculate_width, 0);
      });
      const artboard_height_remain = [...columns_qty].map((data) => {
        return round(data * calculate_width, 0);
      });
      const repeat_height = [...artboard_height_remain].map((data, index) => {
        return round(
          data * artboard_variation_range[index] +
            5 * artboard_variation_range[index],
          0
        );
      });
      const repeat_maximum_qty = [...columns_qty].map((data, index) => {
        return round(data * lens_qty * artboard_variation_range[index], 0);
      });
      const maximum_repeat = Math.max(...repeat_maximum_qty);
      // console.log(lens_qty)
      // console.log(columns_qty)
      // console.log(artboard_height_remain)
      // console.log(repeat_height)
      // console.log(repeat_maximum_qty)
      // console.log(maximum_repeat)
      return maximum_repeat;
    }
  }
  const maximum_repeat_type1 = calculate_repeat_qty(1);
  const maximum_repeat_type2 = calculate_repeat_qty(2);

  // step 4: จำนวนรีพีทเผื่อตั้งเครื่อง
  const maximum_repeat = Math.max(maximum_repeat_type1, maximum_repeat_type2);
  const printing_repeat_qty = Math.ceil(count / maximum_repeat);
  // const repeat_qty = printing_repeat_qty + 12;
  const repeat_qty = printing_repeat_qty;
  // console.log(maximum_repeat)
  // console.log(printing_repeat_qty)
  // console.log(repeat_qty)

  const total_cost = fixed + variable_1 * repeat_qty + variable_2 * repeat_qty;
  const total_price = Math.ceil(total_cost * 1);
  const unit_price = (total_price / count).toFixed(2);

  let price = {
    width: width,
    height: height,
    count: count,
    repeat: repeat_qty,
    total_price: total_price,
    unit_price: unit_price,
  };

  return price;
}

// 22 Jan 2566 update pricing
// function pricing_old(width, height, count, fixed, variable_1, variable_2) {
//   const width_dicut = width + 3;
//   const height_dicut = height + 3;

//   const cutting_1_width = 300;
//   const cutting_1_height = 242;
//   const cutting_2_width = 300;
//   const cutting_2_height = 157;

//   const cutting_1_pattern_1 =
//     Math.floor(cutting_1_width / width_dicut) *
//     Math.floor(cutting_1_height / height_dicut) *
//     4;
//   const cutting_1_pattern_2 =
//     Math.floor(cutting_1_width / height_dicut) *
//     Math.floor(cutting_1_height / width_dicut) *
//     4;
//   const cutting_2_pattern_1 =
//     Math.floor(cutting_2_width / width_dicut) *
//     Math.floor(cutting_2_height / height_dicut) *
//     4;
//   const cutting_2_pattern_2 =
//     Math.floor(cutting_2_width / height_dicut) *
//     Math.floor(cutting_2_height / width_dicut) *
//     4;

//   const max_per_meter = Math.max(
//     cutting_1_pattern_1,
//     cutting_1_pattern_2,
//     cutting_2_pattern_1,
//     cutting_2_pattern_2
//   );
//   const production_size = Math.ceil(count / max_per_meter) * 1.1;

//   const total_cost =
//     fixed + variable_1 * production_size + variable_2 * production_size;
//   const total_price = Math.ceil(total_cost * 1.25);
//   const unit_price = (total_price / count).toFixed(2);

//   let price = {
//     width: width,
//     height: height,
//     count: count,
//     total_price: total_price,
//     unit_price: unit_price,
//   };

//   return price;
// }
