// TEST `MultistepWizard`
// https://github.com/formium/formik/blob/master/examples/MultistepWizard.js
// https://medium.com/javascript-in-plain-english/how-to-create-a-multi-step-form-with-react-hooks-53a85efdff62
import React, { useEffect } from "react";

import Order1ShapeConfigComponent from "./../order-1-shape-config";
import Order1MaterialConfigComponent from "./../order-1-material-config";
import Order1CoatConfigComponent from "./../order-1-coat-config";
import Order1AmountConfigComponent from "./../order-1-amount-config";
import Order2UploadFileConfigComponent from "./../order-2-upload-file-config";
// import UploadFileComponent from "./../upload-file";
import axios from "axios";
import { withFormik, useFormikContext } from "formik";
import { auth } from "../../firebase/index.js";
import firebaseApp from "../../firebase/index.js";

import ReactGA from "react-ga";

// Wizard is a single Formik instance whose children are each page of the
// multi-step form. The form is submitted on each forward transition (can only
// progress with valid input), whereas a backwards step is allowed with
// incomplete data. A snapshot of form state is used as initialValues after each
// transition. Each page has an optional submit handler, and the top-level
// submit is called when the final page is submitted.
const Wizard = ({ children, initialValues, onSubmit }) => {
  const { values, setFieldValue } = useFormikContext();

  const steps = React.Children.toArray(children);
  const step = steps[values.stepProgress];

  // Fetch all shapes
  useEffect(() => {
    setFieldValue("loadingShape", true, false);
    axios
      .get(
        // `http://127.0.0.1:5001/digitalwish-sticker/asia-east2/productOptionsNew/`,
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("optionShape", res.data);
        setFieldValue("optionShape", res.data, false);
        // setFieldValue("optionMaterial", [], false);
        // setFieldValue("optionCoating", [], false);
        // setFieldValue("selectedMaterial", "", false);
        // setFieldValue("selectedCoating", "", false);
        // setFieldValue("pricingSetting", "", false);
        setFieldValue("fetch", false, false);
        setFieldValue("loadingShape", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loadingShape", false, false);
      });
  }, [values.fetch]);

  // Fetch shape > material
  useEffect(() => {
    setFieldValue("loadingMaterial", true, false);
    const selectedShapeId = values.selectedShape && values.selectedShape.myID;
    if (!selectedShapeId) {
      setFieldValue("loadingMaterial", false, false);
      return;
    }
    axios
      .get(
        // `http://127.0.0.1:5001/digitalwish-sticker/asia-east2/productOptionsNew/${selectedShapeId}/material`,
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${selectedShapeId}/material`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("optionMaterial", res.data);
        setFieldValue("optionMaterial", res.data, false);
        // setFieldValue("optionCoating", [], false);
        // setFieldValue("selectedCoating", "", false);
        // setFieldValue("pricingSetting", "", false);
        setFieldValue("fetch", false, false);
        setFieldValue("loadingMaterial", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loadingMaterial", false, false);
      });
  }, [values.fetch, values.selectedShape]);

  // Fetch shape > material > coating
  useEffect(() => {
    setFieldValue("loadingCoating", true, false);
    const selectedShapeId = values.selectedShape && values.selectedShape.myID;
    const selectedMaterialId =
      values.selectedMaterial && values.selectedMaterial.myID;
    if (!selectedShapeId || !selectedMaterialId) {
      setFieldValue("loadingCoating", false, false);
      return;
    }
    axios
      .get(
        // `http://127.0.0.1:5001/digitalwish-sticker/asia-east2/productOptionsNew/${selectedShapeId}/${selectedMaterialId}/coating/`,
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${selectedShapeId}/${selectedMaterialId}/coating/`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("optionCoating", res.data);
        setFieldValue("optionCoating", res.data, false);
        if (res.data.length === 1) {
          let data = res.data[0];
          setFieldValue("selectedCoating", data, false);
          setFieldValue("coat", data.name, true);
          setFieldValue("fixed_cost", data.price.fixed_cost, true);
          setFieldValue("variable_cost_1", data.price.variable_cost_1, true);
          setFieldValue("variable_cost_2", data.price.variable_cost_2, true);
          setFieldValue("stepProgress", 3, true);
        }
        setFieldValue("fetch", false, false);
        setFieldValue("loadingCoating", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loadingCoating", false, false);
      });
  }, [values.fetch, values.selectedShape, values.selectedMaterial]);

  return <>{step}</>;
};

const WizardStep = ({ children }) => children;

const AppComponent = () => {
  useEffect(() => {
    ReactGA.pageview("/customize");
  }, []);

  const { values } = useFormikContext();
  return (
    <>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>
      <Wizard>
        <WizardStep>
          <Order1ShapeConfigComponent />
        </WizardStep>
        <WizardStep>
          <Order1MaterialConfigComponent />
        </WizardStep>
        <WizardStep>
          <Order1CoatConfigComponent />
        </WizardStep>
        <WizardStep>
          <Order1AmountConfigComponent />
        </WizardStep>
        <WizardStep>
          <Order2UploadFileConfigComponent />
        </WizardStep>
      </Wizard>
    </>
  );
};

const EnhancedAppComponent = withFormik({
  mapPropsToValues: (props) => ({
    showImageUrl:
      "https://firebasestorage.googleapis.com/v0/b/digitalwish-sticker.appspot.com/o/original.png?alt=media&token=05a8becb-86a4-4f0c-bd71-d57bb9a82c2b",

    checkLoadOption: false,
    optionShape: [],
    optionMaterial: [],
    optionCoat: [],
    optionCuttingList: [],
    optionUnitOptions: [],

    stepProgress: 0,
    // Step one
    shape: 0,
    material: 0,
    coat: 0,
    shape_index: 0,
    material_index: 0,
    coat_index: 0,
    cutting: 0,
    width: "",
    height: "",
    units: 0,
    price: 0,

    fixed_cost: 0,
    variable_cost_1: 0,
    variable_cost_2: 0,

    extraDeliveryDay: 0,

    // Step two
    approvalStricker: "รออนุมัติแบบ",
    isCheckUploadFileStricker: 0,
    uploadFileStricker: "",
    uploadFileStrickerForFirebase: [],
    comment: "",
    loading: false,
  }),
  validate: (values, { setFieldValue }) => {
    const errors = {};

    // Step 1
    if (values.stepProgress === 0) {
      if (!values.shape) {
        errors.shape = "*กรุณาระบุ";
      }
    }
    // Step 2
    if (values.stepProgress === 1) {
      if (!values.material) {
        errors.material = "*กรุณาระบุ";
      }
    }
    // Step 3
    if (values.stepProgress === 2) {
      if (!values.coat) {
        errors.coat = "*กรุณาระบุ";
      }
    }
    // Step 4
    if (values.stepProgress === 3) {
      // if (
      //   typeof parseInt(values.width) === "number" &&
      //   !isNaN(parseInt(values.width)) &&
      //   Number.isFinite(parseInt(values.width))
      // ) {
      //   if (parseInt(values.width) < 5 || parseInt(values.width) > 305)
      //     errors.width = "10-305mm เท่านั้น";
      // } else {
      //   errors.width = "*กรุณาระบุตัวเลข";
      // }
      if (!values.width) {
        errors.width = "*กรุณาระบุตัวเลข";
      } else {
        if (parseInt(values.width) < 5 || parseInt(values.width) > 305)
          errors.width = "5-305mm เท่านั้น";
      }
      if (!values.height) {
        errors.height = "*กรุณาระบุตัวเลข";
      } else {
        if (parseInt(values.height) < 5 || parseInt(values.height) > 305)
          errors.height = "5-305mm เท่านั้น";
      }
      if (!values.units) {
        errors.units = "*กรุณาระบุ";
      }
    }
    // Step 5
    if (values.stepProgress === 4) {
      if (!values.uploadFileStricker) {
        errors.uploadFileStricker = "*กรุณาระบุ";
      }
    }

    return errors;
  },
  handleSubmit: (values, { setFieldValue, props }) => {
    if (values.stepProgress === 0) {
      setFieldValue("stepProgress", 1, false);
    } else if (values.stepProgress === 1) {
      setFieldValue("stepProgress", 2, false);
    } else if (values.stepProgress === 2) {
      setFieldValue("stepProgress", 3, false);
    } else if (values.stepProgress === 3) {
      setFieldValue("stepProgress", 4, false);
    } else {
      const storageRef = firebaseApp.storage().ref();
      let timeStamp = new Date().toISOString().slice(0, 10);
      setFieldValue("loading", true, false);
      auth.onAuthStateChanged((user) => {
        if (user) {
          // User is signed in.

          // FETCH CART CHECK ITEM
          axios
            .get(
              `https://asia-east2-digitalwish-sticker.cloudfunctions.net/cart?customerID=${user.uid}`,
              {
                headers: {
                  Authorization:
                    "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                },
              }
            )
            .then((res) => {
              console.log("res.data[0].myID", res.data[0]);

              // API PUT IF HAVE ORDER IN
              if (res.data.length) {
                console.log("have item");

                storageRef
                  .child(
                    `${user.uid}/${timeStamp}-${values.uploadFileStrickerForFirebase.name}`
                  )
                  .put(values.uploadFileStrickerForFirebase)
                  .then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((url) => {
                      let messages = [
                        {
                          type: "file",
                          content: `${url}`,
                          info: `${values.uploadFileStrickerForFirebase.name}`,
                          by: "customer",
                          timestamp: new Date(),
                        },
                      ];
                      if (values.comment) {
                        messages.push({
                          type: "text",
                          content: `ข้อมูลเพิ่มเติม: ${values.comment}`,
                          by: "customer",
                          timestamp: new Date(),
                        });
                      }

                      console.log("values", values);
                      let data = {
                        approveMethod: values.approvalStricker,
                        shape: values.shape,
                        coat: values.coat,
                        material: values.material,
                        shape_index: values.shape_index,
                        index_2: values.coat_index,
                        index_1: values.material_index,
                        count: values.units,

                        comment: values.comment,
                        units: values.units,

                        width: values.width,
                        price: values.price,
                        height: values.height,
                        status: "รออนุมัติแบบ",

                        extraDeliveryDay: values.extraDeliveryDay || 0,

                        messages: messages,
                      };
                      res.data[0].itemsList.push(data);

                      let cloneRes = { ...res.data[0] };
                      delete cloneRes.myID;

                      console.log("res.data[0]", res.data[0]);
                      console.log("cloneRes", cloneRes);
                      axios
                        .put(
                          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/cart/${res.data[0].myID}`,
                          cloneRes,
                          {
                            headers: {
                              Authorization:
                                "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                            },
                          }
                        )
                        .then((res) => {
                          console.log("res", res);
                          props.history.push("/cart");
                          setFieldValue("loading", false, false);
                        })
                        .catch(function (err) {
                          console.log("err", err.response);
                          setFieldValue("loading", false, false);
                        });
                    });
                  });
              } else {
                console.log("not have item");
                // API POST IF NOT HAVE ITEM IN CART

                storageRef
                  .child(
                    `${user.uid}/${timeStamp}-${values.uploadFileStrickerForFirebase.name}`
                  )
                  .put(values.uploadFileStrickerForFirebase)
                  .then((snapshot) => {
                    snapshot.ref.getDownloadURL().then((url) => {
                      let messages = [
                        {
                          type: "file",
                          content: `${url}`,
                          info: `${values.uploadFileStrickerForFirebase.name}`,
                          by: "customer",
                          timestamp: new Date(),
                        },
                      ];
                      if (values.comment) {
                        messages.push({
                          type: "text",
                          content: `ข้อมูลเพิ่มเติม: ${values.comment}`,
                          by: "customer",
                          timestamp: new Date(),
                        });
                      }

                      let data = {
                        itemsList: [
                          {
                            approveMethod: values.approvalStricker,
                            shape_index: values.shape_index,
                            index_2: values.coat_index,
                            index_1: values.material_index,
                            count: values.units,
                            coat: values.coat,
                            cutting: values.cutting,
                            comment: values.comment,
                            units: values.units,
                            material: values.material,
                            width: values.width,
                            price: values.price,
                            shape: values.shape,
                            height: values.height,
                            status: "รออนุมัติแบบ",
                            extraDeliveryDay: values.extraDeliveryDay || 0,

                            messages: messages,
                          },
                        ],
                        customerID: user.uid,
                      };

                      console.log("data", data);
                      axios
                        .post(
                          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/cart`,
                          data,
                          {
                            headers: {
                              Authorization:
                                "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                            },
                          }
                        )
                        .then((res) => {
                          console.log("res", res);
                          setFieldValue("loading", false, false);
                          props.history.push("/cart");
                        })
                        .catch(function (err) {
                          console.log("err", err);
                          setFieldValue("loading", false, false);
                        });
                    });
                  });
              }
            })
            .catch(function (err) {
              console.log("err", err);
              setFieldValue("loading", false, false);
            });
        } else {
          console.log("mode guest");
          var cartLocal = JSON.parse(localStorage.getItem("cart"));
          console.log("cartLocal", cartLocal);
          if (cartLocal) {
            console.log("have item");
            storageRef
              .child(
                `guest/${timeStamp}-${values.uploadFileStrickerForFirebase.name}`
              )
              .put(values.uploadFileStrickerForFirebase)
              .then((snapshot) => {
                snapshot.ref.getDownloadURL().then((url) => {
                  let messages = [
                    {
                      type: "file",
                      content: `${url}`,
                      info: `${values.uploadFileStrickerForFirebase.name}`,
                      by: "customer",
                      timestamp: new Date(),
                    },
                  ];
                  if (values.comment) {
                    messages.push({
                      type: "text",
                      content: `ข้อมูลเพิ่มเติม: ${values.comment}`,
                      by: "customer",
                      timestamp: new Date(),
                    });
                  }

                  let data = {
                    approveMethod: values.approvalStricker,
                    shape_index: values.shape_index,
                    index_2: values.coat_index,
                    index_1: values.material_index,
                    coat: values.coat,
                    cutting: values.cutting,
                    comment: values.comment,
                    count: values.units,
                    units: values.units,
                    material: values.material,
                    width: values.width,
                    price: values.price,
                    shape: values.shape,
                    height: values.height,
                    status: "รออนุมัติแบบ",
                    extraDeliveryDay: values.extraDeliveryDay || 0,

                    messages: messages,
                  };
                  cartLocal.itemsList.push(data);
                  console.log("cartLocal", cartLocal);
                  localStorage.setItem("cart", JSON.stringify(cartLocal));
                  setFieldValue("loading", false, false);
                  props.history.push("/cart");
                });
              });
          } else {
            console.log("not have item");
            storageRef
              .child(
                `guest/${timeStamp}-${values.uploadFileStrickerForFirebase.name}`
              )
              .put(values.uploadFileStrickerForFirebase)
              .then((snapshot) => {
                console.log(">>>>");
                snapshot.ref.getDownloadURL().then((url) => {
                  console.log(">>>>>2");
                  let messages = [
                    {
                      type: "file",
                      content: `${url}`,
                      info: `${values.uploadFileStrickerForFirebase.name}`,
                      by: "customer",
                      timestamp: new Date(),
                    },
                  ];
                  if (values.comment) {
                    messages.push({
                      type: "text",
                      content: `ข้อมูลเพิ่มเติม: ${values.comment}`,
                      by: "customer",
                      timestamp: new Date(),
                    });
                  }

                  let data = {
                    itemsList: [
                      {
                        approveMethod: values.approvalStricker,
                        shape_index: values.shape_index,
                        index_2: values.coat_index,
                        index_1: values.material_index,
                        coat: values.coat,
                        cutting: values.cutting,
                        comment: values.comment,
                        count: values.units,
                        units: values.units,
                        material: values.material,
                        width: values.width,
                        price: values.price,
                        shape: values.shape,
                        height: values.height,
                        status: "รออนุมัติแบบ",
                        extraDeliveryDay: values.extraDeliveryDay || 0,

                        messages: messages,
                      },
                    ],
                    customerID: "guest",
                  };
                  localStorage.setItem("cart", JSON.stringify(data));
                  console.log(
                    "localStorage.getItem(cart)",
                    JSON.parse(localStorage.getItem("cart"))
                  );
                  setFieldValue("loading", false, false);
                  props.history.push("/cart");
                });
              })
              .catch(function (err) {
                console.log("err", err);
                setFieldValue("loading", false, false);
              });
          }
          return;
        }
      });
    }
  },
})(AppComponent);

export default EnhancedAppComponent;
