const firebaseConfig = {
  apiKey: "AIzaSyC37-zvzLDAu2DCEVRH-m7HRATPU89G4As",
  authDomain: "digitalwish-sticker.firebaseapp.com",
  databaseURL: "https://digitalwish-sticker.firebaseio.com",
  projectId: "digitalwish-sticker",
  storageBucket: "digitalwish-sticker.appspot.com",
  messagingSenderId: "495753389061",
  appId: "1:495753389061:web:44b34dcd490e4ed635286c",
  measurementId: "G-C742VCYMPH",
};

export default firebaseConfig;
