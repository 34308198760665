import React from "react";

import styled from "styled-components";

const Container = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 4px 8px;
  margin-top: 8px;
  margin-left: 8px;
`;

export default function HighlightContainer(props) {
  return (
    <Container
      style={{
        position: "absolute",
        left: `${props.annotation.geometry.x}%`,
        top: `${
          props.annotation.geometry.y + props.annotation.geometry.height
        }%`,
        ...props.style,
      }}
      geometry={props.annotation.geometry}
    >
      {props.annotation.data && props.annotation.data.text}
    </Container>
  );
}
