import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";

const ModalVariable = ({ values, setFieldValue, pricingType }) => {
  //   console.log("values", values);
  //   console.log("pricingType", pricingType);
  useEffect(() => {
    // console.log("pricingType", pricingType);

    if (
      (values.variableID || values.variableID === 0) &&
      pricingType == "แผ่น"
    ) {
      setFieldValue(
        `${values.modalVariable}Fixed`,
        values.material[values.materialSelected].coating_list[
          values.coatingSelected
        ].price.fixed_cost,
        false
      );
      setFieldValue(
        `${values.modalVariable}Variable1`,
        values.material[values.materialSelected].coating_list[
          values.coatingSelected
        ].price.variable_cost_1,
        false
      );
      setFieldValue(
        `${values.modalVariable}Variable2`,
        values.material[values.materialSelected].coating_list[
          values.coatingSelected
        ].price.variable_cost_2,
        false
      );
    } else if (
      (values.variableID || values.variableID === 0) &&
      pricingType == "ม้วน"
    ) {
      setFieldValue(
        `${values.modalVariable}Fixed`,
        values.material[values.materialSelected].coating_list[
          values.coatingSelected
        ].price_type_1.fixed_cost,
        false
      );
      setFieldValue(
        `${values.modalVariable}Variable1`,
        values.material[values.materialSelected].coating_list[
          values.coatingSelected
        ].price_type_1.variable_cost_1,
        false
      );
      setFieldValue(
        `${values.modalVariable}Variable2`,
        values.material[values.materialSelected].coating_list[
          values.coatingSelected
        ].price_type_1.variable_cost_2,
        false
      );
    } else {
      setFieldValue(`${values.modalVariable}Fixed`, "", false);
      setFieldValue(`${values.modalVariable}Variable1`, "", false);
      setFieldValue(`${values.modalVariable}Variable2`, "", false);
    }
    // if ((values.variableID || values.variableID === 0) && pricingType) {
    //   setFieldValue(
    //     `${values.modalVariable}Fixed`,
    //     values.material[values.materialSelected].coating_list[
    //       values.coatingSelected
    //     ].price.fixed_cost,
    //     false
    //   );
    //   setFieldValue(
    //     `${values.modalVariable}Variable1`,
    //     values.material[values.materialSelected].coating_list[
    //       values.coatingSelected
    //     ].price.variable_cost_1,
    //     false
    //   );
    //   setFieldValue(
    //     `${values.modalVariable}Variable2`,
    //     values.material[values.materialSelected].coating_list[
    //       values.coatingSelected
    //     ].price.variable_cost_2,
    //     false
    //   );
    // } else {
    //   setFieldValue(`${values.modalVariable}Fixed`, "", false);
    //   setFieldValue(`${values.modalVariable}Variable1`, "", false);
    //   setFieldValue(`${values.modalVariable}Variable2`, "", false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.variableID, pricingType]);

  const addOptionVariable = () => {
    setFieldValue("loading", true, false);
    let dataPost = {
      fixed_cost: values[`${values.modalVariable}Fixed`],
      variable_cost_1: values[`${values.modalVariable}Variable1`],
      variable_cost_2: values[`${values.modalVariable}Variable2`],
    };

    if (pricingType == "แผ่น") {
      values.material[values.materialSelected].coating_list[
        values.coatingSelected
      ].price = dataPost;
    } else if (pricingType == "ม้วน") {
      values.material[values.materialSelected].coating_list[
        values.coatingSelected
      ].price_type_1 = dataPost;
    }

    let dataPostNew = {
      material_list: values.material,
    };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptions/h03eqnOmkdOFxZqJxRWy`,
        dataPostNew,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // window.location.reload();
        setFieldValue("variableID", "", false);
        setFieldValue("fetch", true, false);
        setFieldValue("modalVariable", "", false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        setFieldValue("loading", false, false);
        console.log("err", err);
      });
  };

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalVariable ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        {pricingType == "แผ่น" && <div>ราคาสำหรับแบบแผ่น</div>}
        {pricingType == "ม้วน" && <div>ราคาสำหรับแบบม้วน</div>}
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalVariable", "", false);
              setFieldValue("variableID", "", false);
            }}
          >
            &times;
          </span>
        </div>
        {values.modalVariable && (
          <>
            <div className={styles.rowInModal}>
              <Field
                name={`${values.modalVariable}Fixed`}
                className={styles.text}
                placeholder="Fix Cost"
              />
            </div>
            <div className={styles.rowInModal}>
              <Field
                name={`${values.modalVariable}Variable1`}
                className={styles.text}
                placeholder="Variable 1"
              />
            </div>
            <div className={styles.rowInModal}>
              <Field
                name={`${values.modalVariable}Variable2`}
                className={styles.text}
                placeholder="Variable 2"
              />
            </div>
            <div className={`${styles.floatRight} ${styles.rowInModal}`}>
              <button
                type="button"
                className={styles.addOption}
                onClick={() => addOptionVariable()}
              >
                บันทึก
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalVariable;
