import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import axios from "axios";

import StarRating from "../star";
import Placeholder from "./placeholder-person.jpeg";

import moment from "moment";
const formatDateTime = "DD/MM/YYYY";
const FormatDateTime = (date) => {
  return moment(date).format(formatDateTime);
};

const ReviewSection = (props) => {
  const records_per_page = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [reviews, setReviews] = useState([]);
  const [reviewsStat, setReviewsStat] = useState({});

  useEffect(() => {
    // Reviews
    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reviews`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        const reviewData = res.data;
        reviewData.sort((a, b) => {
          const dateA = new Date(a.created_dt);
          const dateB = new Date(b.created_dt);
          return dateB - dateA;
        });
        setReviews(reviewData);

        // Reviews Stat
        axios
          .get(
            `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reviewsStat/OEYGhhtfEJIrNAY0bSw2`,
            {
              headers: {
                Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
              },
            }
          )
          .then((res) => {
            setReviewsStat(res.data);
          })
          .catch(function (err) {
            console.log("err", err);
            setReviews([]);
            setReviewsStat({});
          });
      })
      .catch(function (err) {
        console.log("err", err);
        setReviews([]);
        setReviewsStat({});
      });
  }, []);

  function numPages() {
    return Math.ceil(reviews.length / records_per_page);
  }

  function changePage(page) {
    // Validate page
    if (page < 1) setCurrentPage(1);
    else if (page > numPages()) setCurrentPage(numPages());
    else setCurrentPage(page);
  }

  let pagination = [];
  function renderPaginations() {
    let page = currentPage;
    if (currentPage > 1)
      pagination.push(
        <>
          <li className={styles.arrow} onClick={() => changePage(--page)}>
            {"<"}
          </li>
        </>
      );
    for (let i = 1; i <= numPages(); i++) {
      pagination.push(
        <>
          <li
            onClick={() => changePage(i)}
            className={
              currentPage === i ? styles.activePage : styles.inactivePage
            }
          >
            {i}
          </li>
        </>
      );
    }
    if (currentPage < numPages())
      pagination.push(
        <>
          <li className={styles.arrow} onClick={() => changePage(++page)}>
            {">"}
          </li>
        </>
      );
    return pagination;
  }

  return (
    <>
      <div className={styles.reviewWrapper}>
        <div className={styles.reviewStatWrapper}>
          <div className={styles.reviewStat}>
            <div className={styles.reviewStat1}>
              <div>
                {reviews &&
                  (
                    reviews.reduce((a, b) => {
                      return a + parseInt(b.rating);
                    }, 0) / reviews.length
                  ).toFixed(1)}
                /5
              </div>
              <div>
                <StarRating rating={5} />
              </div>
            </div>
            <div className={styles.reviewStat1}>
              {/* <div>{reviewsStat && (parseInt(reviewsStat.reviewsCount).toLocaleString() || 0)}</div> */}
              <div>{(reviews && reviews.length) || 0}</div>
              <div>รีวิว</div>
            </div>

            <div className={styles.reviewStat1}>
              <div>{reviewsStat && (reviewsStat.orderAgainRate || 0)}%</div>
              <div>กลับมาสั่งอีกครั้ง</div>
            </div>
          </div>
        </div>
        {reviews &&
          reviews.length > 0 &&
          reviews.map((review, index) => {
            return (
              <>
                {index >= (currentPage - 1) * records_per_page &&
                  index < (currentPage + 0) * records_per_page && (
                    <div className={styles.review}>
                      <div className={styles.img}>
                        {review.img ? (
                          <>
                            <img src={review.img} alt={"review"} />
                          </>
                        ) : (
                          <>
                            <img src={Placeholder} alt={"review"} />
                          </>
                        )}
                      </div>
                      <div>
                        <div className={styles.section1}>
                          <StarRating rating={review.rating} />
                          <div className={styles.reviewText}>
                            {review.review}
                          </div>
                        </div>
                        <div className={styles.section2}>
                          <div className={styles.dateName}>{review.name}</div>
                          <div className={styles.dateText}>
                            {review.created_dt &&
                              FormatDateTime(review.created_dt)}
                          </div>
                        </div>
                        <div className={styles.section3}>
                          {review.description}
                        </div>
                      </div>
                      <div></div>
                    </div>
                  )}
              </>
            );
          })}
      </div>
      <div style={{ textAlign: "center" }}>
        {numPages() > 0 && (
          <>
            <ul className={styles.pagination}>{renderPaginations()}</ul>
          </>
        )}
      </div>
    </>
  );
};

export default ReviewSection;
