import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";

const ModalQuantity = ({ values, setFieldValue }) => {
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setErrorMessage("");
    if (values.qualityID || values.qualityID === 0) {
      setFieldValue(
        `${values.modalQuality}Count`,
        values.optionQuantity[values.qualityID],
        false
      );
    } else {
      setFieldValue(`${values.modalQuality}Count`, "", false);
    }
  }, [values.qualityID]);

  const addOptionQuality = () => {
    setErrorMessage("");
    setFieldValue("loading", true, false);
    let data = values[`${values.modalQuality}Count`];
    if (data === undefined || data <= 0) {
      setErrorMessage("กรุณากรอกข้อมูลให้ถูกต้อง");
      setFieldValue("loading", false, false);
      return;
    }
    let dataPost = {};
    dataPost = {
      count_list: values.optionQuantity,
    };
    dataPost.count_list.push(data);

    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
        dataPost,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        setFieldValue("qualityID", "", false);
        setFieldValue("fetch", true, false);
        setFieldValue("modalQuality", "", false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        setFieldValue("loading", false, false);
        console.log("err", err);
      });
  };

  const editOptionQuality = () => {
    setFieldValue("loading", true, false);

    let data = values[`${values.modalQuality}Count`];
    if (data === undefined || data <= 0) {
      setErrorMessage("กรุณากรอกข้อมูลให้ถูกต้อง");
      setFieldValue("loading", false, false);
      return;
    }

    let dataPost = {};
    values.optionQuantity[values.qualityID] = data;
    dataPost = {
      count_list: values.optionQuantity,
    };

    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
        dataPost,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        setFieldValue("qualityID", "", false);
        setFieldValue("fetch", true, false);
        setFieldValue("modalQuality", "", false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        setFieldValue("loading", false, false);
        console.log("err", err);
      });
  };

  const removeOptionQuality = () => {
    setFieldValue("loading", true, false);
    let dataPost = {};
    values.optionQuantity.splice(values.qualityID, 1);
    dataPost = {
      count_list: values.optionQuantity,
    };

    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
        dataPost,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        setFieldValue("fetch", true, false);
        setFieldValue("loading", false, false);
        setFieldValue("modalQuality", "", false);
      })
      .catch(function (err) {
        setFieldValue("loading", false, false);
        console.log("err", err);
      });
  };

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalQuality ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalQuality", "", false);
              setFieldValue("qualityID", "", false);
            }}
          >
            &times;
          </span>
        </div>
        <div className={styles.rowInModal}>
          <div style={{ color: "red", fontSize: "16px" }}>{errorMessage}</div>
          <Field
            name={`${values.modalQuality}Count`}
            type="number"
            className={styles.text}
            placeholder="จำนวน"
          />
        </div>
        <div className={`${styles.floatRight} ${styles.rowInModal}`}>
          {values.qualityID || values.qualityID === 0 ? (
            <button
              type="button"
              className={styles.removeOption}
              onClick={() => removeOptionQuality()}
            >
              ลบ
            </button>
          ) : null}
          <button
            type="button"
            className={styles.addOption}
            onClick={() =>
              values.qualityID || values.qualityID === 0
                ? editOptionQuality()
                : addOptionQuality()
            }
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalQuantity;
