import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { withFormik, useFormikContext, Field } from "formik";
import axios from "axios";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  Redirect,
  useParams,
} from "react-router-dom";

import StepProgress from "../step_progress";
import CardOrder from "../card-order";
import PreviewImage from "../preview-image";
import AnnotationComponent from "../annotation";
import GroupDeliveryPayment from "../group-delivery-payment";
import { STATUS_ORDERS_TYPE } from "../constant-variable.js";
import Placeholder from "./gallery.png";
import { auth } from "../../firebase/index.js";
import firebaseApp from "../../firebase/index.js";

import { ReactComponent as IconCheckSVG } from "../approve-layout/icon-check.svg";
import IconCheckBlackPNG from "../approve-layout/icon-check-black.png";
import IconCheckGreenPNG from "../approve-layout/icon-check-green.png";

import { db } from "../../firebase";

import ReactGA from "react-ga";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function convertTimestamp(originalTimestamp) {
  // Create a Date object from the original timestamp
  const date = new Date(originalTimestamp);

  // Extract date parts
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Format the date into the desired format
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

const ApproveLayoutComponent = (props) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  let query = useQuery();
  const orderIdByParam = query.get("orderId");
  const mode = query.get("mode");
  let { id } = useParams();
  const orderIdByPath = id;
  const { values, setFieldValue } = useFormikContext();
  const [notFound, setNotFound] = useState(false);

  const [selectStep, setSelectStep] = useState(3);
  const [guestMode, setGuestMode] = useState(orderIdByPath ? false : true);
  const [catchOrders, setCatchOrders] = useState([]);
  const [redirect, setRedirect] = useState("");
  const [annotationMode, setAnnotationMode] = useState(
    mode == "approval" ? true : false
  );
  const [selectedItem, setSelectedItem] = useState();
  const [modal, setModal] = useState(false);

  const scrollRef = useRef(null);
  const scrollToElement = () => {
    scrollRef &&
      scrollRef.current &&
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        // block: "nearest",
        // inline: "start",
      });
  };

  // console.log("selectedItem", selectedItem);

  useEffect(() => {
    setSelectedItem(values.itemsList[values.expandCard]);
  }, [values.expandCard, values.itemsList]);

  useEffect(() => {
    if (orderIdByParam) setFieldValue("orderNumber", orderIdByParam, false);
  }, [orderIdByParam, setFieldValue]);

  useEffect(() => {
    if (values.status === "รอชำระเงิน" || values.status === "กำลังดำเนินการ") {
      setSelectStep(3);
    } else if (
      values.status === "กำลังผลิตสินค้า" ||
      values.status === "อยู่ระหว่างจัดส่ง" ||
      values.status === "รายการสำเร็จ" ||
      values.status === "คืนเงินสำเร็จ" ||
      values.status === "ยกเลิก"
    ) {
      setSelectStep(4);
    }
  }, [values.status]);

  console.log("values", values);

  useEffect(() => {
    setFieldValue("loading", true, false);
    async function fetchMyAPI() {
      let myOrderId = orderIdByPath;
      // find order document id
      if (orderIdByParam) {
        const searchResult = await db
          .collection("orders")
          .where("orderID", "==", orderIdByParam)
          .get();
        if (searchResult.docs.length > 0) {
          searchResult.docs.map((doc) => (myOrderId = doc.id));
        } else {
          console.log("Not found");
          setNotFound(true);
          setFieldValue("loading", false, false);
        }
      }
      if (myOrderId) {
        const unsubscribe = await db
          .collection("orders")
          .doc(myOrderId)
          .onSnapshot((snapshot) => {
            if (snapshot.data()) {
              const data = snapshot.data();
              setNotFound(false);
              setFieldValue(
                "fullFetchData",
                { myID: myOrderId, ...data },
                false
              );

              setFieldValue("myID", myOrderId, false);

              setFieldValue("orderID", data.orderID, false);
              setFieldValue("status", data.status, false);
              setFieldValue("itemsList", data.itemsList, false);
              setFieldValue("shippingAddress", data.shippingAddress, false);
              setFieldValue("shippingNumber", data.shippingNumber, false);
              setFieldValue("shippingCourier", data.shippingCourier, false);
              setFieldValue("itemsCost", data.itemsCost, false);
              setFieldValue("shippingCost", data.shippingCost, false);
              setFieldValue("vatCost", data.vatCost, false);
              setFieldValue("totalCost", data.totalCost, false);
              setFieldValue("paymentMethod", data.paymentMethod, false);
              setFieldValue("paymentStatus", data.paymentStatus, false);
              setFieldValue("paymentConfirm", data.paymentConfirm, false);
              setFieldValue("promotion", data.promotion, false);
              setFieldValue("promotionDiscount", data.promotionDiscount, false);
              setFieldValue("fetchMsg", false, false);
              setFieldValue("loading", false, false);
              setModal(false);
            } else {
              setNotFound(true);
              setFieldValue("loading", false, false);
            }
          });
        // setFieldValue("loading", false, false);
        return unsubscribe;
      }
      if (!orderIdByParam && !orderIdByPath)
        setFieldValue("loading", false, false);
    }
    fetchMyAPI();
  }, [orderIdByParam, orderIdByPath, setFieldValue]);

  return (
    <main className={styles.wrapContent}>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>

      {guestMode && (
        <>
          <div className={styles.formWrapper}>
            <h1 className={styles.title}>ตรวจสอบสถานะออเดอร์</h1>
            <span style={{ marginRight: "10px" }}>หมายเลขออเดอร์</span>
            <Field
              name="orderNumber"
              className={styles.inputGreen}
              style={{ marginRight: "10px" }}
            />
            {/* <span style={{ margin: '0 10px' }}>เบอร์โทรศัพท์</span>
                    <Field name="phoneCheck" className={styles.inputGreen} /> */}
            <a href={"/myorder?orderId=" + values.orderNumber}>
              <button
                type="button"
                className={styles.btnGreen}
                // onClick={() => searchOrderNumber(values.orderNumber)}
              >
                ตรวจสอบสถานะ
              </button>
            </a>
          </div>
        </>
      )}

      {!values.myID && (
        <div
          style={{
            background: "rgba(255, 255, 255, 0.8)",
            borderRadius: "40px",
            padding: "20px",
            marginTop: "30px",
            width: "fit-content",
          }}
        >
          <p style={{ margin: "5px 0", color: "black" }}>
            {!orderIdByParam && !orderIdByPath ? "" : "ไม่พบรายการ"}
          </p>
        </div>
      )}

      {values.myID && (
        <>
          <div
            className={styles.mobileScroll}
            style={{
              background: "rgba(255, 255, 255, 0.8)",
              borderRadius: "40px",
              padding: "30px",
              marginTop: "30px",
            }}
          >
            <section>
              {`${values.paymentMethod}` === `transfer_money` &&
              `${values.paymentStatus}` === `pending` ? (
                <div>
                  <button
                    type="button"
                    onClick={() => setModal(true)}
                    disabled={values.waitProcess ? true : false}
                    className={styles.bigBtn}
                  >
                    {values.paymentConfirm &&
                    values.paymentConfirm.length > 0 ? (
                      <h3>แจ้งชำระเงินอีกครั้ง</h3>
                    ) : (
                      <h3>แจ้งชำระเงิน</h3>
                    )}
                  </button>
                </div>
              ) : (
                `${selectedItem && selectedItem.status}` === `รออนุมัติแบบ` && (
                  <>
                    {/* <div>
                          <button
                            type="button"
                            // onClick={() => sendItemStatus()}
                            onClick={() => setAnnotationMode(!annotationMode)}
                            disabled={values.waitProcess ? true : false}
                            className={styles.disabledBtn}
                          >
                            <h3>กรุณารอไฟล์แบบภายใน 24 ชั่วโมง</h3>
                          </button>
                        </div> */}

                    <div>
                      <button
                        type="button"
                        // onClick={() => sendItemStatus()}
                        onClick={() => {
                          setAnnotationMode(true);
                          scrollToElement();
                        }}
                        disabled={values.waitProcess ? true : false}
                        className={
                          selectedItem.approvalStatus == "approved"
                            ? styles.bigBtn
                            : styles.approveBtn
                        }
                      >
                        <h3>
                          {selectedItem.approvalStatus == "approved" ? (
                            <>ดูไฟล์แบบที่อนุมัติ</>
                          ) : (
                            <>ตรวจสอบและอนุมัติแบบ</>
                          )}
                        </h3>
                      </button>
                    </div>
                  </>
                )
              )}
              {/* {`${selectedItem && selectedItem.status}` ===
                    "กำลังผลิตสินค้า" && (
                    <>
                      {values.status === "อยู่ระหว่างจัดส่ง" ? (
                        <div>
                          <button
                            type="button"
                            // onClick={() => sendItemStatus()}
                            onClick={() => setAnnotationMode(!annotationMode)}
                            disabled={values.waitProcess ? true : false}
                            className={
                              selectedItem &&
                              selectedItem.approvalStatus == "approved"
                                ? styles.bigBtn
                                : styles.approveBtn
                            }
                          >
                            <h3>
                              <IconCheckSVG />
                              กำลังจัดส่งสินค้า
                            </h3>
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            type="button"
                            // onClick={() => sendItemStatus()}
                            onClick={() => setAnnotationMode(!annotationMode)}
                            disabled={values.waitProcess ? true : false}
                            className={
                              selectedItem &&
                              selectedItem.approvalStatus == "approved"
                                ? styles.bigBtn
                                : styles.approveBtn
                            }
                          >
                            <h3>
                              <IconCheckSVG />
                              สินค้าของคุณกำลังดำเนินการผลิต....
                            </h3>
                          </button>
                        </div>
                      )}
                    </>
                  )} */}
              {`${selectedItem && selectedItem.status}` ===
                "กำลังผลิตสินค้า" && (
                <>
                  <div>
                    <button
                      type="button"
                      // onClick={() => sendItemStatus()}
                      onClick={() => {
                        setAnnotationMode(true);
                        scrollToElement();
                      }}
                      disabled={values.waitProcess ? true : false}
                      className={
                        selectedItem &&
                        selectedItem.approvalStatus == "approved"
                          ? styles.bigBtn
                          : styles.approveBtn
                      }
                    >
                      <h3>
                        <IconCheckSVG />
                        สินค้าของคุณกำลังดำเนินการผลิต....
                      </h3>
                    </button>
                  </div>
                </>
              )}

              {`${selectedItem && selectedItem.status}` ===
                "กำลังจัดส่งสินค้า" &&
                values.status !== "รายการสำเร็จ" && (
                  <>
                    <div>
                      <button
                        type="button"
                        // onClick={() => sendItemStatus()}
                        // onClick={() => setAnnotationMode(!annotationMode)}
                        onClick={() => {
                          scrollToElement();
                        }}
                        disabled={values.waitProcess ? true : false}
                        // className={
                        //   selectedItem &&
                        //   selectedItem.approvalStatus == "approved"
                        //     ? styles.bigBtn
                        //     : styles.approveBtn
                        // }
                        className={styles.bigBtnWhite}
                      >
                        <h3>
                          <img src={IconCheckBlackPNG} alt="." />
                          กำลังจัดส่งสินค้า
                        </h3>
                      </button>
                    </div>
                  </>
                )}
              {`${values && values.status}` === "รายการสำเร็จ" && (
                <>
                  <div>
                    <button
                      type="button"
                      // onClick={() => sendItemStatus()}
                      // onClick={() => setAnnotationMode(!annotationMode)}
                      onClick={() => {
                        scrollToElement();
                      }}
                      disabled={values.waitProcess ? true : false}
                      // className={
                      //   selectedItem &&
                      //   selectedItem.approvalStatus == "approved"
                      //     ? styles.bigBtn
                      //     : styles.approveBtn
                      // }
                      className={styles.bigBtnSuccess}
                    >
                      <h3>
                        รายการสำเร็จ
                        <img src={IconCheckGreenPNG} alt="." />
                      </h3>
                    </button>
                  </div>
                </>
              )}
            </section>
            <h1 className={styles.title}>รายการออเดอร์</h1>
            <div>
              ออเดอร์หมายเลข #{values.orderID}
              <LabelSatus status={values.status} />
            </div>

            <div style={{ marginBottom: "20px" }}>
              วันที่สั่งซื้อ {convertTimestamp(values.fullFetchData.timeStamp)}
            </div>

            <section className={styles.stepProgressBar}>
              <StepProgress stepIndex={selectStep} />
            </section>
            <section style={{ marginBottom: "10px" }}>
              กรุณาตรวจไฟล์แบบ {values.itemsList.length || 0} รายการ
            </section>
            <section>
              <CardOrder />
            </section>
            <h1 ref={scrollRef}>ใช้สำหรับยืนยันแบบ</h1>

            {annotationMode ? (
              <>
                <section style={{ maxWidth: "900px", margin: "0 auto" }}>
                  <AnnotationComponent
                    setAnnotationMode={setAnnotationMode}
                    annotationMode={annotationMode}
                    selectedItem={selectedItem}
                    itemIndex={values.expandCard}
                    order={values}
                    isAdmin={false}
                  />
                </section>
                <section>
                  <div style={{ margin: "0 auto", textAlign: "center" }}>
                    <button
                      type="button"
                      // onClick={() => sendItemStatus()}
                      onClick={() => {
                        setAnnotationMode(false);
                        // scrollToElement();
                      }}
                      disabled={values.waitProcess ? true : false}
                      className={styles.bigBtnNew}
                    >
                      <h3>กลับไปดูกล่องข้อความ</h3>
                    </button>
                  </div>
                </section>
              </>
            ) : (
              <div className={styles.customGrid50}>
                <>
                  <section className={styles.previewImage}>
                    <PreviewImage
                      allMessage={selectedItem ? selectedItem.messages : []}
                      modal={modal}
                      setModal={setModal}
                      paymentConfirm={values.paymentConfirm}
                      sendSystemMessage={sendSystemMessage}
                    />
                  </section>

                  {values.paymentConfirm.length !== 0 ? (
                    <section className={styles.groupSlipPayment}>
                      <h3>การแจ้งการชำระเงิน</h3>

                      <div className={styles.flexRow}>
                        {values.paymentConfirm.map((data, index) => {
                          return (
                            <acticle className={styles.cardSlip}>
                              <p>ชื่อ นามสกุล*: {data.name}</p>
                              <p>เบอร์โทรศัพท์*: {data.phone}</p>
                              <p>ยอดชำระเงิน: {data.amount}</p>
                              <p>ธนาคารที่โอน: {data.bank}</p>
                              <p>วันที่โอน: {data.date}</p>
                              <p>เวลา: {data.time}</p>
                              <p>
                                สลิปการโอนเงิน:{" "}
                                <a
                                  className={styles.dowloadFileMsg}
                                  href={data.photo}
                                  target="_blank"
                                  downloadFile
                                >
                                  ดาวน์โหลด
                                </a>
                              </p>
                            </acticle>
                          );
                        })}
                      </div>
                    </section>
                  ) : (
                    <section className={styles.groupSlipPayment}>
                      <h3>การแจ้งการชำระเงิน</h3>
                      <div>ไม่พบรายการแจ้งชำระเงิน</div>
                    </section>
                  )}
                </>
              </div>
            )}

            <section
              className={styles.groupDeliveryPayment}
              // style={guestMode ? { border: "1px solid #009473" } : {}}
            >
              <GroupDeliveryPayment itemIndex={values.expandCard} />
            </section>
          </div>
        </>
      )}
    </main>
  );
};

const sendSystemMessage = (myID, itemIndex, message, setFieldValue) => {
  // console.log("myID", myID);
  // console.log("itemIndex", itemIndex);
  // console.log("message", message);
  if (myID && message) {
    let url = window.location.pathname;
    let data = {
      itemIndex: itemIndex,
      msg: {
        by: "admin",
        content: message,
        timestamp: new Date(),
        type: "text",
      },
    };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orderItemMsg/${myID}`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        if (setFieldValue) setFieldValue("fetchMsg", true, false);
        console.log("SEND SYSTEM MESSAGE", res);
      })
      .catch(function (err) {
        console.log("err", err);
      });
  }
};

const EnhancedApproveLayoutComponent = withFormik({
  mapPropsToValues: (props) => ({
    // phoneCheck: '',
    orderNumber: "", //สำหรับค้นหาหมายเลขออเดอร์
    massage: "", //สำหรับ Chat Room
    expandCard: 0, //สำหรับเลือกว่ากด Card ไหน

    orderID: "",
    status: "",
    itemsList: [],

    paymentConfirm: [],
    allOrder: [],
    fetchMsg: false,

    // modal bank
    name: null,
    phone: null,
    bank: null,
    date: null,
    time: null,
    photo: null,
    amount: null,
    isCheckphoto: 0,
    isAdmin: false,

    waitProcess: false,
    loading: false,

    alertSuccess: "",
  }),
  validate: (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "*กรุณาระบุ";
    }
    if (!values.phone) {
      errors.phone = "*กรุณาระบุ";
    }
    if (!/^\d+$/.test(values.phone)) {
      errors.phone = "*ต้องเป็นตัวเลข 0-9";
    }
    if (!values.bank) {
      errors.bank = "*กรุณาระบุ";
    }
    if (!values.date) {
      errors.date = "*กรุณาระบุ";
    }
    if (!values.time) {
      errors.time = "*กรุณาระบุ";
    }
    if (!values.photo) {
      errors.photo = "*กรุณาระบุ";
    }
    if (!values.amount) {
      errors.amount = "*กรุณาระบุ";
    }

    return errors;
  },
  handleSubmit: (values, { setFieldValue, props }) => {
    console.log("values.expandCard", values.expandCard);
    setFieldValue("waitProcess", true, false);
    setFieldValue("loading", true, false);
    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    let cloneFullFetchData = { ...values.fullFetchData };

    storageRef
      // .child(`${user.uid}/${timeStamp}-${values.photo.name}`)
      .child(`${cloneFullFetchData.orderID}/${timeStamp}-${values.photo.name}`)
      .put(values.photo)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          let newData = {
            name: values.name,
            phone: values.phone,
            bank: values.bank,
            date: values.date,
            time: values.time,
            photo: url,
            amount: values.amount,
          };
          console.log("cloneFullFetchData", cloneFullFetchData);
          cloneFullFetchData.paymentConfirm = [
            ...cloneFullFetchData.paymentConfirm,
            newData,
          ];

          cloneFullFetchData.isConfirmPayment = true;
          console.log("cloneFullFetchData", cloneFullFetchData);

          axios
            .put(
              `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/payment/${
                cloneFullFetchData.id || cloneFullFetchData.myID
              }`,
              cloneFullFetchData,
              {
                headers: {
                  Authorization:
                    "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                },
              }
            )
            .then((res) => {
              sendSystemMessage(
                values.myID,
                values.expandCard,
                "ทางเราได้รับการแจ้งการชำระเงินแล้ว จะตรวจสอบไฟล์และส่งไฟล์ให้อนุมัติแบบอีกครั้ง ภายใน 24 ชั่วโมงในเวลาทําการ(08.00-17.00) เมื่อแบบพร้อมทางเราจะส่งการแจ้งเตือนกลับไปที่เบอร์โทรศัพท์และ Email ค่ะ",
                setFieldValue
              );
              console.log("res", res);
              setFieldValue("fetchMsg", true, false);
              setFieldValue("name", "", false);
              setFieldValue("bank", "", false);
              setFieldValue("phone", "", false);
              setFieldValue("photo", "", false);
              setFieldValue("date", "", false);
              setFieldValue("time", "", false);
              setFieldValue("amount", "", false);
              setFieldValue("waitProcess", false, false);
              setFieldValue("loading", false, false);
              setFieldValue("isCheckphoto", 0, false);
              setFieldValue("alertSuccess", "แจ้งชำระเงินเรียบร้อย", false);
            })
            .catch((err) => {
              console.log("err", err);
              setFieldValue("loading", false, false);
              setFieldValue("waitProcess", false, false);
            });
        });
      });

    // auth.onAuthStateChanged((user) => {
    //   if (user) {
    //     // User is signed in.
    //     storageRef
    //       // .child(`${user.uid}/${timeStamp}-${values.photo.name}`)
    //       .child(
    //         `${cloneFullFetchData.orderID}/${timeStamp}-${values.photo.name}`
    //       )
    //       .put(values.photo)
    //       .then((snapshot) => {
    //         snapshot.ref.getDownloadURL().then((url) => {
    //           let newData = {
    //             name: values.name,
    //             phone: values.phone,
    //             bank: values.bank,
    //             date: values.date,
    //             time: values.time,
    //             photo: url,
    //             amount: values.amount,
    //           };
    //           console.log("cloneFullFetchData", cloneFullFetchData);
    //           cloneFullFetchData.paymentConfirm = [
    //             ...cloneFullFetchData.paymentConfirm,
    //             newData,
    //           ];

    //           cloneFullFetchData.isConfirmPayment = true;
    //           console.log("cloneFullFetchData", cloneFullFetchData);

    //           axios
    //             .put(
    //               `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/payment/${
    //                 cloneFullFetchData.id || cloneFullFetchData.myID
    //               }`,
    //               cloneFullFetchData,
    //               {
    //                 headers: {
    //                   Authorization:
    //                     "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
    //                 },
    //               }
    //             )
    //             .then((res) => {
    //               sendSystemMessage(
    //                 values.myID,
    //                 values.expandCard,
    //                 "ทางเราได้รับการแจ้งการชำระเงินแล้ว จะตรวจสอบไฟล์และส่งไฟล์ให้อนุมัติแบบอีกครั้ง ภายใน 24 ชั่วโมงในเวลาทําการ(08.00-17.00) เมื่อแบบพร้อมทางเราจะส่งการแจ้งเตือนกลับไปที่เบอร์โทรศัพท์และ Email ค่ะ",
    //                 setFieldValue
    //               );
    //               console.log("res", res);
    //               setFieldValue("fetchMsg", true, false);
    //               setFieldValue("name", "", false);
    //               setFieldValue("bank", "", false);
    //               setFieldValue("phone", "", false);
    //               setFieldValue("photo", "", false);
    //               setFieldValue("date", "", false);
    //               setFieldValue("time", "", false);
    //               setFieldValue("amount", "", false);
    //               setFieldValue("waitProcess", false, false);
    //               setFieldValue("loading", false, false);
    //               setFieldValue("isCheckphoto", 0, false);
    //               setFieldValue("alertSuccess", "แจ้งชำระเงินเรียบร้อย", false);
    //             })
    //             .catch((err) => {
    //               console.log("err", err);
    //               setFieldValue("loading", false, false);
    //               setFieldValue("waitProcess", false, false);
    //             });
    //         });
    //       });
    //   }
    // });
  },
})(ApproveLayoutComponent);

export default EnhancedApproveLayoutComponent;

const LabelSatus = ({ status }) => {
  if (status === STATUS_ORDERS_TYPE.DOING) {
    return (
      <label className={`${styles.labelStatus} ${styles.orangeStatus}`}>
        สถานะ: กำลังดำเนินการ
      </label>
    );
  } else if (status === STATUS_ORDERS_TYPE.WAIT_PAYMENT) {
    return (
      <label className={`${styles.labelStatus} ${styles.navyStatus}`}>
        สถานะ: รอชำระเงิน
      </label>
    );
  } else if (status === STATUS_ORDERS_TYPE.PRODUCTION) {
    return (
      <label className={`${styles.labelStatus} ${styles.yellowStatus}`}>
        สถานะ: กำลังผลิตสินค้า
      </label>
    );
  } else if (status === STATUS_ORDERS_TYPE.DELIVERY) {
    return (
      <label className={`${styles.labelStatus} ${styles.blueStatus}`}>
        สถานะ: อยู่ระหว่างจัดส่ง
      </label>
    );
  } else if (status === STATUS_ORDERS_TYPE.REFUND) {
    return (
      <label className={`${styles.labelStatus} ${styles.redStatus}`}>
        สถานะ: ขอคืนเงิน
      </label>
    );
  } else if (status === STATUS_ORDERS_TYPE.REFUNDED) {
    return (
      <label className={`${styles.labelStatus} ${styles.greenStatus}`}>
        สถานะ: คืนเงินสำเร็จ
      </label>
    );
  } else if (status === STATUS_ORDERS_TYPE.FINISH) {
    return (
      <label className={`${styles.labelStatus} ${styles.greenStatus}`}>
        สถานะ: รายการสำเร็จ
      </label>
    );
  } else if (status === STATUS_ORDERS_TYPE.CANCEL) {
    return (
      <label className={`${styles.labelStatus} ${styles.redStatus}`}>
        สถานะ: ยกเลิก
      </label>
    );
  } else {
    return <label></label>;
  }
};
