import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

const StarRating = (props) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    useEffect(()=> {
        setRating(props.rating);
    }, [props.rating])
    return (
      <div className={styles.starRating}>
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              key={index}
              className={index <= (hover || rating) ? styles.on : styles.off}
            //   onClick={() => setRating(index)}
            //   onMouseEnter={() => setHover(index)}
            //   onMouseLeave={() => setHover(rating)}
            >
              <span className="star">&#9733;</span>
            </button>
          );
        })}
      </div>
    );
  };

export default StarRating;