import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import {
  useFormikContext,
  withFormik,
  Form,
  Field,
  ErrorMessage,
} from "formik";

import { ReactComponent as S11SampleIcon } from "./s1-1-sample-icon.svg";
import samplePhoto2 from "./sample-kit3.jpg";

import axios from "axios";
import addressData from "./address.json";
import toast, { Toaster } from "react-hot-toast";

const DemoForm = (props) => {
  const modal = props.modal;
  const setModal = props.setModal;
  const { values, setFieldValue } = useFormikContext();
  const [optionCounty, setOptionCounty] = useState([]);
  const [optionCity, setOptionCity] = useState([]);

  useEffect(() => {
    if (values.done) setModal(false);
  }, [setModal, values]);

  // Take Effect every time the hash of the document is changed - CHANGED TO REFS
  // Thinking of https://medium.com/javascript-in-plain-english/creating-a-hash-anchor-link-effect-with-react-router-a63dcb1a9b0e
  //   useEffect(() => {
  //     if (props.location.state !== undefined) {
  //       if (props.location.state.scrollToStepsOrder) {
  //         stepsOrder.current.scrollIntoView({ block: "center" });
  //       } else if (props.location.state.scrollToOurWorks) {
  //         ourWorks.current.scrollIntoView({ block: "center" });
  //       }
  //     }
  //   }, [props.location.state]);

  useEffect(() => {
    // console.log("VALUE", values);

    let newOptionCity = addressData.filter((item) => {
      return item.district == values.county;
    });
    let resultCity = newOptionCity.reduce((unique, o) => {
      if (!unique.some((obj) => obj.sub_district === o.sub_district)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setOptionCity(resultCity);
    // console.log("newOptionCity", resultCity);

    let newOptionCounty = addressData.filter((item) => {
      return item.province == values.provice;
    });
    let resultCounty = newOptionCounty.reduce((unique, o) => {
      if (!unique.some((obj) => obj.district === o.district)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setOptionCounty(resultCounty);
    // console.log("newOptionCounty", resultCounty);
  }, [values]);

  return (
    <>
      {/* <Toaster position="bottom-center" reverseOrder={false} /> */}
      <main>
        {/* <!-- The Modal --> */}
        <Form>
          <div
            className={styles.modal}
            style={
              modal ? { display: "block", zIndex: "9999" } : { display: "none" }
            }
          >
            <div className={styles.modalContent}>
              <div
                onClick={() => setModal(false)}
                style={{ cursor: "pointer", width: "100%", textAlign: "right" }}
              >
                X ปิด
              </div>
              <div className={styles.exampleSticker}>
                {/* <S11SampleIcon /> */}
                <h2>ขอชุดตัวอย่างเนื้อสติกเกอร์</h2>
              </div>

              <div
                className={styles.exampleStickerBox}
                style={{ border: "none" }}
              >
                {/*<Test />*/}
                <div align="center">
                  <img
                    src={samplePhoto2}
                    style={{ width: "100%", borderRadius: "20px" }}
                  />
                </div>
                <div className={styles.exampleStickerBoxDetail}>
                  {/*
                  <h3>ท่านจะได้รับ</h3>
                  <p>คำอธิบาย.............................................</p>
                  <p>คำอธิบาย.............................................</p>
                  <p>คำอธิบาย.............................................</p>
                  <p>คำอธิบาย.............................................</p>
                  */}
                </div>
              </div>

              <div className={styles.groupRow}>
                <div className={styles.isRow}>
                  <div className={styles.leftColumn}>
                    <p>
                      ชื่อ นามสกุล*
                      <ErrorMessage
                        name="name"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="name"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                  <div className={styles.rightColumn}>
                    <p>
                      อีเมล*
                      <ErrorMessage
                        name="email"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="email"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                </div>
                <div className={styles.isRow}>
                  <div className={styles.leftColumn}>
                    <p>
                      ที่อยู่*
                      <ErrorMessage
                        name="address"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="address"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                  <div className={styles.rightColumn}>
                    <p>
                      เบอร์โทรศัพท์*
                      <ErrorMessage
                        name="phone"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="phone"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                </div>
                <div className={styles.isRow}>
                  <div className={styles.leftColumn}>
                    <p>
                      จังหวัด*
                      <ErrorMessage
                        name="provice"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    {/* <Field
                      name="provice"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    /> */}

                    <Field as="select" name="provice" type="text">
                      <option value="" selected>
                        กรุณาเลือกจังหวัด
                      </option>
                      <option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
                      <option value="กระบี่">กระบี่ </option>
                      <option value="กาญจนบุรี">กาญจนบุรี </option>
                      <option value="กาฬสินธุ์">กาฬสินธุ์ </option>
                      <option value="กำแพงเพชร">กำแพงเพชร </option>
                      <option value="ขอนแก่น">ขอนแก่น</option>
                      <option value="จันทบุรี">จันทบุรี</option>
                      <option value="ฉะเชิงเทรา">ฉะเชิงเทรา </option>
                      <option value="ชัยนาท">ชัยนาท </option>
                      <option value="ชัยภูมิ">ชัยภูมิ </option>
                      <option value="ชุมพร">ชุมพร </option>
                      <option value="ชลบุรี">ชลบุรี </option>
                      <option value="เชียงใหม่">เชียงใหม่ </option>
                      <option value="เชียงราย">เชียงราย </option>
                      <option value="ตรัง">ตรัง </option>
                      <option value="ตราด">ตราด </option>
                      <option value="ตาก">ตาก </option>
                      <option value="นครนายก">นครนายก </option>
                      <option value="นครปฐม">นครปฐม </option>
                      <option value="นครพนม">นครพนม </option>
                      <option value="นครราชสีมา">นครราชสีมา </option>
                      <option value="นครศรีธรรมราช">นครศรีธรรมราช </option>
                      <option value="นครสวรรค์">นครสวรรค์ </option>
                      <option value="นราธิวาส">นราธิวาส </option>
                      <option value="น่าน">น่าน </option>
                      <option value="นนทบุรี">นนทบุรี </option>
                      <option value="บึงกาฬ">บึงกาฬ</option>
                      <option value="บุรีรัมย์">บุรีรัมย์</option>
                      <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์ </option>
                      <option value="ปทุมธานี">ปทุมธานี </option>
                      <option value="ปราจีนบุรี">ปราจีนบุรี </option>
                      <option value="ปัตตานี">ปัตตานี </option>
                      <option value="พะเยา">พะเยา </option>
                      <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา </option>
                      <option value="พังงา">พังงา </option>
                      <option value="พิจิตร">พิจิตร </option>
                      <option value="พิษณุโลก">พิษณุโลก </option>
                      <option value="เพชรบุรี">เพชรบุรี </option>
                      <option value="เพชรบูรณ์">เพชรบูรณ์ </option>
                      <option value="แพร่">แพร่ </option>
                      <option value="พัทลุง">พัทลุง </option>
                      <option value="ภูเก็ต">ภูเก็ต </option>
                      <option value="มหาสารคาม">มหาสารคาม </option>
                      <option value="มุกดาหาร">มุกดาหาร </option>
                      <option value="แม่ฮ่องสอน">แม่ฮ่องสอน </option>
                      <option value="ยโสธร">ยโสธร </option>
                      <option value="ยะลา">ยะลา </option>
                      <option value="ร้อยเอ็ด">ร้อยเอ็ด </option>
                      <option value="ระนอง">ระนอง </option>
                      <option value="ระยอง">ระยอง </option>
                      <option value="ราชบุรี">ราชบุรี</option>
                      <option value="ลพบุรี">ลพบุรี </option>
                      <option value="ลำปาง">ลำปาง </option>
                      <option value="ลำพูน">ลำพูน </option>
                      <option value="เลย">เลย </option>
                      <option value="ศรีสะเกษ">ศรีสะเกษ</option>
                      <option value="สกลนคร">สกลนคร</option>
                      <option value="สงขลา">สงขลา </option>
                      <option value="สมุทรสาคร">สมุทรสาคร </option>
                      <option value="สมุทรปราการ">สมุทรปราการ </option>
                      <option value="สมุทรสงคราม">สมุทรสงคราม </option>
                      <option value="สระแก้ว">สระแก้ว </option>
                      <option value="สระบุรี">สระบุรี </option>
                      <option value="สิงห์บุรี">สิงห์บุรี </option>
                      <option value="สุโขทัย">สุโขทัย </option>
                      <option value="สุพรรณบุรี">สุพรรณบุรี </option>
                      <option value="สุราษฎร์ธานี">สุราษฎร์ธานี </option>
                      <option value="สุรินทร์">สุรินทร์ </option>
                      <option value="สตูล">สตูล </option>
                      <option value="หนองคาย">หนองคาย </option>
                      <option value="หนองบัวลำภู">หนองบัวลำภู </option>
                      <option value="อำนาจเจริญ">อำนาจเจริญ </option>
                      <option value="อุดรธานี">อุดรธานี </option>
                      <option value="อุตรดิตถ์">อุตรดิตถ์ </option>
                      <option value="อุทัยธานี">อุทัยธานี </option>
                      <option value="อุบลราชธานี">อุบลราชธานี</option>
                      <option value="อ่างทอง">อ่างทอง </option>
                      <option value="อื่นๆ">อื่นๆ</option>
                    </Field>
                  </div>
                  <div className={styles.rightColumn}>
                    <p>
                      เขต*
                      <ErrorMessage
                        name="county"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    {/* <Field
                      name="county"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    /> */}
                    <Field as="select" name="county" type="text">
                      <option value="">กรุณาเลือกเขต</option>
                      {optionCounty &&
                        optionCounty.map((item) => {
                          return (
                            <option value={item.district}>
                              {item.district}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                </div>
                <div className={styles.isRow}>
                  <div className={styles.leftColumn}>
                    <p>
                      แขวง*
                      <ErrorMessage
                        name="city"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    {/* <Field
                      name="city"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    /> */}
                    <Field as="select" name="city" type="text">
                      <option value="">กรุณาเลือกแขวง</option>
                      {optionCity &&
                        optionCity.map((item) => {
                          return (
                            <option value={item.sub_district}>
                              {item.sub_district}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                  <div className={styles.rightColumn}>
                    <p>
                      รหัสไปรษณีย์*
                      <ErrorMessage
                        name="zip"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="zip"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <button
                  type="submit"
                  className={styles.btnOrange}
                  disabled={values.waitProcess ? true : false}
                >
                  ตกลง
                </button>
                <button
                  type="button"
                  className={styles.btnGreen}
                  disabled={values.waitProcess ? true : false}
                  onClick={() => setModal(false)}
                >
                  ปิด
                </button>
              </div>
            </div>
          </div>
        </Form>
      </main>
    </>
  );
};

const EnhancedDemoForm = withFormik({
  mapPropsToValues: () => ({
    name: "",
    zip: "",
    address: "",
    city: "",
    county: "",
    phone: "",
    provice: "",
    email: "",

    waitProcess: false,
    loading: false,
  }),
  validate: (values) => {
    const errors = {};

    // if (!values.name) {
    //   errors.name = "*กรุณาระบุ";
    // }
    // if (!values.zip) {
    //   errors.zip = "*กรุณาระบุ";
    // }
    // if (!values.address) {
    //   errors.address = "*กรุณาระบุ";
    // }
    // if (!values.city) {
    //   errors.city = "*กรุณาระบุ";
    // }
    // if (!values.county) {
    //   errors.county = "*กรุณาระบุ";
    // }
    // if (!values.phone) {
    //   errors.phone = "*กรุณาระบุ";
    // }
    // if (!/^\d+$/.test(values.phone)) {
    //   errors.phone = "*ต้องเป็นตัวเลข 0-9";
    // }
    // if (!values.email) {
    //   errors.email = "*กรุณาระบุ";
    // }
    // if (!values.provice) {
    //   errors.provice = "*กรุณาระบุ";
    // }

    return errors;
  },
  handleSubmit: (values, { setFieldValue }) => {
    setFieldValue("waitProcess", true, false);
    setFieldValue("loading", true, false);
    let data = {
      fullname: values.name,
      email: values.email,
      phone: values.phone,
      shippingAddress: {
        fullname: values.name,
        zip: values.zip,
        address: values.address,
        city: values.city,
        county: values.county,
        provice: values.provice,
      },
      timeStamp: new Date().toISOString(),
      status: "ok",
    };
    axios
      .post(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/demoRequest`,
        // `http://localhost:5001/digitalwish-sticker/asia-east2/demoRequest`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        setFieldValue("waitProcess", false, false);
        setFieldValue("name", "", false);
        setFieldValue("zip", "", false);
        setFieldValue("address", "", false);
        setFieldValue("city", "", false);
        setFieldValue("county", "", false);
        setFieldValue("phone", "", false);
        setFieldValue("provice", "", false);
        setFieldValue("email", "", false);
        setFieldValue("loading", false, false);
        setFieldValue("done", true, false);
        toast(
          (t) => (
            <div>
              ได้รับข้อมูลเรียบร้อยแล้ว{" "}
              <span onClick={() => toast.dismiss(t.id)}>ปิด</span>
            </div>
          ),
          {
            icon: "✅",
          }
        );
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
        toast.error("ส่งข้อมูลไม่สำเร็จกรุณาลองอีกครั้ง");
      });
  },
})(DemoForm);

export default EnhancedDemoForm;
