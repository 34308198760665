import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";
import firebaseApp from "../../firebase/index.js";

const ModalReviewStat = ({ values, setFieldValue }) => {

  useEffect(() => {
    if (values.reviewsStat && (values.reviewsStat.orderAgainRate || values.reviewsStat.reviewsCount === 0)) {
      setFieldValue(
        `orderAgainRate`,
        values.reviewsStat.orderAgainRate,
        false
      );
      setFieldValue(
        `reviewsCount`,
        values.reviewsStat.reviewsCount,
        false
      );
    } else {
      setFieldValue(`orderAgainRate`, "", false);
      setFieldValue(`reviewsCount`, "", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.reviewsStat]);

  const editOptionShape = () => {
    setFieldValue("loading", true, false);
    let timeStamp = new Date().toISOString().slice(0, 10);
      let dataPost = {
        orderAgainRate: values.orderAgainRate,
        reviewsCount: values.reviewsCount,
      };
      if(!parseInt(dataPost.orderAgainRate) || parseInt(dataPost.orderAgainRate) < 0 || parseInt(dataPost.orderAgainRate) > 100) {
        dataPost.orderAgainRate = 100;
      }
      if(!parseInt(dataPost.reviewsCount)) {
        dataPost.reviewsCount = 0;
      }
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reviewsStat/OEYGhhtfEJIrNAY0bSw2`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("loading", false, false);
          setFieldValue(`orderAgainRate`, "", false);
          setFieldValue(`reviewsCount`, "", false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
  };

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalReviewStat ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalReviewStat", "", false);
              setFieldValue("reviewsCount", "", false);
              setFieldValue("orderAgainRate", "", false);
            }}
          >
            &times;
          </span>
        </div>
        <div className={styles.rowInModal} style={{display:"none"}}>
          <Field
            name={`reviewsCount`}
            className={styles.text}
            placeholder="จำนวนรีวิว"
          />
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`orderAgainRate`}
            className={styles.text}
            placeholder="% กลับมาสั่งอีกครั้ง"
          />
          *0-100
        </div>
        
        <div className={`${styles.floatRight} ${styles.rowInModal}`}>
          <button
            type="button"
            className={styles.addOption}
            onClick={() => editOptionShape()
            }
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalReviewStat;
