import React from "react";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

import { ReactComponent as BannerFooter } from "./banner-footer.svg";
/* Importing the svg file as a React component. */
import QrCode from "./28.svg";

import lineContact from "./line-contact.png";
import facebookContact from "./facebook-contact.png";
import logoNew from "./logo-new.png";

import ReactGA from "react-ga";

const FooterComponent = () => {
  return (
    <footer>
      <div className={styles.bgFooter}>
        <div className={styles.contain}>
          <div className={styles.subContain}>
            {/* <BannerFooter className={styles.banner} /> */}
            <img
              src={logoNew}
              // onClick={() => history.push("/")}
              className={styles.logoBrand}
            />
            <ul className={styles.footerMenu}>
              {/* <li>
            <b>เมนู</b>
          </li> */}
              {/* <li>
                <Link to="/">หน้าแรก</Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: "/",
                    // hash: "#stepsOrder",
                    state: { scrollToStepsOrder: true },
                  }}
                  // onClick={stepsOrderScroll}
                >
                  วิธีการสั่งซื้อ
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: "/",
                    // hash: "#ourWorks",
                    state: { scrollToOurWorks: true },
                  }}
                >
                  ตัวอย่างผลงาน
                </Link>
              </li>
              <li>
                <Link to="/customize">สั่งทำสติกเกอร์</Link>
              </li> */}
              <li>
                <a
                  href="https://lin.ee/sZW5Mh8"
                  target="_tab"
                  className={styles.lineBlock}
                  onClick={() => {
                    ReactGA.event({
                      category: "Link",
                      action: "Line",
                      value: 1,
                    });
                  }}
                >
                  <img src={lineContact} alt="." />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/Digitalwish-sticker-101272075721621"
                  target="_tab"
                  className={styles.facebookBlock}
                  onClick={() => {
                    ReactGA.event({
                      category: "Link",
                      action: "Facebook",
                      value: 1,
                    });
                  }}
                >
                  <img src={facebookContact} alt="." />
                </a>
              </li>
            </ul>
          </div>
          {/* <ul>
          <li>
            <a
              href="https://lin.ee/sZW5Mh8"
              target="_tab"
              className={styles.lineBlock}
            >
              <img src={lineContact} alt="." />
            </a>
          </li>
        </ul> */}
          {/* <ul>
            <li>
              <a
                href="https://lin.ee/sZW5Mh8"
                target="_tab"
                className={styles.lineBlock}
              >
                <img src={lineContact} alt="." />
              </a>
              <a
                href="https://www.facebook.com/Digitalwish-sticker-101272075721621"
                target="_tab"
                className={styles.facebookBlock}
              >
                <img src={facebookContact} alt="." />
              </a>
            </li>
          </ul> */}
          {/* <ul className={styles.colQrCode}>
          <li>
            <b>Scan Line QR code</b>
          </li>
          <li>
            <a href="https://lin.ee/sZW5Mh8" target="_tab">
              <img src={QrCode} />
            </a>
          </li>
        </ul> */}
          {/* <ul className={styles.listContract}>
          <li className={styles.squareLine}>
            <b>Line Official</b>
            <span className={styles.squareWhite}>
              <a href="https://lin.ee/sZW5Mh8" target="_tab">
                @stickerwish
              </a>
            </span>
          </li>
          <li className={styles.squareFace}>
            <b>Facebook</b>
            <span className={styles.squareWhite}>digitalwish.sticker</span>
          </li>
          <li className={styles.squareEmail}>
            <b>Email</b>
            <span className={styles.squareWhite}>
              <a href="mailto:Stickerwishthailand@gmail.com" target="_tab">
                Stickerwishthailand@gmail.com
              </a>
            </span>
          </li>
        </ul> */}
        </div>
      </div>
      <div className={styles.footerBar}>
        บริษัท ดิจิตอล วิช จำกัด เลขที่ 75 หมู่ 3 ตำบลแคราย อำเภอกระทุ่มแบน
        จังหวัดสมุทรสาคร 74110
      </div>
      <div className={styles.footerBar}>
        <iframe
          className={styles.googleMap}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.147564798407!2d100.32046977694293!3d13.648786186732472!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2bef4350e7709%3A0xc821afb769f9edee!2sDigital%20Wish%20Co.%2C%20Ltd.!5e0!3m2!1sth!2sth!4v1695234747786!5m2!1sth!2sth"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className={styles.footerBar}>
        © 2020 Digitalwish Sticker by Digitalwish CO. LTD
      </div>
    </footer>
  );
};

export default FooterComponent;
