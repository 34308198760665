import React, { useEffect, useState } from "react";
import AdminKpi from "../admin-kpi";
import styles from "./index.module.scss";
import fake_data from "./fake-api.json";
import { Link } from "react-router-dom";
import { axiosInst } from "../common-scss/common";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import moment from "moment";

const formatDateTime = "DD-MM-YYYY HH:mm";

const FormatDateTime = (date) => {
  return moment(date).format(formatDateTime);
};

const useInputChange = () => {
  const [input, setInput] = useState({});

  const handleInputChange = (e) =>
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });

  return [input, handleInputChange];
};

const initialLineYears = (n = 10) => {
  var new_date = new Date();
  var start_year = new_date.getFullYear();
  let rows_year = [
    {
      year_id: start_year,
    },
  ];
  for (var i = 1; i <= n; i++) {
    start_year = start_year - 1;
    if (start_year >= "2020") {
      rows_year.push({
        year_id: start_year,
      });
    }
  }
  return rows_year;
};

const AdminDemoMTG = (props) => {
  const [allData, setAlldata] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [checkUpdate, setCheckUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectMonth, setSelectMonth] = useInputChange();
  const [selectYear, setSelectYear] = useInputChange();
  const [textSearch, setTextSearch] = useInputChange();

  useEffect(() => {
    setLoading(true);
    axiosInst.get("/demoRequest").then((res) => {
      // console.log(res.data);
      setAlldata(res.data);
      setLoading(false);
      setCheckUpdate(false);
    });
  }, [checkUpdate]);

  useEffect(() => {
    setLoading(true);
    let filtered_data =
      allData &&
      allData.map((item) => {
        let myTimestamp = new Date(item.timeStamp);
        // console.log(item.timeStamp, myTimestamp);
        if (myTimestamp == "Invalid Date") {
          var dateParts = item.timeStamp.split("/");
          myTimestamp = new Date(
            dateParts[2] - 543,
            dateParts[1] - 1,
            dateParts[0]
          );
          // console.log("NEW" + item.orderID, myTimestamp);
          // U989VPI5XjJ6u3vDgjEM;16/11/2564
        }
        return { ...item, timeStamp: myTimestamp };
      });

    if (selectMonth.month && selectMonth.month != "allMonth") {
      // console.log(selectMonth.month);
      filtered_data = filtered_data.filter(function (item) {
        return item.timeStamp.getMonth() + 1 == selectMonth.month;
      });
    }

    if (selectYear.year && selectYear.year != "allYear") {
      // console.log(selectYear.year);
      filtered_data = filtered_data.filter(function (item) {
        return item.timeStamp.getFullYear() == parseInt(selectYear.year);
      });
    }

    if (textSearch) {
      filtered_data = filtered_data.filter(function (item) {
        return (
          item?.email?.match(textSearch["search"]) ||
          item?.fullname?.match(textSearch["search"]) ||
          item?.phone?.match(textSearch["search"]) ||
          item?.trackingNumber?.match(textSearch["search"])
        );
      });
    }

    console.log("filtered_data", filtered_data);
    setOrderData(filtered_data);
    setLoading(false);
  }, [textSearch, selectYear.year, selectMonth.month, allData]);

  const handleRemove = (index) => {
    // console.log(">>>>", orderData[index].myID)
    setLoading(true);
    axiosInst
      .delete(`demoRequest/${orderData[index].myID}`)
      .then((res) => {
        // console.log("res", res);
        setLoading(false);
        setCheckUpdate(true);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  return (
    <React.Fragment>
      <div class={`loader loader-default ${loading ? "is-active" : ""}`}></div>
      <section className={styles.section1}>
        <AdminKpi kpi={{ order: 10, sales: 1234567, member: 1000 }} />
      </section>
      <section className={styles.section2}>
        <div className={`${styles.containerCol} ${styles.containerMargin}`}>
          <h3>รายการขอตัวอย่าง</h3>
        </div>
        <div>
          <select
            name="month"
            className={styles.inputAdmin}
            onChange={setSelectMonth}
          >
            <option value="allMonth">ทุกเดือน</option>
            <option value="1">มกราคม</option>
            <option value="2">กุมภาพันธ์</option>
            <option value="3">มีนาคม</option>
            <option value="4">เมษายน</option>
            <option value="5">พฤษภาคม</option>
            <option value="6">มิถุนายน</option>
            <option value="7">กรกฎาคม</option>
            <option value="8">สิงหาคม</option>
            <option value="9">กันยายน</option>
            <option value="10">ตุลาคม</option>
            <option value="11">พฤศจิกายน</option>
            <option value="12">ธันวาคม</option>
          </select>

          <select
            name="year"
            className={styles.inputAdmin}
            onChange={setSelectYear}
          >
            <option value="allYear">ทุกปี</option>
            {initialLineYears().map((data) => (
              <option value={`${data.year_id}`}>{data.year_id}</option>
            ))}
          </select>

          <input
            type="text"
            name="search"
            className={styles.inputAdmin}
            placeholder="ค้นหา"
            onChange={setTextSearch}
          />
        </div>
      </section>
      <div className={styles.adminTable}>
        <table>
          <thead>
            <tr>
              <th>วันที่</th>
              <th>ชื่อ นามสกุล*</th>
              <th>อีเมล*</th>
              <th>เบอร์โทรศัพท์*</th>
              <th>ที่อยู่*</th>
              <th>หมายเลขจัดส่ง</th>
              <th>แขวง*</th>
              <th>เขต*</th>
              <th>จังหวัด*</th>
              <th>รหัสไปรษณีย์*</th>

              <th>จัดการ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>รวม</td>
              <td>{orderData.length.toLocaleString()} รายการ</td>
            </tr>
            {orderData
              .sort(
                (a, b) =>
                  // Turn your strings into dates, and then subtract them
                  // to get a value that is either negative, positive, or zero.
                  new Date(b.timeStamp) - new Date(a.timeStamp)
              )
              .map((data, index) => (
                <tr>
                  <td style={{ width: "150px" }}>
                    {data?.timeStamp && FormatDateTime(data.timeStamp)}
                  </td>
                  <td>{data.fullname}</td>
                  <td>{data.email}</td>
                  <td>{data?.phone}</td>
                  <td>{data.shippingAddress.address}</td>
                  <td>
                    {data.trackingNumber ? <>{data.trackingNumber}</> : ""}{" "}
                    <button
                      type="button"
                      className={styles.editThisDemo}
                      onClick={() => {
                        const userInput = prompt("กรอกหมายเลขจัดส่ง");
                        // console.log(data);
                        if (userInput !== null) {
                          // setInputValue(userInput);
                          // console.log(userInput);
                          // setFieldValue("loading", true, false);
                          let updateData = {
                            trackingNumber: userInput,
                          };
                          axios
                            .put(
                              `https://asia-east2-digitalwish-sticker.cloudfunctions.net/demoRequest/${data.myID}`,
                              // `http://localhost:5001/digitalwish-sticker/asia-east2/demoRequest`,
                              updateData,
                              {
                                headers: {
                                  Authorization:
                                    "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                                },
                              }
                            )
                            .then((res) => {
                              // console.log("res", res);
                              setCheckUpdate(true);
                              toast(
                                (t) => (
                                  <div>
                                    บันทึกข้อมูลเรียบร้อยแล้ว{" "}
                                    <span onClick={() => toast.dismiss(t.id)}>
                                      ปิด
                                    </span>
                                  </div>
                                ),
                                {
                                  icon: "✅",
                                }
                              );
                            })
                            .catch(function (err) {
                              console.log("err", err);
                              toast.error("ส่งข้อมูลไม่สำเร็จกรุณาลองอีกครั้ง");
                            });
                        }
                      }}
                    >
                      แก้ไข
                    </button>
                  </td>
                  <td>{data?.shippingAddress.city}</td>
                  <td>{data?.shippingAddress.county}</td>
                  <td>{data?.shippingAddress.provice}</td>
                  <td>{data?.shippingAddress.zip}</td>
                  <th>
                    <button
                      type="button"
                      className={styles.removeThisDemo}
                      onClick={() => handleRemove(index)}
                    >
                      ลบ
                    </button>
                  </th>
                </tr>
              ))}
            {/* {orderData.map((data, index) => (
              <tr>
                <td>{data.fullname}</td>
                <td>{data.email}</td>
                <td>{data.shippingAddress.address}</td>
                <td>{data?.phone}</td>
                <td>{data?.shippingAddress.city}</td>
                <td>{data?.shippingAddress.county}</td>
                <td>{data?.shippingAddress.provice}</td>
                <td>{data?.shippingAddress.zip}</td>
                <td>{data?.timeStamp && FormatDateTime(data.timeStamp)}</td>
                <th>
                  <button
                    type="button"
                    className={styles.removeThisDemo}
                    onClick={() => handleRemove(index)}
                  >
                    ลบ.
                  </button>
                </th>
              </tr>
            ))} */}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default AdminDemoMTG;
