import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";

const ModalVariable = ({ values, setFieldValue, pricingType }) => {

  useEffect(() => {
    if (values.selectedCoating.myID || values.selectedCoating.myID === 0) {
      setFieldValue(
        `${values.modalVariable}Fixed`,
        values.selectedCoating.price.fixed_cost,
        false
      );
      setFieldValue(
        `${values.modalVariable}Variable1`,
        values.selectedCoating.price.variable_cost_1,
        false
      );
      setFieldValue(
        `${values.modalVariable}Variable2`,
        values.selectedCoating.price.variable_cost_2,
        false
      );
    } else {
      setFieldValue(`${values.modalVariable}Fixed`, "", false);
      setFieldValue(`${values.modalMaterial}Variable1`, "", false);
      setFieldValue(`${values.modalMaterial}Variable2`, "", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.modalVariable, values.selectedShape, values.selectedMaterial, values.selectedCoating]);

  const editOptionVariable = () => {
    setFieldValue("loading", true, false);
    let fixed_cost = values[`${values.modalVariable}Fixed`];
    let variable_cost_1 = values[`${values.modalVariable}Variable1`];
    let variable_cost_2 = values[`${values.modalVariable}Variable2`];

    let dataPost = {
      price: {
        fixed_cost: fixed_cost,
        variable_cost_1: variable_cost_1,
        variable_cost_2: variable_cost_2,
      }
    };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}/${values.selectedCoating.myID}`,
        dataPost,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        setFieldValue("fetch", true, false);
        setFieldValue("loading", false, false);
        setFieldValue("modalCoating", "", false);
        let newSelectedCoating = {
          ...values.selectedCoating,
          ...dataPost
        }
        console.log("newSelectedCoating",newSelectedCoating);
        setFieldValue("selectedCoating", newSelectedCoating, false);
      })
      .catch(function (err) {
        setFieldValue("loading", false, false);
        console.log("err", err);
      });
  }

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalVariable ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        {/* {pricingType == "แผ่น" && <div>ราคาสำหรับแบบแผ่น</div>}
        {pricingType == "ม้วน" && <div>ราคาสำหรับแบบม้วน</div>} */}
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalVariable", "", false);
              // setFieldValue("variableID", "", false);
            }}
          >
            &times;
          </span>
        </div>
        {values.modalVariable && (
          <>
            <div className={styles.rowInModal}>
              <Field
                name={`${values.modalVariable}Fixed`}
                className={styles.text}
                placeholder="Fix Cost"
              />
            </div>
            <div className={styles.rowInModal}>
              <Field
                name={`${values.modalVariable}Variable1`}
                className={styles.text}
                placeholder="Variable 1"
              />
            </div>
            <div className={styles.rowInModal}>
              <Field
                name={`${values.modalVariable}Variable2`}
                className={styles.text}
                placeholder="Variable 2"
              />
            </div>
            <div className={`${styles.floatRight} ${styles.rowInModal}`}>
              <button
                type="button"
                className={styles.addOption}
                onClick={() => editOptionVariable()}
              >
                บันทึก
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalVariable;
