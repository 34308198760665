export const STATUS_ORDERS_TYPE = {
  WAIT_PAYMENT: "รอชำระเงิน",
  DOING: "กำลังดำเนินการ",
  PRODUCTION: "กำลังผลิตสินค้า",
  DELIVERY: "อยู่ระหว่างจัดส่ง",
  REFUND: "ขอคืนเงิน",
  REFUNDED: "คืนเงินสำเร็จ",
  FINISH: "รายการสำเร็จ",
  CANCEL: "ยกเลิก",
};
