import React, { useEffect, useState } from "react";
import { withFormik, useFormikContext } from "formik";
import styles from "./index.module.scss";
import axios from "axios";

import firebaseApp from "../../firebase/index.js";
import AdminKpi from "../admin-kpi";
import ModalShape from "./modalShape.js";
import ModalMaterial from "./modalMaterial.js";
import ModalCoating from "./modalCoat.js";
import ModalVariable from "./modalVariable.js";
import ModalQuantity from "./modalQuantity.js";

import moment from "moment";
const formatDateTime = "DD/MM/YYYY";
const FormatDateTime = (date) => {
  return moment(date).format(formatDateTime);
};

const AdminOptionNewComponent = () => {
  const { values, setFieldValue } = useFormikContext();
  const [pricingType, setPricingType] = useState();

  // Fetch all shapes
  useEffect(() => {
    setFieldValue("loading", true, false);
    axios
      .get(
        // `http://127.0.0.1:5001/digitalwish-sticker/asia-east2/productOptionsNew/`,
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // console.log("optionShape", res.data)
        setFieldValue("optionShape", res.data, false);
        // setFieldValue("optionMaterial", [], false);
        // setFieldValue("optionCoating", [], false);
        // setFieldValue("selectedMaterial", "", false);
        // setFieldValue("selectedCoating", "", false);
        // setFieldValue("pricingSetting", "", false);
        setFieldValue("fetch", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  }, [values.fetch]);

  // Fetch shape > material
  useEffect(() => {
    setFieldValue("loading", true, false);
    const selectedShapeId = values.selectedShape.myID;
    if (!selectedShapeId) {
      setFieldValue("loading", false, false);
      return;
    }
    axios
      .get(
        // `http://127.0.0.1:5001/digitalwish-sticker/asia-east2/productOptionsNew/${selectedShapeId}/material`,
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${selectedShapeId}/material`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // console.log("optionMaterial", res.data)
        setFieldValue("optionMaterial", res.data, false);
        // setFieldValue("optionCoating", [], false);
        // setFieldValue("selectedCoating", "", false);
        // setFieldValue("pricingSetting", "", false);

        setFieldValue("fetch", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  }, [values.fetch, values.selectedShape]);

  // Fetch shape > material > coating
  useEffect(() => {
    setFieldValue("loading", true, false);
    const selectedShapeId = values.selectedShape.myID;
    const selectedMaterialId = values.selectedMaterial.myID;
    if (!selectedShapeId || !selectedMaterialId) {
      setFieldValue("loading", false, false);
      return;
    }
    axios
      .get(
        // `http://127.0.0.1:5001/digitalwish-sticker/asia-east2/productOptionsNew/${selectedShapeId}/${selectedMaterialId}/coating/`,
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${selectedShapeId}/${selectedMaterialId}/coating/`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // console.log("optionCoating", res.data)
        setFieldValue("optionCoating", res.data, false);
        // setFieldValue("pricingSetting", "", false);
        // if(values.selectedCoating) {
        //   let find = res.data.filter(function(item) { return item.myID === values.selectedCoating.myID; });
        //   setFieldValue("selectedCoating", find, false);
        // }
        setFieldValue("fetch", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  }, [values.fetch, values.selectedMaterial]);
  // console.log("selectedCoating", values.selectedCoating)

  // Quantity
  // Change quantity option to based on coating value
  // useEffect(() => {
  //   setFieldValue("loading", true, false);
  //   setFieldValue("optionQunatity", [], false);
  //   axios
  //     .get(
  //       `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptions/Rf8b0x8ktshu0y0VGzyV`,
  //       {
  //         headers: {
  //           Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log("res.data[0]", res.data);
  //       if (values.selectedShape.type === "แผ่น") {
  //         setFieldValue("optionQuantity", res.data.count_list, false);
  //       } else if (values.selectedShape.type === "ม้วน") {
  //         setFieldValue("optionQuantity", res.data.count_list_type_1, false);
  //       }
  //       setFieldValue("loading", false, false);
  //     })
  //     .catch(function (err) {
  //       console.log("err", err);
  //       setFieldValue("loading", false, false);
  //     });

  //   return;
  // }, [values.fetch, values.selectedShape]);

  return (
    <main className={styles.wrapContent}>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>
      <section className={styles.section1}>
        <AdminKpi kpi={{ order: 10, sales: 1234567, member: 1000 }} />
      </section>

      <h1 className={styles.title}>ตั้งค่าตัวเลือก</h1>

      <section className={styles.productOptions}>
        <article className={styles.cardProductOption}>
          <h4>รูปแบบสติกเกอร์</h4>
          <div>
            <button
              type="button"
              className={styles.btnOption}
              onClick={() => {
                setFieldValue("selectedShape", "", false);
                setFieldValue("modalShape", "modalShapeAdd", false);
              }}
            >
              เพิ่ม
            </button>
            <ModalShape values={values} setFieldValue={setFieldValue} />
          </div>
          <div>
            {values.optionShape &&
              [...values.optionShape] // Create a shallow copy to avoid mutating the original array
                .filter((each) => each && each.name)
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort in ascending order by shape.name
                .map((shape, index) => {
                  return (
                    <div className={styles.btnRow}>
                      <button
                        type="button"
                        className={`${styles.btnListOption90} ${
                          shape.myID === values.selectedShape.myID &&
                          styles.active
                        }`}
                        onClick={() => {
                          setFieldValue("selectedShape", shape, false);
                          setFieldValue("selectedMaterial", "", false);
                          setFieldValue("selectedCoating", "", false);
                          setFieldValue("optionQuantity", [], false);
                          setFieldValue("optionCoating", [], false);
                        }}
                      >
                        <img
                          src={shape.imgUrl}
                          className={styles.iconImage}
                          alt="."
                        />
                        {shape.name}
                      </button>
                      <button
                        className={styles.btnEdit}
                        type="button"
                        onClick={() => {
                          setFieldValue("modalShape", "modalShapeEdit", false);
                          setFieldValue("selectedShape", shape, false);
                        }}
                      >
                        แก้ไข
                      </button>
                    </div>
                  );
                })}
          </div>
        </article>

        <article className={styles.cardProductOption}>
          <h4>เนื้อวัสดุ</h4>
          {values.selectedShape && (
            <div>
              <button
                type="button"
                className={styles.btnOption}
                onClick={() => {
                  setFieldValue("selectedMaterial", "", false);
                  setFieldValue("modalMaterial", "modalMaterialAdd", false);
                  setFieldValue("selectedCoating", "", false);
                }}
              >
                เพิ่ม
              </button>
              <ModalMaterial values={values} setFieldValue={setFieldValue} />
            </div>
          )}
          <div>
            {values.optionMaterial &&
              [...values.optionMaterial] // Create a shallow copy to avoid mutating the original array
                .filter((each) => each && each.name)
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort in ascending order by material.name
                .map((material, index) => {
                  return (
                    <div className={styles.btnRow}>
                      <button
                        type="button"
                        className={`${styles.btnListOption90} ${
                          material.myID === values.selectedMaterial.myID &&
                          styles.active
                        }`}
                        onClick={() => {
                          setFieldValue("selectedMaterial", material, false);
                          // Quantity based on optionMaterial
                          setFieldValue(
                            "optionQuantity",
                            material.count_list ? material.count_list : [],
                            false
                          );
                          setFieldValue("selectedCoating", "", false);
                        }}
                      >
                        <img
                          src={material.imgUrl}
                          className={styles.iconImage}
                          alt="."
                        />
                        {material.name}
                      </button>
                      <button
                        className={styles.btnEdit}
                        type="button"
                        onClick={() => {
                          setFieldValue(
                            "modalMaterial",
                            "modalMaterialEdit",
                            false
                          );
                          setFieldValue("selectedMaterial", material, false);
                        }}
                      >
                        แก้ไข
                      </button>
                    </div>
                  );
                })}
          </div>
        </article>

        <article className={styles.cardProductOption}>
          <h4>การเคลือบหน้า + กาวหลัง</h4>
          <div>
            {values.selectedShape && values.selectedMaterial && (
              <button
                type="button"
                className={styles.btnOption}
                onClick={() => {
                  setFieldValue("selectedCoating", "", false);
                  setFieldValue("modalCoating", "modalCoatingAdd", false);
                }}
              >
                เพิ่ม
              </button>
            )}
            <ModalCoating values={values} setFieldValue={setFieldValue} />
          </div>
          <div>
            {values.optionCoating &&
              [...values.optionCoating] // Create a shallow copy to avoid mutating the original array
                .filter((each) => each && each.name)
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort in ascending order by coating.name
                .map((coating, index) => {
                  return (
                    <div className={styles.btnRow}>
                      <button
                        type="button"
                        className={`${styles.btnListOption90} ${
                          coating.myID === values.selectedCoating.myID &&
                          styles.active
                        }`}
                        onClick={() => {
                          setFieldValue("selectedCoating", coating, false);
                        }}
                      >
                        <img
                          src={coating.imgUrl}
                          className={styles.iconImage}
                          alt="."
                        />
                        {coating.name}
                      </button>
                      <button
                        className={styles.btnEdit}
                        type="button"
                        onClick={() => {
                          setFieldValue(
                            "modalCoating",
                            "modalCoatingEdit",
                            false
                          );
                          setFieldValue("selectedCoating", coating, false);
                        }}
                      >
                        แก้ไข
                      </button>
                    </div>
                  );
                })}
          </div>
        </article>

        <article className={styles.cardProductOption}>
          <h4>สูตรราคา</h4>
          <div>
            <ModalVariable values={values} setFieldValue={setFieldValue} />
          </div>
          <div></div>
          <div>
            {values.selectedCoating && values.selectedCoating.price && (
              <div className={styles.btnRow}>
                <button type="button" className={`${styles.btnListOption90}`}>
                  <p>Fixed: {values.selectedCoating.price.fixed_cost}</p>
                  <p>
                    Variable_1: {values.selectedCoating.price.variable_cost_1}
                  </p>
                  <p>
                    Variable_2: {values.selectedCoating.price.variable_cost_2}
                  </p>
                </button>
                <button
                  className={styles.btnEdit}
                  type="button"
                  onClick={() => {
                    setFieldValue("modalVariable", "modalVariableEdit", false);
                  }}
                >
                  แก้ไข
                </button>
              </div>
            )}
          </div>
        </article>

        <article className={styles.cardProductOption}>
          <h4>จำนวน</h4>
          <div>
            {values.optionQuantity && values.selectedMaterial && (
              <button
                type="button"
                className={styles.btnOption}
                onClick={() => {
                  setFieldValue(`${values.modalQuality}Count`, "", false);
                  setFieldValue("modalQuality", "modalQualityAdd", false);
                }}
              >
                เพิ่ม
              </button>
            )}
            <ModalQuantity
              values={values}
              setFieldValue={setFieldValue}
              pricingType={pricingType}
            />
          </div>
          <div>
            <>
              {values.optionQuantity &&
                values.optionQuantity
                  .slice() // creates a shallow copy to avoid mutating the original array
                  .sort((a, b) => a - b) // sorts the array in ascending order
                  .map((unitOptions, index) => {
                    // console.log("unitOptions", unitOptions);
                    return (
                      <button
                        type="button"
                        className={styles.btnListOption}
                        onClick={() => {
                          setFieldValue(
                            "modalQuality",
                            "modalQualityEdit",
                            false
                          );
                          setFieldValue("qualityID", index, false);
                        }}
                      >
                        {unitOptions && unitOptions.toLocaleString()} ชิ้น
                      </button>
                    );
                  })}
            </>
          </div>
        </article>
      </section>
    </main>
  );
};

const EnhancedAdminOptionNewComponent = withFormik({
  mapPropsToValues: (props) => ({
    loading: false,
    optionShape: [],
    optionMaterial: [],
    optionCoating: [],
    selectedShape: "",
    selectedMaterial: "",
    selectedCoating: "",

    shape: [],
    material: [],
    coating: [],
    cuttingList: [],
    unitOptions: [],
    shippingOption: [],
    modalAdd: "",
    fetch: false,
  }),
})(AdminOptionNewComponent);

export default EnhancedAdminOptionNewComponent;
