import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";
import firebaseApp from "../../firebase/index.js";

const ModalReference = ({ values, setFieldValue, handleAdd }) => {
  const [displayImage, setDisplayImage] = useState("");
  useEffect(() => {
      setFieldValue(`${values.modalReference}File`, "", false);
      setDisplayImage("")
  }, [values.modalReference]);

  const addOptionMaterial = () => {
    setFieldValue("loading", true, false);
    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    if (values[`${values.modalReference}File`]) {
      storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalReference}File`].name
          }`
        )
        .put(values[`${values.modalReference}File`])
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            handleAdd(url);
            // setFieldValue("fetch", true, false);
            setFieldValue("modalReference", "", false);
            setFieldValue("loading", false, false);
          });
        });
    }
  }

  const handleChangeImgReference = (event) => {
    if (event.target.files) {
      setFieldValue(
        `${values.modalReference}File`,
        event.target.files[0],
        false
      );
    }
  };

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalReference ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalReference", "", false);
              // setFieldValue("selectedMaterial", "", false);
            }}
          >
            &times;
          </span>
        </div>
        <div className={styles.rowInModal}>
          <input
            type="file"
            id="fileMaterial"
            onChange={(e) => handleChangeImgReference(e)}
          />
          <label
            for="fileMaterial"
            className={`${styles.buttonUploadFile} ${styles.label}`}
          >
            อัพโหลดไฟล์
          </label>
        </div>
        <div className={styles.displayImage}>
          {displayImage && <img src={displayImage} />}
        </div>
        <div className={`${styles.floatRight} ${styles.rowInModal}`}>
          <button
            type="button"
            className={styles.addOption}
            onClick={() => addOptionMaterial()}
          >
            เพิ่ม
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalReference;
