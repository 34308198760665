import React, { useState, useEffect } from "react";
import StepProgress from "../step_progress";
import StepProductConfig from "../step-product-config";
import styles from "./index.module.scss";
import { Form, ErrorMessage } from "formik";
import { useFormikContext } from "formik";
import parse from "html-react-parser";

const Order1MaterialConfigComponent = (props) => {
  const [selectStep] = useState(1);
  const { values, setFieldValue } = useFormikContext();

  return (
    <main>
      <div
        class={`loader loader-default ${
          values.loadingMaterial ? "is-active" : ""
        }`}
      />
      <section className={styles.section1}>
        <StepProgress stepIndex={selectStep} />
      </section>

      <div className={styles.wrapContent}>
        <StepProductConfig />

        <section className={styles.groupOptionProductConfig}>
          <Form>
            <h3 className={styles.titalPage}>
              เลือกเนื้อวัสดุ
              <ErrorMessage
                name="material"
                render={(msg) => <span className="error">{msg}</span>}
              />
            </h3>
            <div className={styles.gruop}>
              {
                // values.optionMaterial.map((data, index) => {
                values.optionMaterial
                  .slice() // Create a shallow copy to avoid mutating the original array
                  .filter((each) => each && each.name)
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort in ascending order by data.name
                  .map((data, index) => {
                    return (
                      <button type="submit" className={styles.nextButton}>
                        <article
                          className={`${styles.smallCardProductConfig} ${
                            values.material === data.name && styles.active
                          }`}
                          onClick={() => {
                            // setFieldValue("index_1", index, true);
                            setFieldValue("material", data.name, true);
                            setFieldValue(
                              "extraDeliveryDay",
                              data.extraDeliveryDay || 0,
                              true
                            );
                            setFieldValue("selectedMaterial", data, false);
                            setFieldValue("coat", "", true);
                            setFieldValue("selectedCoating", "", false);
                            setFieldValue("width", "", true);
                            setFieldValue("height", "", false);
                            setFieldValue("units", null, false);
                            setFieldValue("price", "", false);
                          }}
                        >
                          <img alt="." src={data.imgUrl} width="200" />
                          <div className={styles.gruopDetailProducts}>
                            <h4 className={styles.headDetail}>{data.name}</h4>
                            <p style={{ fontSize: "1.3rem" }}>
                              {data.description && parse(data.description)}
                            </p>
                          </div>
                          {data.imgGalleryUrl && (
                            <>
                              <div className={styles.showPhoto}>
                                <img
                                  alt="."
                                  src={data.imgGalleryUrl}
                                  width="200"
                                />
                              </div>
                            </>
                          )}
                        </article>
                      </button>
                    );
                  })
              }
            </div>

            {/* <button type="submit" className={styles.nextButton}>ถัดไป</button> */}
          </Form>
        </section>
      </div>
    </main>
  );
};

export default Order1MaterialConfigComponent;
