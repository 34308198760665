import React from "react";
import styles from "./index.module.scss";

const ToggleModal = ({ icon, heading, content, url, setIsShow }) => {

  return (
    <div
        className={styles.toggleModal}
    >
        <div className={styles.toggleWrapper}>
            <div className={styles.toggleHeading}>
                <div>
                    {icon && <img src={icon} alt="icon"/>}
                    <label>{heading}</label>
                </div>
                <div className={styles.toggleClose} onClick={()=> setIsShow(false)}><label>X</label></div>
            </div>
            <div className={styles.toggleContent}>
                <div>
                    {url ?  <a href={url} target="_blank"> {content}</a> : <>{content}</>}
                </div>
        </div>
        </div>
    </div>
  );
};

export default ToggleModal;
