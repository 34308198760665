import React, { useState, useEffect, useRef } from "react";
import { withFormik, useFormikContext, Field } from "formik";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  Redirect,
  useParams,
} from "react-router-dom";
import styles from "./index.module.scss";
import axios from "axios";

import StepProgress from "../step_progress";
import AdminKpi from "../admin-kpi";
import CardOrder from "../card-order";
import PreviewImage from "../preview-image";
import GroupDeliveryPayment from "../group-delivery-payment";
import { STATUS_ORDERS_TYPE } from "../constant-variable.js";
import Placeholder from "./gallery.png";

import { ReactComponent as IconArrow } from "../upload-file/icon-arrow.svg";
import AnnotationComponent from "../annotation";
import { ReactComponent as IconCheckSVG } from "../approve-layout/icon-check.svg";
import IconCheckBlackPNG from "../approve-layout/icon-check-black.png";
import IconCheckGreenPNG from "../approve-layout/icon-check-green.png";

import { db } from "../../firebase";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function convertTimestamp(originalTimestamp) {
  // Create a Date object from the original timestamp
  const date = new Date(originalTimestamp);

  // Extract date parts
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Format the date into the desired format
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

const AdminOrderComponent = (props) => {
  let query = useQuery();
  const orderIdByParam = query.get("orderId");
  let { id } = useParams();
  const orderIdByPath = id;
  const { values, setFieldValue } = useFormikContext();
  const [notFound, setNotFound] = useState(false);

  const [selectStep, setSelectStep] = useState(3);
  const [annotationMode, setAnnotationMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [modal, setModal] = useState(false);

  const scrollRef = useRef(null);
  const scrollToElement = () => {
    scrollRef &&
      scrollRef.current &&
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        // block: "start",
        // inline: "start",
      });
  };

  useEffect(() => {
    setSelectedItem(values.itemsList[values.expandCard]);
  }, [values.expandCard, values.itemsList]);

  useEffect(() => {
    if (orderIdByParam) setFieldValue("orderNumber", orderIdByParam, false);
  }, [orderIdByParam, setFieldValue]);

  useEffect(() => {
    if (values.status === "รอชำระเงิน" || values.status === "กำลังดำเนินการ") {
      setSelectStep(3);
    } else if (
      values.status === "กำลังผลิตสินค้า" ||
      values.status === "อยู่ระหว่างจัดส่ง" ||
      values.status === "รายการสำเร็จ" ||
      values.status === "คืนเงินสำเร็จ" ||
      values.status === "ยกเลิก"
    ) {
      setSelectStep(4);
    }
  }, [values.status]);

  useEffect(() => {
    setFieldValue("loading", true, false);
    async function fetchMyAPI() {
      let myOrderId = orderIdByPath;
      // find order document id
      if (orderIdByParam) {
        const searchResult = await db
          .collection("orders")
          .where("orderID", "==", orderIdByParam)
          .get();
        if (searchResult.docs.length > 0) {
          searchResult.docs.map((doc) => (myOrderId = doc.id));
        } else {
          console.log("Not found");
          setNotFound(true);
        }
      }
      if (myOrderId) {
        const unsubscribe = await db
          .collection("orders")
          .doc(myOrderId)
          .onSnapshot((snapshot) => {
            if (snapshot.data()) {
              const data = snapshot.data();
              setNotFound(false);
              setFieldValue(
                "fullFetchData",
                { myID: myOrderId, ...data },
                false
              );

              setFieldValue("myID", myOrderId, false);

              setFieldValue("orderID", data.orderID, false);
              setFieldValue("status", data.status, false);
              setFieldValue("itemsList", data.itemsList, false);
              setFieldValue("shippingAddress", data.shippingAddress, false);
              setFieldValue("shippingNumber", data.shippingNumber, false);
              setFieldValue("shippingCourier", data.shippingCourier, false);
              setFieldValue("itemsCost", data.itemsCost, false);
              setFieldValue("shippingCost", data.shippingCost, false);
              setFieldValue("vatCost", data.vatCost, false);
              setFieldValue("totalCost", data.totalCost, false);
              setFieldValue("paymentMethod", data.paymentMethod, false);
              setFieldValue("paymentStatus", data.paymentStatus, false);
              setFieldValue("paymentConfirm", data.paymentConfirm, false);
              setFieldValue("promotion", data.promotion, false);
              setFieldValue("promotionDiscount", data.promotionDiscount, false);
              setFieldValue("fetchMsg", false, false);
              setFieldValue("loading", false, false);
              setModal(false);
            } else {
              setNotFound(true);
              setFieldValue("loading", false, false);
            }
          });
        setFieldValue("false", true, false);
        return unsubscribe;
      }
    }
    fetchMyAPI();
  }, [orderIdByParam, orderIdByPath, setFieldValue]);

  const handleSubmitStatusOrder = (valueStatus) => {
    setFieldValue("waitProcess", true, false);
    setFieldValue("loading", true, false);
    let data = { status: valueStatus };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/${values.myID}`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // console.log("res.data", res.data)
        // setFieldValue("fetchMsg", true, false);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      });
  };

  const handleSubmitPaymentSlip = () => {
    setFieldValue("waitProcess", true, false);
    setFieldValue("loading", true, false);
    let data = { paymentStatus: "success" };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/${values.myID}`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // console.log("res.data", res.data)
        sendSystemMessage(
          values.myID,
          values.expandCard,
          "ทางเราได้ตรวจสอบการชําระเงินแล้ว กรุณาตรวจสอบแบบและกดอนุมัติแบบให้ด้วยค่ะ",
          setFieldValue
        );
        setFieldValue("fetchMsg", true, false);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      });
  };

  return (
    <main className={styles.wrapContent}>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>
      <section className={styles.section1}>
        <AdminKpi kpi={{ order: 10, sales: 1234567, member: 1000 }} />
      </section>

      {!values.myID && (
        <p style={{ margin: "5px 0", color: "orange" }}>ไม่พบรายการ</p>
      )}

      {values.myID && (
        <>
          <h1 className={styles.title}>รายการออเดอร์</h1>
          <div>
            ออเดอร์หมายเลข {values.orderID}
            <SelectBox
              name="status"
              values={values}
              options={[
                {
                  color: "navyStatus",
                  name: STATUS_ORDERS_TYPE.WAIT_PAYMENT,
                },
                {
                  color: "orangeStatus",
                  name: STATUS_ORDERS_TYPE.DOING,
                },
                {
                  color: "yellowStatus",
                  name: STATUS_ORDERS_TYPE.PRODUCTION,
                },
                {
                  color: "blueStatus",
                  name: STATUS_ORDERS_TYPE.DELIVERY,
                },
                {
                  color: "redStatus",
                  name: STATUS_ORDERS_TYPE.REFUND,
                },
                {
                  color: "greenStatus",
                  name: STATUS_ORDERS_TYPE.REFUNDED,
                },
                {
                  color: "greenStatus",
                  name: STATUS_ORDERS_TYPE.FINISH,
                },
                {
                  color: "redStatus",
                  name: STATUS_ORDERS_TYPE.CANCEL,
                },
              ]}
            />
            <button
              type="button"
              className={styles.btnWhite}
              onClick={() => handleSubmitStatusOrder(values.status)}
              disabled={values.waitProcess ? true : false}
            >
              บันทึก
            </button>
            <button
              type="button"
              className={styles.btnWhite}
              onClick={() => props.history.push(`/admin/pdf/${values.myID}`)}
            >
              พิมพ์
            </button>
            <button
              type="button"
              className={styles.btnWhite}
              onClick={() => {
                let password = prompt(
                  `ใส่รหัสผ่านเพื่อยืนยันการลบออเดอร์ ${values.orderID}`
                );
                if (password === "P@ssw0rd") {
                  setFieldValue("loading", true, false);
                  axios
                    .delete(
                      `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/${values.myID}`,
                      {
                        headers: {
                          Authorization:
                            "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                        },
                      }
                    )
                    .then((res) => {
                      // console.log("res.data", res.data)
                      // setFieldValue("fetchMsg", true, false);
                      setFieldValue("waitProcess", false, false);
                      setFieldValue("loading", false, false);
                      alert(`ลบออเดอร์ ${values.orderID} สำเร็จ`);
                      props.history.push(`/admin`);
                    })
                    .catch(function (err) {
                      console.log("err", err);
                      alert(`เกิดข้อผิดพลาด ลบออเดอร์ ${values.orderID}`);
                      setFieldValue("waitProcess", false, false);
                      setFieldValue("loading", false, false);
                    });
                  // }
                } else if (password == null) {
                  // alert("Please input password");
                } else {
                  alert("รหัสผ่านไม่ถูกต้อง");
                }
                // if (
                //   window.confirm(
                //     `ยืนยันการลบออเดอร์ ${values.orderID} ใช่หรือไม่`
                //   )
                // ) {
                //   setFieldValue("loading", true, false);
                //   axios
                //     .delete(
                //       `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/${values.myID}`,
                //       {
                //         headers: {
                //           Authorization:
                //             "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                //         },
                //       }
                //     )
                //     .then((res) => {
                //       // console.log("res.data", res.data)
                //       // setFieldValue("fetchMsg", true, false);
                //       setFieldValue("waitProcess", false, false);
                //       setFieldValue("loading", false, false);
                //       props.history.push(`/admin`);
                //     })
                //     .catch(function (err) {
                //       console.log("err", err);
                //       setFieldValue("waitProcess", false, false);
                //       setFieldValue("loading", false, false);
                //     });
                // }
              }}
            >
              ลบ
            </button>
          </div>

          <section style={{ marginTop: "20px" }}>
            {`${values.paymentMethod}` === `transfer_money` &&
            `${values.paymentStatus}` === `pending` ? (
              <button
                className={styles.bigBtn}
                type="button"
                onClick={() => {
                  handleSubmitPaymentSlip();
                  scrollToElement();
                }}
                disabled={values.waitProcess ? true : false}
              >
                <h3>
                  <IconCheckSVG />
                  ชำระเงินแล้ว
                </h3>
              </button>
            ) : (
              `${selectedItem && selectedItem.status}` === `รออนุมัติแบบ` && (
                <>
                  {/* <div>
                          <button
                            type="button"
                            // onClick={() => sendItemStatus()}
                            onClick={() => setAnnotationMode(!annotationMode)}
                            disabled={values.waitProcess ? true : false}
                            className={styles.disabledBtn}
                          >
                            <h3>กรุณารอไฟล์แบบภายใน 24 ชั่วโมง</h3>
                          </button>
                        </div> */}

                  <div>
                    <button
                      type="button"
                      // onClick={() => sendItemStatus()}
                      onClick={() => {
                        setAnnotationMode(true);
                        scrollToElement();
                      }}
                      disabled={values.waitProcess ? true : false}
                      className={
                        selectedItem.approvalStatus == "approved"
                          ? styles.bigBtn
                          : styles.approveBtn
                      }
                    >
                      <h3>
                        {/* <IconCheckSVG/> */}
                        {selectedItem.approvalStatus == "approved" ? (
                          <>ดูไฟล์แบบที่อนุมัติ</>
                        ) : (
                          <>อัพโหลดไฟล์ให้ลูกค้า</>
                        )}
                      </h3>
                    </button>
                  </div>
                </>
              )
            )}

            {/* {`${selectedItem && selectedItem.status}` ===
                    "กำลังผลิตสินค้า" && (
                    <>
                      {values.status === "อยู่ระหว่างจัดส่ง" ? (
                        <div>
                          <button
                            type="button"
                            // onClick={() => sendItemStatus()}
                            onClick={() => setAnnotationMode(!annotationMode)}
                            disabled={values.waitProcess ? true : false}
                            className={
                              selectedItem &&
                              selectedItem.approvalStatus == "approved"
                                ? styles.bigBtn
                                : styles.approveBtn
                            }
                          >
                            <h3>
                              <IconCheckSVG />
                              กำลังจัดส่งสินค้า
                            </h3>
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            type="button"
                            // onClick={() => sendItemStatus()}
                            onClick={() => setAnnotationMode(!annotationMode)}
                            disabled={values.waitProcess ? true : false}
                            className={
                              selectedItem &&
                              selectedItem.approvalStatus == "approved"
                                ? styles.bigBtn
                                : styles.approveBtn
                            }
                          >
                            <h3>
                              <IconCheckSVG />
                              สินค้าของคุณกำลังดำเนินการผลิต....
                            </h3>
                          </button>
                        </div>
                      )}
                    </>
                  )} */}

            {`${selectedItem && selectedItem.status}` === "กำลังผลิตสินค้า" && (
              <>
                <div>
                  <button
                    type="button"
                    // onClick={() => sendItemStatus()}
                    onClick={() => {
                      setAnnotationMode(true);
                      scrollToElement();
                    }}
                    disabled={values.waitProcess ? true : false}
                    className={
                      selectedItem && selectedItem.approvalStatus == "approved"
                        ? styles.bigBtn
                        : styles.approveBtn
                    }
                  >
                    <h3>
                      <IconCheckSVG />
                      สินค้าของคุณกำลังดำเนินการผลิต....
                    </h3>
                  </button>
                </div>
              </>
            )}

            {`${selectedItem && selectedItem.status}` === "กำลังจัดส่งสินค้า" &&
              values.status !== "รายการสำเร็จ" && (
                <>
                  <div>
                    <button
                      type="button"
                      // onClick={() => sendItemStatus()}
                      // onClick={() => setAnnotationMode(!annotationMode)}
                      onClick={() => {
                        scrollToElement();
                      }}
                      disabled={values.waitProcess ? true : false}
                      // className={
                      //   selectedItem &&
                      //   selectedItem.approvalStatus == "approved"
                      //     ? styles.bigBtn
                      //     : styles.approveBtn
                      // }
                      className={styles.bigBtnWhite}
                    >
                      <h3>
                        <img src={IconCheckBlackPNG} alt="." />
                        กำลังจัดส่งสินค้า
                      </h3>
                    </button>
                  </div>
                </>
              )}

            {`${values && values.status}` === "รายการสำเร็จ" && (
              <>
                <div>
                  <button
                    type="button"
                    // onClick={() => sendItemStatus()}
                    // onClick={() => setAnnotationMode(!annotationMode)}
                    onClick={() => {
                      scrollToElement();
                    }}
                    disabled={values.waitProcess ? true : false}
                    // className={
                    //   selectedItem &&
                    //   selectedItem.approvalStatus == "approved"
                    //     ? styles.bigBtn
                    //     : styles.approveBtn
                    // }
                    className={styles.bigBtnSuccess}
                  >
                    <h3>
                      รายการสำเร็จ
                      <img src={IconCheckGreenPNG} alt="." />
                    </h3>
                  </button>
                </div>
              </>
            )}
          </section>

          <div style={{ marginBottom: "20px" }}>
            วันที่สั่งซื้อ {convertTimestamp(values.fullFetchData.timeStamp)}
          </div>

          <section className={styles.stepProgressBar}>
            <StepProgress stepIndex={selectStep} />
          </section>
          <section style={{ marginBottom: "10px" }}>
            กรุณาตรวจไฟล์แบบ {values.itemsList.length || 0} รายการ
          </section>
          <section>
            <CardOrder />
          </section>
          <h1 ref={scrollRef}>ใช้สำหรับยืนยันแบบ</h1>

          {annotationMode ? (
            <>
              <section style={{ maxWidth: "900px", margin: "0 auto" }}>
                <AnnotationComponent
                  setAnnotationMode={setAnnotationMode}
                  annotationMode={annotationMode}
                  selectedItem={selectedItem}
                  itemIndex={values.expandCard}
                  order={values}
                />
              </section>
              <section>
                <div style={{ margin: "0 auto", textAlign: "center" }}>
                  <button
                    type="button"
                    // onClick={() => sendItemStatus()}
                    onClick={() => {
                      setAnnotationMode(false);
                      // scrollToElement();
                    }}
                    disabled={values.waitProcess ? true : false}
                    className={styles.bigBtnNew}
                  >
                    <h3>กลับไปดูกล่องข้อความ</h3>
                  </button>
                </div>
              </section>
            </>
          ) : (
            <div className={styles.customGrid50}>
              <>
                <section className={styles.previewImage}>
                  <PreviewImage
                    allMessage={selectedItem ? selectedItem.messages : []}
                    modal={modal}
                    setModal={setModal}
                    paymentConfirm={values.paymentConfirm}
                    sendSystemMessage={sendSystemMessage}
                  />
                </section>

                {values.paymentConfirm.length !== 0 ? (
                  <section className={styles.groupSlipPayment}>
                    <h3>การแจ้งการชำระเงิน</h3>

                    <div className={styles.flexRow}>
                      {values.paymentConfirm.map((data, index) => {
                        return (
                          <acticle className={styles.cardSlip}>
                            <p>ชื่อ นามสกุล*: {data.name}</p>
                            <p>เบอร์โทรศัพท์*: {data.phone}</p>
                            <p>ยอดชำระเงิน: {data.amount}</p>
                            <p>ธนาคารที่โอน: {data.bank}</p>
                            <p>วันที่โอน: {data.date}</p>
                            <p>เวลา: {data.time}</p>
                            <p>
                              สลิปการโอนเงิน:{" "}
                              <a
                                className={styles.dowloadFileMsg}
                                href={data.photo}
                                target="_blank"
                                downloadFile
                              >
                                ดาวน์โหลด
                              </a>
                            </p>
                          </acticle>
                        );
                      })}
                    </div>
                  </section>
                ) : (
                  <section className={styles.groupSlipPayment}>
                    <h3>การแจ้งการชำระเงิน</h3>
                    <div>ไม่พบรายการแจ้งชำระเงิน</div>
                  </section>
                )}
              </>
            </div>
          )}

          <section
            className={styles.groupDeliveryPayment}
            // style={guestMode ? { border: "1px solid #009473" } : {}}
          >
            <GroupDeliveryPayment
              sendSystemMessage={sendSystemMessage}
              itemIndex={values.expandCard}
            />
          </section>
        </>
      )}
    </main>
  );
};

const sendSystemMessage = (myID, itemIndex, message, setFieldValue) => {
  // console.log("myID", myID);
  // console.log("itemIndex", itemIndex);
  // console.log("message", message);
  if (myID && message) {
    let url = window.location.pathname;
    let data = {
      itemIndex: itemIndex,
      msg: {
        by: "admin",
        content: message,
        timestamp: new Date(),
        type: "text",
      },
    };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orderItemMsg/${myID}`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // if (setFieldValue) setFieldValue("fetchMsg", true, false);
        console.log("SEND SYSTEM MESSAGE", res);
      })
      .catch(function (err) {
        console.log("err", err);
      });
  }
};

const EnhancedAdminOrderComponent = withFormik({
  mapPropsToValues: (props) => ({
    status: 0,
    massage: "", //สำหรับ Chat Room
    orderNumber: "", //สำหรับค้นหาเลขที่ออเดอร์
    expandCard: 0, //สำหรับเลือกว่ากด Card ไหน

    orderID: [],
    status: [],
    itemsList: [],
    paymentConfirm: [],
    isAdmin: true,

    fetchMsg: false,
    waitProcess: false,
    loading: false,
  }),
})(AdminOrderComponent);

export default EnhancedAdminOrderComponent;

const SelectBox = ({ values, name, options }) => {
  return (
    <div className={styles.selectBox}>
      <div className={styles.selectBoxCurrent} tabindex="1">
        {options.map((list, index) => {
          let lastIndex = index + 1;
          return (
            <div className={styles.selectBoxValue}>
              <Field
                name={name}
                type="radio"
                className={styles.selectBoxInput}
                value={list.name}
                id={`${name}-${lastIndex}`}
                checked={`${values[name]}` === `${list.name}` ? true : false}
              />
              <p
                className={`${styles.selectBoxInputText} ${styles[list.color]}`}
              >
                {list.name}
              </p>
            </div>
          );
        })}

        <div className={styles.selectBoxValue}>
          <Field
            name={name}
            type="radio"
            className={styles.selectBoxInput}
            value="0"
            id={`${name}-0`}
            checked={`${values[name]}` === `${0}` ? true : false}
          />
          <p className={styles.selectBoxInputText}>กรุณาเลือก...</p>
          <IconArrow />
        </div>
      </div>
      <ul className={styles.selectBoxList}>
        {options.map((list, index) => {
          let lastIndex = index + 1;
          return (
            <li>
              <label
                className={`${styles.selectBoxOption} ${styles[list.color]}`}
                for={`${name}-${lastIndex}`}
              >
                {list.name}
              </label>
            </li>
          );
        })}
        <li>
          <label className={styles.selectBoxOption} for={`${name}-0`}>
            กรุณาเลือก...
          </label>
        </li>
      </ul>
    </div>
  );
};
