import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";
import firebaseApp from "../../firebase/index.js";

const ModalShape = ({ values, setFieldValue }) => {
  const [displayImage, setDisplayImage] = useState("");
  useEffect(() => {
    if (values.selectedShape.myID || values.selectedShape.myID === 0) {
      setFieldValue(
        `${values.modalShape}Name`,
        values.selectedShape.name,
        false
      );
      setFieldValue(
        `${values.modalShape}File`,
        values.selectedShape.imgUrl,
        false
      );
      setFieldValue(
        `${values.modalShape}Type`,
        values.selectedShape.type,
        false
      );
      setDisplayImage(values.selectedShape.imgUrl);
    } else {
      setFieldValue(`${values.modalShape}Name`, "", false);
      setFieldValue(`${values.modalShape}File`, "", false);
      setFieldValue(`${values.modalShape}Type`, "", false);
      setDisplayImage("");
    }
    // if(values.modalShape == "modalShapeAdd") {
    //   setFieldValue(`${values.modalShape}Name`, "", false);
    //   setFieldValue(`${values.modalShape}File`, "", false);
    //   setFieldValue(`${values.modalShape}Type`, "", false);
    //   setDisplayImage("")
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.modalShape, values.selectedShape]);

  const addOptionShape = () => {
    setFieldValue("loading", true, false);
    let name = values[`${values.modalShape}Name`];
    let type = values[`${values.modalShape}Type`];

    if (type !== "ม้วน" && type !== "แผ่น") type = "ม้วน";

    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    if (
      values[`${values.modalShape}File`] &&
      values[`${values.modalShape}File`] instanceof Blob
    ) {
      storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalShape}File`].name
          }`
        )
        .put(values[`${values.modalShape}File`])
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            let dataPost = {
              imgUrl: url,
              name: name,
              type: type,
            };
            axios
              .post(
                `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew`,
                dataPost,
                {
                  headers: {
                    Authorization:
                      "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                  },
                }
              )
              .then((res) => {
                setFieldValue("fetch", true, false);
                setFieldValue("modalShape", "", false);
                setFieldValue("loading", false, false);
              })
              .catch(function (err) {
                setFieldValue("loading", false, false);
                console.log("err", err);
              });
          });
        });
    } else {
      let dataPost = {
        name: name,
        type: type,
      };
      axios
        .post(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("loading", false, false);
          setFieldValue("modalShape", "", false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    }
  };

  const editOptionShape = () => {
    setFieldValue("loading", true, false);
    let name = values[`${values.modalShape}Name`];
    let type = values[`${values.modalShape}Type`];
    console.log("values", values);

    if (type !== "ม้วน" && type !== "แผ่น") type = "ม้วน";

    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    if (
      values[`${values.modalShape}File`] &&
      values[`${values.modalShape}File`] instanceof Blob
    ) {
      storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalShape}File`].name
          }`
        )
        .put(values[`${values.modalShape}File`])
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            let dataPost = {
              imgUrl: url,
              name: name,
              type: type,
            };
            axios
              .put(
                `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}`,
                dataPost,
                {
                  headers: {
                    Authorization:
                      "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                  },
                }
              )
              .then((res) => {
                setFieldValue("fetch", true, false);
                setFieldValue("modalShape", "", false);
                setFieldValue("loading", false, false);
              })
              .catch(function (err) {
                setFieldValue("loading", false, false);
                console.log("err", err);
              });
          });
        });
    } else {
      let dataPost = {
        name: name,
        type: type,
      };
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("loading", false, false);
          setFieldValue("modalShape", "", false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    }
  };

  const removeOptionShape = () => {
    setFieldValue("loading", true, false);
    axios
      .delete(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        setFieldValue("fetch", true, false);
        setFieldValue("modalShape", "", false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  };

  const handleChangeImgShape = (event) => {
    if (event.target.files) {
      setFieldValue(`${values.modalShape}File`, event.target.files[0], false);
    }
  };

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalShape ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalShape", "", false);
              // setFieldValue("selectedShape", {}, false);
            }}
          >
            &times;
          </span>
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalShape}Name`}
            className={styles.text}
            placeholder="รูปแบบ"
          />
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalShape}Type`}
            className={styles.text}
            placeholder="ประเภท"
          />
          <label style={{ fontSize: "1rem" }}>(ม้วน / แผ่น เท่านั้น)</label>
        </div>

        <div className={styles.rowInModal}>
          <input
            type="file"
            id="file"
            onChange={(e) => handleChangeImgShape(e)}
          />
          <label
            for="file"
            className={`${styles.buttonUploadFile} ${styles.label}`}
          >
            อัพโหลดไฟล์
          </label>
        </div>
        <div className={styles.displayImage}>
          {displayImage && <img src={displayImage} />}
        </div>
        <div className={`${styles.floatRight} ${styles.rowInModal}`}>
          {values.selectedShape.myID || values.selectedShape.myID === 0 ? (
            <button
              type="button"
              className={styles.removeOption}
              onClick={() => removeOptionShape()}
            >
              ลบ
            </button>
          ) : null}
          <button
            type="button"
            className={styles.addOption}
            onClick={() =>
              values.selectedShape.myID || values.selectedShape.myID === 0
                ? editOptionShape()
                : addOptionShape()
            }
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalShape;
