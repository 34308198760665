import React, { useState, useEffect } from "react";
import md5 from "md5";
import ip from "ip";
import {
  useFormikContext,
  withFormik,
  Form,
  Field,
  ErrorMessage,
} from "formik";

import { Link, useParams } from "react-router-dom";

import LocationFieldsComponent from "../location-fields";

import styles from "./index.module.scss";

import { auth } from "../../firebase/index.js";
import axios from "axios";
import { axiosInst } from "../common-scss/common";
import { i18_th as i18 } from "../common-scss/i18_text";

import addressData from "./address.json";

const EditAddressComponent = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const [optionCounty, setOptionCounty] = useState([]);
  const [optionCity, setOptionCity] = useState([]);
  let { id } = useParams();
  const documentIdByPath = id;
  // const sendSystemMessage = props.sendSystemMessage;
  // console.log("A", props);

  useEffect(() => {
    setFieldValue("documentIdByPath", documentIdByPath);
  }, [documentIdByPath, setFieldValue]);

  useEffect(() => {
    setFieldValue("loading", true, false);
    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/${documentIdByPath}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        const orderDetail = res.data;

        console.log("orderDetail", orderDetail);

        setFieldValue(
          "orderID",
          orderDetail.orderID ? orderDetail.orderID : "",
          false
        );
        setFieldValue(
          "address",
          orderDetail.shippingAddress
            ? orderDetail.shippingAddress.address
            : "",
          false
        );
        setFieldValue(
          "line",
          orderDetail.shippingAddress ? orderDetail.shippingAddress.line : "",
          false
        );
        setFieldValue(
          "county",
          orderDetail.shippingAddress ? orderDetail.shippingAddress.county : "",
          false
        );
        setFieldValue("email", orderDetail.shippingAddress.email, false);
        setFieldValue(
          "fullname",
          orderDetail.shippingAddress.fullname
            ? orderDetail.shippingAddress.fullname
            : orderDetail.fullname,
          false
        );
        setFieldValue(
          "phone",
          orderDetail.shippingAddress.phone
            ? orderDetail.shippingAddress.phone
            : orderDetail.phone,
          false
        );
        setFieldValue(
          "provice",
          orderDetail.shippingAddress
            ? orderDetail.shippingAddress.provice
            : "",
          false
        );
        setFieldValue(
          "zip",
          orderDetail.shippingAddress ? orderDetail.shippingAddress.zip : "",
          false
        );
        setFieldValue(
          "city",
          orderDetail.shippingAddress ? orderDetail.shippingAddress.city : "",
          false
        );

        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  }, [documentIdByPath, setFieldValue]);

  useEffect(() => {
    // console.log("VALUE", values);

    let newOptionCity = addressData.filter((item) => {
      return item.district == values.county;
    });
    let resultCity = newOptionCity.reduce((unique, o) => {
      if (!unique.some((obj) => obj.sub_district === o.sub_district)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setOptionCity(resultCity);
    // console.log("newOptionCity", resultCity);

    let newOptionCounty = addressData.filter((item) => {
      return item.province == values.provice;
    });
    let resultCounty = newOptionCounty.reduce((unique, o) => {
      if (!unique.some((obj) => obj.district === o.district)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setOptionCounty(resultCounty);
    // console.log("newOptionCounty", resultCounty);
  }, [values]);

  return (
    <>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>
      {/* <section className={styles.section1}>
        <StepProgress stepIndex={selectStep} />
      </section> */}

      <Form>
        <section className={styles.myGrid}>
          <div className={styles.myBox}></div>
          <div className={styles.myBox}>
            <h3>ที่อยู่จัดส่ง</h3>
            <LocationFieldsComponent
              optionCity={optionCity}
              optionCounty={optionCounty}
            />

            <button type="submit" className={styles.buttonNext}>
              ยืนยันการแก้ไข
            </button>
            <div style={{ marginTop: "15px" }}>
              <Link to={`/myorder/${documentIdByPath}`}>กลับไปดูออเดอร์</Link>
            </div>
          </div>
        </section>
      </Form>
    </>
  );
};

const EnhancedEditAddressComponent = withFormik({
  mapPropsToValues: () => ({
    documentIdByPath: "",
    orderID: "",
    email: "",
    phone: "",
    address: "",
    county: "",
    city: "",
    provice: "",
    zip: "",
    line: "",
    loading: false,
  }),
  validate: (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = i18.required;
    }
    if (!values.phone) {
      errors.phone = i18.required;
    }
    if (
      !(values.phone.match(/^[0-9]+$/) != null) ||
      values.phone.length < 8 ||
      values.phone.length > 10
    ) {
      errors.phone = "ต้องเป็นตัวเลข 9-10 ตัวอักษรและไม่มีอักขระ";
    }
    if (!values.address) {
      errors.address = i18.required;
    }
    if (!values.fullname) {
      errors.fullname = i18.required;
    }
    if (!values.county) {
      errors.county = i18.required;
    }
    if (!values.city) {
      errors.city = i18.required;
    }
    if (!values.provice) {
      errors.provice = i18.required;
    }
    if (!values.zip) {
      errors.zip = i18.required;
    }

    return errors;
  },
  handleSubmit: (values, { props, setFieldValue }) => {
    setFieldValue("loading", true, false);

    console.log("V", values);

    let data = {
      shippingAddress: {
        address: values.address,
        city: values.city,
        county: values.county,
        fullname: values.fullname,
        provice: values.provice,
        zip: values.zip,
        // phone: values.phone.replace(/[^a-zA-Z ]/g, ""),
        phone: values.phone,
        email: values.email,
        line: values.line,
      },
      orderID: values.orderID,
    };
    console.log("Editing order", data);

    // const sendSystemMessage = (myID, itemIndex, message) => {
    //   // console.log("myID", myID);
    //   // console.log("itemIndex", itemIndex);
    //   // console.log("message", message);
    //   if (myID && message) {
    //     let data = {
    //       itemIndex: itemIndex,
    //       msg: {
    //         by: "admin",
    //         content: message,
    //         timestamp: new Date(),
    //         type: "text",
    //       },
    //     };
    //     axios
    //       .put(
    //         `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orderItemMsg/${myID}`,
    //         data,
    //         {
    //           headers: {
    //             Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
    //           },
    //         }
    //       )
    //       .then((res) => {
    //         // if (setFieldValue) setFieldValue("fetchMsg", true, false);
    //         console.log("SEND SYSTEM MESSAGE", res);
    //       })
    //       .catch(function (err) {
    //         console.log("err", err);
    //       });
    //   }
    // };

    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/shippingAddress/${values.documentIdByPath}`,
        // `http://localhost:5001/digitalwish-sticker/asia-east2/orders/shippingAddress/${values.documentIdByPath}`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // sendSystemMessage(
        //   values.myID,
        //   values.expandCard,
        //   `ทางบริษัทได้ทำการจัดส่งสินค้าให้ลูกค้าเรียบร้อยแล้วค่ะ เลข Tracking: ${values.shippingNumber} หรือกดติดตามสถานะการจัดส่งสินค้าได้ทางด้านล่างค่ะ`,
        // );
        /* Redirecting the user to the page `/myorder/${values.orderID}` */
        window.location.href = `/myorder/${values.documentIdByPath}`;
        console.log("res", res);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        setFieldValue("loading", false, false);
        console.log("err 2", JSON.stringify(err));
      });
  },
  displayName: "EditAddressComponentForm",
})(EditAddressComponent);

export default EnhancedEditAddressComponent;
