export const i18_th = {
  required: "*กรุณาระบุ",

  password_repeat_different: "*รหัสต่างกัน",

  password: {
    wrong_previous_password: "wrong previous password",
    wrong_general: "General wrong password",
  },

  label: {
    password_previous: "รหัสผ่านเดิม",
  },

  login_successful: "เข้าสู้ระบบสำเร็จ",
  login_failed: "ชื่อผู้ใช้ หรือ รหัสผ่าน ผิด",
  account_creation_successful: "สร้างบัญชีสำเร็จ",
  account_creation_failed_general: "สร้างบัญชีไม่สำเร็จ",
  account_creation_failed_email_already_exists: "E-mail ถูกใช้งานแล้ว",
  account_creation_failed_password_too_weak: "รหัสผ่าน อ่อนเกินไป",

  account_password_change_success: "เปลี่ยนรหัสผ่านเรียบร้อย",
  account_password_change_failed_general: "เกิดข้อผิดพลาดในการเปลี่ยนรหัสผ่าน",

  account_password_previous_is_wrong: "รหัสผ่านไม่ถูกต้อง!",

  account_information_update_success: "อัพเดทข้อมูลเรียบร้อย!",
  account_information_update_failed_general: "เกิดข้อผิดพลาดในการอัพเดทข้อมูล!",

  account_my_account: "โปรไฟล์ของฉัน",
  account_login: "เข้าสู่ระบบ",
  account_my_cart: "ตะกร้าสินค้า",
};

function language_mapper(lang_ISO639) {
  switch (lang_ISO639) {
    case "en":
      return {
        123: "wow",
      };
    default:
      return {};
  }
}

function _i18() {
  const lang = {
    success: {
      id: 123,
    },
  };

  Object.keys(lang).forEach((key) => {
    lang[key]["text"] = language_mapper("en")[lang[key]?.id];
  });

  return lang;
}

// console.log(_i18().success)
