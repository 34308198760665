/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useFormikContext, Field } from "formik";

import Kerry from "../approve-layout/kerry.png";
import axios from "axios";
import ThPost from "./thPost.png";

import logoSiamCommercialBank from "../cart/SiamCommercialBank.jpg";
import logoKBank from "../cart/kbank.jpg";
import logoQrCode from "../cart/qrcode.png";
import TranferBank from "../cart/tranfer.png";

import Placeholder from "./gallery.png";
import PlaceholderAI from "./ai.png";
import PlaceholderPDF from "./pdf.png";
import mymap from "./mapDW.jpg";
import { Link } from "react-router-dom";

const Payment = [
  {
    icon: logoSiamCommercialBank,
    name: "Siam Commercial Bank",
    code: "internetbank_scb",
  },
  {
    icon: logoKBank,
    name: " Kasikorn Bank",
    code: "payplus_kbank",
  },
  {
    icon: logoQrCode,
    name: "QR Code",
    code: "bank_qrcode",
  },
  {
    icon: TranferBank,
    name: "โอนเงินแนบสลิป",
    code: "transfer_money",
  },
];

const GroupDeliveryPaymentComponent = (props) => {
  const { values, setFieldValue } = useFormikContext();
  console.log("values", values);
  const [shippingOption, setShippingOption] = useState([]);
  const sendSystemMessage = props.sendSystemMessage;
  const itemIndex = props.itemIndex;

  const handleSubmitTrackingNumber = () => {
    setFieldValue("waitProcess", true, false);
    setFieldValue("loading", true, false);
    // console.log("VV", values);
    let data = {
      itemIndex,
      ...values.fullFetchData,
      shippingNumber: values.shippingNumber,
    };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/tracking/${values.myID}`,
        // `http://localhost:5001/digitalwish-sticker/asia-east2/orders/tracking/${values.myID}`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log(res);
        sendSystemMessage(
          values.myID,
          values.expandCard,
          `ทางบริษัทได้ทำการจัดส่งสินค้าให้ลูกค้าเรียบร้อยแล้วค่ะ เลข Tracking: ${values.shippingNumber} หรือกดติดตามสถานะการจัดส่งสินค้าได้ทางด้านล่างค่ะ`,
          setFieldValue
        );
        console.log("res.data", res.data);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
        setFieldValue("fetchMsg", true, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      });
  };

  const handleSubmitNotifyPicking = () => {
    setFieldValue("waitProcess", true, false);
    setFieldValue("loading", true, false);
    // console.log("AA", values);
    let data = {
      itemIndex,
      ...values.fullFetchData,
      shippingNumber: values.shippingNumber,
    };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/tracking/${values.myID}`,
        // `http://localhost:5001/digitalwish-sticker/asia-east2/orders/tracking/${values.myID}`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log(res);
        sendSystemMessage(
          values.myID,
          values.expandCard,
          `ทางบริษัทได้ทำการผลิตสินค้าเสร็จเรียบร้อยแล้ว ลูกค้าสามารถมารับสินค้าได้ตามแผนที่ด้านล่างค่ะ`,
          setFieldValue
        );
        console.log("res.data", res.data);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
        setFieldValue("fetchMsg", true, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      });
  };

  useEffect(() => {
    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/shippingOptions`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("Shipping", res.data);
        setShippingOption(res.data);
        setFieldValue("fetch", false, false);

        // const myShipping = Shipping.find(item => return  item.courier == shippingCourier)

        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  }, []);

  if (!values.shippingAddress) {
    return <div></div>;
  } else {
    return (
      <>
        <div className={styles.groupDelivery}>
          <h3>การจัดส่ง</h3>
          <Link to={`/myorder/edit/${values.myID}`}>
            <div style={{ color: "#009473" }}>แก้ไขการจัดส่ง</div>
          </Link>
          <div className={styles.containerInformationCustomer}>
            <ul>
              <li>{values.shippingAddress.fullname}</li>
              <li>
                {values.shippingAddress.address} {values.shippingAddress.city}{" "}
                {values.shippingAddress.county} {values.shippingAddress.provice}{" "}
                {values.shippingAddress.zip}
              </li>
            </ul>
            <ul>
              <li>เบอร์โทรศัพท์: {values.shippingAddress.phone}</li>
              <li>อีเมล: {values.shippingAddress.email}</li>
            </ul>
          </div>
          <div className={styles.containerInformation}>
            <h4>จัดส่งโดย</h4>
            <div className={styles.customBlock}>
              <div>
                <img
                  src={
                    shippingOption?.find(
                      (ele) => ele.courier === values.shippingCourier
                    )?.imgUrl
                  }
                  className={styles.deliveryBy}
                  alt="kerry"
                  width="125"
                />
              </div>
              {values.isAdmin &&
                values.shippingCourier === "มารับเองที่โรงงาน" && (
                  <div style={{ marginTop: "15px", marginLeft: "10px" }}>
                    <button
                      onClick={() => handleSubmitNotifyPicking()}
                      style={{ marginLeft: "0", marginBottom: "20px" }}
                      disabled={values.waitProcess ? true : false}
                    >
                      ส่งการแจ้งเตือนให้ลูกค้า
                    </button>
                  </div>
                )}
            </div>
            {values.shippingCourier === "มารับเองที่โรงงาน" ? (
              <>
                <div>
                  <img
                    src={mymap}
                    className={styles.deliveryBy}
                    alt="digitalwish"
                    style={{ maxWidth: "80%" }}
                  />
                </div>
              </>
            ) : (
              <label style={{ fontSize: "1.1rem" }}>
                เลข Tracking:{" "}
                <Field
                  name="shippingNumber"
                  className={styles.inputShippingNumber}
                  type="text"
                  disabled={values.isAdmin ? false : true}
                />
                {values.isAdmin ? (
                  <button
                    onClick={() => handleSubmitTrackingNumber()}
                    disabled={values.waitProcess ? true : false}
                  >
                    ยืนยัน
                  </button>
                ) : (
                  <button>
                    <a
                      target="_blank"
                      href={
                        shippingOption?.find(
                          (ele) => ele.courier === values.shippingCourier
                        )?.shipUrl
                      }
                    >
                      ติดตาม
                    </a>
                  </button>
                )}
              </label>
            )}
          </div>
        </div>

        <div className={styles.groupPayment}>
          <h3 style={{ marginBottom: "20px" }}>การชำระเงิน</h3>
          <label>
            {Payment.map((data) => {
              if (data.code === values.paymentMethod)
                return (
                  <>
                    <img
                      src={data.icon}
                      className={styles.logoBank}
                      width="25"
                      alt="kerry"
                    />
                    {data.name}
                  </>
                );
            })}
          </label>

          <table>
            <thead>
              <tr>
                <th colspan="2" className={styles.textLeft}>
                  สินค้า
                </th>
                <th>จำนวน</th>
                <th>ราคาต่อชิ้น</th>
                <th>มูลค่า</th>
              </tr>
            </thead>
            <tbody>
              {values.itemsList.map((listCard, index) => {
                let extension = listCard.messages[0].info
                  .toLowerCase()
                  .split(".")
                  .pop();
                return (
                  <tr>
                    <td>
                      {/* <img src={listCard.messages[0].content} alt="Product" /> */}
                      {extension === "ai" && (
                        <img
                          // src={dataObjectMapped.messages[0].content}
                          src={PlaceholderAI}
                          className={styles.productPreview}
                          alt="Product"
                        />
                      )}
                      {extension === "pdf" && (
                        <img
                          // src={dataObjectMapped.messages[0].content}
                          src={PlaceholderPDF}
                          className={styles.productPreview}
                          alt="Product"
                        />
                      )}
                      {["png", "jpeg", "jpg", "gif"].includes(extension) && (
                        <img
                          // src={dataObjectMapped.messages[0].content}
                          src={Placeholder}
                          className={styles.productPreview}
                          alt="Product"
                        />
                      )}
                      {!["ai", "pdf", "png", "jpeg", "jpg", "gif"].includes(
                        extension
                      ) && (
                        <img
                          // src={dataObjectMapped.messages[0].content}
                          src={Placeholder}
                          className={styles.productPreview}
                          alt="Product"
                        />
                      )}
                    </td>
                    <td style={{ minWidth: "60px", whiteSpace: "nowrap" }}>
                      <p>{listCard.shape}</p>
                      <span style={{ fontSize: "1rem" }}>
                        {listCard.material} - {listCard.coat} - ขนาด{" "}
                        {listCard.width}x{listCard.height} mm
                      </span>
                    </td>
                    <td className={styles.textCenter}>{listCard.units}</td>
                    <td className={styles.textCenter}>
                      {((listCard.price / listCard.units) * 1.07).toFixed(3)}฿
                    </td>
                    <td className={styles.textCenter}>
                      {(listCard.price * 1.07).toFixed(2)}฿
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tbody>
              <tr>
                <td colspan="4" className={styles.borderTop}>
                  ค่าสินค้ารวม VAT 7%
                </td>
                <td className={`${styles.textCenter} ${styles.borderTop}`}>
                  {(values.itemsCost * 1.07).toFixed(2)}฿
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  ส่วนลดสินค้า{" "}
                  <div className={styles.desciption}>
                    {values.promotion && (
                      <>
                        โค้ดส่วนลด: {values.promotion.code} ส่วนลด{" "}
                        {values.promotion.discountPercent}%
                      </>
                    )}
                  </div>
                </td>
                <td className={styles.textCenter}>
                  {values.promotionDiscount &&
                    parseFloat(values.promotionDiscount).toFixed(2)}
                  ฿
                </td>
              </tr>
              <tr>
                <td colspan="4">
                  ค่าจัดส่ง{" "}
                  <div className={styles.desciption}>
                    {values.shippingCourier ? (
                      <div>
                        {values.shippingCourier} -{" "}
                        {values.fullFetchData.shippingDuration} วันทำการ
                      </div>
                    ) : (
                      "กรุณาเลือกการจัดส่ง"
                    )}
                  </div>
                </td>
                <td className={styles.textCenter}>
                  {values.shippingCost &&
                    parseInt(values.shippingCost).toFixed(2)}
                  ฿
                </td>
              </tr>
              {/* <tr>
                <td colspan="3" className={styles.borderBottom}>
                  ภาษี 7%
                </td>
                <td className={`${styles.textRight} ${styles.borderBottom}`}>
                  {values.vatCost}฿
                </td>
              </tr> */}
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">รวมทั้งหมด</td>
                <td className={styles.textCenter}>
                  {values.totalCost && values.totalCost.toFixed(2)}฿
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        {values &&
          (values.fullFetchData.billingAddress.isTaxDocument === "2" ||
            values.fullFetchData.billingAddress.isTaxDocument === "3") && (
            <div className={styles.groupDelivery}>
              <h3>ออกใบกำกับภาษี</h3>
              <div>
                <p>
                  <b>ชื่อ:</b> {values.fullFetchData.billingAddress.fullname}
                </p>
                <p>
                  <b>ที่อยู่:</b>{" "}
                  {values.fullFetchData.billingAddress.fulladdress}
                </p>
                <p>
                  <b>เลขประจำตัวผู้เสียภาษี:</b>{" "}
                  {values.fullFetchData.billingAddress.taxID}
                </p>
              </div>
            </div>
          )}
      </>
    );
  }
};

export default GroupDeliveryPaymentComponent;
