import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import NavBarComponent from "./components/navbar";
import HomeComponent from "./components/home";
import ApproveLayoutComponent from "./components/approve-layout";
import FormStepShopping from "./components/form_step_shopping";
import FAQ from "./components/faq";

import CartComponent from "./components/cart";
import CheckoutComponent from "./components/checkout";

import AdminListComponent from "./components/admin";
import AdminLoginComponent from "./components/admin-login";
import AdminNavBarComponent from "./components/admin-navbar";
import AdminOrderComponent from "./components/admin-order";
import AdminProductOptionComponent from "./components/admin-product-option";
import AdminDeliveryComponent from "./components/admin-delivery";
import AdminProductOptionNewComponent from "./components/admin-product-option-new";
import AdminPromotionComponent from "./components/admin-promotion";
import AdminReferenceComponent from "./components/admin-reference";
import AdminReviewComponent from "./components/admin-review";

import HomeMemberComponent from "./components/home-member";
import MemberComponent from "./components/member";
import MemberSettingComponent from "./components/member-setting";
import MemberLoginComponent from "./components/member-login";
import MemberRegsterComponent from "./components/member-register";
import MemberAccountComponent from "./components/member-account";
import MemberListComponent from "./components/member-list";
import AdminDemoMTG from "./components/admin-demo-mgt";

import EnhancedEditAddressComponent from "./components/edit-address";

import SuccessfulComponent from "./components/successful";
import OrderFailComponent from "./components/fail";

import { PDF } from "./components/pdf/index";

import * as Sentry from "@sentry/react";

import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
const GA_TRACKING_ID = "UA-142409516-2";
ReactGA.initialize(GA_TRACKING_ID);

const tagManagerArgs = {
  gtmId: "GTM-NLZDCX7",
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: "https://e2b8f8ac1d25bed9d3a9478ca5977465@o4505345515913216.ingest.sentry.io/4505839854485504",
  integrations: [
    // new Sentry.BrowserTracing({
    //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  let url = window.location.pathname;
  let admin = url.search("admin");
  const location = useLocation();
  let mode = 1;
  if (location.pathname === "/") mode = 1;
  else if (location.pathname === "/myorder") mode = 2;
  else mode = 3;

  let bgColor = "transparent";
  if (mode === 1) bgColor = "transparent";
  else if (mode === 2) bgColor = "rgba(255, 255, 255, 0.5)";
  else if (mode === 3)
    bgColor =
      "linear-gradient(90deg, rgba(120,202,237,1) 0%, rgba(246,237,158,1) 65%, rgba(129,200,204,1) 100%)";
  console.log(bgColor);

  let position = "relative";
  position = mode === 3 ? "relative" : "absolute";
  console.log(position);

  return (
    <div className="App">
      {`${admin}` === "-1" ? (
        <NavBarComponent bgColor={bgColor} position={position} />
      ) : (
        <AdminNavBarComponent />
      )}
      <Switch>
        <Route path="/" exact component={HomeComponent} />

        <Route path="/login" exact component={MemberLoginComponent} />
        <Route path="/register" exact component={MemberRegsterComponent} />

        <Route path="/myorder" exact component={ApproveLayoutComponent} />
        <Route
          path="/myorder/edit/:id"
          component={EnhancedEditAddressComponent}
        />
        <Route path="/myorder/:id" component={ApproveLayoutComponent} />

        <Route path="/member" exact component={HomeMemberComponent} />
        <Route
          path="/member/setting"
          exact
          component={MemberAccountComponent}
        />

        <Route path="/cart" exact component={CheckoutComponent} />
        <Route path="/checkout" exact component={CartComponent} />
        <Route path="/customize" exact component={FormStepShopping} />
        <Route path="/faq" exact component={FAQ} />

        <Route
          path="/e-sticker-frontend/successful"
          exact
          component={SuccessfulComponent}
        />
        <Route
          path="/e-sticker-frontend/fail"
          exact
          component={OrderFailComponent}
        />

        <Route path="/admin/login" exact component={AdminLoginComponent} />
        <Route path="/admin" exact component={AdminListComponent} />
        <Route path="/admin/member" exact component={MemberComponent} />
        <Route path="/admin/setting" exact component={MemberSettingComponent} />
        <Route path="/admin/customer" exact component={MemberListComponent} />
        <Route
          path="/admin/myorder/:id"
          exact
          component={AdminOrderComponent}
        />
        {/* <Route
          path="/admin/product"
          exact
          component={AdminProductOptionComponent}
        /> */}
        <Route
          path="/admin/delivery"
          exact
          component={AdminDeliveryComponent}
        />
        <Route
          path="/admin/product-new"
          exact
          component={AdminProductOptionNewComponent}
        />
        <Route
          path="/admin/promotion"
          exact
          component={AdminPromotionComponent}
        />
        <Route path="/admin/review" exact component={AdminReviewComponent} />
        <Route
          path="/admin/reference"
          exact
          component={AdminReferenceComponent}
        />
        <Route path="/admin/demo" exact component={AdminDemoMTG} />

        <Route path="/admin/pdf/:id" exact component={PDF} />
      </Switch>
    </div>
  );
}

export default App;
