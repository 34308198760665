import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import ReactGA from "react-ga";
import { useTranslation, Trans } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

const FaqComponent = () => {
  useEffect(() => {
    ReactGA.pageview("/faq");
  }, []);

  const { t, i18n } = useTranslation();
  const lngs = {
    th: { nativeName: "ภาษาไทย" },
    en: { nativeName: "English" },
  };

  const [allData, setAllData] = useState();

  useEffect(() => {
    fetch("locales/th/translation.json")
      .then((r) => r.json())
      .then((data) => {
        // console.log(data);
        setAllData(data);
      });
  }, []);

  return (
    <>
      <div className={styles.qaSection}>
        <h1 style={{ textAlign: "center" }}>{t("qna.title")}</h1>
        {/* <div style={{ textAlign: "center" }}>ตัวเลือกภาษา: ไทย</div> */}

        <div style={{ textAlign: "center" }}>
          {Object.keys(lngs).map((lng) => (
            <>
              <button
                key={lng}
                className={
                  i18n.resolvedLanguage === lng
                    ? styles.activeLanguage
                    : "normal"
                }
                onClick={() => i18n.changeLanguage(lng)}
              >
                {lngs[lng].nativeName}
              </button>
            </>
          ))}
        </div>

        {/* Group 1 */}
        <h2>{allData && t("questionsGroup1.name")}</h2>
        {allData &&
          allData.questionsGroup1.questions.map((question, index) => {
            const questionGroup = allData.questionsGroup1.questionGroup;
            return (
              <>
                <div className={styles.qa} key={index}>
                  <h3 className={styles.question}>
                    {allData[questionGroup].questions[index].id}){" "}
                    {allData[questionGroup].questions[index].questionText &&
                      ReactHtmlParser(
                        t(`${questionGroup}.questions.${index}.questionText`)
                      )}
                  </h3>
                  <p className={styles.answer}>
                    {allData[questionGroup].questions[index].answerText &&
                      ReactHtmlParser(
                        t(`${questionGroup}.questions.${index}.answerText`)
                      )}
                    {allData[questionGroup].questions[index].imgs &&
                      allData[questionGroup].questions[index].imgs.map(
                        (img) => {
                          return (
                            <div>
                              <a
                                href={`${window.location.origin}/${t(img)}`}
                                target="_tab"
                              >
                                <img
                                  src={`${window.location.origin}/${t(img)}`}
                                />
                              </a>
                            </div>
                          );
                        }
                      )}
                  </p>
                </div>
              </>
            );
          })}

        {/* Group 2 */}
        <h2>{allData && t("questionsGroup2.name")}</h2>
        {allData &&
          allData.questionsGroup2.questions.map((question, index) => {
            const questionGroup = allData.questionsGroup2.questionGroup;
            return (
              <>
                <div className={styles.qa} key={index}>
                  <h3 className={styles.question}>
                    {allData[questionGroup].questions[index].id}){" "}
                    {allData[questionGroup].questions[index].questionText &&
                      ReactHtmlParser(
                        t(`${questionGroup}.questions.${index}.questionText`)
                      )}
                  </h3>
                  <p className={styles.answer}>
                    {allData[questionGroup].questions[index].answerText &&
                      ReactHtmlParser(
                        t(`${questionGroup}.questions.${index}.answerText`)
                      )}
                    {allData[questionGroup].questions[index].imgs &&
                      allData[questionGroup].questions[index].imgs.map(
                        (img) => {
                          return (
                            <div>
                              <a
                                href={`${window.location.origin}/${t(img)}`}
                                target="_tab"
                              >
                                <img
                                  src={`${window.location.origin}/${t(img)}`}
                                />
                              </a>
                            </div>
                          );
                        }
                      )}
                  </p>
                </div>
              </>
            );
          })}

        {/* Group 3 */}
        <h2>{allData && t("questionsGroup3.name")}</h2>
        {allData &&
          allData.questionsGroup3.questions.map((question, index) => {
            const questionGroup = allData.questionsGroup3.questionGroup;
            return (
              <>
                <div className={styles.qa} key={index}>
                  <h3 className={styles.question}>
                    {allData[questionGroup].questions[index].id}){" "}
                    {allData[questionGroup].questions[index].questionText &&
                      ReactHtmlParser(
                        t(`${questionGroup}.questions.${index}.questionText`)
                      )}
                  </h3>
                  <p className={styles.answer}>
                    {allData[questionGroup].questions[index].answerText &&
                      ReactHtmlParser(
                        t(`${questionGroup}.questions.${index}.answerText`)
                      )}
                    {allData[questionGroup].questions[index].imgs &&
                      allData[questionGroup].questions[index].imgs.map(
                        (img) => {
                          return (
                            <div>
                              <a
                                href={`${window.location.origin}/${t(img)}`}
                                target="_tab"
                              >
                                <img
                                  src={`${window.location.origin}/${t(img)}`}
                                />
                              </a>
                            </div>
                          );
                        }
                      )}
                  </p>
                </div>
              </>
            );
          })}

        {/* Group 4 */}
        <h2>{allData && t("questionsGroup4.name")}</h2>
        {allData &&
          allData.questionsGroup4.questions.map((question, index) => {
            const questionGroup = allData.questionsGroup4.questionGroup;
            return (
              <>
                <div className={styles.qa} key={index}>
                  <h3 className={styles.question}>
                    {allData[questionGroup].questions[index].id}){" "}
                    {allData[questionGroup].questions[index].questionText &&
                      ReactHtmlParser(
                        t(`${questionGroup}.questions.${index}.questionText`)
                      )}
                  </h3>
                  <p className={styles.answer}>
                    {allData[questionGroup].questions[index].answerText &&
                      ReactHtmlParser(
                        t(`${questionGroup}.questions.${index}.answerText`)
                      )}
                    {allData[questionGroup].questions[index].imgs &&
                      allData[questionGroup].questions[index].imgs.map(
                        (img) => {
                          return (
                            <div>
                              <a
                                href={`${window.location.origin}/${t(img)}`}
                                target="_tab"
                              >
                                <img
                                  src={`${window.location.origin}/${t(img)}`}
                                />
                              </a>
                            </div>
                          );
                        }
                      )}
                  </p>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
};

export default FaqComponent;
