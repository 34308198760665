import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import axios from "axios";
import { auth } from "../../firebase/index";

import MessageImage from "./checkbox.png";

import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
const tagManagerArgs = {
  gtmId: "GTM-NLZDCX7",
};

TagManager.initialize(tagManagerArgs);

const OrderFailComponent = (props) => {
  useEffect(() => {
    ReactGA.pageview("/e-sticker-frontend/fail");
  }, []);
  const [orderIDLast, setOrderIDLast] = useState("");
  const [orderValue, setOrderValue] = useState(0);
  const [orderPhoneLast, setOrderPhoneLast] = useState("");
  const [myID, setMyID] = useState("");
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    var errorMessage = localStorage.getItem("errorMessage");
    setErrorMessage(errorMessage);
    localStorage.removeItem("errorMessage");
  }, []);


  return (
    <main>
      <div className={styles.centerImg}>
        <h2>เกิดข้อผิดพลาด</h2>
        {/* <h2>เบอร์โทรศัพท์ของคุณคือ {orderPhoneLast}</h2> */}
        <p style={{ marginTop: "15px" }}>
        กรุณาแคปหน้าจอและติดต่อเจ้าหน้าที่ผ่าน Line@: <a href="https://lin.ee/sZW5Mh8" target="_tab">คลิกที่นี่ค่ะ</a>
        </p>
        <p style={{ marginTop: "15px" }}>
          {errorMessage}
        </p>
        <button
          className={styles.btnToMyorder}
          onClick={() => {
            props.history.push(`/cart`);
          }}
        >
          สั่งสินค้าอีกครั้ง คลิกที่นี่ค่ะ
        </button>
      </div>
    </main>
  );
};

export default OrderFailComponent;
