import React, { useEffect, useState } from "react";
import AdminKpi from "../admin-kpi";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";

import { axiosInst } from "../common-scss/common";

import moment from "moment";

const formatDateTime = "DD-MM-YYYY HH:mm";

const FormatDateTime = (date) => {
  return moment(date).format(formatDateTime);
};

const useInputChange = () => {
  const [input, setInput] = useState({});

  const handleInputChange = (e) =>
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });

  return [input, handleInputChange];
};

const initialLineYears = (n = 10) => {
  var new_date = new Date();
  var start_year = new_date.getFullYear();
  let rows_year = [
    {
      year_id: start_year,
    },
  ];
  for (var i = 1; i <= n; i++) {
    start_year = start_year - 1;
    if (start_year >= "2020") {
      rows_year.push({
        year_id: start_year,
      });
    }
  }
  return rows_year;
};

const AdminComponent = (props) => {
  var statusFilter = {
    ALL: "แสดงทั้งหมด",
    WAIT_PAYMENT: "รอชำระเงิน",
    DOING: "กำลังดำเนินการ",
    PRODUCING: "กำลังผลิตสินค้า",
    DILIVERING: "อยู่ระหว่างจัดส่ง",
    REFUN: "ขอเงินคืน",
    DONE_REFUN: "คืนเงินสำเร็จ",
    DONE: "รายการสำเร็จ",
    CANCEL: "ยกเลิก",
  };

  let countOrder = {
    ALL: 0,
    DOING: 0,
    DONE: 0,
    CANCEL: 0,
    WAIT_PAYMENT: 0,
  };

  const [selectStatus, setSelectStatus] = useState(statusFilter.ALL);
  const [textSearch, setTextSearch] = useInputChange();
  const [selectMonth, setSelectMonth] = useInputChange();
  const [selectYear, setSelectYear] = useInputChange();
  const [loading, setLoading] = useState(false);
  const [countOrderIndex, setCountOrderIndex] = useState(0);
  const [countItemIndex, setCountItemIndex] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currentBatchSize, setCurrentBatchSize] = useState(20);

  const [countOrderIndexFilter, setCountOrderIndexFilter] = useState(0);
  const [countItemIndexFilter, setCountItemIndexFilter] = useState(0);
  const [totalPriceFilter, setTotalPriceFilter] = useState(0);

  const [allData, setAlldata] = useState([]);
  const [orderData, setOrderData] = useState([]);
  useEffect(() => {
    let countOrderIndex = 0;
    let countItemIndex = 0;
    let totalPrice = 0;
    setLoading(true);
    axiosInst
      .get("orders?batchSize=20", {
        headers: {
          Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
        },
      })
      .then((res) => {
        const all_data =
          res.data &&
          // res.data.slice().sort((a, b) => {
          //   return new Date(b.timeStamp) - new Date(a.timeStamp);
          // })
          res.data
            .slice()
            .sort((a, b) =>
              a.orderID > b.orderID ? -1 : b.orderID > a.orderID ? 1 : 0
            );
        // console.log("all_data", all_data);
        setAlldata(all_data);
        all_data.map((item) => {
          countOrderIndex += 1;
          countItemIndex += item.itemsList.length;
          totalPrice += item.totalCost;
        });
        setTotalPrice(totalPrice);
        setCountOrderIndex(countOrderIndex);
        setCountItemIndex(countItemIndex);

        setLoading(false);
      })
      .catch((reason) => {
        console.log(reason);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let countOrderIndex = 0;
    let countItemIndex = 0;
    let totalPrice = 0;
    setLoading(true);
    let filtered_data =
      allData &&
      allData.map((item) => {
        let myTimestamp = new Date(item.timeStamp);
        // console.log(item.timeStamp, myTimestamp);
        if (myTimestamp == "Invalid Date") {
          var dateParts = item.timeStamp.split("/");
          myTimestamp = new Date(
            dateParts[2] - 543,
            dateParts[1] - 1,
            dateParts[0]
          );
          // console.log("NEW" + item.orderID, myTimestamp);
          // U989VPI5XjJ6u3vDgjEM;16/11/2564
        }
        return { ...item, timeStamp: myTimestamp };
      });

    if (selectMonth.month && selectMonth.month != "allMonth") {
      // console.log(selectMonth.month);
      filtered_data = filtered_data.filter(function (item) {
        return item.timeStamp.getMonth() + 1 == selectMonth.month;
      });
    }

    if (selectYear.year && selectYear.year != "allYear") {
      // console.log(selectYear.year);
      filtered_data = filtered_data.filter(function (item) {
        return item.timeStamp.getFullYear() == parseInt(selectYear.year);
      });
    }

    if (selectStatus != statusFilter.ALL || !selectStatus) {
      // console.log(selectStatus);
      filtered_data = filtered_data.filter(function (item) {
        return item.status == selectStatus;
      });
    }

    if (textSearch) {
      filtered_data = filtered_data.filter(function (item) {
        return (
          item?.orderID.match(textSearch["search"]) ||
          item?.shippingAddress.fullname.match(textSearch["search"]) ||
          item?.shippingAddress.phone.match(textSearch["search"])
        );
      });
    }

    console.log("filtered_data", filtered_data);
    setOrderData(filtered_data);

    filtered_data &&
      filtered_data.map((item) => {
        countOrderIndex += 1;
        countItemIndex += item.itemsList.length;
        totalPrice += item.totalCost;
      });

    setTotalPriceFilter(totalPrice);
    setCountOrderIndexFilter(countOrderIndex);
    setCountItemIndexFilter(countItemIndex);
    setLoading(false);
  }, [
    textSearch,
    selectYear.year,
    selectMonth.month,
    selectStatus,
    allData,
    statusFilter.ALL,
  ]);

  if (Array.isArray(allData)) {
    allData.map((dataObjectMapped) => {
      if (
        dataObjectMapped.status === statusFilter.DONE ||
        dataObjectMapped.status === statusFilter.DONE_REFUN
      ) {
        countOrder.DONE = countOrder.DONE + 1;
      } else if (
        dataObjectMapped.status === statusFilter.DOING ||
        dataObjectMapped.status === statusFilter.PRODUCING ||
        dataObjectMapped.status === statusFilter.DILIVERING
      ) {
        countOrder.DOING = countOrder.DOING + 1;
      } else if (
        dataObjectMapped.status === statusFilter.CANCEL ||
        dataObjectMapped.status === statusFilter.REFUN
      ) {
        countOrder.CANCEL = countOrder.CANCEL + 1;
      } else if (dataObjectMapped.status === statusFilter.WAIT_PAYMENT) {
        countOrder.WAIT_PAYMENT = countOrder.WAIT_PAYMENT + 1;
      }
      countOrder.ALL = countOrder.ALL + 1;
      return null;
    });
  }
  return (
    <React.Fragment>
      <div class={`loader loader-default ${loading ? "is-active" : ""}`}></div>
      <section className={styles.section1}>
        <AdminKpi kpi={{ order: 10, sales: 1234567, member: 1000 }} />
      </section>
      <section className={styles.section2}>
        <div className={`${styles.containerCol} ${styles.containerMargin}`}>
          <h3>รายการคำสั่งซื้อ</h3>
        </div>
        <div className={`${styles.containerRow} ${styles.containerMargin}`}>
          <div
            className={`${styles.statusAdmin} ${styles.statusDoing} ${
              styles.statusMargin
            } ${styles.divButton} ${
              `${selectStatus}` === `${statusFilter.DOING}` &&
              styles.divButtonActive
            }`}
            onClick={() => setSelectStatus(statusFilter.DOING)}
          >
            กำลังดำเนินการ - {countOrder.DOING} รายการ
          </div>
          <div
            className={`${styles.statusAdmin} ${styles.statusWaitPayment} ${
              styles.statusMargin
            } ${styles.divButton} ${
              `${selectStatus}` === `${statusFilter.WAIT_PAYMENT}` &&
              styles.divButtonActive
            }`}
            onClick={() => setSelectStatus(statusFilter.WAIT_PAYMENT)}
          >
            รอชำระเงิน - {countOrder.WAIT_PAYMENT} รายการ
          </div>
          <div
            className={`${styles.statusAdmin} ${styles.statusDone} ${
              styles.statusMargin
            } ${styles.divButton} ${
              `${selectStatus}` === `${statusFilter.DONE}` &&
              styles.divButtonActive
            }`}
            onClick={() => setSelectStatus(statusFilter.DONE)}
          >
            เสร็จสิ้น - {countOrder.DONE} รายการ
          </div>
          <div
            className={`${styles.statusAdmin} ${styles.statusCancel} ${
              styles.statusMargin
            } ${styles.divButton} ${
              `${selectStatus}` === `${statusFilter.CANCEL}` &&
              styles.divButtonActive
            }`}
            onClick={() => setSelectStatus(statusFilter.CANCEL)}
          >
            ยกเลิก - {countOrder.CANCEL} รายการ
          </div>
          <div
            className={`${styles.statusAdmin} ${styles.statusNormal} ${
              styles.statusMargin
            } ${styles.divButton} ${
              `${selectStatus}` === `${statusFilter.ALL}` &&
              styles.divButtonActive
            }`}
            onClick={() => setSelectStatus(statusFilter.ALL)}
          >
            แสดงทั้งหมด
          </div>

          <select
            name="month"
            className={styles.inputAdmin}
            onChange={setSelectMonth}
          >
            <option value="allMonth">ทุกเดือน</option>
            <option value="1">มกราคม</option>
            <option value="2">กุมภาพันธ์</option>
            <option value="3">มีนาคม</option>
            <option value="4">เมษายน</option>
            <option value="5">พฤษภาคม</option>
            <option value="6">มิถุนายน</option>
            <option value="7">กรกฎาคม</option>
            <option value="8">สิงหาคม</option>
            <option value="9">กันยายน</option>
            <option value="10">ตุลาคม</option>
            <option value="11">พฤศจิกายน</option>
            <option value="12">ธันวาคม</option>
          </select>

          <select
            name="year"
            className={styles.inputAdmin}
            onChange={setSelectYear}
          >
            <option value="allYear">ทุกปี</option>
            {initialLineYears().map((data) => (
              <option value={`${data.year_id}`}>{data.year_id}</option>
            ))}
          </select>

          <input
            type="text"
            name="search"
            className={styles.inputAdmin}
            placeholder="ค้นหา"
            onChange={setTextSearch}
          />
        </div>
      </section>
      <div className={styles.adminTable}>
        <table>
          <thead>
            <tr>
              <th>วันที่ออเดอร์</th>
              <th>เลขออเดอร์</th>
              <th>รายการ SW</th>
              {/* <th>เลขสมาชิก</th> */}
              <th>ชื่อ นามสกุล</th>
              <th>เบอร์โทรศัพท์</th>
              <th>อีเมล</th>
              <th>รายการสินค้า</th>
              <th>ราคารวม</th>
              <th>สถานนะ</th>
              <th>เลขการที่จัดส่ง</th>
              {/* <th>ผู้รับผิดชอบ</th> */}
              <th>จัดการ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>รวม</td>
              <td>{countOrderIndexFilter.toLocaleString()} รายการ</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>{countItemIndexFilter.toLocaleString()} รายการ</td>
              <td>
                {totalPriceFilter
                  ? totalPriceFilter.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0}
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            {orderData.map((dataObjectMapped) => {
              var statusOrder = styles.statusCancel;
              // var textSearchMatch =
              // dataObjectMapped.orderID.match(textSearch["search"]) ||
              // dataObjectMapped?.shippingAddress.fullname.match(
              //   textSearch["search"]
              // ) ||
              // dataObjectMapped?.shippingAddress.phone.match(
              //   textSearch["search"]
              // );
              // var textSearchMatch = dataObjectMapped;
              if (
                dataObjectMapped.status === statusFilter.DONE ||
                dataObjectMapped.status === statusFilter.DONE_REFUN
              ) {
                statusOrder = styles.statusDone;
              } else if (
                dataObjectMapped.status === statusFilter.DOING ||
                dataObjectMapped.status === statusFilter.PRODUCING ||
                dataObjectMapped.status === statusFilter.DILIVERING
              ) {
                statusOrder = styles.statusDoing;
              } else if (
                dataObjectMapped.status === statusFilter.CANCEL ||
                dataObjectMapped.status === statusFilter.REFUN
              ) {
                statusOrder = styles.statusCancel;
              } else if (
                dataObjectMapped.status === statusFilter.WAIT_PAYMENT
              ) {
                statusOrder = styles.statusWaitPayment;
              }

              const itemIDs = dataObjectMapped.itemsList
                .map((item) => item.itemID)
                .join(", ");

              // if (textSearchMatch) {
              return (
                <tr key={dataObjectMapped.orderID}>
                  <td>
                    {/* {dataObjectMapped.timeStamp.toISOString().split("T")[0]} */}
                    {/* {dataObjectMapped.timeStamp} */}
                    {/* {dataObjectMapped.timeStamp.toString() == "Invalid Date"
                      ? "Invalid Date"
                      : dataObjectMapped.timeStamp.toISOString().split("T")[0]} */}
                    {dataObjectMapped?.timeStamp &&
                      FormatDateTime(dataObjectMapped.timeStamp)}
                  </td>
                  <td>{dataObjectMapped.orderID}</td>
                  <td style={{ maxWidth: "180px" }}>{itemIDs}</td>
                  {/* <td>{dataObjectMapped.customerID}</td> */}
                  <td>{dataObjectMapped.shippingAddress.fullname}</td>
                  <td>{dataObjectMapped?.shippingAddress.phone}</td>
                  <td>{dataObjectMapped?.shippingAddress.email}</td>
                  <td>{dataObjectMapped?.itemsList?.length || 0}</td>
                  <td>
                    {dataObjectMapped.totalCost &&
                      dataObjectMapped.totalCost.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td>
                    <div
                      className={`${styles.statusAdmin} ${styles.statusCenter} ${statusOrder}`}
                    >
                      {dataObjectMapped.status}
                    </div>
                  </td>
                  <td>{dataObjectMapped.shippingNumber}</td>
                  {/* <td>{dataObjectMapped?.reposiable_name}</td> */}
                  <td>
                    <Link to={"/admin/myorder/" + dataObjectMapped.myID}>
                      จัดการ
                    </Link>
                  </td>
                </tr>
              );
              // }
            })}
          </tbody>
        </table>
      </div>
      <div
        style={{ textAlign: "center", cursor: "pointer", margin: "50px" }}
        onClick={() => {
          let countOrderIndex = 0;
          let countItemIndex = 0;
          let totalPrice = 0;

          let batchSize = currentBatchSize + 20;
          setCurrentBatchSize(batchSize);
          setLoading(true);
          axiosInst
            .get(`orders?batchSize=${batchSize}`, {
              headers: {
                Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
              },
            })
            .then((res) => {
              const all_data =
                res.data &&
                // res.data.slice().sort((a, b) => {
                //   return new Date(b.timeStamp) - new Date(a.timeStamp);
                // })
                res.data
                  .slice()
                  .sort((a, b) =>
                    a.orderID > b.orderID ? -1 : b.orderID > a.orderID ? 1 : 0
                  );
              // console.log("all_data", all_data);
              setAlldata(all_data);
              all_data.map((item) => {
                countOrderIndex += 1;
                countItemIndex += item.itemsList.length;
                totalPrice += item.totalCost;
              });
              setTotalPrice(totalPrice);
              setCountOrderIndex(countOrderIndex);
              setCountItemIndex(countItemIndex);
              setLoading(false);
            })
            .catch((reason) => {
              console.log(reason);
              setLoading(false);
            });
        }}
      >
        แสดงรายการเพิ่ม
      </div>

      <div
        style={{ textAlign: "center", cursor: "pointer", margin: "50px" }}
        onClick={() => {
          let countOrderIndex = 0;
          let countItemIndex = 0;
          let totalPrice = 0;

          let batchSize = 99999;
          setCurrentBatchSize(batchSize);
          setLoading(true);
          axiosInst
            .get(`orders?batchSize=${batchSize}`, {
              headers: {
                Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
              },
            })
            .then((res) => {
              const all_data =
                res.data &&
                // res.data.slice().sort((a, b) => {
                //   return new Date(b.timeStamp) - new Date(a.timeStamp);
                // })
                res.data
                  .slice()
                  .sort((a, b) =>
                    a.orderID > b.orderID ? -1 : b.orderID > a.orderID ? 1 : 0
                  );
              // console.log("all_data", all_data);
              setAlldata(all_data);
              all_data.map((item) => {
                countOrderIndex += 1;
                countItemIndex += item.itemsList.length;
                totalPrice += item.totalCost;
              });
              setTotalPrice(totalPrice);
              setCountOrderIndex(countOrderIndex);
              setCountItemIndex(countItemIndex);
              setLoading(false);
            })
            .catch((reason) => {
              console.log(reason);
              setLoading(false);
            });
        }}
      >
        แสดงรายการทั้งหมด
      </div>
    </React.Fragment>
  );
};

export default AdminComponent;
