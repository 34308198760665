import React, { useState, useEffect, useRef } from "react";
import Annotation from "react-image-annotation";
import {
  PointSelector,
  RectangleSelector,
  OvalSelector,
} from "react-image-annotation/lib/selectors";
import HighlightContainer from "../annotation/HighlightContainer";
import HighlightContainerDisplay from "../annotation/HighlightContainerDisplay";
import ContentContainer from "../annotation/ContentContainer";
import MyEditor from "../annotation/MyEditor";
import MyOverlay from "../annotation/MyOverlay";

import styles from "./index.module.scss";
import { Field, Form, ErrorMessage } from "formik";
import { useFormikContext } from "formik";
import axios from "axios";
import firebaseApp from "../../firebase/index.js";
import { auth } from "../../firebase/index.js";
import { db } from "../../firebase";

import { ReactComponent as IconCheckSVG } from "../approve-layout/icon-check.svg";
import { ReactComponent as IconCircle } from "../order-1-product-config/icon-circle.svg";
import logoKbank from "./kbank.jpg";
import logoScb from "./scb.png";
import logoScb2 from "./scb2.png";
import camera from "./camera.png";
import photo from "./photo.png";
import send from "./send.png";
import { ReactComponent as IconUploadFile } from "../order-2-upload-file-config/icon-upload-file.svg";

const PreviewImageComponent = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const modal = props.modal;
  const setModal = props.setModal;
  const paymentConfirm = props.paymentConfirm;
  const sendSystemMessage = props.sendSystemMessage;
  const [messageMode, setMessageMode] = useState(false);
  // const [allMessage, setAllMessage] = useState([]);
  const allMessage = props.allMessage;
  const testRef = useRef(null);
  const scrollToElement = () => {
    testRef &&
      testRef.current &&
      testRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
  };

  // useEffect(() => {
  //   // console.log("values", values);

  //   async function fetchMyAPI() {
  //     if (values.myID) {
  //       // console.log("values.myID", values.myID);
  //       const unsubscribe = await db
  //         .collection("orders")
  //         .doc(values.myID)
  //         .onSnapshot((snapshot) => {
  //           const data = snapshot.data();
  //           const messages = data.itemsList[values.expandCard].messages;
  //           setAllMessage(messages);
  //         });
  //       return unsubscribe;
  //     }
  //   }
  //   fetchMyAPI();
  // }, [allMessage.length, messageMode, values, values.expandCard, values.myID]);

  useEffect(() => {
    scrollToElement();
  }, [allMessage]);

  const handleChange = (event) => {
    console.log("PAYMENT");
    setFieldValue("waitProcess", true, false);
    setFieldValue("loading", true, false);

    if (event.target.files) {
      const storageRef = firebaseApp.storage().ref();
      let timeStamp = new Date().toISOString().slice(0, 10);
      let file = event.target.files[0];
      let windowUrl = window.location.pathname;
      auth.onAuthStateChanged((user) => {
        let uploadPath = ``;
        if (user) {
          // User is signed in.
          uploadPath = `${user.uid}/${timeStamp}-${file.name}`;
        } else {
          uploadPath = `${values.orderID}/${timeStamp}-${file.name}`;
        }
        storageRef
          .child(uploadPath)
          .put(file)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              let data = {
                itemIndex: values.expandCard,
                msg: {
                  by: windowUrl.search("admin") !== -1 ? "admin" : "customer",
                  content: url,
                  timestamp: new Date(),
                  info: `${file.name}`,
                  type: "file",
                },
              };
              console.log("data", data);
              axios
                .put(
                  `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orderItemMsg/${values.myID}`,
                  data,
                  {
                    headers: {
                      Authorization:
                        "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                    },
                  }
                )
                .then((res) => {
                  console.log("res", res);
                  // window.location.reload();
                  // setFieldValue("fetchMsg", true, false);
                  setFieldValue("waitProcess", false, false);
                  setFieldValue("loading", false, false);
                })
                .catch(function (err) {
                  console.log("err", err);
                  setFieldValue("waitProcess", false, false);
                  setFieldValue("loading", false, false);
                });
            });
          });
      });
    }
  };

  const sendMessage = () => {
    console.log("PAYMENT2");

    setMessageMode(true);

    if (values.massage) {
      setFieldValue("waitProcess", true, false);
      setFieldValue("loading", true, false);
      let url = window.location.pathname;
      let data = {
        itemIndex: values.expandCard,
        msg: {
          by: url.search("admin") !== -1 ? "admin" : "customer",
          content: values.massage,
          timestamp: new Date(),
          type: "text",
        },
      };
      setFieldValue("massage", "", false);
      // console.log("values.myID", values.myID)
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orderItemMsg/${values.myID}`,
          data,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          console.log("res", res);
          // setFieldValue("fetchMsg", true, false);
          setFieldValue("waitProcess", false, false);
          setFieldValue("loading", false, false);
        })
        .catch(function (err) {
          console.log("err", err);
          setFieldValue("waitProcess", false, false);
          setFieldValue("loading", false, false);
        });
    }
  };

  // const sendItemStatus = () => {
  //   console.log("PAYMENT3");
  //   setFieldValue("waitProcess", true, false);
  //   setFieldValue("loading", true, false);

  //   let data = {
  //     itemIndex: values.expandCard,
  //     // status: "อนุมัติแบบ",
  //     status: "กำลังผลิตสินค้า",
  //     timestamp: new Date(),
  //   };
  //   setFieldValue("massage", "", false);
  //   console.log("data", data);
  //   axios
  //     .put(
  //       `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orderItemStatus/${values.myID}`,
  //       data,
  //       {
  //         headers: {
  //           Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log("res", res);
  //       sendSystemMessage(
  //         values.myID,
  //         values.expandCard,
  //         "อนุมัติแบบสำเร็จ",
  //         setFieldValue
  //       );
  //       sendSystemMessage(
  //         values.myID,
  //         values.expandCard,
  //         "ทางเราได้รับอนุมัติแบบเรียบร้อยแล้ว เราจะรีบผลิตและจัดส่งสินค้าให้คุณโดยเร็ว ทางเราจะส่งเลข Tracking ให้หลังจากจัดส่งสินค้าแล้ว คุณสามารถเข้ามาติดตามได้ที่เว็บไซต์ของเราได้ในภายหลัง",
  //         setFieldValue
  //       );
  //       setFieldValue("fetchMsg", true, false);
  //       setFieldValue("waitProcess", false, false);
  //       setFieldValue("loading", false, false);
  //     })
  //     .catch(function (err) {
  //       console.log("err", err);
  //       setFieldValue("waitProcess", false, false);
  //       setFieldValue("loading", false, false);
  //     });
  // };

  const handleChangeModal = (event) => {
    if (event.target.files) {
      setFieldValue("photo", event.target.files[0], true);
      setFieldValue("isCheckphoto", true, false);
    }
  };

  useEffect(() => {
    setFieldValue(
      "name",
      values.shippingAddress ? values.shippingAddress.fullname : ""
    );
    setFieldValue(
      "phone",
      values.shippingAddress ? values.shippingAddress.phone : ""
    );
    setFieldValue(
      "amount",
      values.totalCost ? Math.ceil(values.totalCost) : ""
    );
    setFieldValue("bank", "scb", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  // useEffect(() => {
  //   animateScroll.scrollToBottom({
  //     containerId: "scrollBottomDiv",
  //   });
  // }, []);

  if (values.hasOwnProperty("itemsList") && values.itemsList.length >= 1) {
    function reverseArr(input) {
      var ret = new Array();
      for (var i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
      }
      return ret;
    }

    // const message_reorder = reverseArr(
    //   values.itemsList[values.expandCard].messages
    // );
    // const message_reorder = values.itemsList[values.expandCard].messages;
    const message_reorder = allMessage;

    return (
      <>
        <div className={styles.square} id="scrollBottomDiv">
          {message_reorder.map((listMsg) => {
            if (listMsg.type === "text") {
              return (
                <>
                  <article
                    className={`${styles.newMsg} ${
                      listMsg.by === "customer"
                        ? styles.flexEnd
                        : styles.flexStart
                    }`}
                  >
                    {listMsg.by === "customer" ? (
                      <>
                        <p className={styles.massage}>{listMsg.content}</p>
                        <div className={styles.groupUser}>
                          <IconCircle />
                          <p>{listMsg.by}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.groupUser}>
                          <IconCircle />
                          <p>{listMsg.by}</p>
                        </div>
                        <p className={styles.massage}>{listMsg.content}</p>
                      </>
                    )}
                  </article>{" "}
                  <div
                    className={
                      listMsg.by === "customer"
                        ? styles.timestampRight
                        : styles.timestampLeft
                    }
                  >
                    {listMsg.timestamp
                      ? "เมื่อ " +
                        new Date(listMsg.timestamp).toLocaleDateString(
                          "th-TH",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }
                        )
                      : ""}
                  </div>
                </>
              );
            } else if (listMsg.type === "file") {
              return (
                // <article className={`${styles.newMsg} ${listMsg.by === "customer" && styles.flexEnd}`}>
                <>
                  <article
                    className={`${styles.newMsg} ${
                      listMsg.by === "customer"
                        ? styles.flexEnd
                        : styles.flexStart
                    }`}
                  >
                    {listMsg.by === "customer" ? (
                      <>
                        <p className={styles.massage}>
                          <label className={styles.fileMsg}>
                            <a
                              className={styles.dowloadFileMsg}
                              href={listMsg.content}
                              target="_blank"
                              downloadFile
                            >
                              {/* {listMsg.info} */}
                              คลิกที่นี่เพื่อเปิดไฟล์
                            </a>
                          </label>
                          {/* <a
                          className={styles.dowloadFileMsg}
                          href={listMsg.content}
                          target="_blank"
                          downloadFile
                        >
                          ดาวน์โหลด
                        </a> */}
                        </p>
                        <div className={styles.groupUser}>
                          <IconCircle />
                          <p>{listMsg.by}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.groupUser}>
                          <IconCircle />
                          <p>{listMsg.by}</p>
                        </div>
                        <p className={styles.massage}>
                          <label className={styles.fileMsg}>
                            <a
                              className={styles.dowloadFileMsg}
                              href={listMsg.content}
                              target="_blank"
                              downloadFile
                            >
                              {/* {listMsg.info} */}
                              คลิกที่นี่เพื่อเปิดไฟล์
                            </a>
                          </label>
                          {/* <a
                          className={styles.dowloadFileMsg}
                          href={listMsg.content}
                          target="_blank"
                          downloadFile
                        >
                          ดาวน์โหลด
                        </a> */}
                        </p>
                      </>
                    )}
                  </article>
                  <div
                    className={
                      listMsg.by === "customer"
                        ? styles.timestampRight
                        : styles.timestampLeft
                    }
                  >
                    {listMsg.timestamp
                      ? "เมื่อ " +
                        new Date(listMsg.timestamp).toLocaleDateString(
                          "th-TH",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }
                        )
                      : ""}
                  </div>
                </>
              );
            } else if (listMsg.type === "approval") {
              return (
                <>
                  <article
                    className={`${styles.newMsg} ${
                      listMsg.by === "customer"
                        ? styles.flexEnd
                        : styles.flexStart
                    }`}
                  >
                    {listMsg.by === "customer" ? (
                      <>
                        <p className={styles.massage}>
                          <div className={styles.approvalMsg}>
                            {listMsg &&
                            listMsg.content.hasOwnProperty(
                              "approvalImgExtension"
                            ) &&
                            ["jpg", "jpeg", "png"].includes(
                              listMsg.content.approvalImgExtension
                            ) ? (
                              <div className={styles.annotationCanvas}>
                                {listMsg && listMsg.content.approvalImgUrl ? (
                                  <Annotation
                                    src={listMsg.content.approvalImgUrl}
                                    annotations={
                                      listMsg.content.annotaions || []
                                    }
                                    disableAnnotation={true}
                                    disableOverlay={true}
                                    disableEditor={true}
                                    disableSelector={true}
                                    renderEditor={(props) => {
                                      return <MyEditor {...props} />;
                                    }}
                                    renderOverlay={(props) => {
                                      return <MyOverlay {...props} />;
                                    }}
                                    renderHighlight={(props) => {
                                      // console.log("props", props);
                                      return (
                                        <HighlightContainerDisplay {...props} />
                                      );
                                    }}
                                    renderContent={(props) => {
                                      return <ContentContainer {...props} />;
                                    }}
                                  />
                                ) : (
                                  <div>ไม่พบไฟล์อนุมัติแบบ</div>
                                )}
                              </div>
                            ) : (
                              <div>{listMsg.content.approvalImgExtension}</div>
                            )}
                          </div>
                          <div>
                            {listMsg.content.approvalNumber && (
                              <b>ครั้งที่ {listMsg.content.approvalNumber}.</b>
                            )}
                          </div>
                          <div>
                            {listMsg.hasOwnProperty("content") &&
                              listMsg.content.hasOwnProperty("attachments") &&
                              listMsg.content.attachments.length > 0 && (
                                <div style={{ marginTop: "10px" }}>
                                  <b>ไฟล์แนบ:</b>
                                  <div>
                                    <ul>
                                      {listMsg &&
                                        (listMsg.content.attachments || []).map(
                                          (item, index) => {
                                            return (
                                              <li>
                                                <a
                                                  href={item.attachment_url}
                                                  target="_blank"
                                                >
                                                  {index + 1}.{" "}
                                                  <u>{item.attachment_name}</u>
                                                </a>
                                              </li>
                                            );
                                          }
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              )}
                            {listMsg &&
                              listMsg.hasOwnProperty("content") &&
                              listMsg.content.hasOwnProperty("comment") &&
                              listMsg.content.comment != "" && (
                                <div style={{ marginTop: "10px" }}>
                                  <b>คำบรรยายในการแก้ไข:</b>
                                  <div>
                                    {(listMsg && listMsg.content.comment) || ""}
                                  </div>
                                </div>
                              )}

                            {listMsg &&
                              listMsg.hasOwnProperty("content") &&
                              listMsg.content.hasOwnProperty("annotaions") &&
                              listMsg.content.annotaions.length > 0 && (
                                <div
                                  className="annotationsList"
                                  style={{ marginTop: "10px" }}
                                >
                                  <b>การแก้ไข:</b>
                                  <ul>
                                    {listMsg &&
                                      listMsg.content.annotaions.map((item) => {
                                        return (
                                          <li>
                                            {item.data.marker}. {item.data.text}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              )}
                          </div>
                        </p>
                        <div className={styles.groupUser}>
                          <IconCircle />
                          <p>{listMsg.by}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.groupUser}>
                          <IconCircle />
                          <p>{listMsg.by}</p>
                        </div>
                        <p className={styles.massage}>
                          <div className={styles.approvalMsg}>
                            {listMsg &&
                            listMsg.content.hasOwnProperty(
                              "approvalImgExtension"
                            ) &&
                            ["jpg", "jpeg", "png"].includes(
                              listMsg.content.approvalImgExtension
                            ) ? (
                              <img src={listMsg.content.approvalImgUrl} />
                            ) : (
                              <div>{listMsg.content.approvalImgExtension}</div>
                            )}
                          </div>
                          <div>
                            {listMsg.content.approvalNumber && (
                              <b>ครั้งที่ {listMsg.content.approvalNumber}.</b>
                            )}
                          </div>
                          <div>{listMsg.content.message}</div>
                        </p>
                      </>
                    )}
                  </article>
                  <div
                    className={
                      listMsg.by === "customer"
                        ? styles.timestampRight
                        : styles.timestampLeft
                    }
                  >
                    {listMsg.timestamp
                      ? "เมื่อ " +
                        new Date(listMsg.timestamp).toLocaleDateString(
                          "th-TH",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }
                        )
                      : ""}
                  </div>
                </>
              );
            }
          })}
          <div ref={testRef}></div>
        </div>

        {/* <div className={styles.chatInput}>
          <div className={styles.camera}>
            <input
              type="file"
              id="file"
              onChange={(e) => handleChange(e)}
              disabled={values.waitProcess ? true : false}
            />
            <label for="file">
              <img src={camera} />
            </label>
          </div>
          <div className={styles.photo}>
            <input
              type="file"
              id="file"
              onChange={(e) => handleChange(e)}
              disabled={values.waitProcess ? true : false}
            />
            <label for="file">
              <img src={photo} />
            </label>
          </div>
          <div className={styles.input}>
            <Field
              name="massage"
              className={styles.inputGreen}
              type="text"
              placeholder="พิมพ์ข้อความ"
              disabled={values.waitProcess ? true : false}
            />
          </div>
          <div className={styles.send}>
            <img src={send} onClick={() => sendMessage()} />
          </div>
        </div> */}

        <div className={styles.contactButton}>
          <a href="https://lin.ee/sZW5Mh8" target="_tab">
            <button>ติดต่อเจ้าหน้าที่ Digitalwish</button>
          </a>
        </div>

        <div className={styles.inputBox}>
          {/* <Field
            name="massage"
            className={styles.inputGreen}
            type="text"
            placeholder="พิมพ์ข้อความ..."
            disabled={values.waitProcess ? true : false}
          />

          <div className={styles.btnGroup}>
            <button
              type="button"
              onClick={() => sendMessage()}
              disabled={values.waitProcess ? true : false}
            >
              ส่ง
            </button>
            <input
              type="file"
              id="file"
              onChange={(e) => handleChange(e)}
              disabled={values.waitProcess ? true : false}
            />
            <label for="file" className={styles.btnCustomWidth}>
              อัพโหลดไฟล์
            </label>
          </div> */}

          {/* moved to aprrove-layout
          {values.paymentStatus}
          {values.itemsList[values.expandCard].status}
          {`${values.paymentMethod}` === `transfer_money` &&
          `${values.paymentStatus}` === `pending` ? (
            <div>
              <button
                type="button"
                onClick={() => setModal(true)}
                disabled={values.waitProcess ? true : false}
              >
                {paymentConfirm && paymentConfirm.length > 0 ? (
                  <h3>แจ้งชำระเงินอีกครั้ง</h3>
                ) : (
                  <h3>แจ้งชำระเงิน</h3>
                )}
              </button>
            </div>
          ) : (
            `${values.itemsList[values.expandCard].status}` ===
              `รออนุมัติแบบ` && (
              <div>
                <button
                  type="button"
                  onClick={() => sendItemStatus()}
                  disabled={values.waitProcess ? true : false}
                >
                  <h3>
                    <IconCheckSVG /> อนุมัติแบบ
                  </h3>
                </button>
              </div>
            )
          )} */}

          {/* <!-- The Modal --> */}
          <div
            className={styles.modal}
            style={modal ? { display: "block" } : { display: "none" }}
          >
            <div className={styles.modalContent}>
              <div
                onClick={() => setModal(false)}
                style={{ cursor: "pointer", width: "100%", textAlign: "right" }}
              >
                X ปิด
              </div>

              <div className={styles.exampleSticker}>
                <h2>ยืนยันการชำระเงิน</h2>
              </div>
              <h5 style={{ color: "#009473", display: "inline-block" }}>
                {values.alertSuccess}
              </h5>
              <Form>
                <div className={styles.groupColumn}>
                  <div className={styles.leftColumn}>
                    <p>
                      ชื่อ นามสกุล*
                      <ErrorMessage
                        name="name"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <input type="file" />
                    <Field
                      name="name"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                    <p>
                      ยอดชำระเงิน
                      <ErrorMessage
                        name="amount"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="amount"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>

                  <div className={styles.rightColumn}>
                    <p>
                      เบอร์โทรศัพท์*
                      <ErrorMessage
                        name="phone"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="phone"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                    <p>
                      สลิปการโอนเงิน*
                      <ErrorMessage
                        name="photo"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                      {values.isCheckphoto !== 0 ? (
                        values.isCheckphoto ? (
                          <span style={{ color: "#009473", fontSize: "12px" }}>
                            อัพโหลดสำเร็จ
                          </span>
                        ) : (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            อัพโหลดไม่สำเร็จ
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </p>
                    <input
                      type="file"
                      id="file2"
                      onChange={(e) => handleChangeModal(e)}
                      disabled={values.waitProcess ? true : false}
                    />
                    <label
                      for="file2"
                      className={`${styles.buttonUploadFile} ${styles.label}`}
                    >
                      <IconUploadFile />
                      อัพโหลดไฟล์
                    </label>
                  </div>
                </div>

                <div
                  // className={styles.groupColumn}
                  style={{ marginTop: "40px" }}
                >
                  <p>
                    ธนาคารที่โอน
                    <ErrorMessage
                      name="bank"
                      render={(msg) => <span className="error">{msg}</span>}
                    />
                  </p>
                  <button
                    className={`${styles.bankPayment2} ${
                      values.bank === "scb" && styles.active
                    }`}
                    type="button"
                    onClick={() => setFieldValue("bank", "scb", true)}
                    disabled={values.waitProcess ? true : false}
                  >
                    <img src={logoScb2} className={styles.imgBank} />
                    <div className={styles.groupBankDetail}>
                      {/* <p>กสิกรไทย</p>
                      <p>105-847488-2</p>
                      <p>บจก. ดิติทัล วิช</p> */}
                      <p className={styles.big}>115-263675-3</p>
                      <p>บริษัท มิลเลี่ยน โพลีซีล อินดัสตรี้</p>
                      <p>ไทยพาณิชย์</p>
                    </div>
                  </button>
                </div>

                <div className={styles.groupColumn2}>
                  <div className={styles.leftColumn}>
                    <p>
                      วันที่โอน
                      <ErrorMessage
                        name="date"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="date"
                      type="date"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                  <div className={styles.rightColumn}>
                    <p>
                      เวลา
                      <ErrorMessage
                        name="time"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="time"
                      type="time"
                      style={{ width: "170px" }}
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className={styles.btnGreenModal}
                  disabled={values.waitProcess ? true : false}
                >
                  ตกลง
                </button>
                <button
                  type="button"
                  className={styles.btnGreenModal2}
                  onClick={() => {
                    setModal(false);
                    setFieldValue("alertSuccess", "", false);
                  }}
                  disabled={values.waitProcess ? true : false}
                >
                  ปิด
                </button>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <div></div>;
  }
};

export default PreviewImageComponent;
