import React, { useRef, useState, useEffect, createRef } from "react";
import { useReactToPrint } from "react-to-print";
import styles from "./index.module.scss";
import axios from "axios";
import logo from "./logo-dw.jpg";
import qr from "./qr.svg";
import QRCode from "qrcode.react";

const WhyComponentToPrint = (data) => {
  console.log("data", data);
  return (
    <div>
      <div className={styles.page}>
        <div className={styles.subpage}>
          {/* Head Page */}
          <div
            className={styles.row}
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <img src={logo} width="130" style={{ marginLeft: "-10px" }} />
            <div>
              <h3 className={styles.headPage}>
                ใบเสนอราคา/ใบยืนยันการสั่งซื้อ
              </h3>
              <h5 style={{ textAlign: "center" }}>
                ออเดอร์หมายเลข #{data.orderID}
              </h5>
            </div>
            {/* <img src={qr} width='100' /> */}
            <QRCode
              value={`http://sticker.digitalwish.co.th/myorder/${data.id}`}
              size={70}
            />
          </div>

          <div style={{ textAlign: "right", fontSize: "1rem" }}>
            <div>
              {data.payload && data.payload.timeStamp ? (
                <>
                  วันที่สั่งซื้อ:{" "}
                  {new Date(data.payload.timeStamp).toLocaleDateString("en-GB")}
                </>
              ) : (
                ""
              )}
            </div>
            <div>
              {data.payload &&
              data.payload.paymentConfirm &&
              data.payload.paymentConfirm.length > 0 ? (
                <>
                  {" "}
                  วันที่ชำระ:{" "}
                  {new Date(
                    data.payload.paymentConfirm[
                      data.payload.paymentConfirm.length - 1
                    ].date
                  ).toLocaleDateString("en-GB")}
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={styles.wrapper}>
            <div>
              <h4 style={{ margin: "20px 0 10px" }}>ผู้ส่ง / FROM</h4>
              <div className={` ${styles.box}`}>
                <div className={styles.col50}>
                  <p>
                    <b>ชื่อ:</b> Million Polyseal Official
                  </p>
                  <p>
                    <b>ที่อยู่:</b> 81/258-9 ซอยเพชรเกษม 116 ถนนเพชรเกษม
                    แขวงหนองค้างพลู, เขตหนองแขม, จังหวัด กรุงเทพมหานคร 10160
                  </p>
                </div>
                <div className={styles.col50}>
                  <p>
                    <b>เบอร์โทรศัพท์:</b> 0971565224
                  </p>
                  <p>
                    <b>อีเมล:</b> stickerwish.th@gmail.com{" "}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h4 style={{ margin: "20px 0 10px" }}>ผู้รับ / TO</h4>
              <div className={`${styles.box}`}>
                <div className={styles.col50}>
                  <p>
                    <b>ชื่อ:</b> {data.addressCustomer.fullname}
                  </p>
                  <p>
                    <b>ที่อยู่:</b> {data.addressCustomer.address} แขวง
                    {data.addressCustomer.city} {data.addressCustomer.county}{" "}
                    จังหวัด {data.addressCustomer.provice}{" "}
                    {data.addressCustomer.zip}
                  </p>
                </div>
                <div className={styles.col50}>
                  <p>
                    <b>เบอร์โทรศัพท์:</b> {data.addressCustomer.phone}
                  </p>
                  <p>
                    <b>อีเมล:</b> {data.addressCustomer.email}
                  </p>
                  {/* <p>
                    <b>เลขประจำตัวผู้เสียภาษี:</b>{" "}
                    {data.payload && data.payload.billingAddress.taxID
                      ? data.payload.billingAddress.taxID
                      : "-"}
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          {(data.billingAddress.isTaxDocument === "2" ||
            data.billingAddress.isTaxDocument === "3") && (
            <div className={styles.taxWrapper}>
              <div>
                <h4 style={{ margin: "20px 0 10px" }}>ที่อยู่ใบกำกับภาษี</h4>
                <div className={` ${styles.taxBox}`}>
                  <div>
                    <p>
                      <b>ชื่อ:</b> {data.billingAddress.fullname}
                    </p>
                    <p>
                      <b>ที่อยู่:</b> {data.billingAddress.fulladdress}
                    </p>
                    <p>
                      <b>เลขประจำตัวผู้เสียภาษี:</b> {data.billingAddress.taxID}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <h4 style={{ margin: "20px 0 10px" }}>
            ออเดอร์หมายเลข #{data.orderID}
          </h4>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>รายการ</th>
                <th>รายละเอียด</th>
                <th>จำนวน</th>
                <th>ราคาต่อหน่วย</th>
                <th style={{ textAlign: "right" }}>มูลค่า</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map(
                (data) => (
                  // data.itemID && (
                  <tr>
                    <td>{data.index}</td>
                    <td style={{ whiteSpace: "nowrap" }}>{data.itemID}</td>
                    <td>{data.name}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {data.qty ? data.qty + " ชิ้น" : " "}
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {data.pricePerUnit ? `${data.pricePerUnit} บาท` : ""}
                    </td>
                    <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                      {data.price ? `${data.price} บาท` : ""}
                    </td>
                  </tr>
                )
                // )
              )}
              <tr className={styles.borderTop}>
                <td colSpan={6}></td>
              </tr>
              <tr>
                <td
                  colSpan={5}
                  style={{
                    whiteSpace: "nowrap",
                    // paddingTop: "0",
                    paddingBottom: "0",
                  }}
                >
                  ค่าสินค้ารวม
                </td>
                <td
                  style={{
                    whiteSpace: "nowrap",
                    // paddingTop: "0",
                    paddingBottom: "0",
                    textAlign: "right",
                  }}
                >
                  {data.payload && data.payload.itemsCost.toFixed(2)} บาท
                </td>
              </tr>

              <tr>
                <td colSpan={5} style={{ paddingTop: "0", paddingBottom: "0" }}>
                  ภาษี 7%
                </td>
                <td
                  style={{
                    whiteSpace: "nowrap",
                    paddingTop: "0",
                    paddingBottom: "0",
                    textAlign: "right",
                  }}
                >
                  {data.payload && data.payload.vatCost} บาท
                </td>
              </tr>
              <tr>
                <td colSpan={5} style={{ paddingTop: "0", paddingBottom: "0" }}>
                  ส่วนลดสินค้า
                </td>
                <td
                  style={{
                    whiteSpace: "nowrap",
                    paddingTop: "0",
                    paddingBottom: "0",
                    textAlign: "right",
                  }}
                >
                  {data.payload && data.payload.promotionDiscount} บาท
                </td>
              </tr>

              <tr>
                {/* <td colSpan={5} style={{ paddingTop: "0", paddingBottom: "0" }}> */}
                <td
                  colSpan={5}
                  style={{
                    whiteSpace: "nowrap",
                    paddingTop: "0",
                  }}
                >
                  ค่าจัดส่ง{" "}
                  {data.payload && data.payload.shippingCourier ? (
                    <label>
                      {data.payload.shippingCourier} -{" "}
                      {data.payload.shippingDuration} วันทำการ
                    </label>
                  ) : (
                    "กรุณาเลือกการจัดส่ง"
                  )}
                  {/* <div className={styles.desciption}>
                    {data.payload && data.payload.shippingCourier ? (
                      <div>
                        {data.payload.shippingCourier} -{" "}
                        {data.payload.shippingDuration} วันทำการ
                      </div>
                    ) : (
                      "กรุณาเลือกการจัดส่ง"
                    )}
                  </div> */}
                </td>
                <td
                  style={{
                    whiteSpace: "nowrap",
                    paddingTop: "0",
                    textAlign: "right",
                  }}
                >
                  {data.payload &&
                    parseInt(data.payload.shippingCost).toFixed(2)}{" "}
                  บาท{" "}
                </td>
              </tr>
              <tr className={styles.borderTop}>
                <td colSpan={6}></td>
              </tr>
              <tr>
                <td colSpan={5}>รวมทั้งหมด</td>
                <td style={{ whiteSpace: "nowrap", textAlign: "right" }}>
                  {data.payload && data.payload.totalCost.toFixed(2)} บาท
                </td>
              </tr>
            </tbody>
          </table>

          <h4 style={{ margin: "20px 0 10px" }}>หมายเหตุ</h4>
          <div
            className={`${styles.row} ${styles.boxNote}`}
            style={{ height: "fit-content" }}
          >
            <textarea></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * This is a wrapper, as the react-to-printer requires a component
 * in order to render and print.
 * There might be other/better ways of doing this, but for now, this
 * works.
 * NOTE: If no such wrapper, react-to-print complains about:
 * "work only Class based components"
 */
class FunctionalComponentWrapper extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <WhyComponentToPrint {...this.props} />;
  }
}

export const PDF = (props) => {
  const componentRef = useRef();
  const [data, setData] = useState([]);

  const [id, setID] = useState("");
  const [orderID, setOrderID] = useState("");
  const [orderPayload, setOrderPayload] = useState();
  const [addressCustomer, setAddressCustomer] = useState({});
  const [billingAddress, setBillingAddress] = useState({});
  const [lineItems, setLineItems] = useState([]);

  // Fetch Docuement Receive
  useEffect(() => {
    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders/${props.match.params.id}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("ALL DATA", res.data);
        setID(props.match.params.id);
        setOrderID(res.data.orderID);
        setOrderPayload(res.data);
        setAddressCustomer(res.data.shippingAddress);
        setBillingAddress(res.data.billingAddress);
        const itemListArray = [];
        res.data.itemsList.map((data, index) => {
          function padLeadingZeros(num, size) {
            var s = num + "";
            while (s.length < size) s = "0" + s;
            return s;
          }
          let indexPlus = index + 1;
          let itemNumber = "";
          if (data.itemID) itemNumber = data.itemID;
          else itemNumber = `SW${padLeadingZeros(indexPlus, 7)}`;
          const mapper = {
            index: indexPlus,
            // itemID: res.data.orderID.replace("DW", "IT") + "/" + indexPlus,
            itemID: itemNumber,
            name: `${data.shape} - ${data.material} - ${data.coat} - ขนาด ${data.width}x${data.height} mm`,
            qty: data.units,
            price: data.price,
            pricePerUnit: (data.price / data.units).toFixed(3),
          };
          itemListArray.push(mapper);
        });
        setLineItems(itemListArray);
      })
      .catch(function (err) {
        console.log("err", err);
      });
  }, [props.match.params.id]);

  const handlePrint = useReactToPrint({
    content: () => {
      return componentRef.current;
    },
  });

  useEffect(() => {
    const test = [...lineItems];
    const maximumLine = billingAddress.isTaxDocument ? 11 : 17;
    for (let i = 0; i < maximumLine - lineItems.length; i++) {
      test.push({
        index: "",
        itemID: "",
        name: "",
        qty: "",
        price: "",
        pricePerUnit: "",
      });
    }
    setData(test);
  }, [billingAddress, lineItems]);

  return (
    <>
      <div style={{ margin: "0 auto", textAlign: "center" }}>
        <button onClick={handlePrint} className={styles.printButton}>
          พิมพ์เอกสาร
        </button>
      </div>
      <FunctionalComponentWrapper
        ref={componentRef}
        data={data}
        id={id}
        orderID={orderID}
        payload={orderPayload}
        addressCustomer={addressCustomer}
        billingAddress={billingAddress}
      />
    </>
  );
};
