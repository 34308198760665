import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";

import styles from "./index.module.scss";
const LocationFieldsComponent = (props) => {
  const onlyLocation = props.onlyLocation ? props.onlyLocation : false;
  const emailDisabled = props.emailDisabled ? props.emailDisabled : false;
  return (
    <div className={styles.gridContainer}>
      {/*  GRID ITEM ISN"T EVEN USED? DONT EVEN HAVE SCSS WTF? */}

      {!onlyLocation && (
        <>
          <div className={styles.gridItem}>
            <div className={styles.formControl}>
              <p>
                อีเมล{" "}
                <ErrorMessage
                  name="email"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />
              </p>
              <Field name="email" type="email" />
            </div>
          </div>

          <div className={styles.gridItem}>
            <div className={styles.formControl}>
              <p>
                เบอร์โทรศัพท์*{" "}
                <ErrorMessage
                  name="phone"
                  render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
                />
              </p>
              <Field
                name="phone"
                type="text"

                // onChange={(e) => {

                //   console.log(e.target.value);
                // }}
              />
            </div>
          </div>
        </>
      )}

      <div className={styles.gridItem}>
        <div className={styles.formControl}>
          <p>
            ที่อยู่*{" "}
            <ErrorMessage
              name="address"
              render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
            />
          </p>
          <Field name="address" type="text" />
        </div>
      </div>

      <div className={styles.gridItem}>
        <div className={styles.formControl}>
          <p>
            ไอดี LINE{" "}
            <ErrorMessage
              name="line"
              render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
            />
          </p>
          <Field name="line" type="text" />
        </div>
      </div>

      <div className={styles.gridItem}>
        <div className={styles.formControl}>
          <p>
            ชื่อบุคคล / ชื่อบริษัท*{" "}
            <ErrorMessage
              name="fullname"
              render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
            />
          </p>
          <Field name="fullname" type="text" />
        </div>
      </div>

      <div className={styles.gridItem}>
        <div className={styles.formControl}>
          <p>
            จังหวัด*{" "}
            <ErrorMessage
              name="provice"
              render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
            />
          </p>
          <Field as="select" name="provice" type="text">
            <option value="" selected>
              กรุณาเลือกจังหวัด
            </option>
            <option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
            <option value="กระบี่">กระบี่ </option>
            <option value="กาญจนบุรี">กาญจนบุรี </option>
            <option value="กาฬสินธุ์">กาฬสินธุ์ </option>
            <option value="กำแพงเพชร">กำแพงเพชร </option>
            <option value="ขอนแก่น">ขอนแก่น</option>
            <option value="จันทบุรี">จันทบุรี</option>
            <option value="ฉะเชิงเทรา">ฉะเชิงเทรา </option>
            <option value="ชัยนาท">ชัยนาท </option>
            <option value="ชัยภูมิ">ชัยภูมิ </option>
            <option value="ชุมพร">ชุมพร </option>
            <option value="ชลบุรี">ชลบุรี </option>
            <option value="เชียงใหม่">เชียงใหม่ </option>
            <option value="เชียงราย">เชียงราย </option>
            <option value="ตรัง">ตรัง </option>
            <option value="ตราด">ตราด </option>
            <option value="ตาก">ตาก </option>
            <option value="นครนายก">นครนายก </option>
            <option value="นครปฐม">นครปฐม </option>
            <option value="นครพนม">นครพนม </option>
            <option value="นครราชสีมา">นครราชสีมา </option>
            <option value="นครศรีธรรมราช">นครศรีธรรมราช </option>
            <option value="นครสวรรค์">นครสวรรค์ </option>
            <option value="นราธิวาส">นราธิวาส </option>
            <option value="น่าน">น่าน </option>
            <option value="นนทบุรี">นนทบุรี </option>
            <option value="บึงกาฬ">บึงกาฬ</option>
            <option value="บุรีรัมย์">บุรีรัมย์</option>
            <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์ </option>
            <option value="ปทุมธานี">ปทุมธานี </option>
            <option value="ปราจีนบุรี">ปราจีนบุรี </option>
            <option value="ปัตตานี">ปัตตานี </option>
            <option value="พะเยา">พะเยา </option>
            <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา </option>
            <option value="พังงา">พังงา </option>
            <option value="พิจิตร">พิจิตร </option>
            <option value="พิษณุโลก">พิษณุโลก </option>
            <option value="เพชรบุรี">เพชรบุรี </option>
            <option value="เพชรบูรณ์">เพชรบูรณ์ </option>
            <option value="แพร่">แพร่ </option>
            <option value="พัทลุง">พัทลุง </option>
            <option value="ภูเก็ต">ภูเก็ต </option>
            <option value="มหาสารคาม">มหาสารคาม </option>
            <option value="มุกดาหาร">มุกดาหาร </option>
            <option value="แม่ฮ่องสอน">แม่ฮ่องสอน </option>
            <option value="ยโสธร">ยโสธร </option>
            <option value="ยะลา">ยะลา </option>
            <option value="ร้อยเอ็ด">ร้อยเอ็ด </option>
            <option value="ระนอง">ระนอง </option>
            <option value="ระยอง">ระยอง </option>
            <option value="ราชบุรี">ราชบุรี</option>
            <option value="ลพบุรี">ลพบุรี </option>
            <option value="ลำปาง">ลำปาง </option>
            <option value="ลำพูน">ลำพูน </option>
            <option value="เลย">เลย </option>
            <option value="ศรีสะเกษ">ศรีสะเกษ</option>
            <option value="สกลนคร">สกลนคร</option>
            <option value="สงขลา">สงขลา </option>
            <option value="สมุทรสาคร">สมุทรสาคร </option>
            <option value="สมุทรปราการ">สมุทรปราการ </option>
            <option value="สมุทรสงคราม">สมุทรสงคราม </option>
            <option value="สระแก้ว">สระแก้ว </option>
            <option value="สระบุรี">สระบุรี </option>
            <option value="สิงห์บุรี">สิงห์บุรี </option>
            <option value="สุโขทัย">สุโขทัย </option>
            <option value="สุพรรณบุรี">สุพรรณบุรี </option>
            <option value="สุราษฎร์ธานี">สุราษฎร์ธานี </option>
            <option value="สุรินทร์">สุรินทร์ </option>
            <option value="สตูล">สตูล </option>
            <option value="หนองคาย">หนองคาย </option>
            <option value="หนองบัวลำภู">หนองบัวลำภู </option>
            <option value="อำนาจเจริญ">อำนาจเจริญ </option>
            <option value="อุดรธานี">อุดรธานี </option>
            <option value="อุตรดิตถ์">อุตรดิตถ์ </option>
            <option value="อุทัยธานี">อุทัยธานี </option>
            <option value="อุบลราชธานี">อุบลราชธานี</option>
            <option value="อ่างทอง">อ่างทอง </option>
            <option value="อื่นๆ">อื่นๆ</option>
          </Field>
        </div>
      </div>

      <div className={styles.gridItem}>
        <div className={styles.formControl}>
          <p>
            เขต*{" "}
            <ErrorMessage
              name="county"
              render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
            />
          </p>
          <Field as="select" name="county" type="text">
            <option value="">กรุณาเลือกเขต</option>
            {props.optionCounty &&
              props.optionCounty.map((item) => {
                return <option value={item.district}>{item.district}</option>;
              })}
          </Field>
          {/* <Field name="county" type="text" /> */}
        </div>
      </div>

      <div className={styles.gridItem}>
        <div className={styles.formControl}>
          <p>
            แขวง*{" "}
            <ErrorMessage
              name="city"
              render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
            />
          </p>
          <Field as="select" name="city" type="text">
            <option value="">กรุณาเลือกแขวง</option>
            {props.optionCity &&
              props.optionCity.map((item) => {
                return (
                  <option value={item.sub_district}>{item.sub_district}</option>
                );
              })}
          </Field>
          {/* <Field name="city" type="text" /> */}
        </div>
      </div>

      <div className={styles.gridItem}>
        <div className={styles.formControl}>
          <p>
            รหัสไปรษณีย์*{" "}
            <ErrorMessage
              name="zip"
              render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
            />
          </p>
          <Field name="zip" type="text" />
        </div>
      </div>
    </div>
  );
};

export default LocationFieldsComponent;
