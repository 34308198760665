import React, { useEffect, useState } from "react";
import { withFormik, useFormikContext } from "formik";
import styles from "./index.module.scss";
import axios from "axios";

import AdminKpi from "../admin-kpi";
import ModalShipping from "./modalShipping.js";
import ModalQuality from "./modalQuality.js";
import ModalShape from "./modalShape.js";
import ModalMaterial from "./modalMaterial.js";
import ModalCoat from "./modalCoat.js";
import ModalVariable from "./modalVariable.js";
import ModalReview from "./modalReview.js";
import ModalReviewStat from "./modalReviewStat.js";
import firebaseApp from "../../firebase/index.js";

import StarRating from "../star";
import Placeholder from "./placeholder-person.jpeg";

import moment from "moment";
const formatDateTime = "DD/MM/YYYY";
const FormatDateTime = (date) => {
  return moment(date).format(formatDateTime);
};

const AdminReviewComponent = () => {
  const { values, setFieldValue } = useFormikContext();
  const [pricingType, setPricingType] = useState();
  // Fetch Optiom
  useEffect(() => {
    setFieldValue("loading", true, false);
    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptions/HOnTVwWrX27N7tql4WQE`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("res.data[0]", res.data);
        setFieldValue("shape", res.data.shape_list, false);
        setFieldValue("fetch", false, false);

        axios
          .get(
            `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptions/h03eqnOmkdOFxZqJxRWy`,
            {
              headers: {
                Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
              },
            }
          )
          .then((res) => {
            console.log("res.data[0]", res.data);
            setFieldValue("material", res.data.material_list, false);
            setFieldValue("fetch", false, false);

            axios
              .get(
                `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptions/Rf8b0x8ktshu0y0VGzyV`,
                {
                  headers: {
                    Authorization:
                      "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                  },
                }
              )
              .then((res) => {
                // console.log("res.data[0]", res.data.count_list)
                setFieldValue("unitOptions", res.data, false);
                setFieldValue("fetch", false, false);

                // Shipping option
                axios
                  .get(
                    `https://asia-east2-digitalwish-sticker.cloudfunctions.net/shippingOptions`,
                    {
                      headers: {
                        Authorization:
                          "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                      },
                    }
                  )
                  .then((res) => {
                    // console.log("res.data[0]", res.data)
                    setFieldValue("shippingOption", res.data, false);
                    setFieldValue("fetch", false, false);

                    // Reviews
                    axios
                      .get(
                        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reviews`,
                        {
                          headers: {
                            Authorization:
                              "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                          },
                        }
                      )
                      .then((res) => {
                        console.log("res.data[0]", res.data);
                        const reviewData = res.data;
                        reviewData.sort((a, b) => {
                          const dateA = new Date(a.created_dt);
                          const dateB = new Date(b.created_dt);
                          return dateB - dateA;
                        });
                        setFieldValue("reviews", reviewData, false);
                        setFieldValue("fetch", false, false);

                        // Reviews Stat
                        axios
                          .get(
                            `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reviewsStat/OEYGhhtfEJIrNAY0bSw2`,
                            {
                              headers: {
                                Authorization:
                                  "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                              },
                            }
                          )
                          .then((res) => {
                            console.log("reviewsStat", res.data);
                            setFieldValue("reviewsStat", res.data, false);
                            setFieldValue("fetch", false, false);

                            setFieldValue("loading", false, false);
                          })
                          .catch(function (err) {
                            console.log("err", err);
                            setFieldValue("loading", false, false);
                          });
                      })
                      .catch(function (err) {
                        console.log("err", err);
                        setFieldValue("loading", false, false);
                      });
                  })
                  .catch(function (err) {
                    console.log("err", err);
                    setFieldValue("loading", false, false);
                  });
              })
              .catch(function (err) {
                console.log("err", err);
                setFieldValue("loading", false, false);
              });
          })
          .catch(function (err) {
            console.log("err", err);
            setFieldValue("loading", false, false);
          });
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.fetch]);

  // Seclect Coating form materail
  useEffect(() => {
    if (values.materialSelected || values.materialSelected === 0) {
      setFieldValue(
        "coating",
        values.material[values.materialSelected].coating_list,
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.materialSelected]);

  const handleChangePDF = (event) => {
    console.log("event", event);
    const storageRef = firebaseApp.storage().ref();
    if (event.target.files) {
      storageRef
        .child(`pdf`)
        .put(event.target.files[0])
        .then((snapshot) => {
          console.log("snapshot", snapshot);
          snapshot.ref.getDownloadURL().then((url) => {
            setFieldValue(`PDF`, url, false);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <main className={styles.wrapContent}>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>
      <section className={styles.section1}>
        <AdminKpi kpi={{ order: 10, sales: 1234567, member: 1000 }} />
      </section>

      <h1 className={styles.title}>รีวิว</h1>

      <section
        className={styles.productOptions}
        style={{ justifyContent: "start" }}
      >
        <article className={styles.cardProductOption}>
          <h4>รายการรีวิว</h4>
          <div>
            <button
              type="button"
              className={styles.btnOption}
              onClick={() =>
                setFieldValue("modalReview", "modalReviewAdd", false)
              }
            >
              เพิ่ม
            </button>
            <button
              type="button"
              className={styles.btnOption}
              onClick={() =>
                setFieldValue("modalReviewStat", "modalReviewStatEdit", false)
              }
            >
              ตั้งค่า
            </button>
            <ModalReview values={values} setFieldValue={setFieldValue} />
            <ModalReviewStat values={values} setFieldValue={setFieldValue} />
            {/* <ModalReviewsStat values={values} setFieldValue={setFieldValue} /> */}
          </div>
          <div className={styles.reviewWrapper}>
            <div className={styles.reviewStat}>
              <div className={styles.reviewStat1}>
                <div>
                  {values.reviews &&
                    (
                      values.reviews.reduce((a, b) => {
                        return a + parseInt(b.rating);
                      }, 0) / values.reviews.length
                    ).toFixed(1)}
                  /5
                </div>
                <div>
                  <StarRating rating={5} />
                </div>
              </div>
              <div className={styles.reviewStat1}>
                {/* <div>{values.reviewsStat && (parseInt(values.reviewsStat.reviewsCount).toLocaleString() || 0)}</div> */}
                <div>{(values.reviews && values.reviews.length) || 0}</div>
                <div>รีวิว</div>
              </div>

              <div className={styles.reviewStat1}>
                <div>
                  {values.reviewsStat &&
                    (values.reviewsStat.orderAgainRate || 0)}
                  %
                </div>
                <div>กลับมาสั่งอีกครั้ง</div>
              </div>
            </div>
            {values.reviews &&
              values.reviews.length > 0 &&
              values.reviews.map((review) => {
                return (
                  <>
                    <div className={styles.review}>
                      <div className={styles.img}>
                        {review.img ? (
                          <>
                            <img src={review.img} alt={"review"} />
                          </>
                        ) : (
                          <>
                            <img src={Placeholder} alt={"review"} />
                          </>
                        )}
                      </div>
                      <div>
                        <div className={styles.section1}>
                          <StarRating rating={review.rating} />
                          <div className={styles.reviewText}>
                            {review.review}
                          </div>
                        </div>
                        <div className={styles.section2}>
                          <div className={styles.dateName}>{review.name}</div>
                          <div className={styles.dateText}>
                            {review.created_dt &&
                              FormatDateTime(review.created_dt)}
                          </div>
                        </div>
                        <div className={styles.section3}>
                          {review.description}
                        </div>
                        <button
                          type="button"
                          className={styles.btnReview}
                          onClick={() => {
                            setFieldValue(
                              "modalReview",
                              "modalReviewEdit",
                              false
                            );
                            setFieldValue("reviewID", review.myID, false);
                          }}
                        >
                          แก้ไข
                        </button>
                      </div>
                      <div></div>
                    </div>
                  </>
                );
              })}
          </div>
        </article>
      </section>
    </main>
  );
};

const EnhancedAdminReviewComponent = withFormik({
  mapPropsToValues: (props) => ({
    loading: false,
    shape: [],

    material: [],

    coating: [],

    cuttingList: [],

    unitOptions: [],

    shippingOption: [],

    modalAdd: "",
    fetch: false,
  }),
})(AdminReviewComponent);

export default EnhancedAdminReviewComponent;
