import React, { useState } from "react";
import StepProgress from "../step_progress";
import StepProductConfig from "../step-product-config";
import styles from "./index.module.scss";
import { Form, ErrorMessage } from "formik";
import { useFormikContext } from "formik";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";

const Order1ShapeConfigComponent = (props) => {
  const [selectStep] = useState(1);
  const { values, setFieldValue } = useFormikContext();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const queryType = query.get("type");

  return (
    <main>
      <div
        class={`loader loader-default ${
          values.loadingShape ? "is-active" : ""
        }`}
      />
      <section className={styles.section1}>
        <StepProgress stepIndex={selectStep} />
      </section>

      <div className={styles.wrapContent}>
        <StepProductConfig />

        <section className={styles.groupOptionProductConfig}>
          <Form>
            <h3 className={styles.titalPage}>
              {/* เลือกรูปแบบสติกเกอร์ */}
              <ErrorMessage
                name="shape"
                render={(msg) => <span className="error">{msg}</span>}
              />
            </h3>

            <div style={{ marginBottom: "20px" }}>
              {(!queryType || queryType === "แผ่น") && (
                <>
                  <div>
                    <h3 className={styles.titalPage}>
                      สั่งซื้อสติกเกอร์แบบแผ่น
                    </h3>
                  </div>
                  <div className={styles.gruop}>
                    {
                      // values.optionShape.filter(function (data, index) {
                      //     return data.type === "แผ่น";
                      // }).map(function (data, index) {
                      values.optionShape
                        .filter((data) => data.type === "แผ่น")
                        .filter((each) => each && each.name)
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort in ascending order by data.name
                        .map((data, index) => {
                          return (
                            <button type="submit" className={styles.nextButton}>
                              <article
                                className={`${styles.smallCardProductConfig} ${
                                  values.shape === data.name && styles.active
                                }`}
                                onClick={() => {
                                  setFieldValue("shape", data.name, true);
                                  // setFieldValue("pricingType", data.type, true);
                                  // setFieldValue("shape_index", index, true);
                                  setFieldValue("selectedShape", data, false);
                                  setFieldValue("selectedMaterial", "", false);
                                  setFieldValue("selectedCoating", "", false);
                                  setFieldValue("optionCoating", [], false);
                                }}
                              >
                                <img alt="." src={data.imgUrl} />
                                <p style={{ fontSize: "1.4rem" }}>
                                  {data.name}
                                </p>
                              </article>
                            </button>
                          );
                        })
                    }
                  </div>
                </>
              )}
            </div>

            <div style={{ marginBottom: "20px" }}>
              {(!queryType || queryType === "ม้วน") && (
                <>
                  <div>
                    <h3 className={styles.titalPage}>
                      สั่งซื้อสติกเกอร์แบบม้วน
                    </h3>
                  </div>
                  <div className={styles.gruop}>
                    {
                      // values.optionShape
                      //   .filter(function (data, index) {
                      //     return data.type === "ม้วน";
                      //   })
                      //   .map(function (data, index) {
                      values.optionShape
                        .filter((data) => data.type === "ม้วน")
                        .filter((each) => each && each.name)
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort in ascending order by data.name
                        .map((data, index) => {
                          return (
                            <button type="submit" className={styles.nextButton}>
                              <article
                                className={`${styles.smallCardProductConfig} ${
                                  values.shape === data.name && styles.active
                                }`}
                                onClick={() => {
                                  setFieldValue("shape", data.name, true);
                                  // setFieldValue("pricingType", data.type, true);
                                  // setFieldValue("shape_index", index, true);
                                  setFieldValue("selectedShape", data, false);
                                  setFieldValue("selectedMaterial", "", false);
                                  setFieldValue("selectedCoating", "", false);
                                  setFieldValue("optionCoating", [], false);
                                }}
                              >
                                <img alt="." src={data.imgUrl} />
                                <p style={{ fontSize: "1.4rem" }}>
                                  {data.name}
                                </p>
                              </article>
                            </button>
                          );
                        })
                    }
                  </div>
                </>
              )}
            </div>

            {/* <div style={{marginBottom: "20px"}}>
              {(!queryType || (queryType !== "ม้วน" && queryType !== "แผ่น")) && (
                <>
                  <div><h3 className={styles.titalPage}>สั่งซื้อสติกเกอร์แบบอื่นๆ</h3></div>
                  <div className={styles.gruop}>
                  {
                    values.optionShape.filter(function (data, index) {
                        return data.type !== "ม้วน" && data.type !== "แผ่น";
                    }).map(function (data, index) {
                        return (
                          <button type="submit" className={styles.nextButton}>
                            <article
                              className={`${styles.smallCardProductConfig} ${
                                values.shape === data.name && styles.active
                              }`}
                              onClick={() => {
                                setFieldValue("shape", data.name, true);
                                // setFieldValue("pricingType", data.type, true);
                                // setFieldValue("shape_index", index, true);
                                setFieldValue("selectedShape", data, false);
                                setFieldValue("selectedMaterial", "", false);
                                setFieldValue("selectedCoating", "", false);
                                setFieldValue("optionCoating", [], false);
                              }}
                            >
                              <img alt="." src={data.imgUrl} />
                              <p style={{fontSize: "1.4rem"}}>{data.name}</p>
                            </article>
                          </button>
                        );
                    })
                  }
                  </div>
                </>
              )}
            </div> */}
            {/* <div className={styles.gruop}>
              {values.optionShape.map((data, index) => {
                if (queryType) {
                  if (data.name.includes(queryType))
                    return (
                      <button type="submit" className={styles.nextButton}>
                        <article
                          className={`${styles.smallCardProductConfig} ${
                            values.shape === data.name && styles.active
                          }`}
                          onClick={() => {
                            setFieldValue("shape", data.name, true);
                            // setFieldValue("pricingType", data.type, true);
                            // setFieldValue("shape_index", index, true);
                            setFieldValue("selectedShape", data, false);
                            setFieldValue("selectedMaterial", "", false);
                            setFieldValue("selectedCoating", "", false);
                            setFieldValue("optionCoating", [], false);
                          }}
                        >
                          <img alt="." src={data.imgUrl} />
                          <p style={{fontSize: "1.4rem"}}>{data.name}</p>
                        </article>
                      </button>
                    );
                  else return;
                } else
                  return (
                    <button type="submit" className={styles.nextButton}>
                      <article
                        className={`${styles.smallCardProductConfig} ${
                          values.shape === data.name && styles.active
                        }`}
                        onClick={() => {
                          setFieldValue("shape", data.name, true);
                          // setFieldValue("pricingType", data.type, true);
                          // setFieldValue("shape_index", index, true);
                          setFieldValue("selectedShape", data, false);
                          setFieldValue("material", "", true);
                          setFieldValue("selectedMaterial", "", false);
                          setFieldValue("coat", "", true);
                          setFieldValue("selectedCoating", "", false);
                          setFieldValue("width", "", true);
                          setFieldValue("height", "", false);
                          setFieldValue("units", null, false);
                          setFieldValue("price", "", false);
                          setFieldValue("optionCoating", [], false);
                        }}
                      >
                        <img alt="." src={data.imgUrl} />
                        <p style={{fontSize: "1.4rem"}}>{data.name}</p>
                      </article>
                    </button>
                  );
              })}
            </div> */}

            {/* <button type="submit" >
              ถัดไป
            </button> */}
          </Form>
        </section>
      </div>
    </main>
  );
};

export default Order1ShapeConfigComponent;
