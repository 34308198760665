import React, { useState, useEffect } from "react";
import StepProgress from "../step_progress";
import styles from "./index.module.scss";
import { Field, Form, ErrorMessage } from "formik";
import { useFormikContext } from "formik";

import { ReactComponent as IconCorrect } from "./icon-correct.svg";

const StepProductConfigComponent = (props) => {
  const [selectStep] = useState(1);
  const { values, setFieldValue } = useFormikContext();

  return (
    <section className={styles.stepProgressProductConfig}>
      <h4 className={styles.titleProductConfig}>รูปแบบสติกเกอร์</h4>
      <p className={styles.detailProductConfig}>
        {values.shape ? (
          <>
            <IconCorrect />
            <label>{values.shape}</label>
          </>
        ) : (
          "-"
        )}
      </p>
      <h4 className={styles.titleProductConfig}>เนื้อวัสดุ</h4>
      <p className={styles.detailProductConfig}>
        {values.material ? (
          <>
            <IconCorrect />
            <label>{values.material}</label>
          </>
        ) : (
          "-"
        )}
      </p>
      <h4 className={styles.titleProductConfig}>การเคลือบหน้า + กาวหลัง</h4>
      <p className={styles.detailProductConfig}>
        {values.coat ? (
          <>
            <IconCorrect />
            <label>{values.coat}</label>
          </>
        ) : (
          "-"
        )}
      </p>
      <h4 className={styles.titleProductConfig}>ขนาด</h4>
      <p className={styles.detailProductConfig}>
        {values.height || values.width ? (
          <>
            <IconCorrect />
            <label>
              {values.width}mm X {values.height}mm
            </label>
          </>
        ) : (
          "-"
        )}
      </p>
      <h4 className={styles.titleProductConfig}>จำนวน</h4>
      <p className={styles.detailProductConfig}>
        {values.units ? (
          <>
            <IconCorrect />
            <label>{values.units.toLocaleString()} ชิ้น</label>
          </>
        ) : (
          "-"
        )}
      </p>
      <h4 className={styles.titleProductConfig}>ราคาต่อชิ้น</h4>
      <p className={styles.detailProductConfig}>
        {values.units ? (
          <>
            <IconCorrect />({((values.price * 1.07) / values.units).toFixed(2)}{" "}
            บาท/ชิ้น)
          </>
        ) : (
          "-"
        )}
      </p>
      <p>รวม</p>
      <h3 className={styles.priceProductConfig}>
        {(values.price * 1.07).toFixed(2)} บาท
      </h3>

      {values.stepProgress !== 0 && (
        <button
          type="button"
          className={styles.nextButton}
          onClick={() =>
            setFieldValue("stepProgress", values.stepProgress - 1, false)
          }
          style={{ fontSize: "1.3rem" }}
        >
          ย้อนกลับ
        </button>
      )}
    </section>
  );
};

export default StepProductConfigComponent;
