import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./index.module.scss";

import Slider from "react-slick";
import { Link } from "react-router-dom";
import {
  useFormikContext,
  withFormik,
  Form,
  Field,
  ErrorMessage,
} from "formik";

import { ReactComponent as Banner } from "./banner.svg";

import Marquee from "react-fast-marquee";
import Iframe from "react-iframe-click";

import { ReactComponent as S11SampleIcon } from "./s1-1-sample-icon.svg";
import { ReactComponent as S12BasketIcon } from "./s1-2-basket-icon.svg";
import { ReactComponent as S13StatusIcon } from "./s1-3-status-icon.svg";
import { ReactComponent as S21OrderIcon } from "./s2-1-order-icon.svg";
import { ReactComponent as S31ArtIcon } from "./s3-1-art-icon.svg";
import { ReactComponent as S32PPWhiteIcon } from "./s3-2-pp-white-icon.svg";
import { ReactComponent as S33PPSilverIcon } from "./s3-3-pp-silver-icon.svg";
import { ReactComponent as S34PPTransIcon } from "./s3-4-pp-trans-icon.svg";
import { ReactComponent as S41LogoIcon } from "./s4-1-logo-icon.svg";
import { ReactComponent as S51OrderIcon } from "./s5-1-order-icon.svg";

import { ReactComponent as Test } from "./test.svg";

import orderStep1 from "./order-step-1.png";
import orderStep2 from "./order-step-2.png";
import orderStep3 from "./order-step-3.png";
import orderStep4 from "./order-step-4.png";

import paperArt from "./paper-art.png";
import ppTrans from "./pp-trans.png";
import ppWhite from "./pp-white.png";
import ppSilver from "./pp-silver.png";

import arrowRight from "./right.png";

// import banner from "./banner.png";
import banner from "./banner3.jpg";

import cert1 from "./cert1.png";
import cert2 from "./cert2.png";
import cert3 from "./cert3.png";

import samplePhoto from "./sample-kit.png";
import samplePhoto2 from "./sample-kit2.jpg";

import gallery11 from "./gallery-1-1.png";
import gallery12 from "./gallery-1-2.png";
import gallery13 from "./gallery-1-3.png";
// import gallery14 from "./gallery-1-4.png";
import gallery15 from "./gallery-1-5.jpg";
import gallery21 from "./gallery-2-1.png";
import gallery22 from "./gallery-2-2.png";
import gallery23 from "./gallery-2-3.png";
import gallery24 from "./gallery-2-4.png";
import gallery25 from "./gallery-2-5.png";
import gallery26 from "./gallery-2-6.png";
import gallery27 from "./gallery-2-7.jpg";
import gallery28 from "./gallery-2-8.jpg";
import gallery29 from "./gallery-2-9.jpg";
import gallery210 from "./gallery-2-10.jpg";
import gallery211 from "./gallery-2-11.jpg";
import gallery212 from "./gallery-2-12.jpg";
import gallery31 from "./gallery-3-1.png";
import gallery32 from "./gallery-3-2.png";
import gallery33 from "./gallery-3-3.png";
import gallery34 from "./gallery-3-4.png";
import gallery35 from "./gallery-3-5.png";
import gallery36 from "./gallery-3-6.jpg";
import gallery37 from "./gallery-3-7.jpg";
import gallery38 from "./gallery-3-8.jpg";
import gallery41 from "./gallery-4-1.png";
import gallery42 from "./gallery-4-2.png";
import gallery43 from "./gallery-4-3.png";
import gallery44 from "./gallery-4-4.png";
import gallery45 from "./gallery-4-5.png";
import gallery46 from "./gallery-4-6.png";
import gallery47 from "./gallery-4-7.jpg";
//NEW
import gallery51 from "./gallery-5-1.jpg";
import gallery52 from "./gallery-5-2.jpg";

import widgets_contactIcon from "./widgets_contactIcon.png";
import widgets_lineIcon from "./widgets_lineIcon.png";
import widgets_phoneIcon from "./widgets_phoneIcon.png";
import widgets_facebookIcon from "./widgets_facebookIcon.png";

import StarRating from "../star";
import Placeholder from "./placeholder-person.jpeg";

import videoHolder from "./video-placeholder.png";

// import orderType1 from "./orderType1.jpg";
// import orderType2 from "./orderType2.jpg";

import NewOrderType1 from "./new-1.png";
import NewOrderType2 from "./new-2.png";

import Menu1 from "./สั่งซื้อ-01.png";
import Menu2 from "./สั่งซื้อ-02.png";

import FooterComponent from "../footer";

import axios from "axios";
import addressData from "./address.json";
import toast, { Toaster } from "react-hot-toast";

import ReactGA from "react-ga";
import ReviewSection from "../reviews";

import ToggleModal from "./ToggleModal.js";

const HomeComponent = (props) => {
  useEffect(() => {
    ReactGA.pageview("/");
  }, []);

  let settings = {
    // className: "slider variable-width",
    // centerMode: true,
    variableWidth: true,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 200,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 4,
    // swipeToSlide: true,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const stepsOrder = useRef(null);
  const ourWorks = useRef(null);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const { values, setFieldValue } = useFormikContext();
  const [optionCounty, setOptionCounty] = useState([]);
  const [optionCity, setOptionCity] = useState([]);

  const [toggleMenu, setToggleMenu] = useState(false);

  const [toggleContact1, setToggleContact1] = useState(false);
  const [toggleContact2, setToggleContact2] = useState(false);
  const [toggleContact3, setToggleContact3] = useState(false);

  useEffect(() => {
    if (values.done) setModal(false);
  }, [values]);

  // Take Effect every time the hash of the document is changed - CHANGED TO REFS
  // Thinking of https://medium.com/javascript-in-plain-english/creating-a-hash-anchor-link-effect-with-react-router-a63dcb1a9b0e
  useEffect(() => {
    if (props.location.state !== undefined) {
      if (props.location.state.scrollToStepsOrder) {
        stepsOrder.current.scrollIntoView({ block: "center" });
      } else if (props.location.state.scrollToOurWorks) {
        ourWorks.current.scrollIntoView({ block: "center" });
      }
    }
  }, [props.location.state]);

  useEffect(() => {
    // console.log("VALUE", values);

    let newOptionCity = addressData.filter((item) => {
      return item.district == values.county;
    });
    let resultCity = newOptionCity.reduce((unique, o) => {
      if (!unique.some((obj) => obj.sub_district === o.sub_district)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setOptionCity(resultCity);
    // console.log("newOptionCity", resultCity);

    let newOptionCounty = addressData.filter((item) => {
      return item.province == values.provice;
    });
    let resultCounty = newOptionCounty.reduce((unique, o) => {
      if (!unique.some((obj) => obj.district === o.district)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setOptionCounty(resultCounty);
    // console.log("newOptionCounty", resultCounty);
  }, [values]);

  // Fetch Reference
  useEffect(() => {
    setFieldValue("loading", true, false);
    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reference/ZwdyBLoBPhe7SqEjrK5B`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("references", res.data.list);
        // Order
        function compare(a, b) {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        }
        let newReferences = res.data.list;
        if (newReferences) newReferences.sort(compare);
        setFieldValue("references", newReferences || [], false);
        setFieldValue("fetch", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.toaster}>
        <Toaster position="bottom-center" reverseOrder={false} />
      </div>

      {toggleContact1 && (
        <ToggleModal
          icon={widgets_lineIcon}
          heading={"พูดคุยผ่าน Line"}
          content={"@stickerwish"}
          url={"https://lin.ee/sZW5Mh8"}
          setIsShow={setToggleContact1}
        />
      )}
      {toggleContact2 && (
        <ToggleModal
          icon={widgets_phoneIcon}
          heading={"โทรหาเรา"}
          content={"0971565224"}
          url={"tel:0971565224"}
          setIsShow={setToggleContact2}
        />
      )}
      {toggleContact3 && (
        <ToggleModal
          icon={widgets_facebookIcon}
          heading={"Digitalwish.sticker"}
          content={"Digitalwish.sticker"}
          url={"https://www.facebook.com/Digitalwish-sticker-101272075721621"}
          setIsShow={setToggleContact3}
        />
      )}
      <div className={styles.floatingMenu}>
        <ul>
          <li className={toggleMenu ? styles.show : styles.hide}>
            <a href="https://lin.ee/sZW5Mh8" target="_blank">
              {/* <a onClick={()=> setToggleContact1(true)}> */}
              {/* <label>พูดคุยผ่าน Line</label> */}
              <label>@stickerwish</label>
              <div>
                <img
                  className={styles.line}
                  src={widgets_lineIcon}
                  alt="contact"
                />
              </div>
            </a>
          </li>
          <li className={toggleMenu ? styles.show : styles.hide}>
            <a href="tel:0971565224">
              {/* <a onClick={() => setToggleContact2(true)}> */}
              {/* <label>โทรหาเรา</label> */}
              <label>097-156-5224</label>
              <div>
                <img
                  className={styles.phone}
                  src={widgets_phoneIcon}
                  alt="contact"
                />
              </div>
            </a>
          </li>
          <li className={toggleMenu ? styles.show : styles.hide}>
            <a
              href="https://www.facebook.com/Digitalwish-sticker-101272075721621"
              target="_blank"
            >
              {/* <a onClick={()=> setToggleContact3(true)}> */}
              <label>Digitalwish.sticker</label>
              <div>
                <img
                  className={styles.facebook}
                  src={widgets_facebookIcon}
                  alt="contact"
                />
              </div>
            </a>
          </li>
          <li onClick={() => setToggleMenu(!toggleMenu)}>
            <label>ติดต่อเราได้ที่นี่เลยค่ะ</label>
            <div>
              <img
                className={styles.contact}
                src={widgets_contactIcon}
                alt="contact"
              />
            </div>
          </li>
        </ul>
      </div>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>
      <main>
        <div className={styles.myBanner}>
          <div className={styles.caption}>
            <div className={styles.caption1}>
              สร้างสรรค์งานสติ๊กเกอร์ในแบบของคุณ
            </div>
            <div className={styles.caption2}>
              สั่งสติ๊กเกอร์ได้ง่ายๆ พร้อมจัดส่งถึงมือคุณ
            </div>
            <div className={styles.caption3}>
              <Link to="/customize" type="button" className={styles.ctaButton}>
                สั่งซื้อสินค้า คลิ๊กเลย!!
              </Link>
            </div>
          </div>
        </div>
        {/*<Banner className={styles.banner} />*/}
        {/* <div className={styles.customizeBanner}>
          <Link to="/customize">
          <img src={banner} className={styles.banner} />
          <Link to="/customize">
            <button>
              <b>เริ่มสั่งทำสติ๊กเกอร์เลย!</b>
            </button>
          </Link>
          </Link>
        </div> */}

        {/* <section className={styles.section1CTA}>
          <Link to="/customize?type=แผ่น" className={styles.typeOrder}>
            <img src={orderType1} />
            <div>สั่งสิ้นค้ารูปแบบแผ่น</div>
          </Link>
          <Link to="/customize?type=ม้วน" className={styles.typeOrder}>
            <img src={orderType2} />
            <div>สั่งสิ้นค้ารูปแบบม้วน</div>
          </Link>
        </section> */}

        <section className={styles.section1CTANewNew}>
          <div className={styles.ctaShape}>
            <Link to="/customize?type=แผ่น" className={styles.type1}>
              <div>สินค้ารูปแบบแผ่น</div>
              <div
                style={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <Link
                  to="/customize?type=แผ่น"
                  type="button"
                  className={styles.ctaButtonShape2}
                >
                  สั่งซื้อสินค้า
                </Link>
              </div>
            </Link>
            {/* <div>
              <Link
                to="/customize?type=แผ่น"
                type="button"
                className={styles.ctaButtonShape}
              >
                สั่งซื้อสินค้า
              </Link>
            </div> */}
          </div>
          <div className={styles.ctaShape}>
            <Link to="/customize?type=ม้วน" className={styles.type2}>
              <div>สินค้ารูปแบบม้วน</div>
              <div
                style={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <Link
                  to="/customize?type=ม้วน"
                  type="button"
                  className={styles.ctaButtonShape2}
                >
                  สั่งซื้อสินค้า
                </Link>
              </div>
            </Link>
            {/* <div>
              <Link
                to="/customize?type=ม้วน"
                type="button"
                className={styles.ctaButtonShape}
              >
                สั่งซื้อสินค้า
              </Link>
            </div> */}
          </div>
        </section>

        {/* <section className={styles.section1CTANew}>
            <Link to="/customize?type=แผ่น" className={styles.type1}>
              <div style={{color: "red"}}>สั่งสินค้ารูปแบบแผ่น</div>
            </Link>
            <Link to="/customize?type=ม้วน" className={styles.type2}>
              <div style={{color: "red"}}>สั่งสินค้ารูปแบบม้วน</div>
            </Link>
        </section> */}

        {/* <section className={styles.sectionMenu}>
          <Link to="/customize?type=แผ่น">
            <img src={Menu1} />
          </Link>
          <img src={Menu2} />
        </section> */}

        {/* <section className={styles.section1}>
          <div className={styles.horizontalScroller}>
            <button onClick={() => setModal(true)}>
              <S11SampleIcon />
              ขอชุดตัวอย่างเนื้อสติกเกอร์
            </button>
            <Link
              to={{
                pathname: "/",
                // hash: "#stepsOrder",
                state: { scrollToStepsOrder: true },
              }}
            >
              <button onClick={() => setModal(false)}>
                <S12BasketIcon />
                ขั้นตอนการสั่งซื้อ
              </button>
            </Link>
            <Link
              to={{
                pathname: "/myorder",
                // hash: "#stepsOrder",
                state: { scrollToStepsOrder: true },
              }}
            >
              <button>
                <S13StatusIcon /> ดูสถานะการสั่งซื้อ
              </button>
            </Link>
          </div>
        </section> */}

        {/* <!-- The Modal --> */}
        <Form>
          <div
            className={styles.modal}
            style={modal ? { display: "block" } : { display: "none" }}
          >
            <div className={styles.modalContent}>
              <div className={styles.exampleSticker}>
                <S11SampleIcon />
                ขอชุดตัวอย่างเนื้อสติกเกอร์
              </div>

              <div
                className={styles.exampleStickerBox}
                style={{ border: "none" }}
              >
                {/*<Test />*/}
                <div align="center">
                  <img
                    src={samplePhoto2}
                    style={{ width: "100%", borderRadius: "20px" }}
                  />
                </div>
                <div className={styles.exampleStickerBoxDetail}>
                  {/*
                  <h3>ท่านจะได้รับ</h3>
                  <p>คำอธิบาย.............................................</p>
                  <p>คำอธิบาย.............................................</p>
                  <p>คำอธิบาย.............................................</p>
                  <p>คำอธิบาย.............................................</p>
                  */}
                </div>
              </div>

              <div className={styles.groupRow}>
                <div className={styles.isRow}>
                  <div className={styles.leftColumn}>
                    <p>
                      ชื่อ นามสกุล*
                      <ErrorMessage
                        name="name"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="name"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                  <div className={styles.rightColumn}>
                    <p>
                      อีเมล*
                      <ErrorMessage
                        name="email"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="email"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                </div>
                <div className={styles.isRow}>
                  <div className={styles.leftColumn}>
                    <p>
                      ที่อยู่*
                      <ErrorMessage
                        name="address"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="address"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                  <div className={styles.rightColumn}>
                    <p>
                      เบอร์โทรศัพท์*
                      <ErrorMessage
                        name="phone"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="phone"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                </div>
                <div className={styles.isRow}>
                  <div className={styles.leftColumn}>
                    <p>
                      จังหวัด*
                      <ErrorMessage
                        name="provice"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    {/* <Field
                      name="provice"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    /> */}

                    <Field as="select" name="provice" type="text">
                      <option value="" selected>
                        กรุณาเลือกจังหวัด
                      </option>
                      <option value="กรุงเทพมหานคร">กรุงเทพมหานคร</option>
                      <option value="กระบี่">กระบี่ </option>
                      <option value="กาญจนบุรี">กาญจนบุรี </option>
                      <option value="กาฬสินธุ์">กาฬสินธุ์ </option>
                      <option value="กำแพงเพชร">กำแพงเพชร </option>
                      <option value="ขอนแก่น">ขอนแก่น</option>
                      <option value="จันทบุรี">จันทบุรี</option>
                      <option value="ฉะเชิงเทรา">ฉะเชิงเทรา </option>
                      <option value="ชัยนาท">ชัยนาท </option>
                      <option value="ชัยภูมิ">ชัยภูมิ </option>
                      <option value="ชุมพร">ชุมพร </option>
                      <option value="ชลบุรี">ชลบุรี </option>
                      <option value="เชียงใหม่">เชียงใหม่ </option>
                      <option value="เชียงราย">เชียงราย </option>
                      <option value="ตรัง">ตรัง </option>
                      <option value="ตราด">ตราด </option>
                      <option value="ตาก">ตาก </option>
                      <option value="นครนายก">นครนายก </option>
                      <option value="นครปฐม">นครปฐม </option>
                      <option value="นครพนม">นครพนม </option>
                      <option value="นครราชสีมา">นครราชสีมา </option>
                      <option value="นครศรีธรรมราช">นครศรีธรรมราช </option>
                      <option value="นครสวรรค์">นครสวรรค์ </option>
                      <option value="นราธิวาส">นราธิวาส </option>
                      <option value="น่าน">น่าน </option>
                      <option value="นนทบุรี">นนทบุรี </option>
                      <option value="บึงกาฬ">บึงกาฬ</option>
                      <option value="บุรีรัมย์">บุรีรัมย์</option>
                      <option value="ประจวบคีรีขันธ์">ประจวบคีรีขันธ์ </option>
                      <option value="ปทุมธานี">ปทุมธานี </option>
                      <option value="ปราจีนบุรี">ปราจีนบุรี </option>
                      <option value="ปัตตานี">ปัตตานี </option>
                      <option value="พะเยา">พะเยา </option>
                      <option value="พระนครศรีอยุธยา">พระนครศรีอยุธยา </option>
                      <option value="พังงา">พังงา </option>
                      <option value="พิจิตร">พิจิตร </option>
                      <option value="พิษณุโลก">พิษณุโลก </option>
                      <option value="เพชรบุรี">เพชรบุรี </option>
                      <option value="เพชรบูรณ์">เพชรบูรณ์ </option>
                      <option value="แพร่">แพร่ </option>
                      <option value="พัทลุง">พัทลุง </option>
                      <option value="ภูเก็ต">ภูเก็ต </option>
                      <option value="มหาสารคาม">มหาสารคาม </option>
                      <option value="มุกดาหาร">มุกดาหาร </option>
                      <option value="แม่ฮ่องสอน">แม่ฮ่องสอน </option>
                      <option value="ยโสธร">ยโสธร </option>
                      <option value="ยะลา">ยะลา </option>
                      <option value="ร้อยเอ็ด">ร้อยเอ็ด </option>
                      <option value="ระนอง">ระนอง </option>
                      <option value="ระยอง">ระยอง </option>
                      <option value="ราชบุรี">ราชบุรี</option>
                      <option value="ลพบุรี">ลพบุรี </option>
                      <option value="ลำปาง">ลำปาง </option>
                      <option value="ลำพูน">ลำพูน </option>
                      <option value="เลย">เลย </option>
                      <option value="ศรีสะเกษ">ศรีสะเกษ</option>
                      <option value="สกลนคร">สกลนคร</option>
                      <option value="สงขลา">สงขลา </option>
                      <option value="สมุทรสาคร">สมุทรสาคร </option>
                      <option value="สมุทรปราการ">สมุทรปราการ </option>
                      <option value="สมุทรสงคราม">สมุทรสงคราม </option>
                      <option value="สระแก้ว">สระแก้ว </option>
                      <option value="สระบุรี">สระบุรี </option>
                      <option value="สิงห์บุรี">สิงห์บุรี </option>
                      <option value="สุโขทัย">สุโขทัย </option>
                      <option value="สุพรรณบุรี">สุพรรณบุรี </option>
                      <option value="สุราษฎร์ธานี">สุราษฎร์ธานี </option>
                      <option value="สุรินทร์">สุรินทร์ </option>
                      <option value="สตูล">สตูล </option>
                      <option value="หนองคาย">หนองคาย </option>
                      <option value="หนองบัวลำภู">หนองบัวลำภู </option>
                      <option value="อำนาจเจริญ">อำนาจเจริญ </option>
                      <option value="อุดรธานี">อุดรธานี </option>
                      <option value="อุตรดิตถ์">อุตรดิตถ์ </option>
                      <option value="อุทัยธานี">อุทัยธานี </option>
                      <option value="อุบลราชธานี">อุบลราชธานี</option>
                      <option value="อ่างทอง">อ่างทอง </option>
                      <option value="อื่นๆ">อื่นๆ</option>
                    </Field>
                  </div>
                  <div className={styles.rightColumn}>
                    <p>
                      เขต*
                      <ErrorMessage
                        name="county"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    {/* <Field
                      name="county"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    /> */}
                    <Field as="select" name="county" type="text">
                      <option value="">กรุณาเลือกเขต</option>
                      {optionCounty &&
                        optionCounty.map((item) => {
                          return (
                            <option value={item.district}>
                              {item.district}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                </div>
                <div className={styles.isRow}>
                  <div className={styles.leftColumn}>
                    <p>
                      แขวง*
                      <ErrorMessage
                        name="city"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    {/* <Field
                      name="city"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    /> */}
                    <Field as="select" name="city" type="text">
                      <option value="">กรุณาเลือกแขวง</option>
                      {optionCity &&
                        optionCity.map((item) => {
                          return (
                            <option value={item.sub_district}>
                              {item.sub_district}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                  <div className={styles.rightColumn}>
                    <p>
                      รหัสไปรษณีย์*
                      <ErrorMessage
                        name="zip"
                        render={(msg) => <span className="error">{msg}</span>}
                      />
                    </p>
                    <Field
                      name="zip"
                      type="text"
                      disabled={values.waitProcess ? true : false}
                    />
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <button
                  type="submit"
                  className={styles.btnGreen}
                  disabled={values.waitProcess ? true : false}
                >
                  ตกลง
                </button>
                <button
                  type="button"
                  className={styles.btnGreen}
                  disabled={values.waitProcess ? true : false}
                  onClick={() => setModal(false)}
                >
                  ปิด
                </button>
              </div>
            </div>
          </div>
        </Form>

        <section id="stepsOrder" ref={stepsOrder} className={styles.section2}>
          <h2>ขั้นตอนการสั่งซื้อ</h2>
          <ol className={styles.stepsOrder}>
            <li>
              <span>
                <img src={orderStep1} />
              </span>
              <p>เลือกรูปแบบสติกเกอร์ที่ต้องการ</p>
            </li>
            <li>
              <span>
                <img src={orderStep2} />
              </span>
              <p>อัพโหลดไฟล์ (AI/PDF)</p>
            </li>
            <li>
              <span>
                <img src={orderStep3} />
              </span>
              <p>ตรวจสอบและคอนเฟิร์มงานก่อนผลิต</p>
            </li>
            <li>
              <span>
                <img src={orderStep4} />
              </span>
              <p>ผลิต และ จัดส่ง</p>
            </li>
          </ol>
          {/* <Link to="/customize">
            <button>
              <S21OrderIcon />

              <b>สั่งซื้อสินค้า</b>
            </button>
          </Link> */}
        </section>

        <section className={styles.sectionNewBanner}>
          <div>งานพิมพ์คมชัด วัสดุคุณภาพสูง มีให้เลือกหลายรูปแบบ</div>
        </section>

        <section
          className={styles.section4}
          onClick={() => {
            // console.log("AAA");
          }}
        >
          {/* <div align="center" className={styles.videoContainer}>
            <iframe
              src="https://www.youtube.com/embed/uRY7WbQwfDo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div> */}
          <div align="center" style={{ flexBasis: "60%" }}>
            {/* <S41LogoIcon /> */}
            <div
              className={styles.videoWrapper}
              onClick={() => {
                // console.log("AAA");
              }}
            >
              <Iframe
                src="https://www.youtube.com/embed/BNHrSjxQhN4"
                onInferredClick={() => {
                  ReactGA.event({
                    category: "Link",
                    action: "กดเล่นวีดีโอ",
                    value: 1,
                  });
                }}
                width="560"
                height="315"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></Iframe>
              ;
              {/* <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/BNHrSjxQhN4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                onClick={() => {
                  console.log("AAA");
                  ReactGA.event({
                    category: "Link",
                    action: "กดเล่นวีดีโอ",
                    value: 1,
                  });
                }}
              ></iframe> */}
            </div>
            {/* <img src={videoHolder} /> */}
          </div>
          <div style={{ flexBasis: "50%" }}>
            {/* <h2>เกี่ยวกับเรา</h2>
            <p>
              <b>Digital Wish Sticker</b>{" "}
              ให้บริการด้านการพิมพ์ฉลากสติ๊กเกอร์สำหรับผลิตภัณฑ์ของคุณ
              โดยบริการของเราออกแบบเพื่อให้คุณสั่งซื้อสติ๊กเกอร์ออนไลน์ตามแบบที่คุณต้องการอย่างสะดวก
              รวดเร็ว พร้อมจัดส่งภายใน 3-5วัน และบริการแก้ไขอาร์ตเวิร์คฟรี
              ในราคาที่คุ้มค่าที่สุด เพียงในไม่กี่คลิ๊ก !
              อีกทั้งหากคุณไม่แน่ใจในวัสดุการพิมพ์ ทางเรายินดีจัดส่งตัวอย่างฟรี
              ได้ที่นี่ >{" "}
              <button onClick={() => setModal(true)}>ขอตัวอย่าง</button>
            </p>
            <p>
              <br />
              ดิจิตอลวิชให้ความสำคัญกับทุกรายละเอียดเพื่อให้แน่ใจว่าสิ่งที่เรานำเสนอมีคุณภาพดีที่สุดโดยเฉพาะความคมชัดและสีของงานพิมพ์ซึ่งรองรับด้วยเทคโนโลยีการพิมพ์สูงสุด
              โดยเฉพาะลูกค้าที่ต้องการพิมพ์ฉลากสติ๊กเกอร์สำหรับอาหารและเครื่องดื่ม
              ทางDigital Wish คือหนึ่งในทางเลือกที่ดีที่สุด
              การันตีด้วยรางวัลชนะเลิศการประกวดผลิตฉลากสินค้าในหมวด Food&
              Beverage Labels ของการแข่งขัน 13th HP Digital Print Excellence
              Awards2020 จัดโดย HP และมอบรางวัลโดย BJC !
            </p> */}
            <p>
              <b>Digital Wish</b>{" "}
              ให้บริการด้านการพิมพ์สติกเกอร์สำหรับผลิตภัณฑ์ของคุณโดยบริการของเราออกแบบเพื่อให้คุณสั่งซื้อสติกเกอร์ออนไลน์ตามแบบที่คุณต้องการอย่างสะดวก
              และรวดเร็ว พร้อมจัดส่งภายใน 5-7 วันในราคาที่คุ้มค่าที่สุด
            </p>
          </div>
        </section>

        {/* <section className={styles.marqueeSlider}>
          <h2>ผลงานของเรา</h2>
          <Marquee speed={20} gradient={false} pauseOnHover>
            {values.references &&
              values.references.map((reference) => {
                return (
                  <>
                    <img src={reference.img} />
                  </>
                );
              })}
          </Marquee>
        </section> */}

        <section className={styles.slickSlider}>
          <h2>ผลงานของเรา</h2>
          <Slider {...settings}>
            {values.references &&
              values.references.map((reference) => {
                return (
                  <>
                    <img src={reference.img} />
                  </>
                );
              })}
          </Slider>
        </section>

        <section className={styles.reviewSection}>
          <h2 style={{ textAlign: "center" }}>รีวิวจากลูกค้า</h2>
          <ReviewSection values setFieldValue />
        </section>

        {/* <section id="ourWorks" ref={ourWorks} className={styles.section3Title}>
          <h2>ผลงานของเรา</h2>
        </section> */}

        {/* <section id="ourWorks" ref={ourWorks} className={styles.section3}>
          <div className={styles.cardWrapper}>
            <div className={styles.card}>
              กระดาษ Art
              <div className={styles.gallery1}></div>
              <div className={styles.myClick}>แตะที่รูปเพื่อดูตัวอย่าง</div>
            </div>
            <div className={styles.card}>
              PP ขาว
              <div className={styles.gallery2}></div>
              <div className={styles.myClick}>แตะที่รูปเพื่อดูตัวอย่าง</div>
            </div>
            <div className={styles.card}>
              PP เงิน
              <div className={styles.gallery3}></div>
              <div className={styles.myClick}>แตะที่รูปเพื่อดูตัวอย่าง</div>
            </div>
            <div className={styles.card}>
              PP ใส
              <div className={styles.gallery4}></div>
              <div className={styles.myClick}>แตะที่รูปเพื่อดูตัวอย่าง</div>
            </div>
          </div>
        </section> */}

        <div
          className={styles.modal}
          style={modal1 ? { display: "block" } : { display: "none" }}
        >
          <div className={styles.modalContent1}>
            <span onClick={() => setModal1(false)} className={styles.close}>
              &times;
            </span>
            <div className={styles.exampleSticker}>
              <S11SampleIcon />
              ตัวอย่างงาน
            </div>
            <div className={styles.groupRow}>
              <S33PPSilverIcon id="s33Svg" />
              <S33PPSilverIcon id="s33Svg" />
              <S33PPSilverIcon id="s33Svg" />
            </div>
          </div>
        </div>

        <div
          className={styles.modal}
          style={modal2 ? { display: "block" } : { display: "none" }}
        >
          <div className={styles.modalContent1}>
            <span onClick={() => setModal2(false)} className={styles.close}>
              &times;
            </span>
            <div className={styles.exampleSticker}>
              <S11SampleIcon />
              ตัวอย่างงาน
            </div>
            <div className={styles.groupRow}>
              <S33PPSilverIcon id="s33Svg" />
              <S33PPSilverIcon id="s33Svg" />
              <S33PPSilverIcon id="s33Svg" />
            </div>
          </div>
        </div>

        <div
          className={styles.modal}
          style={modal3 ? { display: "block" } : { display: "none" }}
        >
          <div className={styles.modalContent1}>
            <span onClick={() => setModal3(false)} className={styles.close}>
              &times;
            </span>
            <div className={styles.exampleSticker}>
              <S11SampleIcon />
              ตัวอย่างงาน
            </div>
            <div className={styles.groupRow}>
              <S33PPSilverIcon id="s33Svg" />
              <S33PPSilverIcon id="s33Svg" />
              <S33PPSilverIcon id="s33Svg" />
            </div>
          </div>
        </div>

        <div
          className={styles.modal}
          style={modal4 ? { display: "block" } : { display: "none" }}
        >
          <div className={styles.modalContent1}>
            <span onClick={() => setModal4(false)} className={styles.close}>
              &times;
            </span>
            <div className={styles.exampleSticker}>
              <S11SampleIcon />
              ตัวอย่างงาน
            </div>
            <div className={styles.groupRow}>
              <S33PPSilverIcon id="s33Svg" />
              <S33PPSilverIcon id="s33Svg" />
              <S33PPSilverIcon id="s33Svg" />
            </div>
          </div>
        </div>

        {/* <button className={styles.orderButton}>
          <S51OrderIcon />
          <Link to="/customize">สั่งซื้อสติกเกอร์</Link>
        </button> */}

        {/* <section id="certificates" className={styles.certificates}>
          <h2>ใบรับรองมาตราฐาน</h2>
          <div className={styles.myGrid}>
            <div>
              <img src={cert1} />
            </div>
            <div>
              <img src={cert3} />
            </div>
            <div>
              <img src={cert2} />
            </div>
          </div>
        </section> */}
      </main>
      <FooterComponent />
    </>
  );
};

const EnhancedHomeComponent = withFormik({
  mapPropsToValues: () => ({
    name: "",
    zip: "",
    address: "",
    city: "",
    county: "",
    phone: "",
    provice: "",
    email: "",

    waitProcess: false,
    loading: false,
  }),
  validate: (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "*กรุณาระบุ";
    }
    if (!values.zip) {
      errors.zip = "*กรุณาระบุ";
    }
    if (!values.address) {
      errors.address = "*กรุณาระบุ";
    }
    if (!values.city) {
      errors.city = "*กรุณาระบุ";
    }
    if (!values.county) {
      errors.county = "*กรุณาระบุ";
    }
    if (!values.phone) {
      errors.phone = "*กรุณาระบุ";
    }
    if (!/^\d+$/.test(values.phone)) {
      errors.phone = "*ต้องเป็นตัวเลข 0-9";
    }
    if (!values.email) {
      errors.email = "*กรุณาระบุ";
    }
    if (!values.provice) {
      errors.provice = "*กรุณาระบุ";
    }

    return errors;
  },
  handleSubmit: (values, { setFieldValue }) => {
    setFieldValue("waitProcess", true, false);
    setFieldValue("loading", true, false);
    let data = {
      fullname: values.name,
      email: values.email,
      phone: values.phone,
      shippingAddress: {
        fullname: values.name,
        zip: values.zip,
        address: values.address,
        city: values.city,
        county: values.county,
        provice: values.provice,
      },
      timeStamp: new Date().toISOString(),
      status: "ok",
    };
    axios
      .post(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/demoRequest`,
        // `http://localhost:5001/digitalwish-sticker/asia-east2/demoRequest`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        setFieldValue("waitProcess", false, false);
        setFieldValue("name", "", false);
        setFieldValue("zip", "", false);
        setFieldValue("address", "", false);
        setFieldValue("city", "", false);
        setFieldValue("county", "", false);
        setFieldValue("phone", "", false);
        setFieldValue("provice", "", false);
        setFieldValue("email", "", false);
        setFieldValue("loading", false, false);
        setFieldValue("done", true, false);
        // toast.success("ได้รับข้อมูลเรียบร้อยแล้ว");
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
        toast.error("ส่งข้อมูลไม่สำเร็จกรุณาลองอีกครั้ง");
      });
  },
})(HomeComponent);

export default EnhancedHomeComponent;
