import React from "react";

import styled from "styled-components";

const Container = styled.div`
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  bottom: -45px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 15px;
  pointer-events: none;
  position: absolute;
  right: 5px;
  transition: opacity 0.21s ease-in-out;
  user-select: none;
`;

export default function MyOverlay(props) {
  return <Container>กดที่ภาพเพื่อเพิ่มการแก้ไข</Container>;
}
