import React, { useState, useRef } from "react";
import Annotation from "react-image-annotation";
import {
  PointSelector,
  RectangleSelector,
  OvalSelector,
} from "react-image-annotation/lib/selectors";
import HighlightContainer from "./HighlightContainer";
import HighlightContainerDisplay from "./HighlightContainerDisplay";
import ContentContainer from "./ContentContainer";
import MyEditor from "./MyEditor";
import MyOverlay from "./MyOverlay";

import Switch from "react-switch";
import styles from "./index.module.scss";
import {
  withFormik,
  useFormikContext,
  Field,
  Form,
  ErrorMessage,
} from "formik";
import axios from "axios";

import HolderImage from "./holder.png";

import { STATUS_ORDERS_TYPE } from "../constant-variable.js";
import { auth } from "../../firebase/index.js";
import firebaseApp from "../../firebase/index.js";
import { ReactComponent as IconCheckSVG } from "../approve-layout/icon-check.svg";

const AnnotationComponent = (props) => {
  const isAdmin =
    props.isAdmin == false ? false : localStorage.getItem("isAdmin");
  const { values, setFieldValue } = useFormikContext();
  const order = props.order;
  const itemIndex = props.itemIndex;
  const setAnnotationMode = props.setAnnotationMode;
  const annotationMode = props.annotationMode;
  const selectedItem = props.selectedItem;
  const activeApproval =
    selectedItem &&
    selectedItem.hasOwnProperty("approvalList") &&
    selectedItem.approvalList.find((o) => o.isActive);
  console.log("activeApproval", activeApproval);

  const [allowAnnotate, setAllowAnnotate] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  const [annotation, setAnnotation] = useState({});

  const [customerComment, setCustomerComment] = useState();
  const [customerAttachments, setCustomerAttachments] = useState([]);

  const [imagePreview, setImagePreview] = useState(null);
  const [imagePreviewFile, setImagePreviewFile] = useState(null);

  const [noticeMessage, setNoticeMessage] = useState();

  const [confirm, setConfirm] = useState(false);

  const [darkmode, setDarkmode] = useState(false);

  const onAnnotationChange = (annotation) => {
    setAnnotation(annotation);
  };

  const onAnnotationSubmit = (annotation) => {
    const { geometry, data } = annotation;

    setAnnotations([
      ...annotations,
      {
        geometry,
        data: {
          ...data,
          id: Math.random(),
          marker: annotations.length + 1,
        },
      },
    ]);
    setAnnotation({});
  };

  function getFileExt(name) {
    if (!name) {
      return;
    }

    const lastDot = name.lastIndexOf(".");
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1).toLowerCase();

    return ext;
  }

  const onImageAdminChange = (event) => {
    setNoticeMessage(null);
    if (event.target.files && event.target.files[0]) {
      const name = event.target.files[0].name;
      const fileExtention = getFileExt(name);
      const allowed = ["jpg", "jpeg", "png"];
      if (allowed.includes(fileExtention)) {
        setImagePreview(URL.createObjectURL(event.target.files[0]));
        setImagePreviewFile(event.target.files[0]);
      } else {
        setNoticeMessage({
          isError: true,
          message: "กรุณาเลือกไฟล์ภาพ jpg, jpeg, png",
        });
      }
    }
  };

  const onImageCustomerChange = (event) => {
    setNoticeMessage(null);
    if (event.target.files && event.target.files[0]) {
      console.log("event.target.files", event.target.files);
      let attachmentList = [];
      for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files.item(i);
        const name = file.name;
        const fileExtention = getFileExt(name);
        const allowed = ["jpg", "jpeg", "png", "ai", "pdf"];
        if (allowed.includes(fileExtention)) {
          attachmentList.push(file);
        } else {
          setNoticeMessage({
            isError: true,
            message: "กรุณาเลือกไฟล์ภาพ jpg, jpeg, png, ai, pdf",
          });
        }
      }
      setCustomerAttachments(attachmentList);
    }
  };

  const uploadFileToFirebase = (uploadImage) => {
    setNoticeMessage(null);
    return new Promise(function (resolve, reject) {
      if (uploadImage) {
        console.log("Uploading file");
        const storageRef = firebaseApp.storage().ref();
        let timeStamp = new Date().toISOString().slice(0, 10);
        let file = uploadImage;
        // auth.onAuthStateChanged((user) => {
        //   if (user && order.myID) {
        //     setFieldValue("waitProcess", true, false);
        //     setFieldValue("loading", true, false);
        //     storageRef
        //       .child(`${user.uid}/${timeStamp}-${file.name}`)
        //       .put(file)
        //       .then((snapshot) => {
        //         snapshot.ref.getDownloadURL().then((url) => {
        //           console.log("File uploaded", url);
        //           resolve({
        //             attachment_extension: getFileExt(uploadImage.name),
        //             attachment_name: uploadImage.name,
        //             attachment_url: url,
        //           });
        //         });
        //       })
        //       .catch((err) => {
        //         reject(err);
        //         console.log("err", err);
        //         setNoticeMessage({
        //           isError: true,
        //           message: "อัพโหลดไฟล์ล้มเหลว",
        //         });
        //       });
        //   }
        // });
        if (order.myID) {
          setFieldValue("waitProcess", true, false);
          setFieldValue("loading", true, false);
          storageRef
            .child(`${order.myID}/${timeStamp}-${file.name}`)
            .put(file)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                console.log("File uploaded", url);
                resolve({
                  attachment_extension: getFileExt(uploadImage.name),
                  attachment_name: uploadImage.name,
                  attachment_url: url,
                });
              });
            })
            .catch((err) => {
              reject(err);
              console.log("err", err);
              setNoticeMessage({
                isError: true,
                message: "อัพโหลดไฟล์ล้มเหลว",
              });
            });
        }
      } else {
        console.log("No file to upload");
        setNoticeMessage({
          isError: true,
          message: "กรุณาเลือกไฟล์เพื่ออัพโหลด",
        });
        reject("กรุณาเลือกไฟล์เพื่ออัพโหลด");
      }
    });
  };

  const adminSubmit = async () => {
    console.log("adminSubmit");
    setFieldValue("waitProcess", true, false);
    const uploadFile = await uploadFileToFirebase(imagePreviewFile);
    // Add to approval list
    let data = {
      itemIndex: itemIndex,
      attachment_url: uploadFile.attachment_url,
      attachment_extension: uploadFile.attachment_extension,
      attachment_name: uploadFile.attachment_name,
    };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/approvalList/admin/${order.myID}`,
        // `http://localhost:5001/digitalwish-sticker/asia-east2/approvalList/admin/${order.myID}`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        // window.location.reload();
        // setFieldValue("fetchMsg", true, false);
        setNoticeMessage({
          isError: false,
          message: "อัพโหลดไฟล์เรียบร้อย",
        });
        setFieldValue("fetchMsg", true, false);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("fetchMsg", true, false);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      });
  };

  const customerSubmit = async () => {
    console.log("adminSubmit");
    setFieldValue("waitProcess", true, false);
    const uploadFiles = [];
    for (let i = 0; i < customerAttachments.length; i++) {
      uploadFiles.push(uploadFileToFirebase(customerAttachments[i]));
    }
    const customerAttachmentsUrl = await Promise.all(uploadFiles);

    // Update to approval list
    let data = {
      itemIndex: itemIndex,
      approvalNumber: activeApproval.approvalNumber,
      annotaions: annotations,
      comment: customerComment,
      attachments: customerAttachmentsUrl,
    };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/approvalList/customer/${order.myID}`,
        // `http://localhost:5001/digitalwish-sticker/asia-east2/approvalList/customer/${order.myID}`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        // window.location.reload();
        // setFieldValue("fetchMsg", true, false);
        setNoticeMessage({
          isError: false,
          message: "ส่งข้อมูลเรียบร้อย",
        });
        setFieldValue("fetchMsg", true, false);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("fetchMsg", true, false);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      });
  };

  const customerApprove = () => {
    console.log("Customer Approve");
    setFieldValue("waitProcess", true, false);
    setFieldValue("loading", true, false);
    let data = {
      itemIndex: itemIndex,
      approvalNumber: activeApproval.approvalNumber,
    };
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/approvalList/customerApprove/${order.myID}`,
        // `http://localhost:5001/digitalwish-sticker/asia-east2/approvalList/customerApprove/${order.myID}`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        // window.location.reload();
        // setFieldValue("fetchMsg", true, false);
        setNoticeMessage({
          isError: false,
          message: "ส่งข้อมูลเรียบร้อย",
        });
        setFieldValue("fetchMsg", true, false);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("fetchMsg", true, false);
        setFieldValue("waitProcess", false, false);
        setFieldValue("loading", false, false);
      });
  };

  return (
    <main>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>

      <div
        className={`${styles.commentSection} ${darkmode && styles.darkmode}`}
      >
        <div className={styles.themeSwitcher}>
          <label>
            <span style={{ marginRight: "5px" }}>Background Light</span>
            <Switch
              checked={darkmode}
              onChange={() => setDarkmode(!darkmode)}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
              className={styles.reactSwitch}
              id="material-switch"
            />
            <span style={{ marginLeft: "5px" }}>Dark</span>
          </label>
        </div>

        <div className={styles.noticeMessage}>
          {selectedItem &&
            (selectedItem.approvalStatus === "pendingOnAdmin" ||
              selectedItem.approvalStatus === "rejected") && (
              <h2>
                แบบของคุณจะพร้อมและเสร็จสมบูรณ์
                <br />
                ภายใน 24 ชั่วโมง
                <br />
                ทางเราจะแจ้งเตือนกลับไปที่ Email และเบอร์โทรศัพท์ของคุณ
              </h2>
            )}
        </div>

        {isAdmin && (
          <div className={styles.adminPanel}>
            <h2>สำหรับ Admin</h2>

            {activeApproval && (
              <div>
                <h3>ข้อมูลการแก้ไขล่าสุดจากลูกค้า</h3>
                <div className={styles.annotationCanvasAdmin}>
                  {activeApproval && activeApproval.approvalImgUrl ? (
                    <Annotation
                      src={activeApproval.approvalImgUrl}
                      annotations={activeApproval.annotaions || []}
                      disableAnnotation={true}
                      disableOverlay={true}
                      disableEditor={true}
                      disableSelector={true}
                      renderEditor={(props) => {
                        return <MyEditor {...props} />;
                      }}
                      renderOverlay={(props) => {
                        return <MyOverlay {...props} />;
                      }}
                      renderHighlight={(props) => {
                        // console.log("props", props);
                        return <HighlightContainerDisplay {...props} />;
                      }}
                      renderContent={(props) => {
                        return <ContentContainer {...props} />;
                      }}
                    />
                  ) : (
                    <div>ไม่พบไฟล์อนุมัติแบบ</div>
                  )}
                </div>

                {selectedItem.approvalStatus === "rejected" && (
                  <div>
                    <div className={styles.commentBox}>
                      <b>ไฟล์แนบ:</b>
                      <div>
                        <div>
                          <ul>
                            {activeApproval &&
                              (activeApproval.attachments || []).map(
                                (item, index) => {
                                  return (
                                    <li>
                                      <a
                                        href={item.attachment_url}
                                        target="_blank"
                                      >
                                        {index + 1}.{" "}
                                        <u>{item.attachment_name}</u>
                                      </a>
                                    </li>
                                  );
                                }
                              )}
                          </ul>
                          {activeApproval &&
                            activeApproval.attachments &&
                            activeApproval.attachments.length <= 0 &&
                            "-"}
                        </div>
                      </div>

                      <b>คำบรรยายในการแก้ไข:</b>
                      <div>
                        <div>
                          {activeApproval && activeApproval.comment
                            ? activeApproval.comment
                            : "-"}
                        </div>
                      </div>

                      <b>การแก้ไข:</b>
                      <div className="annotationsList">
                        <ul>
                          {activeApproval &&
                            activeApproval.annotaions.map((item) => {
                              return (
                                <li>
                                  {item.data.marker}. {item.data.text}
                                </li>
                              );
                            })}
                        </ul>
                        {activeApproval &&
                          activeApproval.annotaions &&
                          activeApproval.annotaions.length <= 0 &&
                          "-"}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div style={{ marginTop: "40px" }}>
              {selectedItem.approvalStatus === "approved" ? (
                <h2 style={{ marginTop: "20px", color: "#009473" }}>
                  อนุมัติแบบเรียบร้อยแล้ว
                </h2>
              ) : (
                <>
                  <h3>อัพโหลดไฟล์สำหรับส่งให้ลูกค้า</h3>

                  {imagePreview && (
                    <div className={styles.annotationCanvasAdmin}>
                      <Annotation
                        src={imagePreview}
                        annotations={[]}
                        disableAnnotation={true}
                        disableOverlay={true}
                        disableEditor={true}
                        disableSelector={true}
                        renderEditor={(props) => {
                          return <MyEditor {...props} />;
                        }}
                        renderOverlay={(props) => {
                          return <MyOverlay {...props} />;
                        }}
                        renderHighlight={(props) => {
                          // console.log("props", props);
                          return <HighlightContainerDisplay {...props} />;
                        }}
                        renderContent={(props) => {
                          return <ContentContainer {...props} />;
                        }}
                      />
                    </div>
                  )}

                  <div>
                    <input
                      type="file"
                      onChange={onImageAdminChange}
                      style={{
                        display: "block",
                        width: "200px",
                        margin: "0 auto",
                      }}
                    />
                    {noticeMessage && (
                      <div className={styles.message}>
                        {noticeMessage && noticeMessage.isError ? (
                          <span className={styles.error}>
                            {noticeMessage.message}
                          </span>
                        ) : (
                          <span>{noticeMessage.message}</span>
                        )}
                      </div>
                    )}
                    <button
                      type="button"
                      onClick={adminSubmit}
                      disabled={values.waitProcess ? true : false}
                      className={styles.normalBtn}
                      style={{ marginTop: "10px" }}
                    >
                      ส่งแจ้งเตือนลูกค้า
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {activeApproval &&
          !isAdmin &&
          selectedItem.approvalStatus === "approved" && (
            <>
              <h2>สำหรับลูกค้า</h2>
              <div className={styles.annotationCanvasAdmin}>
                {activeApproval && activeApproval.approvalImgUrl ? (
                  <>
                    <Annotation
                      src={activeApproval.approvalImgUrl}
                      annotations={activeApproval.annotaions || []}
                      disableAnnotation={true}
                      disableOverlay={true}
                      disableEditor={true}
                      disableSelector={true}
                      renderEditor={(props) => {
                        return <MyEditor {...props} />;
                      }}
                      renderOverlay={(props) => {
                        return <MyOverlay {...props} />;
                      }}
                      renderHighlight={(props) => {
                        // console.log("props", props);
                        return <HighlightContainerDisplay {...props} />;
                      }}
                      renderContent={(props) => {
                        return <ContentContainer {...props} />;
                      }}
                    />
                    <h2 style={{ marginTop: "20px", color: "#009473" }}>
                      อนุมัติแบบเรียบร้อยแล้ว
                    </h2>
                  </>
                ) : (
                  <div>ไม่พบไฟล์อนุมัติแบบ</div>
                )}
              </div>
            </>
          )}

        {activeApproval &&
          !isAdmin &&
          selectedItem.approvalStatus === "pendingOnCustomer" && (
            <div className={styles.customerPanel}>
              <h2>สำหรับลูกค้า</h2>

              <div>
                <a
                  className={styles.blue}
                  style={{ fontSize: "18px" }}
                  href={activeApproval.approvalImgUrl}
                  target="_tab"
                  download
                >
                  ดาวน์โหลดไฟล์
                </a>
              </div>
              <div className={styles.annotationCanvas}>
                <Annotation
                  src={activeApproval.approvalImgUrl}
                  annotations={annotations}
                  type={PointSelector.TYPE}
                  value={annotation}
                  onChange={onAnnotationChange}
                  onSubmit={onAnnotationSubmit}
                  renderEditor={(props) => {
                    return <MyEditor {...props} />;
                  }}
                  renderOverlay={(props) => {
                    return <MyOverlay {...props} />;
                  }}
                  renderHighlight={(props) => {
                    // console.log("props", props);
                    return <HighlightContainer {...props} />;
                  }}
                  renderContent={(props) => {
                    return <ContentContainer {...props} />;
                  }}
                  disableAnnotation={!allowAnnotate}
                  disableOverlay={!allowAnnotate}
                  disableEditor={!allowAnnotate}
                  disableSelector={!allowAnnotate}
                />
              </div>
              {!allowAnnotate ? (
                <>
                  {!confirm ? (
                    <>
                      <button
                        type="button"
                        // onClick={customerApprove}
                        onClick={() => setConfirm(true)}
                        disabled={values.waitProcess ? true : false}
                        className={styles.bigBtn3}
                      >
                        อนุมัติแบบ
                      </button>
                      <div>
                        หรือ{" "}
                        <label
                          className={styles.blue}
                          onClick={() => setAllowAnnotate(!allowAnnotate)}
                        >
                          ขอเปลี่ยนแปลงแบบ
                        </label>
                      </div>
                    </>
                  ) : (
                    <div>
                      <h3>กรุณาตรวจสอบอย่างละเอียด</h3>
                      <div>
                        ไม่สามารถทำการเปลี่ยนแปลงได้หลังจากได้รับอนุมัติคำสั่งซื้อและไม่สามารถยกเลิกออเดอร์ได้
                      </div>
                      <button
                        type="button"
                        onClick={customerApprove}
                        disabled={values.waitProcess ? true : false}
                        className={styles.bigBtn2}
                      >
                        เริ่มสั่งผลิตทันที
                      </button>
                      <div>
                        หรือ{" "}
                        <label
                          className={styles.blue}
                          onClick={() => {
                            setAllowAnnotate(!allowAnnotate);
                            setConfirm(false);
                          }}
                        >
                          ขอเปลี่ยนแปลงแบบ
                        </label>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div>
                    <div className={styles.commentBox}>
                      <b>อัพโหลดไฟล์แนบ:</b>
                      <input
                        type="file"
                        style={{
                          display: "block",
                          width: "200px",
                          margin: "0 auto",
                        }}
                        onChange={onImageCustomerChange}
                        multiple
                      />
                      <div>
                        <div>
                          <ul>
                            {customerAttachments &&
                            customerAttachments.length > 0 ? (
                              customerAttachments.map((item, index) => {
                                return (
                                  <li>
                                    {index + 1}.{item.name}
                                  </li>
                                );
                              })
                            ) : (
                              <div>-</div>
                            )}
                          </ul>
                        </div>
                      </div>

                      <b>คำบรรยายในการแก้ไข:</b>
                      <div>
                        <Field
                          name="comments"
                          render={({ field, form: { touched, errors } }) => (
                            <div>
                              <textarea
                                {...field}
                                className={styles.textarea}
                                type="text"
                                placeholder="คำบรรยายในการแก้ไข"
                                onChange={(e) =>
                                  setCustomerComment(e.target.value)
                                }
                              />
                            </div>
                          )}
                        />
                      </div>

                      <b>การแก้ไข:</b>
                      <div className="annotationsList">
                        <ul>
                          {annotations && annotations.length > 0 ? (
                            annotations.map((item) => {
                              return (
                                <li>
                                  {item.data.marker}. {item.data.text}
                                </li>
                              );
                            })
                          ) : (
                            <div>-</div>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div>
                    {noticeMessage && (
                      <div className={styles.message}>
                        {noticeMessage && noticeMessage.isError ? (
                          <span className={styles.error}>
                            {noticeMessage.message}
                          </span>
                        ) : (
                          <span>{noticeMessage.message}</span>
                        )}
                      </div>
                    )}
                    <button
                      type="button"
                      onClick={customerSubmit}
                      disabled={values.waitProcess ? true : false}
                      className={styles.normalBtn}
                    >
                      ส่งการแก้ไข
                    </button>
                  </div>
                  <div>
                    หรือ{" "}
                    <label
                      className={styles.red}
                      onClick={() => setAllowAnnotate(!allowAnnotate)}
                    >
                      ยกเลิก
                    </label>
                  </div>
                </>
              )}
            </div>
          )}
        {/* <div>
          <Form>
            <div>
              <p>
                เบอร์โทรศัพท์:
                <ErrorMessage
                  name="notiPhone"
                  render={(msg) => <span className="error">{msg}</span>}
                />
              </p>
              <Field
                name="notiPhone"
                type="text"
                disabled={values.waitProcess ? true : false}
              />
            </div>
            <div>
              <p>
                อีเมล:
                <ErrorMessage
                  name="notiPhone"
                  render={(msg) => <span className="error">{msg}</span>}
                />
              </p>
              <Field
                name="notiPhone"
                type="text"
                disabled={values.waitProcess ? true : false}
              />
            </div>
          </Form>
        </div> */}
      </div>
    </main>
  );
};

const EnhancedAnnotationComponent = withFormik({
  mapPropsToValues: (props) => ({}),
  validate: (values) => {
    const errors = {};
    return errors;
  },
  handleSubmit: (values, { setFieldValue, props }) => {},
})(AnnotationComponent);

export default EnhancedAnnotationComponent;
