import React from "react";
import styles from "./index.module.scss";
import { useFormikContext } from "formik";
import Placeholder from "./gallery.png";
import PlaceholderAI from "./ai.png";
import PlaceholderPDF from "./pdf.png";

import { ReactComponent as Circle } from "../approve-layout/circle.svg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// const CardOrderComponentOld = (props) => {
//   const { values, setFieldValue } = useFormikContext();
//   console.log("Q", values.status);

//   return (
//     <Carousel responsive={responsive}>
//       {values.hasOwnProperty("itemsList") &&
//         values.itemsList.map((listCard, index) => {
//           function padLeadingZeros(num, size) {
//             var s = num + "";
//             while (s.length < size) s = "0" + s;
//             return s;
//           }
//           let indexPlus = index + 1;
//           let itemNumber = "";
//           if (listCard.itemID) itemNumber = listCard.itemID;
//           else itemNumber = `SW${padLeadingZeros(indexPlus, 7)}`;
//           let extension = listCard.messages[0].info
//             .toLowerCase()
//             .split(".")
//             .pop();
//           // console.log("listCard", listCard)
//           return (
//             <div
//               className={`${styles.card} ${
//                 `${values.expandCard}` === `${index}` && styles.active
//               }`}
//               onClick={() => setFieldValue("expandCard", index, false)}
//             >
//               <h4>
//                 หมายเลขรายการ{" "}
//                 {/* {values.orderID.replace("DW", "IT") + "/" + indexPlus} */}
//                 {itemNumber}
//               </h4>
//               <div className={styles.description}>
//                 {/* <img src={listCard.messages[0].content} /> */}
//                 {/* <img src={Placeholder} /> */}

//                 {extension === "ai" && (
//                   <img
//                     // src={dataObjectMapped.messages[0].content}
//                     src={PlaceholderAI}
//                     className={styles.productPreview}
//                     alt="Product"
//                   />
//                 )}
//                 {extension === "pdf" && (
//                   <img
//                     // src={dataObjectMapped.messages[0].content}
//                     src={PlaceholderPDF}
//                     className={styles.productPreview}
//                     alt="Product"
//                   />
//                 )}
//                 {["png", "jpeg", "jpg", "gif"].includes(extension) && (
//                   <img
//                     // src={dataObjectMapped.messages[0].content}
//                     src={Placeholder}
//                     className={styles.productPreview}
//                     alt="Product"
//                   />
//                 )}
//                 {!["ai", "pdf", "png", "jpeg", "jpg", "gif"].includes(
//                   extension
//                 ) && (
//                   <img
//                     // src={dataObjectMapped.messages[0].content}
//                     src={Placeholder}
//                     className={styles.productPreview}
//                     alt="Product"
//                   />
//                 )}

//                 {/* <Circle /> */}
//                 <h4>รูปแบบ{listCard.shape}</h4>
//                 <p>
//                   {listCard.material} - {listCard.coat} - ขนาด {listCard.width}x
//                   {listCard.height} mm
//                 </p>
//                 <h4 className={styles.quality}>{listCard.units} ชิ้น</h4>
//                 <h4 className={styles.price}>
//                   {(listCard.price * 1.07).toFixed(2)}฿
//                 </h4>
//               </div>
//               <LabelSatus
//                 status={
//                   values.status === "รายการสำเร็จ"
//                     ? values.status
//                     : listCard.status
//                 }
//                 paymentStatus={values.paymentStatus}
//                 paymentMethod={values.paymentMethod}
//               />
//             </div>
//           );
//         })}
//     </Carousel>
//   );
// };

const CardOrderComponent = (props) => {
  const { values, setFieldValue } = useFormikContext();
  console.log("Q", values.status);

  return (
    <Carousel responsive={responsive}>
      {values.hasOwnProperty("itemsList") &&
        values.itemsList.map((listCard, index) => {
          function padLeadingZeros(num, size) {
            var s = num + "";
            while (s.length < size) s = "0" + s;
            return s;
          }
          let indexPlus = index + 1;
          let itemNumber = "";
          if (listCard.itemID) itemNumber = listCard.itemID;
          else itemNumber = `SW${padLeadingZeros(indexPlus, 7)}`;
          let extension = listCard.messages[0].info
            .toLowerCase()
            .split(".")
            .pop();
          // console.log("listCard", listCard)
          return (
            <div
              className={`${styles.card} ${
                `${values.expandCard}` === `${index}` && styles.active
              }`}
              onClick={() => setFieldValue("expandCard", index, false)}
            >
              <h4 style={{marginBottom: "10px", fontWeight: "bold"}}>
                หมายเลขรายการ{" "}
                {/* {values.orderID.replace("DW", "IT") + "/" + indexPlus} */}
                {itemNumber}
              </h4>
              <div className={styles.flexContainer} style={{marginBottom: "10px"}}>
                <div>
                {extension === "ai" && (
                  <img
                    // src={dataObjectMapped.messages[0].content}
                    src={PlaceholderAI}
                    className={styles.productPreview}
                    alt="Product"
                  />
                )}
                {extension === "pdf" && (
                  <img
                    // src={dataObjectMapped.messages[0].content}
                    src={PlaceholderPDF}
                    className={styles.productPreview}
                    alt="Product"
                  />
                )}
                {["png", "jpeg", "jpg", "gif"].includes(extension) && (
                  <img
                    // src={dataObjectMapped.messages[0].content}
                    src={Placeholder}
                    className={styles.productPreview}
                    alt="Product"
                  />
                )}
                {!["ai", "pdf", "png", "jpeg", "jpg", "gif"].includes(
                  extension
                ) && (
                  <img
                    // src={dataObjectMapped.messages[0].content}
                    src={Placeholder}
                    className={styles.productPreview}
                    alt="Product"
                  />
                )}
                </div>
                <div style={{marginLeft: "10px"}}>
                  <h4>รูปแบบ{listCard.shape}</h4>
                  <p>
                    {listCard.material} - {listCard.coat} - ขนาด {listCard.width}x
                    {listCard.height} mm
                  </p>
                  <div className={styles.flexContainer} style={{marginTop: "10px"}}>
                    <div>
                      <h4 className={styles.quality}>{listCard.units} ชิ้น</h4>
                    </div>
                    <div style={{marginLeft: "20px"}}>
                      <h4 className={styles.price}>
                        {(listCard.price * 1.07).toFixed(2)}฿
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              
              <LabelSatus
                status={
                  values.status === "รายการสำเร็จ"
                    ? values.status
                    : listCard.status
                }
                paymentStatus={values.paymentStatus}
                paymentMethod={values.paymentMethod}
              />
            </div>
          );
        })}
    </Carousel>
  );
};

export default CardOrderComponent;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
  },
};

const LabelSatus = ({ status, paymentStatus, paymentMethod }) => {
  if (status === "รออนุมัติแบบ" && paymentMethod !== "transfer_money") {
    return (
      <label className={`${styles.labelStatus} ${styles.orangeStatus}`}>
        สถานะ: รออนุมัติแบบ
      </label>
    );
  } else if (status === "อนุมัติแบบ" && paymentMethod !== "transfer_money") {
    return (
      <label className={`${styles.labelStatus} ${styles.greenStatus}`}>
        สถานะ: อนุมัติแบบ
      </label>
    );
  } else if (
    paymentStatus === "pending" &&
    paymentMethod === "transfer_money"
  ) {
    return (
      <label className={`${styles.labelStatus} ${styles.blueStatus}`}>
        สถานะ: รอการยืนยันชำระเงิน
      </label>
    );
  } else if (status === "รออนุมัติแบบ" && paymentMethod === "transfer_money") {
    return (
      <label className={`${styles.labelStatus} ${styles.orangeStatus}`}>
        สถานะ: รออนุมัติแบบ
      </label>
    );
  } else if (status === "อนุมัติแบบ" && paymentMethod === "transfer_money") {
    return (
      <label className={`${styles.labelStatus} ${styles.greenStatus}`}>
        สถานะ: อนุมัติแบบ
      </label>
    );
  } else if (status === "รายการสำเร็จ" && paymentMethod === "transfer_money") {
    return (
      <label className={`${styles.labelStatus} ${styles.greenStatus}`}>
        สถานะ: รายการสำเร็จ
      </label>
    );
  } else if (status === "ยกเลิก") {
    return (
      <label className={`${styles.labelStatus} ${styles.redStatus}`}>
        สถานะ: ยกเลิก
      </label>
    );
  } else {
    return (
      <label className={`${styles.labelStatus} ${styles.yellowStatus}`}>
        สถานะ: {status}
      </label>
    );
  }
};
