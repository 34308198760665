import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";

import { axiosInst } from "../common-scss/common";

const AdminKpiComponent = (props) => {
  let history = useHistory();
  const [countCustomer, setCountCustomer] = useState("");
  const [countOrderAll, setCountOrderAll] = useState("");
  const [countOrderSuccess, setCountOrderSuccess] = useState("");
  const [countOrderNotSuccess, setCountOrderNotSuccess] = useState("");
  const [totalPriceAll, setTotalPriceAll] = useState(0);
  const [totalPriceSuccess, setTotalPriceSuccess] = useState(0);
  const [totalPriceNotSuccess, setTotalPriceNotSuccess] = useState(0);

  useEffect(() => {
    axiosInst
      .get("orders?batchSize=9999", {
        headers: {
          Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
        },
      })
      .then((res) => {
        console.log("res", res);
        let totalPriceAll = 0;
        let countOrderAll = res.data.length
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        res.data.map((data) => {
          totalPriceAll = totalPriceAll + data.totalCost;
        });
        totalPriceAll = parseInt(totalPriceAll)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        setTotalPriceAll(totalPriceAll);
        setCountOrderAll(countOrderAll);

        let totalPriceSuccess = 0;
        let OrderSuccess = res.data.filter(function (el) {
          return el.status == "รายการสำเร็จ";
        });
        let countOrderSuccess = OrderSuccess.length
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        setCountOrderSuccess(countOrderSuccess + "/" + countOrderAll);
        OrderSuccess.map((data) => {
          totalPriceSuccess = totalPriceSuccess + data.totalCost;
        });
        totalPriceSuccess = parseInt(totalPriceSuccess)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        setTotalPriceSuccess(totalPriceSuccess + "/" + totalPriceAll);

        let totalPriceNotSuccess = 0;
        let OrderNotSuccess = res.data.filter(function (el) {
          return el.status != "รายการสำเร็จ";
        });
        let countOrderNotSuccess = OrderNotSuccess.length
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        setCountOrderNotSuccess(countOrderNotSuccess + "/" + countOrderAll);
        OrderNotSuccess.map((data) => {
          totalPriceNotSuccess = totalPriceNotSuccess + data.totalCost;
        });
        totalPriceNotSuccess = parseInt(totalPriceNotSuccess)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        setTotalPriceNotSuccess(totalPriceNotSuccess + "/" + totalPriceAll);
      })
      .catch((reason) => {
        console.log(reason);
      });
    axiosInst
      .get("customers", {
        headers: {
          Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
        },
      })
      .then((res) => {
        setCountCustomer(res.data.length);
      })
      .catch((reason) => {
        console.log(reason);
      });
  }, []);

  return (
    <>
      <main className={styles.containerKPI}>
        <article className={styles.cardOval}>
          <p>คำสั่งซื้อ (เสร็จสิ้น)</p>
          <h3>{countOrderSuccess}</h3>
        </article>
        <article className={styles.cardOval}>
          <p>คำสั่งซื้อ (ยังไม่เสร็จสิ้น)</p>
          <h3>{countOrderNotSuccess}</h3>
        </article>
        <article className={styles.cardOval}>
          <p>ยอดขาย (เสร็จสิ้น)</p>
          <h3>{totalPriceSuccess}</h3>
        </article>
        <article className={styles.cardOval}>
          <p>ยอดขาย (ยังไม่เสร็จสิ้น)</p>
          <h3>{totalPriceNotSuccess}</h3>
        </article>
        <article className={styles.cardOval}>
          <p>สมาชิก</p>
          <h3>{countCustomer}</h3>
        </article>
      </main>
      <section>
        <button
          className={styles.buttonList}
          onClick={() => {
            history.push("/admin");
          }}
        >
          รายการคำสั่งซื้อ
        </button>
        <button
          className={styles.buttonList}
          onClick={() => {
            history.push("/admin/demo");
          }}
        >
          รายการขอตัวอย่าง
        </button>
        <button
          className={styles.buttonList}
          onClick={() => {
            history.push("/admin/customer");
          }}
        >
          รายการสมาชิก
        </button>
        {/* <button
          className={styles.buttonList}
          onClick={() => {
            history.push("/admin/product");
          }}
        >
          ตั้งค่าตัวเลือก
        </button> */}
        <button
          className={styles.buttonList}
          onClick={() => {
            history.push("/admin/product-new");
          }}
        >
          ตั้งค่าตัวเลือก
        </button>
        <button
          className={styles.buttonList}
          onClick={() => {
            history.push("/admin/review");
          }}
        >
          ตั้งค่ารีวิว
        </button>
        <button
          className={styles.buttonList}
          onClick={() => {
            history.push("/admin/reference");
          }}
        >
          ตั้งค่าผลงาน
        </button>
        <button
          className={styles.buttonList}
          onClick={() => {
            history.push("/admin/promotion");
          }}
        >
          โค้ดส่วนลด
        </button>
        <button
          className={styles.buttonList}
          onClick={() => {
            history.push("/admin/delivery");
          }}
        >
          ตั้งค่าการจัดส่งและเงื่อนไข
        </button>
      </section>
    </>
  );
};

export default AdminKpiComponent;
