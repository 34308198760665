import React from "react";
import { Field, ErrorMessage } from "formik";

import styles from "./index.module.scss";

const LocationFieldsComponent = (props) => {
  return (
    <>
      <div style={{ marginTop: "10px", color: "red" }}>
        *ใบกำกับภาษีจะส่งไปหลังจากที่ลูกค้าได้รับสินค้าแล้วประมาณ 7-10 วันทำการ
      </div>
      <div className={styles.gridContainer} style={{ marginTop: "20px" }}>
        {/*  GRID ITEM ISN"T EVEN USED? DONT EVEN HAVE SCSS WTF? */}

        <div className={styles.gridItem}>
          <div className={styles.formControl}>
            <p>
              ชื่อบุคคล / ชื่อบริษัท*{" "}
              <ErrorMessage
                name="billingFullname"
                render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
              />
            </p>
            <Field name="billingFullname" type="text" />
          </div>
        </div>

        <div className={styles.gridItem}>
          <div className={styles.formControl}>
            <p>
              ที่อยู่*{" "}
              <ErrorMessage
                name="billingFulladdress"
                render={(msg) => <span style={{ color: "red" }}>{msg}</span>}
              />
            </p>
            <Field name="billingFulladdress" type="text" />
          </div>
        </div>

        <div className={styles.gridItem}>
          <div className={styles.formControl}>
            <p>เลขประจำตัวผู้เสียภาษี</p>
            <Field name="billingTaxID" type="text" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationFieldsComponent;
