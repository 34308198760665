import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";
import firebaseApp from "../../firebase/index.js";

const ModalMaterial = ({ values, setFieldValue }) => {
  const [displayImage, setDisplayImage] = useState("");
  const [displayImageGallery, setDisplayImageGallery] = useState("");

  useEffect(() => {
    if (values.selectedMaterial.myID || values.selectedMaterial.myID === 0) {
      setFieldValue(
        `${values.modalMaterial}Name`,
        values.selectedMaterial.name,
        false
      );
      // setFieldValue(
      //   `${values.modalMaterial}File`,
      //   values.selectedMaterial.imgUrl,
      //   false
      // );
      // setFieldValue(
      //   `${values.modalMaterial}FileGallery`,
      //   values.selectedMaterial.imgGalleryUrl,
      //   false
      // );
      setFieldValue(`${values.modalMaterial}File`, "", false);
      setFieldValue(`${values.modalMaterial}FileGallery`, "", false);
      setFieldValue(
        `${values.modalMaterial}Description`,
        values.selectedMaterial.description,
        false
      );
      setFieldValue(
        `${values.modalMaterial}ExtraDeliveryDay`,
        values.selectedMaterial.extraDeliveryDay || "",
        false
      );
      setDisplayImage(values.selectedMaterial.imgUrl);
      setDisplayImageGallery(values.selectedMaterial.imgGalleryUrl);
    } else {
      setFieldValue(`${values.modalMaterial}Name`, "", false);
      setFieldValue(`${values.modalMaterial}Description`, "", false);
      setFieldValue(`${values.modalMaterial}ExtraDeliveryDay`, "", false);
      setFieldValue(`${values.modalMaterial}File`, "", false);
      setFieldValue(`${values.modalMaterial}FileGallery`, "", false);
      setDisplayImage("");
      setDisplayImageGallery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.modalMaterial, values.selectedShape, values.selectedMaterial]);

  const addOptionMaterial = async () => {
    setFieldValue("loading", true, false);
    let name = values[`${values.modalMaterial}Name`];
    let description = values[`${values.modalMaterial}Description`];
    let extraDeliveryDay = values[`${values.modalMaterial}ExtraDeliveryDay`];

    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    // if (values[`${values.modalMaterial}File`]) {
    //   storageRef
    //     .child(
    //       `productOptions/${timeStamp}-${
    //         values[`${values.modalMaterial}File`].name
    //       }`
    //     )
    //     .put(values[`${values.modalMaterial}File`])
    //     .then((snapshot) => {
    //       snapshot.ref.getDownloadURL().then((url) => {
    //         let dataPost = {
    //           imgUrl: url,
    //           name: name,
    //           description: description,
    //         };
    //         axios
    //           .post(
    //             // `http://127.0.0.1:5001/digitalwish-sticker/asia-east2/productOptionsNew/${values.selectedShape.myID}`,
    //             `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}`,
    //             dataPost,
    //             {
    //               headers: {
    //                 Authorization:
    //                   "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
    //               },
    //             }
    //           )
    //           .then((res) => {
    //             setFieldValue("fetch", true, false);
    //             setFieldValue("modalMaterial", "", false);
    //             setFieldValue("loading", false, false);
    //           })
    //           .catch(function (err) {
    //             setFieldValue("loading", false, false);
    //             console.log("err", err);
    //           });
    //       });
    //     });
    // } else {
    //   let dataPost = {
    //     name: name,
    //     description: description,
    //   };
    //   axios
    //     .post(
    //       // `http://127.0.0.1:5001/digitalwish-sticker/asia-east2/productOptionsNew/${values.selectedShape.myID}`,
    //       `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}`,
    //       dataPost,
    //       {
    //         headers: {
    //           Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       setFieldValue("fetch", true, false);
    //       setFieldValue("loading", false, false);
    //       setFieldValue("modalMaterial", "", false);
    //     })
    //     .catch(function (err) {
    //       setFieldValue("loading", false, false);
    //       console.log("err", err);
    //     });
    // }

    if (
      values[`${values.modalMaterial}File`] &&
      values[`${values.modalMaterial}File`] instanceof Blob &&
      values[`${values.modalMaterial}FileGallery`] &&
      values[`${values.modalMaterial}FileGallery`] instanceof Blob
    ) {
      // console.log("A");
      const snapshot = await storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalMaterial}File`].name
          }`
        )
        .put(values[`${values.modalMaterial}File`]);

      const url = await snapshot.ref.getDownloadURL();

      const snapshot2 = await storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalMaterial}FileGallery`].name
          }`
        )
        .put(values[`${values.modalMaterial}FileGallery`]);

      const urlGallery = await snapshot2.ref.getDownloadURL();
      // console.log("Normal", url);
      // console.log("Gallery", urlGallery);

      let dataPost = {
        imgUrl: url,
        imgGalleryUrl: urlGallery,
        name: name,
        description: description,
        extraDeliveryDay: extraDeliveryDay,
      };
      axios
        .post(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("modalMaterial", "", false);
          setFieldValue("loading", false, false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    } else if (
      values[`${values.modalMaterial}File`] &&
      values[`${values.modalMaterial}File`] instanceof Blob
    ) {
      // console.log("B");

      const snapshot = await storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalMaterial}File`].name
          }`
        )
        .put(values[`${values.modalMaterial}File`]);

      const url = await snapshot.ref.getDownloadURL();
      // console.log("Normal", url);

      let dataPost = {
        imgUrl: url,
        name: name,
        description: description,
        extraDeliveryDay: extraDeliveryDay,
      };
      axios
        .post(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("modalMaterial", "", false);
          setFieldValue("loading", false, false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    } else if (
      values[`${values.modalMaterial}FileGallery`] &&
      values[`${values.modalMaterial}FileGallery`] instanceof Blob
    ) {
      // console.log("C");

      const snapshot2 = await storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalMaterial}FileGallery`].name
          }`
        )
        .put(values[`${values.modalMaterial}FileGallery`]);

      const urlGallery = await snapshot2.ref.getDownloadURL();
      // console.log("Gallery", urlGallery);
      let dataPost = {
        imgGalleryUrl: urlGallery,
        name: name,
        description: description,
        extraDeliveryDay: extraDeliveryDay,
      };
      axios
        .post(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("modalMaterial", "", false);
          setFieldValue("loading", false, false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    } else {
      // console.log("D");
      let dataPost = {
        name: name,
        description: description,
        extraDeliveryDay: extraDeliveryDay,
      };
      axios
        .post(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("modalMaterial", "", false);
          setFieldValue("loading", false, false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    }
  };

  const editOptionMaterial = async () => {
    setFieldValue("loading", true, false);
    let name = values[`${values.modalMaterial}Name`];
    let description = values[`${values.modalMaterial}Description`];
    let extraDeliveryDay = values[`${values.modalMaterial}ExtraDeliveryDay`];

    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    if (
      values[`${values.modalMaterial}File`] &&
      values[`${values.modalMaterial}File`] instanceof Blob &&
      values[`${values.modalMaterial}FileGallery`] &&
      values[`${values.modalMaterial}FileGallery`] instanceof Blob
    ) {
      // console.log("A");
      const snapshot = await storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalMaterial}File`].name
          }`
        )
        .put(values[`${values.modalMaterial}File`]);

      const url = await snapshot.ref.getDownloadURL();

      const snapshot2 = await storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalMaterial}FileGallery`].name
          }`
        )
        .put(values[`${values.modalMaterial}FileGallery`]);

      const urlGallery = await snapshot2.ref.getDownloadURL();
      // console.log("Normal", url);
      // console.log("Gallery", urlGallery);

      let dataPost = {
        imgUrl: url,
        imgGalleryUrl: urlGallery,
        name: name,
        description: description,
        extraDeliveryDay: extraDeliveryDay,
      };
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("modalMaterial", "", false);
          setFieldValue("loading", false, false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    } else if (
      values[`${values.modalMaterial}File`] &&
      values[`${values.modalMaterial}File`] instanceof Blob
    ) {
      // console.log("B");

      const snapshot = await storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalMaterial}File`].name
          }`
        )
        .put(values[`${values.modalMaterial}File`]);

      const url = await snapshot.ref.getDownloadURL();
      // console.log("Normal", url);

      let dataPost = {
        imgUrl: url,
        name: name,
        description: description,
        extraDeliveryDay: extraDeliveryDay,
      };
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("modalMaterial", "", false);
          setFieldValue("loading", false, false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    } else if (
      values[`${values.modalMaterial}FileGallery`] &&
      values[`${values.modalMaterial}FileGallery`] instanceof Blob
    ) {
      // console.log("C");

      const snapshot2 = await storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalMaterial}FileGallery`].name
          }`
        )
        .put(values[`${values.modalMaterial}FileGallery`]);

      const urlGallery = await snapshot2.ref.getDownloadURL();
      // console.log("Gallery", urlGallery);
      let dataPost = {
        imgGalleryUrl: urlGallery,
        name: name,
        description: description,
        extraDeliveryDay: extraDeliveryDay,
      };
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("modalMaterial", "", false);
          setFieldValue("loading", false, false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    } else {
      // console.log("D");
      let dataPost = {
        name: name,
        description: description,
        extraDeliveryDay: extraDeliveryDay,
      };
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("modalMaterial", "", false);
          setFieldValue("loading", false, false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    }
  };

  const removeOptionMaterial = () => {
    setFieldValue("loading", true, false);
    axios
      .delete(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        setFieldValue("fetch", true, false);
        setFieldValue("modalMaterial", "", false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  };

  const handleChangeImgMaterial = (event) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setDisplayImage(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setFieldValue(
        `${values.modalMaterial}File`,
        event.target.files[0],
        false
      );
    }
  };

  const handleChangeImgMaterialGallery = (event) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setDisplayImageGallery(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);

      setDisplayImageGallery("");
      setFieldValue(
        `${values.modalMaterial}FileGallery`,
        event.target.files[0],
        false
      );
    }
  };

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalMaterial ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalMaterial", "", false);
              // setFieldValue("selectedMaterial", "", false);
            }}
          >
            &times;
          </span>
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalMaterial}Name`}
            className={styles.text}
            placeholder="รูปแบบ"
          />
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalMaterial}Description`}
            className={styles.text}
            placeholder="รายละเอียด"
          />
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalMaterial}ExtraDeliveryDay`}
            className={styles.text}
            placeholder="วันจัดส่งเพิ่มเติม"
          />
        </div>
        <div className={styles.rowInModal}>
          <input
            type="file"
            id="fileMaterial"
            onChange={(e) => handleChangeImgMaterial(e)}
          />
          <label
            for="fileMaterial"
            className={`${styles.buttonUploadFile} ${styles.label}`}
          >
            อัพโหลดไฟล์
          </label>
        </div>
        <div className={styles.displayImage}>
          {displayImage && <img src={displayImage} />}
        </div>
        <div className={styles.rowInModal}>
          <input
            type="file"
            id="fileMaterialGallery"
            onChange={(e) => handleChangeImgMaterialGallery(e)}
          />
          <label
            for="fileMaterialGallery"
            className={`${styles.buttonUploadFile} ${styles.label}`}
          >
            อัพโหลดไฟล์ รูปตัวอย่าง
          </label>
        </div>
        <div className={styles.displayImage}>
          {displayImageGallery && <img src={displayImageGallery} />}
        </div>
        <div className={`${styles.floatRight} ${styles.rowInModal}`}>
          {values.selectedMaterial.myID ||
          values.selectedMaterial.myID === 0 ? (
            <button
              type="button"
              className={styles.removeOption}
              onClick={() => removeOptionMaterial()}
            >
              ลบ
            </button>
          ) : null}
          <button
            type="button"
            className={styles.addOption}
            onClick={() =>
              values.selectedMaterial.myID || values.selectedMaterial.myID === 0
                ? editOptionMaterial()
                : addOptionMaterial()
            }
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalMaterial;
