import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";
import firebaseApp from "../../firebase/index.js";

const ModalShipping = ({ values, setFieldValue }) => {
  const [displayImage, setDisplayImage] = useState("");
  useEffect(() => {
    let shippingOptioned = values.shippingOption.find(
      (shippingOption) => `${shippingOption.myID}` === `${values.shippingID}`
    );
    if (shippingOptioned && values.shippingID) {
      setFieldValue(
        `${values.modalShipping}Courier`,
        shippingOptioned.courier,
        false
      );
      setFieldValue(
        `${values.modalShipping}Duration`,
        shippingOptioned.duration,
        false
      );
      setFieldValue(
        `${values.modalShipping}Rate`,
        shippingOptioned.rate,
        false
      );
      setFieldValue(
        `${values.modalShipping}File`,
        shippingOptioned.imgUrl,
        false
      );
      setFieldValue(
        `${values.modalShipping}Url`,
        shippingOptioned.shipUrl,
        false
      );
      setDisplayImage(shippingOptioned.imgUrl);
    } else {
      setFieldValue(`${values.modalShipping}Courier`, "", false);
      setFieldValue(`${values.modalShipping}Duration`, "", false);
      setFieldValue(`${values.modalShipping}Rate`, "", false);
      setFieldValue(`${values.modalShipping}File`, "", false);
      setFieldValue(`${values.modalShipping}Url`, "", false);
      setDisplayImage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.shippingID]);

  const addOptionShipping = () => {
    setDisplayImage("");
    setFieldValue("loading", true, false);
    let data = {
      courier: values[`${values.modalShipping}Courier`],
      duration: values[`${values.modalShipping}Duration`],
      rate: values[`${values.modalShipping}Rate`],
      imgUrl: values[`${values.modalShipping}File`],
      shipUrl: values[`${values.modalShipping}Url`],
    };
    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    if (values[`${values.modalShipping}File`]) {
      storageRef
        .child(
          `shippingOptions/${timeStamp}-${
            values[`${values.modalShipping}File`].name
          }`
        )
        .put(values[`${values.modalShipping}File`])
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            console.log(url);
            data.imgUrl = url;
            axios
              .post(
                `https://asia-east2-digitalwish-sticker.cloudfunctions.net/shippingOptions`,
                data,
                {
                  headers: {
                    Authorization:
                      "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                  },
                }
              )
              .then((res) => {
                setFieldValue("fetch", true, false);
                setFieldValue("modalShipping", "", false);
                setFieldValue("loading", false, false);

                setFieldValue(`${values.modalShipping}Courier`, "", false);
                setFieldValue(`${values.modalShipping}Duration`, "", false);
                setFieldValue(`${values.modalShipping}Rate`, "", false);
                setFieldValue(`${values.modalShipping}File`, "", false);
                setFieldValue(`${values.modalShipping}Url`, "", false);
                setDisplayImage("");
              })
              .catch(function (err) {
                console.log("err", err);
                setFieldValue("loading", false, false);
              });
          });
        });
    } else {
      axios
        .post(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/shippingOptions`,
          data,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("modalShipping", "", false);
          setFieldValue("loading", false, false);

          setFieldValue(`${values.modalShipping}Courier`, "", false);
          setFieldValue(`${values.modalShipping}Duration`, "", false);
          setFieldValue(`${values.modalShipping}Rate`, "", false);
          setFieldValue(`${values.modalShipping}File`, "", false);
          setFieldValue(`${values.modalShipping}Url`, "", false);
          setDisplayImage("");
        })
        .catch(function (err) {
          console.log("err", err);
          setFieldValue("loading", false, false);
        });
    }
  };

  const editOptionShipping = () => {
    setDisplayImage("");
    setFieldValue("loading", true, false);
    let data = {
      courier: values[`${values.modalShipping}Courier`],
      duration: values[`${values.modalShipping}Duration`],
      rate: values[`${values.modalShipping}Rate`],
      imgUrl: values[`${values.modalShipping}File`],
      shipUrl: values[`${values.modalShipping}Url`],
    };
    console.log(data);

    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    console.log("VALUE", values);
    // console.log("TEST", parseInt(values.modalShippingEditDuration));
    // console.log("TYPE", values.modalShippingEditFile.type);

    if (!parseInt(values.modalShippingEditDuration)) {
      data.duration = 0;
      setFieldValue("modalShippingEditDuration", 0, false);
    }

    if (!parseInt(values.modalShippingEditRate)) {
      data.rate = 0;
      setFieldValue("modalShippingEditRate", 0, false);
    }

    if (values[`${values.modalShipping}File`]) {
      // if (values[`${values.modalShipping}File`]) {
      // console.log("A");
      storageRef
        .child(
          `shippingOptions/${timeStamp}-${
            values[`${values.modalShipping}File`].name
          }`
        )
        .put(values[`${values.modalShipping}File`])
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            console.log(url);
            data.imgUrl = url;
            setFieldValue("modalShippingEditFile", url, false);
            setDisplayImage(url);
            axios
              .put(
                `https://asia-east2-digitalwish-sticker.cloudfunctions.net/shippingOptions/${values.shippingID}`,
                data,
                {
                  headers: {
                    Authorization:
                      "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                  },
                }
              )
              .then((res) => {
                setFieldValue("fetch", true, false);
                setFieldValue("loading", false, false);
                setFieldValue("modalShipping", "", false);

                setFieldValue(`${values.modalShipping}Courier`, "", false);
                setFieldValue(`${values.modalShipping}Duration`, "", false);
                setFieldValue(`${values.modalShipping}Rate`, "", false);
                setFieldValue(`${values.modalShipping}File`, "", false);
                setFieldValue(`${values.modalShipping}Url`, "", false);
                setDisplayImage("");
              })
              .catch(function (err) {
                console.log("err", err);
                setFieldValue("loading", false, false);
              });
          });
        });
    } else {
      console.log("B");
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/shippingOptions/${values.shippingID}`,
          data,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("loading", false, false);
          setFieldValue("modalShipping", "", false);

          setFieldValue(`${values.modalShipping}Courier`, "", false);
          setFieldValue(`${values.modalShipping}Duration`, "", false);
          setFieldValue(`${values.modalShipping}Rate`, "", false);
          setFieldValue(`${values.modalShipping}File`, "", false);
          setFieldValue(`${values.modalShipping}Url`, "", false);
          setDisplayImage("");
        })
        .catch(function (err) {
          console.log("err", err);
          setFieldValue("loading", false, false);
        });
    }
  };

  const removeOptionShipping = () => {
    setFieldValue("loading", true, false);
    setDisplayImage("");
    axios
      .delete(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/shippingOptions/${values.shippingID}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        setFieldValue("fetch", true, false);
        setFieldValue("loading", false, false);
        setFieldValue("modalShipping", "", false);

        setFieldValue(`${values.modalShipping}Courier`, "", false);
        setFieldValue(`${values.modalShipping}Duration`, "", false);
        setFieldValue(`${values.modalShipping}Rate`, "", false);
        setFieldValue(`${values.modalShipping}File`, "", false);
        setFieldValue(`${values.modalShipping}Url`, "", false);
        setDisplayImage("");
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  };

  const handleChangeImgShape = (event) => {
    // console.log("event", event);
    if (event.target.files) {
      setFieldValue(
        `${values.modalShipping}File`,
        event.target.files[0],
        false
      );
    }
  };

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalShipping ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalShipping", "", false);
              setFieldValue("shippingID", "", false);
            }}
          >
            &times;
          </span>
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalShipping}Courier`}
            className={styles.text}
            placeholder="ผู้จัดส่ง"
          />
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalShipping}Duration`}
            className={styles.text}
            placeholder="ระยะเวลาการจัดส่ง"
          />
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalShipping}Rate`}
            className={styles.text}
            placeholder="ราคา"
          />
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalShipping}Url`}
            className={styles.text}
            placeholder="ลิ้ง URL"
          />
        </div>
        <div className={styles.rowInModal}>
          <input
            type="file"
            id="file10"
            onChange={(e) => handleChangeImgShape(e)}
          />
          <label
            for="file10"
            className={`${styles.buttonUploadFile} ${styles.label}`}
          >
            อัพโหลดไฟล์
          </label>
        </div>
        <div className={styles.displayImage}>
          {displayImage && <img src={displayImage} />}
        </div>
        <div className={`${styles.floatRight} ${styles.rowInModal}`}>
          {!values.shippingID ? null : (
            <button
              type="button"
              className={styles.removeOption}
              onClick={() => removeOptionShipping()}
            >
              ลบ
            </button>
          )}
          <button
            type="button"
            className={styles.addOption}
            onClick={() =>
              !values.shippingID ? addOptionShipping() : editOptionShipping()
            }
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalShipping;
