import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";
import firebaseApp from "../../firebase/index.js";

const ModalCoating = ({ values, setFieldValue }) => {
  const [displayImage, setDisplayImage] = useState("");
  useEffect(() => {
    if (values.selectedCoating.myID || values.selectedCoating.myID === 0) {
      setFieldValue(
        `${values.modalCoating}Name`,
        values.selectedCoating.name,
        false
      );
      setFieldValue(
        `${values.modalCoating}File`,
        values.selectedCoating.imgUrl,
        false
      );
      setFieldValue(
        `${values.modalCoating}Description`,
        values.selectedCoating.description,
        false
      );
      setDisplayImage(values.selectedCoating.imgUrl);
    } else {
      setFieldValue(`${values.modalCoating}Name`, "", false);
      setFieldValue(`${values.modalCoating}Description`, "", false);
      setFieldValue(`${values.modalCoating}File`, "", false);
      setDisplayImage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.modalCoating, values.selectedShape, values.selectedCoating]);

  const addOptionCoating = () => {
    setFieldValue("loading", true, false);
    let name = values[`${values.modalCoating}Name`];
    let description = values[`${values.modalCoating}Description`];

    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    if (
      values[`${values.modalCoating}File`] &&
      values[`${values.modalCoating}File`] instanceof Blob
    ) {
      storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalCoating}File`].name
          }`
        )
        .put(values[`${values.modalCoating}File`])
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            let dataPost = {
              imgUrl: url,
              name: name,
              description: description,
              price: {
                fixed_cost: 0,
                variable_cost_1: 0,
                variable_cost_2: 0,
              },
            };
            axios
              .post(
                // `http://127.0.0.1:5001/digitalwish-sticker/asia-east2/productOptionsNew/${values.selectedShape.myID}`,
                `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
                dataPost,
                {
                  headers: {
                    Authorization:
                      "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                  },
                }
              )
              .then((res) => {
                setFieldValue("fetch", true, false);
                setFieldValue("modalCoating", "", false);
                setFieldValue("loading", false, false);
              })
              .catch(function (err) {
                setFieldValue("loading", false, false);
                console.log("err", err);
              });
          });
        });
    } else {
      let dataPost = {
        name: name,
        description: description,
        price: {
          fixed_cost: 0,
          variable_cost_1: 0,
          variable_cost_2: 0,
        },
      };
      axios
        .post(
          // `http://127.0.0.1:5001/digitalwish-sticker/asia-east2/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("loading", false, false);
          setFieldValue("modalCoating", "", false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    }
  };

  const editOptionCoating = () => {
    setFieldValue("loading", true, false);
    let name = values[`${values.modalCoating}Name`];
    let description = values[`${values.modalCoating}Description`];

    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    if (
      values[`${values.modalCoating}File`] &&
      values[`${values.modalCoating}File`] instanceof Blob
    ) {
      storageRef
        .child(
          `productOptions/${timeStamp}-${
            values[`${values.modalCoating}File`].name
          }`
        )
        .put(values[`${values.modalCoating}File`])
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            let dataPost = {
              imgUrl: url,
              name: name,
              description: description,
            };
            console.log(dataPost);
            axios
              .put(
                `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}/${values.selectedCoating.myID}`,
                dataPost,
                {
                  headers: {
                    Authorization:
                      "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                  },
                }
              )
              .then((res) => {
                setFieldValue("fetch", true, false);
                setFieldValue("modalCoating", "", false);
                setFieldValue("loading", false, false);
              })
              .catch(function (err) {
                setFieldValue("loading", false, false);
                console.log("err", err);
              });
          });
        });
    } else {
      let dataPost = {
        name: name,
        description: description,
      };
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}/${values.selectedCoating.myID}`,
          dataPost,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("loading", false, false);
          setFieldValue("modalCoating", "", false);
        })
        .catch(function (err) {
          setFieldValue("loading", false, false);
          console.log("err", err);
        });
    }
  };

  const removeOptionCoating = () => {
    setFieldValue("loading", true, false);
    axios
      .delete(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/productOptionsNew/${values.selectedShape.myID}/${values.selectedMaterial.myID}/${values.selectedCoating.myID}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        setFieldValue("fetch", true, false);
        setFieldValue("modalCoating", "", false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  };

  const handleChangeImgCoating = (event) => {
    if (event.target.files) {
      setFieldValue(`${values.modalCoating}File`, event.target.files[0], false);
    }
  };

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalCoating ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalCoating", "", false);
              setFieldValue("coatingID", "", false);
            }}
          >
            &times;
          </span>
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalCoating}Name`}
            className={styles.text}
            placeholder="รูปแบบ"
          />
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalCoating}Description`}
            className={styles.text}
            placeholder="รายละเอียด"
          />
        </div>
        <div className={styles.rowInModal}>
          <input
            type="file"
            id="fileCoating"
            onChange={(e) => handleChangeImgCoating(e)}
          />
          <label
            for="fileCoating"
            className={`${styles.buttonUploadFile} ${styles.label}`}
          >
            อัพโหลดไฟล์
          </label>
        </div>
        <div className={styles.displayImage}>
          {displayImage && <img src={displayImage} />}
        </div>
        <div className={`${styles.floatRight} ${styles.rowInModal}`}>
          {values.selectedCoating.myID || values.selectedCoating.myID === 0 ? (
            <button
              type="button"
              className={styles.removeOption}
              onClick={() => removeOptionCoating()}
            >
              ลบ
            </button>
          ) : null}
          <button
            type="button"
            className={styles.addOption}
            onClick={() =>
              values.selectedCoating.myID || values.selectedCoating.myID === 0
                ? editOptionCoating()
                : addOptionCoating()
            }
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalCoating;
