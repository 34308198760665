import React from "react";

import styled, { keyframes } from "styled-components";

const fadeInScale = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Container = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  margin-top: 16px;
  transform-origin: top left;
  animation: ${fadeInScale} 0.31s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
`;

export default function MyEditor(props) {
  return (
    <Container
      style={{
        position: "absolute",
        left: `${props.annotation.geometry.x}%`,
        top: `${
          props.annotation.geometry.y + props.annotation.geometry.height
        }%`,
      }}
    >
      <TextEditor
        onChange={(e) =>
          props.onChange({
            ...props.annotation,
            data: {
              ...props.annotation.data,
              text: e.target.value,
            },
          })
        }
        onSubmit={props.onSubmit}
        value={props.annotation.data && props.annotation.data.text}
      />
    </Container>
  );
}

const Holder = styled.div`
  border-radius: 10px;
`;

const Inner = styled.div`
  padding: 8px 16px;
  border-radius: 10px;
  textarea {
    border: 0;
    border-radius: 10px;
    font-size: 14px;
    margin: 6px 0;
    min-height: 50px;
    outline: 0;
    min-width: 200px;
  }
`;

const Button = styled.div`
  background: whitesmoke;
  border: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  font-size: 1rem;
  margin: 0;
  outline: 0;
  padding: 8px 16px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  transition: background 0.21s ease-in-out;
  &:focus,
  &:hover {
    background: #eeeeee;
  }
`;

export function TextEditor(props) {
  return (
    <Holder>
      <Inner>
        <textarea
          placeholder="เขียนคำอธิบาย"
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onChange={props.onChange}
          value={props.value}
        ></textarea>
      </Inner>
      {props.value && <Button onClick={props.onSubmit}>บันทึก</Button>}
    </Holder>
  );
}
