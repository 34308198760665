import React, { useState, useEffect } from "react";
import ip from "ip";
import {
  useFormikContext,
  withFormik,
  Form,
  Field,
  ErrorMessage,
} from "formik";

import StepProgress from "../step_progress";
import LocationFieldsComponent from "../location-fields";
import TaxFieldsComponent from "../tax-fields";

import styles from "./index.module.scss";
import TranferBank from "./tranfer.png";

import { auth } from "../../firebase/index.js";
import axios from "axios";
import { axiosInst } from "../common-scss/common";
import { i18_th as i18 } from "../common-scss/i18_text";

import addressData from "./address.json";

import Placeholder from "./gallery.png";
import PlaceholderAI from "./ai.png";
import PlaceholderPDF from "./pdf.png";

import ReactGA from "react-ga";

const Payment = [
  {
    icon: TranferBank,
    name: "โอนเงินแนบสลิป",
    code: "transfer_money",
  },
];

const CartComponent = () => {
  const { values, errors, setFieldValue } = useFormikContext();
  const [selectStep] = useState(2);
  const [shippingFee, setShippingFee] = useState(0);
  const [promotion, setPromotion] = useState();
  const [promotionDiscount, setPromotionDiscount] = useState(0);
  const [shippingDuration, setShippingDuration] = useState(0);
  const [shippingExtraDuration, setShippingExtraDuration] = useState(0);
  const [shippingOriginalDuration, setShippingOriginalDuration] = useState(0);
  const [shippingCourier, setShippingCourier] = useState("");
  const [optionCounty, setOptionCounty] = useState([]);
  const [optionCity, setOptionCity] = useState([]);
  const [shippingOptions, setShippingOptions] = useState([]);

  let priceTotal = 0;

  useEffect(() => {
    ReactGA.pageview("/checkout");
  }, []);

  // Get orders list
  // If member get from database
  // If guest get from localStorage
  useEffect(() => {
    setFieldValue("loading", true, false);
    auth.onAuthStateChanged((user) => {
      console.log("Check if member or guest", user);
      if (user && user != null) {
        // If member get from database
        console.log("MEMBER MODE > ID", user.uid);
        setFieldValue("uid", user.uid, false);
        console.log("Getting member orders");
        axios
          .get(
            `https://asia-east2-digitalwish-sticker.cloudfunctions.net/cart?customerID=${user.uid}`,
            {
              headers: {
                Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
              },
            }
          )
          .then((res) => {
            console.log("Done getting orders", res.data[0]);
            setFieldValue("itemsList", res.data[0].itemsList, false);
            let extraDeliveryDay = 0;
            for (let i = 0; i < res.data[0].itemsList.length; i++) {
              if (
                parseInt(res.data[0].itemsList[i].extraDeliveryDay) >
                extraDeliveryDay
              )
                extraDeliveryDay = parseInt(
                  res.data[0].itemsList[i].extraDeliveryDay
                );
            }
            setShippingExtraDuration(extraDeliveryDay);
            setFieldValue("loading", false, false);
          })
          .catch((err) => {
            console.log("Error Getting member orders", err);
            setFieldValue("loading", false, false);
          });
      } else {
        // If guest get from localStorage
        console.log("GUEST MODE");
        console.log("Getting guest orders");
        let cartLocal = JSON.parse(localStorage.getItem("cart"));
        if (cartLocal) {
          console.log("From cartLocal", cartLocal);
          setFieldValue("itemsList", cartLocal.itemsList, false);
          let extraDeliveryDay = 0;
          // Find Max Extra Delivery Duration
          for (let i = 0; i < cartLocal.itemsList.length; i++) {
            if (
              parseInt(cartLocal.itemsList[i].extraDeliveryDay) >
              extraDeliveryDay
            )
              extraDeliveryDay = parseInt(
                cartLocal.itemsList[i].extraDeliveryDay
              );
          }
          setShippingExtraDuration(extraDeliveryDay);
          setFieldValue("loading", false, false);
        } else {
          setFieldValue("loading", false, false);
        }
      }
    });

    return;
  }, []);

  // Get Shipping options
  useEffect(() => {
    console.log("Get Shipping options");
    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/shippingOptions`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("Done Get Shipping options", res.data);
        setShippingOptions(res.data);
      })
      .catch(function (err) {
        console.log("Error Get Shipping options", err);
      });
  }, []);

  // Set payment default to transfer money
  useEffect(() => {
    setFieldValue("payment", "transfer_money", true);
  }, []);

  // Show tax information
  useEffect(() => {
    if (values.isTaxDocument === "2" || values.isTaxDocument === "3") {
      setFieldValue("billingFullname", values.fullname, false);
      let copyAddress = values.address;
      if (values.county) copyAddress += ` ${values.county}`;
      if (values.city) copyAddress += ` แขวง ${values.city}`;
      if (values.provice) copyAddress += ` จังหวัด ${values.provice}`;
      if (values.zip) copyAddress += ` ${values.zip}`;
      setFieldValue("billingFulladdress", copyAddress, false);
    } else {
      setFieldValue("billingFullname", "", false);
      setFieldValue("billingFulladdress", "", false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isTaxDocument]);

  // Update address options
  useEffect(() => {
    let newOptionCity = addressData.filter((item) => {
      return item.district == values.county;
    });
    let resultCity = newOptionCity.reduce((unique, o) => {
      if (!unique.some((obj) => obj.sub_district === o.sub_district)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setOptionCity(resultCity);

    let newOptionCounty = addressData.filter((item) => {
      return item.province == values.provice;
    });
    let resultCounty = newOptionCounty.reduce((unique, o) => {
      if (!unique.some((obj) => obj.district === o.district)) {
        unique.push(o);
      }
      return unique;
    }, []);
    setOptionCounty(resultCounty);
  }, [values]);

  const applyPromotion = () => {
    setFieldValue("errorMessage", "", false);
    setFieldValue("successMessage", "", false);
    setFieldValue("loading", true, false);

    let code = values.applyCode;

    if (!code) {
      setFieldValue("errorMessage", "กรุณากรอกโค้ดส่วนลด", false);
      setFieldValue("fetch", false, false);
      setFieldValue("loading", false, false);
      return;
    }

    setPromotion(false);
    setPromotionDiscount(0);

    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/promotion/check/${code}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("res.data[0]", res.data);
        let promotion = res.data;
        console.log(promotion);
        // setFieldValue("promotions", res.data, false);
        if (!promotion) {
          setFieldValue("errorMessage", `ไม่พบโค้ดส่วนลด ${code}`, false);
          setFieldValue("successMessage", "", false);
          setFieldValue("loading", false, false);
          setFieldValue("fetch", false, false);
        } else if (!promotion.isActive) {
          setFieldValue(
            "errorMessage",
            `โค้ดส่วนลด ${code} ถูกใช้งานแล้ว`,
            false
          );
          setFieldValue("successMessage", "", false);
          setFieldValue("fetch", false, false);
          setFieldValue("loading", false, false);
        } else if (promotion.isActive === true) {
          setFieldValue(
            "successMessage",
            `คุณได้รับส่วนลด ${parseInt(promotion.discountPercent)}%`,
            false
          );
          setFieldValue("errorMessage", "", false);
          setFieldValue("fetch", false, false);
          setFieldValue("loading", false, false);
          setPromotion(promotion);
          setPromotionDiscount(
            (priceTotal * 1.07 * parseInt(promotion.discountPercent)) / 100
          );
        }
        setFieldValue("applyCode", "", false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  };

  return (
    <>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>

      <section className={styles.section1}>
        <StepProgress stepIndex={selectStep} />
      </section>

      <Form>
        <section className={styles.myGrid}>
          <div className={styles.myBox}>
            <h3>สรุปออเดอร์</h3>
            <div className={styles.wrapTable}>
              <table className={styles.tableCustom}>
                <thead className={styles.borderBottom}>
                  <tr>
                    <th colspan="3" className={styles.textLeft}>
                      สินค้า
                    </th>
                    <th>จำนวน</th>
                    <th>ราคาต่อชิ้น</th>
                    <th>มูลค่า</th>
                  </tr>
                </thead>
                <tbody>
                  {values.itemsList.map((dataObjectMapped) => {
                    priceTotal = priceTotal + parseInt(dataObjectMapped.price);

                    let extraDeliveryDay = values.extraDeliveryDay || 0;
                    if (extraDeliveryDay > shippingExtraDuration)
                      setShippingExtraDuration(extraDeliveryDay);

                    let extension = dataObjectMapped.messages[0].info
                      .toLowerCase()
                      .split(".")
                      .pop();
                    return (
                      <>
                        <tr>
                          <td colspan="3">
                            <div
                              className={`${styles.containerRowCart} ${styles.flexNoWrap}`}
                            >
                              {/* <img
                                src={dataObjectMapped.messages[0].content}
                                className={styles.productPreview}
                                alt="Product"
                              /> */}
                              {extension === "ai" && (
                                <img
                                  // src={dataObjectMapped.messages[0].content}
                                  src={PlaceholderAI}
                                  className={styles.productPreview}
                                  alt="Product"
                                />
                              )}
                              {extension === "pdf" && (
                                <img
                                  // src={dataObjectMapped.messages[0].content}
                                  src={PlaceholderPDF}
                                  className={styles.productPreview}
                                  alt="Product"
                                />
                              )}
                              {["png", "jpeg", "jpg", "gif"].includes(
                                extension
                              ) && (
                                <img
                                  // src={dataObjectMapped.messages[0].content}
                                  src={Placeholder}
                                  className={styles.productPreview}
                                  alt="Product"
                                />
                              )}
                              {![
                                "ai",
                                "pdf",
                                "png",
                                "jpeg",
                                "jpg",
                                "gif",
                              ].includes(extension) && (
                                <img
                                  // src={dataObjectMapped.messages[0].content}
                                  src={Placeholder}
                                  className={styles.productPreview}
                                  alt="Product"
                                />
                              )}
                              {/* (ai, jpg, jpeg, png, pdf) */}
                              <div className={styles.containerCol}>
                                <div className={styles.name}>
                                  สติกเกอร์{dataObjectMapped.shape}
                                </div>
                                <div className={styles.desciption}>
                                  {dataObjectMapped.material}-
                                  {dataObjectMapped.coat}-
                                  {/* {dataObjectMapped.cutting} */}
                                  ขนาด
                                  {dataObjectMapped.width}x
                                  {dataObjectMapped.height}mm
                                </div>
                              </div>
                            </div>
                          </td>
                          <td
                            className={`${styles.textCenter} ${styles.textCenterMobile}`}
                          >
                            {dataObjectMapped.units}
                          </td>
                          <td
                            className={`${styles.textCenter} ${styles.textCenterMobile}`}
                          >
                            {(
                              (dataObjectMapped.price /
                                dataObjectMapped.units) *
                              1.07
                            ).toFixed(2)}
                            ฿
                          </td>
                          <td
                            className={`${styles.textCenter} ${styles.textCenterMobile}`}
                          >
                            {(dataObjectMapped.price * 1.07).toFixed(2)}฿
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="3"
                            className={`${styles.textCenterMobileNewRow}`}
                          >
                            จำนวน {dataObjectMapped.units} ชิ้น
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="3"
                            className={`${styles.textCenterMobileNewRow}`}
                          >
                            ราคาต่อชิ้น{" "}
                            {(
                              (dataObjectMapped.price /
                                dataObjectMapped.units) *
                              1.07
                            ).toFixed(2)}
                            ฿
                          </td>
                        </tr>
                        <tr>
                          <td
                            colspan="3"
                            className={`${styles.textCenterMobileNewRow}`}
                          >
                            ราคา {(dataObjectMapped.price * 1.07).toFixed(2)}฿
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
                <tfoot className={styles.borderTop}>
                  <tr>
                    <td colspan="5">ค่าสินค้ารวม VAT 7%</td>
                    <td
                      className={`${styles.textCenter} ${styles.textCenterMobile}`}
                    >
                      {(priceTotal * 1.07).toFixed(2)}฿
                    </td>
                  </tr>
                  <tr>
                    <td className={`${styles.textCenterMobileNewRow}`}>
                      {(priceTotal * 1.07).toFixed(2)}฿
                    </td>
                  </tr>

                  {/* <tr>
                    <td colspan="4">VAT 7%</td>
                    <td
                      className={`${styles.textCenter} ${styles.textCenterMobile}`}
                    >
                      {(priceTotal * 7) / 100}฿
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      className={`${styles.textCenterMobileNewRow} ${styles.rowTr}`}
                    >
                      {(priceTotal * 7) / 100}฿
                    </td>
                  </tr> */}
                  <tr>
                    <td colspan="5">
                      <div className={styles.containerCol}>
                        <div className={styles.name}>ส่วนลดสินค้า</div>
                        <div className={styles.desciption}>
                          {promotion && (
                            <>
                              โค้ดส่วนลด: {promotion.code} ส่วนลด{" "}
                              {promotion.discountPercent}%
                            </>
                          )}
                        </div>
                      </div>
                    </td>
                    <td
                      className={`${styles.textCenter} ${styles.textCenterMobile}`}
                    >
                      {promotionDiscount &&
                        parseFloat(promotionDiscount).toFixed(2)}
                      ฿
                    </td>
                  </tr>
                  <tr>
                    <td className={`${styles.textCenterMobileNewRow}`}>
                      {promotionDiscount &&
                        parseFloat(promotionDiscount).toFixed(2)}
                      ฿
                    </td>
                  </tr>

                  <tr>
                    <td colspan="5">
                      <div className={styles.containerCol}>
                        <div className={styles.name}>ค่าจัดส่ง</div>
                        <div className={styles.desciption}>
                          {shippingCourier ? (
                            <div>
                              {shippingCourier} - {shippingDuration} วันทำการ
                            </div>
                          ) : (
                            "กรุณาเลือกการจัดส่ง"
                          )}
                        </div>
                      </div>
                    </td>
                    <td
                      className={`${styles.textCenter} ${styles.textCenterMobile}`}
                    >
                      {shippingFee && parseInt(shippingFee).toFixed(2)}฿
                    </td>
                  </tr>

                  <tr>
                    <td className={`${styles.textCenterMobileNewRow}`}>
                      {shippingFee && parseInt(shippingFee).toFixed(2)}฿
                    </td>
                  </tr>

                  <tr className={styles.borderTop}>
                    <td colspan="5">รวมทั้งหมด</td>
                    <td
                      className={`${styles.textCenter} ${styles.textCenterMobile}`}
                    >
                      {parseFloat(
                        priceTotal +
                          (priceTotal * 7) / 100 -
                          parseFloat(promotionDiscount) +
                          parseInt(shippingFee)
                      )?.toFixed(2)}
                      ฿
                    </td>
                  </tr>
                  <tr>
                    <td className={`${styles.textCenterMobileNewRow}`}>
                      {parseFloat(
                        priceTotal +
                          (priceTotal * 7) / 100 -
                          parseFloat(promotionDiscount) +
                          parseInt(shippingFee)
                      )?.toFixed(2)}
                      ฿
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div
              style={{ marginTop: "20px" }}
              className={styles.promotionSection}
            >
              <Field
                name="applyCode"
                type="text"
                placeholder="โค้ดส่วนลดสินค้า"
              />
              <button
                // className={styles.buttonGreen}
                onClick={() => {
                  applyPromotion();
                }}
              >
                ตรวจสอบโค้ด
              </button>
              <div>
                {values.successMessage && (
                  <div style={{ color: "green" }}>{values.successMessage}</div>
                )}
                {values.errorMessage && (
                  <div style={{ color: "red" }}>{values.errorMessage}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.myBox}>
            <h3>ที่อยู่จัดส่ง</h3>
            <LocationFieldsComponent
              optionCity={optionCity}
              optionCounty={optionCounty}
            />

            <h3 style={{ marginTop: "20px" }}>
              เลือกการจัดส่ง{" "}
              <ErrorMessage
                name="shippingOption"
                render={(msg) => <span className="error">{msg}</span>}
              />
            </h3>

            {shippingOptions
              // .sort((a, b) => (a.courier == "มารับเองที่โรงงาน" ? 1 : -1))
              .map((shippingOption, index) => {
                var end_date = new Date();
                end_date.setDate(
                  end_date.getDate() + parseInt(shippingOption.duration)
                );
                return (
                  shippingOption.courier != "มารับเองที่โรงงาน" && (
                    <>
                      <button
                        type="button"
                        className={`${styles.btnShippingOption} ${
                          values.shippingOption === index && styles.active
                        }`}
                        onClick={() => {
                          setFieldValue("shippingOption", index, true);
                          setShippingCourier(shippingOption.courier);
                          setShippingFee(shippingOption.rate);
                          setShippingDuration(
                            parseInt(
                              parseInt(shippingOption.duration) +
                                parseInt(shippingExtraDuration)
                            )
                          );
                          setShippingOriginalDuration(shippingOption.duration);
                          setFieldValue(
                            "totalPrice",
                            priceTotal +
                              (priceTotal * 7) / 100 -
                              parseFloat(promotionDiscount) +
                              parseInt(shippingFee),
                            false
                          );
                          setFieldValue("totalItemPrice", priceTotal, false);
                          setFieldValue(
                            "shippingDuration",
                            parseInt(
                              parseInt(shippingOption.duration) +
                                parseInt(shippingExtraDuration)
                            ),
                            false
                          );
                          setFieldValue(
                            "shippingExtraDuration",
                            shippingExtraDuration,
                            false
                          );
                          setFieldValue(
                            "shippingOriginalDuration",
                            parseInt(shippingOption.duration),
                            false
                          );
                          setFieldValue(
                            "shippingCost",
                            shippingOption.rate,
                            false
                          );
                          setFieldValue(
                            "shippingCourier",
                            shippingOption.courier,
                            false
                          );
                          setFieldValue("promotion", promotion || false, false);
                          setFieldValue(
                            "promotionDiscount",
                            promotionDiscount || 0,
                            false
                          );
                        }}
                      >
                        <h4 style={{ marginTop: "0" }}>
                          {shippingOption.courier}
                        </h4>
                        <div>รับสินค้าโดยประมาณ</div>
                        <h4>
                          {/* {end_date.toISOString().slice(0, 10)}  */}(
                          {parseInt(shippingOption.duration) +
                            parseInt(shippingExtraDuration)}{" "}
                          วัน)
                        </h4>
                        <p>ราคา {shippingOption.rate} บาท</p>
                      </button>
                    </>
                  )
                );
              })}

            {shippingOptions
              // .sort((a, b) => (a.courier == "มารับเองที่โรงงาน" ? 1 : -1))
              .map((shippingOption, index) => {
                var end_date = new Date();
                end_date.setDate(
                  end_date.getDate() + parseInt(shippingOption.duration)
                );
                return (
                  shippingOption.courier == "มารับเองที่โรงงาน" && (
                    <>
                      <button
                        type="button"
                        className={`${styles.btnShippingOption} ${
                          values.shippingOption === index && styles.active
                        }`}
                        onClick={() => {
                          setFieldValue("shippingOption", index, true);
                          setShippingCourier(shippingOption.courier);
                          setShippingFee(shippingOption.rate);
                          setShippingDuration(
                            parseInt(
                              parseInt(shippingOption.duration) +
                                parseInt(shippingExtraDuration)
                            )
                          );
                          // setShippingExtraDuration(shippingExtraDuration);
                          setShippingOriginalDuration(shippingOption.duration);
                          setFieldValue(
                            "totalPrice",
                            priceTotal +
                              (priceTotal * 7) / 100 -
                              parseFloat(promotionDiscount) +
                              parseInt(shippingFee),
                            false
                          );
                          setFieldValue("totalItemPrice", priceTotal, false);
                          setFieldValue(
                            "shippingDuration",
                            parseInt(
                              parseInt(shippingOption.duration) +
                                parseInt(shippingExtraDuration)
                            ),
                            false
                          );
                          setFieldValue(
                            "shippingExtraDuration",
                            shippingExtraDuration,
                            false
                          );
                          setFieldValue(
                            "shippingOriginalDuration",
                            parseInt(shippingOption.duration),
                            false
                          );
                          setFieldValue(
                            "shippingCost",
                            shippingOption.rate,
                            false
                          );
                          setFieldValue(
                            "shippingCourier",
                            shippingOption.courier,
                            false
                          );
                          setFieldValue("promotion", promotion || false, false);
                          setFieldValue(
                            "promotionDiscount",
                            promotionDiscount || 0,
                            false
                          );
                        }}
                      >
                        <h4 style={{ marginTop: "0" }}>
                          {shippingOption.courier}
                        </h4>
                        <div>รับสินค้าโดยประมาณ</div>
                        <h4>
                          {/* {end_date.toISOString().slice(0, 10)}  */}(
                          {parseInt(shippingOption.duration) +
                            parseInt(shippingExtraDuration)}{" "}
                          วัน)
                        </h4>
                        <p>ราคา {shippingOption.rate} บาท</p>
                        <div style={{ marginTop: "10px" }}>
                          <a
                            href={shippingOption.shipUrl}
                            target="_tab"
                            style={{
                              color: "black",
                              textDecoration: "underline",
                            }}
                          >
                            แผนที่บริษัท
                          </a>
                        </div>
                      </button>
                    </>
                  )
                );
              })}

            <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
              ออกใบกำกับภาษี
            </h3>
            <div style={{ marginBottom: "20px" }}>
              <div className={styles.containerColBank}>
                <Field
                  name="isTaxDocument"
                  type="radio"
                  value="1"
                  checked={values.isTaxDocument === "1" ? true : false}
                />{" "}
                ไม่รับใบกำกับภาษี
              </div>
              <div className={styles.containerColBank}>
                <Field name="isTaxDocument" type="radio" value="2" />{" "}
                รับใบกำกับภาษี
              </div>
              {/* 
              <div className={styles.containerColBank}>
                <Field name="isTaxDocument" type="radio" value="3" />{" "}
                ข้อมูลเดียวกับที่อยู่จัดส่ง
              </div> */}

              <div
                className={
                  values.isTaxDocument === "2"
                    ? styles.contentDisplayBlock
                    : styles.contentDisplayNone
                }
              >
                <TaxFieldsComponent />
              </div>
              <div
                className={
                  values.isTaxDocument === "3"
                    ? styles.contentDisplayBlock
                    : styles.contentDisplayNone
                }
              >
                <TaxFieldsComponent />
              </div>
            </div>

            <div
              className={styles.containerRow}
              style={{ marginBottom: "10px" }}
            >
              <div
                className={styles.containerColBank}
                style={{ marginRight: "10px" }}
              >
                <Field name="approve" type="checkbox" value="1" />
              </div>
              ยอมรับเงื่อนไขตามเอกสารนี้
              <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/digitalwish-sticker.appspot.com/o/pdf?alt=media&token=e5de51a0-3f36-4507-8edf-71828552cde4"
              >
                (อ่านที่นี่)
              </a>
              <ErrorMessage
                name="approve"
                render={(msg) => <span className="error">{msg}</span>}
              />
            </div>

            <div className="error" style={{ fontSize: "1.1rem" }}>
              {errors &&
                Object.keys(errors).length > 0 &&
                "รบกวนให้กรอกข้อมูลทุกช่องที่มี*"}
            </div>
            <button type="submit" className={styles.buttonNext}>
              ถัดไป
            </button>
          </div>
        </section>
      </Form>
    </>
  );
};

const EnhancedCartComponent = withFormik({
  mapPropsToValues: () => ({
    itemsList: [],
    paymentOptions: [],
    shippingOptions: [],

    orderID: "",
    priceTotal: "",
    shippingOption: "",
    payment: "",

    email: "",
    fullname: "",
    phone: "",
    address: "",
    county: "",
    city: "",
    provice: "",
    zip: "",

    line: "",

    billingFullname: "",
    billingFulladdress: "",
    billingTaxID: "",
    isTaxDocument: "1",
    checkedBoxInfo: false,
    approve: "",
    loading: false,
  }),
  validate: (values) => {
    const errors = {};
    // if (!values.email) {
    //   errors.email = i18.required;
    // }
    if (!values.phone) {
      errors.phone = i18.required;
    }
    if (
      !(values.phone.match(/^[0-9]+$/) != null) ||
      values.phone.length < 8 ||
      values.phone.length > 10
    ) {
      errors.phone = "ต้องเป็นตัวเลข 9-10 ตัวอักษรและไม่มีอักขระ";
    }
    if (!values.address) {
      errors.address = i18.required;
    }
    if (!values.fullname) {
      errors.fullname = i18.required;
    }
    if (!values.county) {
      errors.county = i18.required;
    }
    if (!values.city) {
      errors.city = i18.required;
    }
    if (!values.provice) {
      errors.provice = i18.required;
    }
    if (!values.zip) {
      errors.zip = i18.required;
    }
    if (values.shippingOption === "") {
      errors.shippingOption = i18.required;
    }
    if (!values.payment) {
      errors.payment = i18.required;
    }

    if (!values.checkedBoxInfo) {
      // รับ หรือ ไม่รับก็ได้
    }
    if (!values.approve) {
      errors.approve = i18.required;
    }

    console.log("VALIDATE", values);
    console.log("Validation error", errors);
    return errors;
  },
  handleSubmit: (values, { props, setFieldValue }) => {
    setFieldValue("loading", true, false);
    localStorage.setItem("errorMessage", "");

    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/runningNumber/CYK663a0iBWHKLKvsl38`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("Get running number", res.data);

        // Generate a random order ID
        let orderIDLast = 0;
        let itemIDLast = 0;
        console.log("currentOrderIDLast", res.data.orderID);
        console.log("currentItemIDLast", res.data.itemID);

        orderIDLast = parseInt(res.data.orderID.slice(2)) + 1;
        itemIDLast = parseInt(res.data.itemID.slice(2)) + 1;
        orderIDLast = formatOrderID(orderIDLast);

        let newOrderIDLast = orderIDLast;
        let newItemIDLast = formatItemID(
          parseInt(res.data.itemID.slice(2)) + values.itemsList.length
        );

        console.log("newOrderIDLast", newOrderIDLast);
        console.log("newItemIDLast", newItemIDLast);

        // Prepare itemsList
        console.log("values.itemsList", values.itemsList);
        const newItemList = prepareNewItemList(values.itemsList, itemIDLast);
        console.log("newItemList", newItemList);

        // Create the order data object
        const orderData = createOrderData(values, orderIDLast, newItemList);
        console.log("Creating order", orderData);

        // Order notifications
        const orderNotificationText = generateOrderNotificationText(orderData);
        console.log("Order notification", orderNotificationText);
        lineNotify(orderNotificationText);

        // Store some values in localStorage
        storeValuesInLocalStorage(
          orderIDLast,
          values.phone,
          orderData.totalCost,
          orderData.shippingAddress.email
        );

        // Item notifications
        // If no item notifications
        if (!orderData.itemsList || orderData.itemsList.length === 0) {
          if (values.itemsList) {
            const noItemListNotificationText = `NO ITEM!\n ##itemIDLast: ${itemIDLast} \n## newItemIDLast: ${newItemIDLast} \n## ${JSON.stringify(
              values.itemsList
            )}`;
            lineNotify(noItemListNotificationText);
          } else {
            const noItemListNotificationText = `NO ITEM!\n ##itemIDLast: ${itemIDLast} \n## newItemIDLast: ${newItemIDLast}`;
            lineNotify(noItemListNotificationText);
          }
        }

        // If normal item notifications
        for (let i = 0; i < orderData.itemsList.length; i++) {
          const item = orderData.itemsList[i];
          let itemNotificationText = generateItemNotificationText(
            orderData.orderID,
            item,
            i + 1,
            orderData.itemsList.length
          );
          console.log("Item notification", itemNotificationText);
          lineNotify(itemNotificationText);
        }

        // POST request to create the order
        console.log("Creating Order");
        axios
          .post(
            `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders`,
            orderData,
            {
              headers: {
                Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
              },
            }
          )
          .then((res) => {
            console.log("Order created", res);
            const updatedRunningNumber = {
              orderID: newOrderIDLast,
              itemID: newItemIDLast,
            };
            if (
              orderData.promotionDiscount &&
              orderData.promotion &&
              orderData.promotion.id
            ) {
              const updatePromotion = {
                isActive: false,
                orderID: orderData.orderID,
                used_dt: new Date(),
              };
              console.log("Updating RunningNumber");
              axios
                .put(
                  `https://asia-east2-digitalwish-sticker.cloudfunctions.net/runningNumber/CYK663a0iBWHKLKvsl38`,
                  updatedRunningNumber,
                  {
                    headers: {
                      Authorization:
                        "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                    },
                  }
                )
                .then((running_res) => {
                  console.log("Updated runningNumber", running_res);
                  axios
                    .put(
                      `https://asia-east2-digitalwish-sticker.cloudfunctions.net/promotion/${orderData.promotion.id}`,
                      updatePromotion,
                      {
                        headers: {
                          Authorization:
                            "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                        },
                      }
                    )
                    .then((promo_res) => {
                      console.log("Updated promotion", promo_res);
                      setFieldValue("loading", false, false);
                      props.history.push("/e-sticker-frontend/successful");
                    })
                    .catch(function (err) {
                      handleOrderError(err, props.history);
                    });
                })
                .catch(function (err) {
                  handleOrderError(err, props.history);
                });
            } else {
              console.log("No promotion");
              console.log("Updating RunningNumber");
              axios
                .put(
                  `https://asia-east2-digitalwish-sticker.cloudfunctions.net/runningNumber/CYK663a0iBWHKLKvsl38`,
                  updatedRunningNumber,
                  {
                    headers: {
                      Authorization:
                        "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                    },
                  }
                )
                .then((running_res) => {
                  console.log("Updated runningNumber", running_res);
                  setFieldValue("loading", false, false);
                  props.history.push("/e-sticker-frontend/successful");
                })
                .catch(function (err) {
                  console.log("Error Updated runningNumber", err);
                  handleOrderError(err, props.history);
                });
            }
          })
          .catch(function (err) {
            console.log("Error creating order", err);
            handleOrderError(err, props.history);
          });
      })
      .catch(function (err) {
        handleOrderError(err, props.history);
      });

    function generateRandomID() {
      return Math.floor(Math.random() * 10000);
    }

    function makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    }

    function formatOrderID(orderID) {
      const randomString = Math.random().toString(36).substring(2, 7);
      return `DW${orderID.toString().padStart(4, "0")}-${randomString}`;
    }

    function formatItemID(itemID) {
      const itemAsString = String(itemID); // Ensure it's a string
      if (!isNaN(itemAsString)) {
        return `SW${itemAsString.padStart(7, "0")}`;
      } else {
        return `SW${"0".padStart(7, "0")}-${makeid(5)}`;
      }
    }

    function prepareNewItemList(itemsList, itemIDLast) {
      return itemsList.map((item) => {
        const itemIDText = formatItemID(itemIDLast++);
        return {
          ...item,
          itemID: itemIDText,
          approvalStatus: "pendingOnAdmin",
          approvalList: [],
        };
      });
    }

    function createOrderData(values, orderID, newItemList) {
      return {
        billingAddress: {
          fulladdress: values.billingFulladdress,
          fullname: values.billingFullname,
          taxID: values.billingTaxID,
          isTaxDocument: values.isTaxDocument,
        },
        customerID: values.uid || "",
        itemsCost: values.totalItemPrice,
        itemsList: newItemList,
        orderID,
        paymentInfo: values.payment,
        paymentMethod: values.payment,
        paymentRef: values.payment,
        paymentStatus: "pending",
        shippingCost: values.shippingCost,
        shippingCourier: values.shippingCourier,
        shippingDuration: values.shippingDuration || "",
        shippingExtraDuration: values.shippingExtraDuration || "",
        shippingOriginalDuration: values.shippingOriginalDuration || "",
        shippingNumber: "",
        shippingStatus: "",
        status:
          values.payment === "transfer_money" ? "รอชำระเงิน" : "กำลังดำเนินการ",
        totalCost: Number(
          parseFloat(
            values.totalItemPrice +
              (values.totalItemPrice * 7) / 100 -
              parseFloat(values.promotionDiscount).toFixed(2) +
              parseInt(values.shippingCost)
          ).toFixed(2)
        ),
        vatCost: (values.totalItemPrice * 7) / 100,
        promotion: values.promotion || false,
        promotionDiscount: Number(values.promotionDiscount.toFixed(2)) || 0,
        timeStamp: new Date(),
        paymentConfirm: [],
        shippingAddress: {
          address: values.address,
          city: values.city,
          county: values.county,
          fullname: values.fullname,
          provice: values.provice,
          zip: values.zip,
          phone: values.phone,
          email: values.email || "-",
          line: values.line,
        },
      };
    }

    function storeValuesInLocalStorage(orderIDLast, phone, totalCost, email) {
      localStorage.setItem("orderIDLast", orderIDLast);
      localStorage.setItem("orderPhoneLast", phone);
      localStorage.setItem("orderValue", totalCost);
      localStorage.setItem("orderEmail", email || "");
    }

    function generateOrderNotificationText(orderData) {
      let message = `------ NEW ORDER ${orderData.orderID} ------\n`;
      message += `อีเมล:  ${orderData.shippingAddress.email || "-"}\n`;
      message += `เบอร์โทรศัพท์:  ${orderData.shippingAddress.phone}\n`;
      message += `ชื่อ: ${orderData.shippingAddress.fullname}\n`;
      message += `ที่อยู่: ${orderData.shippingAddress.address} ${orderData.shippingAddress.county} แขวง ${orderData.shippingAddress.city} จังหวัด ${orderData.shippingAddress.provice} ${orderData.shippingAddress.zip}\n`;
      message += `ไอดี LINE: ${orderData.shippingAddress.line}\n`;
      message += `การจัดส่ง: ${orderData.shippingCourier}\n`;
      message += `-------\n`;
      message += `ทั้งหมด ${orderData.itemsList.length} รายการ\n`;
      message += `ค่าสินค้ารวม VAT 7%: ${orderData.totalCost}\n`;
      message += `ส่วนลดสินค้า:  ${orderData.promotionDiscount}\n`;
      message += `ค่าจัดส่ง:  ${orderData.shippingCost}\n`;
      message += `รวมทั้งหมด:  ${orderData.totalCost}`;
      return message;
    }

    function generateItemNotificationText(orderID, item, itemNo, totalItemNo) {
      let message = `รายการ ${itemNo}/${totalItemNo} ${orderID} ------\n`;
      message += `#${item.itemID}\n`;
      message += `${item.shape}\n`;
      message += `${item.material} - ${item.coat}\n`;
      message += `ขนาด: ${item.width} x ${item.height}\n`;
      message += `จำนวน: ${item.units}\n`;
      message += `มูลค่า: ${(item.price * 1.07).toFixed(2)}\n`;
      message += `ราคาต่อชิ้น: ${((item.price / item.units) * 1.07).toFixed(
        2
      )}\n`;
      message += `หมายเหตุ: ${item.comment}`;
      message += `URL: ${item.messages[0].content}`;
      return message;
    }

    function handleOrderError(err, history) {
      setFieldValue("loading", false, false);
      console.log("Error", JSON.stringify(err));
      localStorage.setItem("errorMessage", JSON.stringify(err));
      const text = `------ ข้อความจากระบบ\nสั่งออเดอร์ [ERROR]\n${JSON.stringify(
        err
      )}`;
      lineNotify(text);
      history.push("/e-sticker-frontend/fail");
    }
  },
  displayName: "CartComponentForm",
})(CartComponent);

export default EnhancedCartComponent;

async function lineNotify(lineNotificationText) {
  const line_channel_token = "DOQUplBSNc3FWmtDBF0SvSJk89bbaLw7adt5w6iDKvv";
  // const line_channel_token = "RcaCYKQT7uJYG34Iy4BOI8infxEDQOY1us9yA7qtvCx";
  const xMineApiKey = "5f20d727-721c-4867-8d70-32d5c43bad99";
  try {
    let config = {
      url: `https://asia-east2-minenoti-app.cloudfunctions.net/service/line-noti`,
      method: "post",
      timeout: 8000,
      headers: {
        "x-mineapi-key": xMineApiKey,
      },
      data: {
        service: "LINE-NOTI",
        payload: [
          {
            to: line_channel_token,
            message: lineNotificationText,
          },
        ],
      },
    };
    let res = await axios(config);
    // console.log("res", res);
    if (res.data.status === "success") {
      console.log(res.status);
    }
    // return res.data.result;
    return;
  } catch (err) {
    console.error("err", err.response.data);
    // return err.data;
    return;
  }
}
