import React, { useEffect, useState } from "react";
import { withFormik, useFormikContext } from "formik";
import styles from "./index.module.scss";
import axios from "axios";

import AdminKpi from "../admin-kpi";
import ModalPromotion from "./modalPromotion.js";

import moment from "moment";
const formatDateTime = "DD-MM-YYYY HH:mm";
const FormatDateTime = (date) => {
  return moment(date).format(formatDateTime);
};

const AdminPromotionComponent = () => {
  const { values, setFieldValue } = useFormikContext();
  // Fetch Promotion
  useEffect(() => {
    setFieldValue("loading", true, false);
    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/promotion`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // console.log("res.data[0]", res.data)
        setFieldValue("promotions", res.data, false);
        setFieldValue("fetch", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.fetch]);

  useEffect(()=> {
    changeFilter("all");
  },[values.promotions])

  const handleRemove = (id) => {
    // console.log(">>>>", datelist[index].myID)
    setFieldValue("loading", true, false);
    axios
      .delete(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/promotion/${id}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // console.log("res", res);
        setFieldValue("loading", false, false);
        setFieldValue("fetch", true, false);
      })
      .catch((err) => {
        setFieldValue("loading", false, false);
        console.log("err", err);
      });
  };

  const changeFilter = (mode) => {
    let filterPromotions = [];
    if(mode === "all") {
      filterPromotions = values.promotions;
    } else if(mode === "active") {
      filterPromotions = values.promotions.filter( promotion => promotion.isActive );
    } else if(mode === "inactive") {
      filterPromotions = values.promotions.filter( promotion => !promotion.isActive );
    }
    filterPromotions && filterPromotions.length > 0 && filterPromotions.sort((a, b) => new Date(b.created_dt) - new Date(a.created_dt));
    return setFieldValue("filterPromotions", filterPromotions, false);
  }

  return (
    <main className={styles.wrapContent}>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>
      <section className={styles.section1}>
        <AdminKpi kpi={{ order: 10, sales: 1234567, member: 1000 }} />
      </section>

      <h1 className={styles.title}>โค้ดส่วนลด</h1>

      <section
        className={styles.productOptions}
        style={{ justifyContent: "start" }}
      >

        <div style={{width: "100%"}}>
          <article className={styles.cardProductOption}>
            <h4>รายการโค้ดส่วนลด</h4>
            <div>
            <button
                type="button"
                className={styles.btnOption}
                onClick={() =>
                  setFieldValue("modalPromotion", "modalPromotionAdd", false)
                }
              >
                เพิ่ม
              </button>
              <ModalPromotion values={values} setFieldValue={setFieldValue} />
            </div>
          </article>
        </div>
        <div>
          <label onClick={()=> {changeFilter("all")}}>ทั้งหมด</label> / <label onClick={()=> {changeFilter("active")}}>ยังไม่ได้ใช้งาน</label> / <label onClick={()=> {changeFilter("inactive")}}>ใช้แล้ว</label>
        </div>
        <div className={styles.adminTable}>
          <table>
            <thead>
              <th>โค้ดส่วนลด</th>
              <th>% ส่วนลด</th>
              <th>สถานะ</th>
              <th>สร้างเมื่อ</th>
              <th>ออเดอร์</th>
              <th>ถูกใช้เมื่อ</th>
              <th>จัดการ</th>
            </thead>
            <tbody>
            {values.filterPromotions &&
              values.filterPromotions.map((promotion, index) => {
                // console.log(promotion)
                return (
                  <>
                  <tr>
                    <td>{promotion.code && promotion.code}</td>
                    <td>{promotion.discountPercent && promotion.discountPercent}%</td>
                    <td>{promotion.isActive ? <><label className={styles.statusDone}>ใช้งานได้</label></> : <><label className={styles.statusCancel}>ถูกใช้งานแล้ว</label></>}</td>
                    <td>{promotion.created_dt && FormatDateTime(promotion.created_dt)}</td>
                    <td>{promotion.orderID ? promotion.orderID : "-"}</td>
                    <td>{promotion.used_dt ? FormatDateTime(promotion.used_dt) : "-"}</td>
                    <td>{promotion.isActive ? <>
                      <button
                        type="button"
                        className={styles.removeThisDemo}
                        onClick={() => handleRemove(promotion.myID)}
                      >
                        ลบ
                      </button>
                    </> : <>
                      <button
                          type="button"
                          className={styles.removeThisDemo}
                          onClick={() => handleRemove(promotion.myID)}
                        >
                          ลบ
                      </button>
                    </>}</td>
                  </tr>
                  </>
                );
              })
            }
              
            </tbody>
          </table>
        </div>
      </section>
    </main>
  );
};

const EnhancedAdminPromotionComponent = withFormik({
  mapPropsToValues: (props) => ({
    loading: false,
    promotion: [],
    modalAdd: "",
    fetch: false,
  }),
})(AdminPromotionComponent);

export default EnhancedAdminPromotionComponent;
