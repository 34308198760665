import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";
import firebaseApp from "../../firebase/index.js";

const ModalPromotion = ({ values, setFieldValue }) => {
  // console.log("values", values)
  const [displayImage, setDisplayImage] = useState("");
  useEffect(() => {
    let promotionOptioned = values.promotion && values.promotion.find(
      (promotion) => `${promotion.myID}` === `${values.promotionID}`
    );
    if (promotionOptioned && values.promotionID) {
      setFieldValue(
        `${values.modalPromotion}Code`,
        promotionOptioned.code,
        false
      );
      setFieldValue(
        `${values.modalPromotion}DiscountPercent`,
        promotionOptioned.discountPercent,
        false
      );
    } else {
      setFieldValue(`${values.modalPromotion}Code`, "", false);
      setFieldValue(`${values.modalPromotion}DiscountPercent`, "", false);
      setDisplayImage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.promotionID]);

  const randomCode = (length = 5) => {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  const addOptionPromotion = () => {
    setFieldValue("errorMessage", "", false);
    setFieldValue("loading", true, false);
    let data = {
      code: values[`${values.modalPromotion}Code`],
      discountPercent: values[`${values.modalPromotion}DiscountPercent`],
      isActive: true,
      created_dt: new Date(),
    };
    let timeStamp = new Date().toISOString().slice(0, 10);

    if (!data.code || data.code.length < 3) {
      data.code = randomCode();
      setFieldValue("modalPromotionAddRating", data.code, false);
    }
    if (!parseInt(data.discountPercent) || parseInt(data.discountPercent) < 1 || parseInt(data.discountPercent) > 100) {
      data.discountPercent = 10;
      setFieldValue("modalPromotionAddRating", data.discountPercent, false);
    }

    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/promotion/check/${data.code}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("res.data[0]", res.data)
        let promotion = res.data;
        console.log(promotion)
        // setFieldValue("promotions", res.data, false);
        if(!promotion) {

          axios
            .post(
              `https://asia-east2-digitalwish-sticker.cloudfunctions.net/promotion`,
              data,
              {
                headers: {
                  Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                },
              }
            )
            .then((res) => {
              setFieldValue("fetch", true, false);
              setFieldValue("modalPromotion", "", false);
              setFieldValue("loading", false, false);

              setFieldValue(`${values.modalPromotion}Code`, "", false);
              setFieldValue(`${values.modalPromotion}DiscountPercent`, "", false);
            })
            .catch(function (err) {
              console.log("err", err);
              setFieldValue("loading", false, false);
            });
          
        } else {
          setFieldValue("errorMessage", "โค้ดส่วนลดซ้ำ", false);
          setFieldValue("fetch", false, false);
          setFieldValue("loading", false, false);
        }
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });

    
  };

  const editOptionPromotion = () => {
    setDisplayImage("");
    setFieldValue("loading", true, false);
    let data = {
      name: values[`${values.modalPromotion}Name`],
      rating: values[`${values.modalPromotion}Rating`],
      review: values[`${values.modalPromotion}Promotion`],
      description: values[`${values.modalPromotion}Description`],
      img: values[`${values.modalPromotion}File`],
      created_dt: new Date(),
    };
    // console.log(data);

    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    // console.log("VALUE", values);
    // console.log("TEST", parseInt(values.modalPromotionEditDuration));
    // console.log("TYPE", values.modalPromotionEditFile.type);
    if (!parseInt(values.modalPromotionEditRating) || parseInt(values.modalPromotionEditRating) < 0 || parseInt(values.modalPromotionEditRating) > 5) {
      data.rating = 5;
      setFieldValue("modalPromotionEditRating", 5, false);
    }

    if (values[`${values.modalPromotion}File`]) {
      // if (values[`${values.modalPromotion}File`]) {
      // console.log("A");
      storageRef
        .child(
          `promotion/${timeStamp}-${
            values[`${values.modalPromotion}File`].name
          }`
        )
        .put(values[`${values.modalPromotion}File`])
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            console.log(url);
            data.img = url;
            setFieldValue("modalPromotionEditFile", url, false);
            setDisplayImage(url);
            axios
              .put(
                `https://asia-east2-digitalwish-sticker.cloudfunctions.net/promotion/${values.promotionID}`,
                data,
                {
                  headers: {
                    Authorization:
                      "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                  },
                }
              )
              .then((res) => {
                setFieldValue("fetch", true, false);
                setFieldValue("loading", false, false);
                setFieldValue("modalPromotion", "", false);

                setFieldValue(`${values.modalPromotion}Name`, "", false);
                setFieldValue(`${values.modalPromotion}Rating`, "", false);
                setFieldValue(`${values.modalPromotion}Promotion`, "", false);
                setFieldValue(`${values.modalPromotion}Description`, "", false);
                setFieldValue(`${values.modalPromotion}File`, "", false);
                setFieldValue(`${values.modalPromotion}Created_dt`, "", false);
                setDisplayImage("");
              })
              .catch(function (err) {
                console.log("err", err);
                setFieldValue("loading", false, false);
              });
          });
        });
    } else {
      console.log("B");
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/promotion/${values.promotionID}`,
          data,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("loading", false, false);
          setFieldValue("modalPromotion", "", false);

          setFieldValue(`${values.modalPromotion}Name`, "", false);
          setFieldValue(`${values.modalPromotion}Rating`, "", false);
          setFieldValue(`${values.modalPromotion}Promotion`, "", false);
          setFieldValue(`${values.modalPromotion}Description`, "", false);
          setFieldValue(`${values.modalPromotion}File`, "", false);
          setFieldValue(`${values.modalPromotion}Created_dt`, "", false);
          setDisplayImage("");
        })
        .catch(function (err) {
          console.log("err", err);
          setFieldValue("loading", false, false);
        });
    }
  };

  const removeOptionPromotion = () => {
    setFieldValue("loading", true, false);
    setDisplayImage("");
    axios
      .delete(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/promotion/${values.promotionID}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        setFieldValue("fetch", true, false);
        setFieldValue("loading", false, false);
        setFieldValue("modalPromotion", "", false);

        setFieldValue(`${values.modalPromotion}Name`, "", false);
        setFieldValue(`${values.modalPromotion}Rating`, "", false);
        setFieldValue(`${values.modalPromotion}Promotion`, "", false);
        setFieldValue(`${values.modalPromotion}Description`, "", false);
        setFieldValue(`${values.modalPromotion}File`, "", false);
        setFieldValue(`${values.modalPromotion}Created_dt`, "", false);
        setDisplayImage("");
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  };

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalPromotion ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalPromotion", "", false);
              setFieldValue("promotionID", "", false);
            }}
          >
            &times;
          </span>
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalPromotion}Code`}
            className={styles.text}
            placeholder="โค้ดส่วนลด"
          />
          *กรณีไม่ใส่ หรือ ความยาวน้อยกว่า 3 ตัวระบบจะ Random ให้ 5 หลัก
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalPromotion}DiscountPercent`}
            className={styles.text}
            placeholder="% ส่วนลด"
          />
          *1-100 กรณีไม่ใส่ระบบจะตั้ง 10% 
        </div>
        {/* <div>{values[`${values.modalPromotion}Created_dt`].toString()}</div> */}
        <div style={{color: "red"}}>{values.errorMessage}</div>
        <div className={`${styles.floatRight} ${styles.rowInModal}`}>
          {!values.promotionID ? null : (
            <button
              type="button"
              className={styles.removeOption}
              onClick={() => removeOptionPromotion()}
            >
              ลบ
            </button>
          )}
          <button
            type="button"
            className={styles.addOption}
            onClick={() =>
              !values.promotionID ? addOptionPromotion() : editOptionPromotion()
            }
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalPromotion;
