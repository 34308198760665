import React, { useEffect, useState } from "react";
import { withFormik, useFormikContext } from "formik";
import styles from "./index.module.scss";
import axios from "axios";

import AdminKpi from "../admin-kpi";
import ModalReference from "./modalReference.js";

//https://www.npmjs.com/package/@thaddeusjiang/react-sortable-list
import { SortableList, SortableItem } from '@thaddeusjiang/react-sortable-list';

import moment from "moment";
const formatDateTime = "DD-MM-YYYY HH:mm";
const FormatDateTime = (date) => {
  return moment(date).format(formatDateTime);
};

const AdminReferenceComponent = () => {
  const { values, setFieldValue } = useFormikContext();
  const [items, setItems] = useState([
    // { id: 1, img: 'Item 1' },
    // { id: 2, img: 'Item 2' },
    // { id: 3, img: 'Item 3' },
  ]);

  // console.log("items", items)

  // Fetch Reference
  useEffect(() => {
    setFieldValue("loading", true, false);
    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reference/ZwdyBLoBPhe7SqEjrK5B`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        console.log("references", res.data)
        setFieldValue("references", res.data.list, false);
        setItems(res.data.list);
        setFieldValue("fetch", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.fetch]);

  // Update
  const handleSave = () => {
    // console.log(">>>>", datelist[index].myID)
    setFieldValue("loading", true, false);
    let newItems = items;
    // Reorder id
    for(let i = 0; i < newItems.length; i++) {
      newItems[i].id = i+1;
    }
    const data = {
      list: newItems
    }
    console.log("REORDER", data)
    axios
      .put(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reference/ZwdyBLoBPhe7SqEjrK5B`,
        data,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // console.log("res", res);
        setFieldValue("loading", false, false);
        setFieldValue("fetch", true, false);
      })
      .catch((err) => {
        setFieldValue("loading", false, false);
        console.log("err", err);
      });
  };

  const handleAdd = (img) => {
    let newItems = items
    newItems.push({
      id: items.length + 1,
      img: img
    });
    return setItems(newItems);
  };

  const handleRemove = (id) => {
    const newItems = items.filter(object => {
      return object.id !== id;
    });
    return setItems(newItems);
  };

  const handleReset = () => {
    const newItems = values.references;
    return setItems(newItems);
  };

  return (
    <main className={styles.wrapContent}>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>
      <section className={styles.section1}>
        <AdminKpi kpi={{ order: 10, sales: 1234567, member: 1000 }} />
      </section>

      <h1 className={styles.title}>ตั้งค่าผลงาน</h1>

      <section
        className={styles.productOptions}
        style={{ justifyContent: "start" }}
      >

        <div style={{width: "100%"}}>
          <article className={styles.cardProductOption}>
            <h4>รายการผลงาน</h4>
            <div>
                <div>
                <button
                    type="button"
                    className={styles.btnOption}
                    onClick={() =>
                      setFieldValue("modalReference", "modalReferenceAdd", false)
                    }
                  >
                      เพิ่ม
                  </button>
                  <button
                    type="button"
                    className={styles.btnOption}
                    onClick={() =>
                      handleReset()
                    }
                  >
                      ล้างค่า
                  </button>
                  <button
                    type="button"
                    className={styles.btnOption}
                    onClick={() =>
                      handleSave()
                    }
                  >
                      บันทึก
                  </button>
                  <ModalReference values={values} handleAdd={handleAdd} setFieldValue={setFieldValue} />
                </div>
                <div className={styles.sort}>
                  <SortableList items={items} setItems={setItems} horizontal>
                    {({ items }) => (
                      <>
                        <div className={styles.sortWrapper}>
                        {items.map((item) => (
                          <>
                            <div className={styles.sortItem}>
                              <SortableItem key={item.id} id={item.id}>
                                <label>{item.id}</label>
                                <img src={item.img}/>
                              </SortableItem>
                              <label onClick={() => { if(window.confirm("คุณต้องการลบใช่หรือไม่")) handleRemove(item.id);}}>ลบ</label>
                            </div>
                          </>
                        ))}
                        </div>
                      </>
                    )}
                  </SortableList>
                </div>
            </div>
          </article>
        </div>
      </section>
    </main>
  );
};

const EnhancedAdminReferenceComponent = withFormik({
  mapPropsToValues: (props) => ({
    loading: false,
    references: [],
    modalAdd: "",
    fetch: false,
  }),
})(AdminReferenceComponent);

export default EnhancedAdminReferenceComponent;
