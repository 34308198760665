import React from "react";

import styled from "styled-components";

const Container = styled.div`
  border: solid 2px #666;
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3), 0 0 0 2px rgba(0, 0, 0, 0.2),
    0 5px 4px rgba(0, 0, 0, 0.4);
  height: 18px;
  width: 18px;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  color: black !important;
`;

export default function HighlightContainerDisplay(props) {
  return (
    <Container
      style={{
        top: `${props.annotation.geometry && props.annotation.geometry.y}%`,
        left: `${props.annotation.geometry && props.annotation.geometry.x}%`,
      }}
    >
      {props.annotation.data.marker}
    </Container>
  );
}
