import React, { useState } from "react";
import StepProgress from "../step_progress";
import StepProductConfig from "../step-product-config";
import styles from "./index.module.scss";
import { Form, ErrorMessage } from "formik";
import { useFormikContext } from "formik";
import parse from "html-react-parser";

const Order1CoatConfigComponent = (props) => {
  const [selectStep] = useState(1);
  const { values, setFieldValue } = useFormikContext();

  return (
    <main>
      <div
        class={`loader loader-default ${
          values.loadingCoating ? "is-active" : ""
        }`}
      />
      <section className={styles.section1}>
        <StepProgress stepIndex={selectStep} />
      </section>

      <div className={styles.wrapContent}>
        <StepProductConfig />

        <section className={styles.groupOptionProductConfig}>
          <Form style={{ width: "100%" }}>
            <h3 className={styles.titalPage}>
              เลือกการเคลือบหน้า + กาวหลัง
              <ErrorMessage
                name="coat"
                render={(msg) => <span className="error">{msg}</span>}
              />
            </h3>
            <div className={styles.gruop}>
              {
                // values.optionCoating.map((data, index) => {
                values.optionCoating
                  .slice() // Create a shallow copy to avoid mutating the original array
                  .filter((each) => each && each.name)
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort in ascending order by data.name
                  .map((data, index) => {
                    return (
                      <button type="submit" className={styles.nextButton}>
                        <article
                          className={`${styles.smallCardProductConfig} ${
                            values.coat === data.name && styles.active
                          }`}
                          onClick={() => {
                            // setFieldValue("index_2", index, true);
                            setFieldValue(
                              "fixed_cost",
                              data.price.fixed_cost,
                              true
                            );
                            setFieldValue(
                              "variable_cost_1",
                              data.price.variable_cost_1,
                              true
                            );
                            setFieldValue(
                              "variable_cost_2",
                              data.price.variable_cost_2,
                              true
                            );
                            setFieldValue("selectedCoating", data, false);
                            setFieldValue("coat", data.name, true);
                            setFieldValue("width", "", true);
                            setFieldValue("height", "", false);
                            setFieldValue("units", null, false);
                            setFieldValue("price", "", false);
                          }}
                        >
                          <img alt="." src={data.imgUrl} width="200" />
                          <div className={styles.gruopDetailProducts}>
                            <h4 className={styles.headDetail}>{data.name}</h4>
                            <p>{parse(data.description)}</p>
                          </div>

                          {/* <img alt="." src={data.imgUrl} width="200" />
                      <div className={styles.gruopDetailProducts}>
                        <p className={styles.headDetail}>{data.name}</p>
                        <p>{parse(data.description)}</p>
                      </div> */}
                        </article>
                      </button>
                    );
                  })
              }
            </div>
          </Form>
        </section>
      </div>
    </main>
  );
};

export default Order1CoatConfigComponent;
