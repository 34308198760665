import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import axios from "axios";
import { auth } from "../../firebase/index";

import MessageImage from "./checkbox.png";

import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
const tagManagerArgs = {
  gtmId: "GTM-NLZDCX7",
};

TagManager.initialize(tagManagerArgs);

const Order1ProductConfigComponent = (props) => {
  useEffect(() => {
    ReactGA.pageview("/e-sticker-frontend/successful");
  }, []);
  const [orderIDLast, setOrderIDLast] = useState("");
  const [orderValue, setOrderValue] = useState(0);
  const [orderPhoneLast, setOrderPhoneLast] = useState("");
  const [orderEmail, setOrderEmail] = useState("");
  
  const [myID, setMyID] = useState("");

  useEffect(() => {
    var orderIDLast = localStorage.getItem("orderIDLast");
    setOrderIDLast(orderIDLast);
    var orderValueLocal = localStorage.getItem("orderValue");
    setOrderValue(orderValueLocal);
    var orderEmailLocal = localStorage.getItem("orderEmail");
    setOrderEmail(orderEmailLocal);

    TagManager.dataLayer({
      ecommerce: {
        purchase: {
          actionField: {
            id: orderIDLast,
            revenue: orderValueLocal,
          },
        },
      },
    });
    var orderPhoneLast = localStorage.getItem("orderPhoneLast");
    setOrderPhoneLast(orderPhoneLast);
    localStorage.removeItem("cart");
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        axios
          .get(
            `https://asia-east2-digitalwish-sticker.cloudfunctions.net/orders?customerID=${user.uid}`,
            {
              headers: {
                Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
              },
            }
          )
          .then((res) => {
            const OrderMatch = res.data.find((ele) => {
              return ele.orderID === localStorage.getItem("orderIDLast");
            });
            console.log("OrderMatch", OrderMatch);
            if (OrderMatch) {
              setMyID(OrderMatch.myID);
            }
          })
          .catch(function (err) {
            console.log("err", err);
          });
      }
    });
  }, []);

  return (
    <main>
      <div className={styles.centerImg}>
        <h2>หมายเลขออเดอร์ของคุณคือ <label style={{color: "red"}}>{orderIDLast}</label></h2>
        {/* <h2>เบอร์โทรศัพท์ของคุณคือ {orderPhoneLast}</h2> */}
        <p style={{ marginTop: "15px" }}>
          ยอดสั่งซื้อ {parseFloat(orderValue).toFixed(2)} บาท
        </p>
        <p style={{ marginTop: "15px" }}>
          กรุณาบันทึกเลข Order สำหรับติดตามสถานะ
        </p>
        <p style={{ marginTop: "15px" }}>
          ทางบริษัทได้ทำการจัดส่งเลขออเดอร์ของคุณไปยัง{" "}{orderEmail}{" "}ตามข้อมูลเบื่องต้นที่คุณให้มาเรียบร้อยแล้วค่ะ
        </p>
        <img alt="." src={MessageImage} width="300" />
        <button
          className={styles.btnToMyorder}
          onClick={() => {
            props.history.push(`/myorder?orderId=${orderIDLast}`);
          }}
        >
          ดูคำสั่งซื้อและชำระเงิน คลิกที่นี่ค่ะ
        </button>
      </div>
    </main>
  );
};

export default Order1ProductConfigComponent;
