import React, { useState, useEffect } from "react";
import StepProgress from "../step_progress";
import StepProductConfig from "../step-product-config";
import styles from "./index.module.scss";
import { Field, Form, ErrorMessage } from "formik";
import { useFormikContext } from "formik";
import { ReactComponent as IconUploadFile } from "./icon-upload-file.svg";
import { ReactComponent as IconCart } from "./icon-cart.svg";
import CheckIcon from "./check.png";
import Placeholder from "./gallery.png";
import PlaceholderAI from "./ai.png";
import PlaceholderPDF from "./pdf.png";

const Order1AmountConfigComponent = (props) => {
  const [selectStep] = useState(2);
  const { values, setFieldValue } = useFormikContext();
  const [previewImage, setPreviewImage] = useState();
  const [previewImageType, setPriviewImageType] = useState();
  const [loadingImg, setLoadingImg] = useState(false);

  const handleChange = (event) => {
    setLoadingImg(true);
    setPreviewImage(undefined);
    setPriviewImageType(undefined);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log("file", file.name);
      const fileType = file.type;
      if (
        [
          "image/png",
          "image/jpg",
          "image/jpeg",
          "image/gif",
          "application/pdf",
          "application/postscript",
        ].includes(fileType)
      ) {
        setPreviewImage(URL.createObjectURL(file));
        setPriviewImageType(fileType);
        setFieldValue("uploadFileStrickerForFirebase", file, false);
        setFieldValue("uploadFileStricker", URL.createObjectURL(file), true);
        setFieldValue("isCheckUploadFileStricker", true, false);
      } else {
        setFieldValue("isCheckUploadFileStricker", false, false);
      }
    } else {
      setFieldValue("isCheckUploadFileStricker", false, false);
    }
    setTimeout(() => {
      setLoadingImg(false);
    }, 2000);
  };

  console.log("previewImageType", previewImageType);

  return (
    <main>
      <section className={styles.section1}>
        <StepProgress stepIndex={selectStep} />
      </section>

      <div className={styles.wrapContent}>
        <StepProductConfig />

        <section className={styles.groupOptionProductConfig}>
          <Form style={{ width: "100%" }}>
            <h3 className={styles.titalPage} style={{ marginTop: "10px" }}>
              อัพโหลดไฟล์งาน
              <ErrorMessage
                name="uploadFileStricker"
                render={(msg) => (
                  <span className="error" style={{ fontSize: "1rem" }}>
                    {msg}
                  </span>
                )}
              />
            </h3>
            <div className={styles.box}>
              {loadingImg && (
                <>
                  <div className={styles.progress}>
                    <div
                      role="progressbar progress-striped"
                      style={{ width: "100%", height: "100%" }}
                      className={styles.progress_bar}
                    ></div>
                  </div>
                </>
              )}
              {!loadingImg && previewImageType && previewImage && (
                <div>
                  <label>
                    {previewImageType === "application/postscript" && (
                      <img
                        // src={dataObjectMapped.messages[0].content}
                        src={PlaceholderAI}
                        className={styles.productPreview}
                        alt="Product"
                      />
                    )}
                    {previewImageType === "application/pdf" && (
                      <img
                        // src={dataObjectMapped.messages[0].content}
                        src={PlaceholderPDF}
                        className={styles.productPreview}
                        alt="Product"
                      />
                    )}
                    {[
                      "image/png",
                      "image/jpg",
                      "image/jpeg",
                      "image/gif",
                    ].includes(previewImageType) && (
                      <img
                        // src={dataObjectMapped.messages[0].content}
                        src={previewImage}
                        className={styles.productPreview}
                        alt="Product"
                      />
                    )}
                    {![
                      "image/png",
                      "image/jpg",
                      "image/jpeg",
                      "image/gif",
                      "application/pdf",
                      "application/postscript",
                    ].includes(previewImageType) && (
                      <img
                        // src={dataObjectMapped.messages[0].content}
                        src={Placeholder}
                        className={styles.productPreview}
                        alt="Product"
                      />
                    )}
                    <div>
                      {
                        <a href={values.uploadFileStricker} target="_tab">
                          {values.uploadFileStrickerForFirebase.name}
                        </a>
                      }
                    </div>
                  </label>
                </div>
              )}
              <div className={styles.flexMiddle}>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => handleChange(e)}
                />
                <label
                  for="file"
                  className={`${styles.buttonUploadFile} ${styles.label}`}
                  style={{ fontSize: "1rem" }}
                >
                  <IconUploadFile />
                  อัพโหลดไฟล์
                </label>
              </div>
            </div>
            {!previewImageType && (
              <div style={{ marginBottom: "10px" }}>
                (ai, jpg, jpeg, png, pdf) *อัพโหลดไฟล์ AI,PDF
                จะได้ความละเอียดภาพสูงกว่า JPG และ PNG
              </div>
            )}
            {values.isCheckUploadFileStricker !== 0 ? (
              values.isCheckUploadFileStricker ? (
                <span
                  className={styles.statusUploadFile}
                  style={{
                    color: "#009473",
                    marginLeft: "0",
                    marginTop: "20px",
                    fontSize: "20px",
                    display: "block",
                  }}
                >
                  <img
                    // src={dataObjectMapped.messages[0].content}
                    src={CheckIcon}
                    className={styles.productPreview}
                    alt="Product"
                    height={20}
                  />
                  <span style={{ marginLeft: "5px" }}>อัพโหลดไฟล์สำเร็จ</span>
                </span>
              ) : (
                <span
                  className={styles.statusUploadFile}
                  style={{
                    color: "red",
                    marginLeft: "0",
                    marginTop: "20px",
                    display: "block",
                  }}
                >
                  *กรุณาอัพโหลดไฟล์ ai, jpg, jpeg, png, pdf
                </span>
              )
            ) : (
              ""
            )}
            <h3 className={styles.titalPage} style={{ marginTop: "20px" }}>
              เพิ่มเติม
            </h3>
            <Field
              name="comment"
              as="textarea"
              className={styles.textArea}
              rows="6"
            />
            <button type="submit" className={styles.btnCart}>
              <IconCart />
              <b>ถัดไป</b>
            </button>
          </Form>
        </section>
      </div>
    </main>
  );
};

export default Order1AmountConfigComponent;
