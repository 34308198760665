import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Field } from "formik";
import axios from "axios";
import firebaseApp from "../../firebase/index.js";

const ModalReview = ({ values, setFieldValue }) => {
  // console.log("values", values)
  const [displayImage, setDisplayImage] = useState("");
  useEffect(() => {
    let reviewsOptioned = values.reviews && values.reviews.find(
      (reviews) => `${reviews.myID}` === `${values.reviewID}`
    );
    if (reviewsOptioned && values.reviewID) {
      setFieldValue(
        `${values.modalReview}Name`,
        reviewsOptioned.name,
        false
      );
      setFieldValue(
        `${values.modalReview}Rating`,
        reviewsOptioned.rating,
        false
      );
      setFieldValue(
        `${values.modalReview}Review`,
        reviewsOptioned.review,
        false
      );
      setFieldValue(
        `${values.modalReview}File`,
        reviewsOptioned.img,
        false
      );
      setFieldValue(
        `${values.modalReview}Description`,
        reviewsOptioned.description,
        false
      );
      setFieldValue(
        `${values.modalReview}Created_dt`,
        reviewsOptioned.created_dt,
        false
      );
      setDisplayImage(reviewsOptioned.img);
    } else {
      setFieldValue(`${values.modalReview}Name`, "", false);
      setFieldValue(`${values.modalReview}Rating`, "", false);
      setFieldValue(`${values.modalReview}Review`, "", false);
      setFieldValue(`${values.modalReview}Description`, "", false);
      setFieldValue(`${values.modalReview}File`, "", false);
      setFieldValue(`${values.modalReview}Created_dt`, "", false);
      setDisplayImage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.reviewID]);

  const addOptionReview = () => {
    setDisplayImage("");
    setFieldValue("loading", true, false);
    let data = {
      name: values[`${values.modalReview}Name`],
      rating: values[`${values.modalReview}Rating`],
      review: values[`${values.modalReview}Review`],
      description: values[`${values.modalReview}Description`],
      img: values[`${values.modalReview}File`],
      created_dt: new Date(),
    };
    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    if (!parseInt(values.modalReviewAddRating) || parseInt(values.modalReviewAddRating) < 0 || parseInt(values.modalReviewAddRating) > 5) {
      data.rating = 5;
      setFieldValue("modalReviewAddRating", 5, false);
    }

    if (values[`${values.modalReview}File`]) {
      console.log("AAA")
      storageRef
        .child(
          `reviews/${timeStamp}-${
            values[`${values.modalReview}File`].name
          }`
        )
        .put(values[`${values.modalReview}File`])
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            console.log(url);
            data.img = url;
            axios
              .post(
                `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reviews`,
                data,
                {
                  headers: {
                    Authorization:
                      "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                  },
                }
              )
              .then((res) => {
                setFieldValue("fetch", true, false);
                setFieldValue("modalReview", "", false);
                setFieldValue("loading", false, false);

                setFieldValue(`${values.modalReview}Name`, "", false);
                setFieldValue(`${values.modalReview}Rating`, "", false);
                setFieldValue(`${values.modalReview}Review`, "", false);
                setFieldValue(`${values.modalReview}Description`, "", false);
                setFieldValue(`${values.modalReview}File`, "", false);
                setFieldValue(`${values.modalReview}Created_dt`, "", false);
                setDisplayImage("");
              })
              .catch(function (err) {
                console.log("err", err);
                setFieldValue("loading", false, false);
              });
          });
        });
    } else {
      console.log("BBB")
      axios
        .post(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reviews`,
          data,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("modalReview", "", false);
          setFieldValue("loading", false, false);

          setFieldValue(`${values.modalReview}Name`, "", false);
          setFieldValue(`${values.modalReview}Rating`, "", false);
          setFieldValue(`${values.modalReview}Review`, "", false);
          setFieldValue(`${values.modalReview}Description`, "", false);
          setFieldValue(`${values.modalReview}File`, "", false);
          setFieldValue(`${values.modalReview}Created_dt`, "", false);
          setDisplayImage("");
        })
        .catch(function (err) {
          console.log("err", err);
          setFieldValue("loading", false, false);
        });
    }
  };

  const editOptionReview = () => {
    setDisplayImage("");
    setFieldValue("loading", true, false);
    let data = {
      name: values[`${values.modalReview}Name`],
      rating: values[`${values.modalReview}Rating`],
      review: values[`${values.modalReview}Review`],
      description: values[`${values.modalReview}Description`],
      img: values[`${values.modalReview}File`],
      // created_dt: new Date(),
    };
    // console.log(data);

    const storageRef = firebaseApp.storage().ref();
    let timeStamp = new Date().toISOString().slice(0, 10);

    // console.log("VALUE", values);
    // console.log("TEST", parseInt(values.modalReviewEditDuration));
    // console.log("TYPE", values.modalReviewEditFile.type);
    if (!parseInt(values.modalReviewEditRating) || parseInt(values.modalReviewEditRating) < 0 || parseInt(values.modalReviewEditRating) > 5) {
      data.rating = 5;
      setFieldValue("modalReviewEditRating", 5, false);
    }

    if (values[`${values.modalReview}File`]) {
      // if (values[`${values.modalReview}File`]) {
      // console.log("A");
      storageRef
        .child(
          `reviews/${timeStamp}-${
            values[`${values.modalReview}File`].name
          }`
        )
        .put(values[`${values.modalReview}File`])
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((url) => {
            console.log(url);
            data.img = url;
            setFieldValue("modalReviewEditFile", url, false);
            setDisplayImage(url);
            axios
              .put(
                `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reviews/${values.reviewID}`,
                data,
                {
                  headers: {
                    Authorization:
                      "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
                  },
                }
              )
              .then((res) => {
                setFieldValue("fetch", true, false);
                setFieldValue("loading", false, false);
                setFieldValue("modalReview", "", false);

                setFieldValue(`${values.modalReview}Name`, "", false);
                setFieldValue(`${values.modalReview}Rating`, "", false);
                setFieldValue(`${values.modalReview}Review`, "", false);
                setFieldValue(`${values.modalReview}Description`, "", false);
                setFieldValue(`${values.modalReview}File`, "", false);
                setFieldValue(`${values.modalReview}Created_dt`, "", false);
                setDisplayImage("");
              })
              .catch(function (err) {
                console.log("err", err);
                setFieldValue("loading", false, false);
              });
          });
        });
    } else {
      // console.log("B");
      axios
        .put(
          `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reviews/${values.reviewID}`,
          data,
          {
            headers: {
              Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
            },
          }
        )
        .then((res) => {
          setFieldValue("fetch", true, false);
          setFieldValue("loading", false, false);
          setFieldValue("modalReview", "", false);

          setFieldValue(`${values.modalReview}Name`, "", false);
          setFieldValue(`${values.modalReview}Rating`, "", false);
          setFieldValue(`${values.modalReview}Review`, "", false);
          setFieldValue(`${values.modalReview}Description`, "", false);
          setFieldValue(`${values.modalReview}File`, "", false);
          setFieldValue(`${values.modalReview}Created_dt`, "", false);
          setDisplayImage("");
        })
        .catch(function (err) {
          console.log("err", err);
          setFieldValue("loading", false, false);
        });
    }
  };

  const removeOptionReview = () => {
    setFieldValue("loading", true, false);
    setDisplayImage("");
    axios
      .delete(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/reviews/${values.reviewID}`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        setFieldValue("fetch", true, false);
        setFieldValue("loading", false, false);
        setFieldValue("modalReview", "", false);

        setFieldValue(`${values.modalReview}Name`, "", false);
        setFieldValue(`${values.modalReview}Rating`, "", false);
        setFieldValue(`${values.modalReview}Review`, "", false);
        setFieldValue(`${values.modalReview}Description`, "", false);
        setFieldValue(`${values.modalReview}File`, "", false);
        setFieldValue(`${values.modalReview}Created_dt`, "", false);
        setDisplayImage("");
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  };

  const handleChangeImgReview = (event) => {
    console.log("event", event.target.files);
    if (event.target.files) {
      setFieldValue(
        `${values.modalReview}File`,
        event.target.files[0],
        false
      );
    }
  };

  return (
    <div
      className={styles.modal}
      style={{ display: values.modalReview ? "block" : "none" }}
    >
      <div className={styles.modalContent}>
        <div>
          <span
            className={styles.close}
            onClick={() => {
              setFieldValue("modalReview", "", false);
              setFieldValue("reviewID", "", false);
            }}
          >
            &times;
          </span>
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalReview}Name`}
            className={styles.text}
            placeholder="ชื่อผู้รีวิว"
          />
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalReview}Rating`}
            className={styles.text}
            placeholder="คะแนน"
          />
          *ใส่เลข 1-5 เท่านั้น
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalReview}Review`}
            className={styles.text}
            placeholder="รีวิว"
          />
        </div>
        <div className={styles.rowInModal}>
          <Field
            name={`${values.modalReview}Description`}
            className={styles.text}
            placeholder="คำอธิบาย"
          />
        </div>
        <div className={styles.rowInModal}>
        <input
            type="file"
            id="file99"
            onChange={(e) => handleChangeImgReview(e)}
          />
          <label
            for="file99"
            className={`${styles.buttonUploadFile} ${styles.label}`}
          >
            อัพโหลดไฟล์
          </label>
        </div>
        <div className={styles.displayImage}>
          {displayImage && <img src={displayImage} />}
        </div>
        {/* <div>{values[`${values.modalReview}Created_dt`].toString()}</div> */}
        <div className={`${styles.floatRight} ${styles.rowInModal}`}>
          {!values.reviewID ? null : (
            <button
              type="button"
              className={styles.removeOption}
              onClick={() => removeOptionReview()}
            >
              ลบ
            </button>
          )}
          <button
            type="button"
            className={styles.addOption}
            onClick={() =>
              !values.reviewID ? addOptionReview() : editOptionReview()
            }
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalReview;
