import React, { useEffect, useState } from "react";
import { withFormik, useFormikContext } from "formik";
import styles from "./index.module.scss";
import axios from "axios";

import AdminKpi from "../admin-kpi";
import ModalShipping from "./modalShipping.js";
import ModalQuality from "./modalQuality.js";
import ModalShape from "./modalShape.js";
import ModalMaterial from "./modalMaterial.js";
import ModalCoat from "./modalCoat.js";
import ModalVariable from "./modalVariable.js";
import ModalReview from "./modalReview.js";
import ModalReviewStat from "./modalReviewStat.js";
import firebaseApp from "../../firebase/index.js";

import StarRating from "../star";
import Placeholder from "./placeholder-person.jpeg";

import moment from "moment";
const formatDateTime = "DD/MM/YYYY";
const FormatDateTime = (date) => {
  return moment(date).format(formatDateTime);
};

const AdminDeliveryComponent = () => {
  const { values, setFieldValue } = useFormikContext();
  const [pricingType, setPricingType] = useState();
  // Fetch Optiom
  useEffect(() => {
    setFieldValue("loading", true, false);
    // Shipping option
    axios
      .get(
        `https://asia-east2-digitalwish-sticker.cloudfunctions.net/shippingOptions`,
        {
          headers: {
            Authorization: "Basic ZGlnaXRhbHdpc2g6SzZDd2N3dkF6QVNDRGZWNg==",
          },
        }
      )
      .then((res) => {
        // console.log("res.data[0]", res.data)
        setFieldValue("shippingOption", res.data, false);
        setFieldValue("fetch", false, false);
        setFieldValue("loading", false, false);
      })
      .catch(function (err) {
        console.log("err", err);
        setFieldValue("loading", false, false);
      });
  }, [values.fetch]);

  // Seclect Coating form materail
  useEffect(() => {
    if (values.materialSelected || values.materialSelected === 0) {
      setFieldValue(
        "coating",
        values.material[values.materialSelected].coating_list,
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.materialSelected]);

  const handleChangePDF = (event) => {
    console.log("event", event);
    const storageRef = firebaseApp.storage().ref();
    if (event.target.files) {
      storageRef
        .child(`pdf`)
        .put(event.target.files[0])
        .then((snapshot) => {
          console.log("snapshot", snapshot);
          snapshot.ref.getDownloadURL().then((url) => {
            setFieldValue(`PDF`, url, false);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <main className={styles.wrapContent}>
      <div
        class={`loader loader-default ${values.loading ? "is-active" : ""}`}
      ></div>
      <section className={styles.section1}>
        <AdminKpi kpi={{ order: 10, sales: 1234567, member: 1000 }} />
      </section>

      <h1 className={styles.title}>รายการจัดส่ง</h1>

      <section
        className={styles.productOptions}
        style={{ justifyContent: "start" }}
      >
        <article className={styles.cardProductOption}>
          <h4>รูปแบบการจัดส่ง</h4>
          <div>
            <button
              type="button"
              className={styles.btnOption}
              onClick={() =>
                setFieldValue("modalShipping", "modalShippingAdd", false)
              }
            >
              เพิ่ม
            </button>
            <ModalShipping values={values} setFieldValue={setFieldValue} />
          </div>
          <div>
            {values.shippingOption.map((shipping) => {
              return (
                <button
                  type="button"
                  className={styles.btnListOption}
                  onClick={() => {
                    setFieldValue("modalShipping", "modalShippingEdit", false);
                    setFieldValue("shippingID", shipping.myID, false);
                  }}
                >
                  {shipping.courier} {shipping.duration} วัน {shipping.rate}฿
                </button>
              );
            })}
          </div>
        </article>
      </section>

      <h1 className={styles.title}>เงื่อนไข</h1>

      <section
        className={styles.productOptions}
        style={{ justifyContent: "start" }}
      >
        <article className={styles.cardProductOption}>
          <h4>เอกสารเงื่อนไข</h4>
          <input
            type="file"
            id="pdf"
            onChange={(e) => {
              handleChangePDF(e);
            }}
          />
          <label
            for="pdf"
            className={`${styles.buttonUploadFile} ${styles.label}`}
            style={{
              display: "flex",
              maxWidth: "204px",
              justifyContent: "center",
            }}
          >
            อัพโหลดไฟล์
          </label>
          <a
            className={styles.btnListOption}
            href="https://firebasestorage.googleapis.com/v0/b/digitalwish-sticker.appspot.com/o/pdf?alt=media&token=e5de51a0-3f36-4507-8edf-71828552cde4"
            style={{
              display: "flex",
              maxWidth: "204px",
              justifyContent: "center",
            }}
          >
            ดูเอกสาร
          </a>
        </article>
      </section>
    </main>
  );
};

const EnhancedAdminDeliveryComponent = withFormik({
  mapPropsToValues: (props) => ({
    loading: false,
    shape: [],

    material: [],

    coating: [],

    cuttingList: [],

    unitOptions: [],

    shippingOption: [],

    modalAdd: "",
    fetch: false,
  }),
})(AdminDeliveryComponent);

export default EnhancedAdminDeliveryComponent;
